<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="契约共建"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="more-building-body-container">
      <!-- <van-tabs @click="handleTab" v-model="selectedTab" color="#4479ED">
        <van-tab v-for="item in buildingTypeList" :key="item.dictLabel" :title="item.dictLabel" :name="item.dictValue"> -->
          <buildingCard v-for="(item,index) in dataList" :key="item.name" :data="item" :index="index + 1" :showFlag="true"></buildingCard>
        <!-- </van-tab>
      </van-tabs> -->
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
import BuildingCard from './buildingCard.vue';
import data from 'assets/json/building.js';
export default {
  data() {
    return {
      dataList: [],
      selectedTab: '',
      buildingTypeList: [],
    };
  },
  computed: {
  },
  components: { BuildingCard },
  mounted() {
    this.getBuildingType();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab(value) {
      this.selectedTab = value;
      this.getBuildingList();
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getBuildingType() {
      api.getOrganizationJoint({}).then(res => {
        this.buildingTypeList = res.data;
        this.selectedTab = res.data[0].dictValue;
        this.getBuildingList();
      })
    },
    getBuildingList({ page = this.page, size = this.size } = {}) {
      api.getJointList({
        // jointType: '',
        page: 1,
        pageSize: 100,
        // name: '',
      }).then(res => {
        this.dataList = res.data.records;
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .more-building-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;


  }
}
</style>
