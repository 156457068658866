<template>
  <div class="article-detail-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="article-detail-body-container" v-html="content">
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      content: '',
      data: {}
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    this.getArticleDetail({ id });
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getArticleDetail(params) {
      api.getResouArticleDetail(params).then(res => {
        this.content = res.data.content;
        this.data = res.data;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.article-detail-container {
  height: 100vh;
  width: 100vw;
  background-color: #FFF;
  
  .article-detail-body-container {
    height: calc(100vh - 46px);
    width: calc(100vw - 32px);
    margin: 16px;
    // background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    // background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 14px;
    line-height: 20px;

  }
}
</style>
