<template>
  <div class="building-container">
    <van-nav-bar
      class="building-nav-bar"
      title="邻·共建"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="building-body-container">
      <div class="image-bar"></div>
      <!-- 卡片 -->
      <div class="card-menu-list">
        <div
          :class="item.class"
          v-for="(item, index) in cardList"
          :key="`party_${index}`"
        >
          <img :src="item.icon" class="yjy-icon" alt="预约挂号" />
          <div class="text">
            <h3>{{ item.num }}</h3>
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <!-- 按钮栏 -->
      <div class="icon-bar-con">
        <h1>资源共享</h1>
        <div class="icon-bar">
          <div
            v-for="(icon, index) in iconList"
            @click="gotoLink(icon.path)"
            :key="`${icon.name}_${index}`"
          >
            <img class="icon" :src="icon.icon" :alt="icon.name" />
            <p>{{ icon.name }}</p>
          </div>
        </div>
      </div>

      <!-- 两地报到 -->
      <div class="report-bar" @click="handleLaunch">
        <span class="report-title">两地报到 双岗服务</span>
        <wx-open-launch-weapp
          id="launch-btn"
          username="gh_7c2a52b94bda"
          path="/pages/activity/activity.html"
          @ready="onReady"
          @launch="onLaunch"
          @error="onError"
        >
          <script type="text/wxtag-template">
            <span style="color: #2a93ed; font-size: 16px; display: flex; align-items: center;">
              前往 >
              <!-- <van-icon name="arrow" color="#228feb" size="16px" /> -->
            </span>
          </script>
        </wx-open-launch-weapp>
      </div>
      <!-- @click="gotoLink('bothLocationReport')" -->
      <!-- 契约共建 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">契约共建</span>
          </div>
          <!-- <span class="text-title">组织共建</span> -->
          <div @click="gotoLink('moreBuilding')">
            <span class="more">更多</span>
            <img
              src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png"
              alt=""
            />
          </div>
        </div>
        <div class="card-bar-content">
          <!-- <van-tabs @click="handleTab" v-model="selectedTab" color="#4479ED">
            <van-tab v-for="item in buildingTypeList" :key="item.dictLabel" :title="item.dictLabel" :name="item.dictValue"> -->
              <div class="svg-title">
                <svg-icon icon-class="building" />
                {{`${currentMonth}月轮值主席单位`}}
              </div>
              <buildingCard
                :key="buildingType.name"
                :data="buildingType"
              ></buildingCard>
            <!-- </van-tab>
          </van-tabs> -->
        </div>
      </div>
      <!-- 共建活动 -->
      <div class="coin-bar-con">
        <div class="coin-bar activity" @click="gotoLink('coBuildingActivity')">
          <h1>共建活动</h1>
          <!-- <h3>板块简介</h3> -->
        </div>
        <div class="coin-bar map" @click="gotoLink('buildingMap')">
          <h1>共建地图</h1>
          <!-- <h3>板块简介</h3> -->
        </div>
      </div>
      <!-- 东盛共建岗 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">东盛共建岗</span>
          </div>
          <!-- <span class="text-title">东盛共建岗</span> -->
        </div>
        <div class="card-bar-content">
          <van-tabs @click="handleTab1" color="#4479ED">
            <van-tab v-for="item in departTypeList" :key="item.dictLabel" :title="item.dictLabel" :name="item.dictValue">
              <departList :type="item.dictValue"></departList>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "api";
import { mapMutations, mapState } from "vuex";
import DepartList from "./departList.vue";
import BuildingCard from "./buildingCard.vue";
import data from "assets/json/building.js";
import { init } from "utils/common";
export default {
  data() {
    return {
      iconList: [
        {
          name: "国际社区",
          icon:
            "http://dssq.ikeqiao.net/data/image/h5/images/building/international.png",
          path: "wysl",
        },
        {
          name: "社区小匠人",
          icon:
            "http://dssq.ikeqiao.net/data/image/h5/images/building/talent.png",
          path: "wiseMan",
        },
        {
          name: "共享场地",
          icon:
            "http://dssq.ikeqiao.net/data/image/h5/images/building/ground.png",
          path: "sharedSites",
        },
      ],
      cardList: [
        {
          name: "契约共建单位数",
          num: 30,
          icon:
            "http://dssq.ikeqiao.net/data/image/h5/images/building/qiyuegongjian-icon.png",
          class: "qiyuegongjian",
        },
        {
          name: "契约共建达人数",
          num: 231,
          icon:
            "http://dssq.ikeqiao.net/data/image/h5/images/building/jinguo-icon.png",
          class: "jinguo",
        },
      ],
      selectedTab: "",
      dataList: [],
      buildingTypeList: [], // 契约共建类型
      departTypeList: [], // 东盛共建岗类型
      currentMonth: new Date().getMonth() + 1, // 当前月份
      buildingType: {
        contactsUser: "",
        cover: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/gjdw.jpg",
        createTime: "2021-10-08 17:02:39",
        description: "负责供电营业区内的电力供应，满足广大客户经济建设和生活用电的需要。",
        grade: 88,
        id: 9,
        jointType: 2,
        jointTypeText: "机关单位",
        name: "国家电网绍兴供电公司诸暨供电分公司",
        operationTime: "每天8:50-16:30",
        phone: "0575-88393438",
        place: "浙江省绍兴市诸暨市金诸暨大道517号",
        updateTime: "2021-10-08 17:02:39",
      }, // 当月轮值主席单位
    };
  },
  created() {
    init();
  },
  computed: {},
  components: { DepartList, BuildingCard },
  mounted() {
    // this.dataList = data
    //   .filter((item) => item.type === this.selectedTab)
    //   .splice(0, 1);
    this.getBuildingType();
    this.getDepartType();
  },
  watch: {},
  methods: {
    onLaunch(e) {
      // this.$toast('launched');
      console.log("launched");
    },
    onReady(e) {
      console.log("onReady");
    },
    onError(e) {
      // this.$toast(`fail:${e.detail}`);
      console.log("fail", e.detail);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab(value) {
      console.log('value', value);
      
      this.selectedTab = value;
      this.getBuildingList();
    },
    handleTab1() {},
    getBuildingList() {
      api.getJointList({
        jointType: this.selectedTab,
        page: 1,
        pageSize: 1,
        name: '',
      }).then(res => {
        this.dataList = res.data.records;
      })
    },
    getBuildingType() {
      api.getOrganizationJoint({}).then(res => {
        this.buildingTypeList = res.data;
        this.selectedTab = res.data[0].dictValue;
        this.getBuildingList();
      })
    },
    getDepartType() {
      api.getCommunityType({}).then(res => {
        this.departTypeList = res.data;
      })
    },
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
    handleLaunch() {
      const btn = document.getElementById("launch-btn");
      console.log("btn", btn);
      btn.launch();
    },
  },
};
</script>

<style lang="scss" scoped>
.building-container {
  height: 100vh;
  width: 100vw;
  .building-nav-bar {
    background-image: linear-gradient(
      to left,
      rgb(52, 208, 247),
      rgb(149, 244, 212),
      rgb(9, 220, 188)
    );
    ::v-deep .van-nav-bar__title {
      color: white;
    }
    ::v-deep .van-icon {
      color: white;
    }
  }
  .building-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png")
      no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 176px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/building-bar.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .card-menu-list {
      width: 100%;
      background-color: #fff;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      > div {
        position: relative;
        width: 47%;
        height: 80px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .text {
          > h3 {
            margin-top: 16px;
            font-size: 20px;
            color: rgba(0, 0, 0, 0.85);
          }
          > p {
            line-height: 24px;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.65);
          }
        }
        > img {
          height: 4rem;
          // position: absolute;
          // bottom: 0.8rem;
          // left: 0;
        }
      }
      .jinguo {
        background: #fff1ee;
      }
      .qiyuegongjian {
        background: #e6f4ff;
      }
    }

    .icon-bar-con {
      margin-bottom: 10px;
      background: #fff;
      padding: 16px 10px;
      > h1 {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 500;
      }
      .icon-bar {
        display: flex;
        align-items: center;
        justify-content: space-around;
        > div {
          width: 20%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 15px;
          .icon {
            width: 47px;
            height: 47px;
            margin-bottom: 5px;
          }
          > p {
            text-align: center;
            font-size: 10px;
            font-weight: 400;
          }
        }
      }
    }

    .report-bar {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 0 10px;
      height: 42px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/report-bg.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .report-title {
        font-size: 18px;
        color: #fff;
      }
      .report-arrow {
        color: #2a93ed;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }

    .coin-bar-con {
      display: flex;
      .coin-bar {
        width: 100%;
        margin: 10px;
        height: 100px;
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/coin-bg.png")
          no-repeat;
        background-size: 100% 100%;
        padding: 10px;
        color: #fff;
        > h1 {
          font-size: 18px;
          line-height: 32px;
        }
        > h3 {
          font-size: 12px;
        }
      }

      .map {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/map-bg.png")
          no-repeat;
        background-size: 100% 100%;
        // margin-left: 0;
      }
      .activity {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/activity-bg.png")
          no-repeat;
        background-size: 100% 100%;
        // margin-left: 0;
      }
    }

    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      .card-bar-title {
        background-color: #fff;
        width: 100%;
        // height: 2rem;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-bar {
            height: 18px;
            width: 4px;
            background: #4479ed;
            margin-right: 10px;
          }
          .title-text {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
          }
          .more {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
          i {
            color: #4479ed;
          }
        }
      }
      .card-bar-content {
        width: 100%;
        .svg-title {
          background: #FFF;
          padding: 5px 12px;
          font-size: 14px;
          >svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
