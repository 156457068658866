<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>反馈评价</h1>
      </div>
      <div class="form-bar">
        <div class="title-bar">
          <span>活动名称</span>
          <span>{{title}}</span>
        </div>
        <van-form @submit="onSubmit">
          <div class="section" style="padding: 0.8rem">
            <div class="title">反馈评价</div>
            <van-field
              name="content"
              size="large"
              v-model="description"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              :rules="[{ required: true, message: '请输入反馈内容' }]"
              placeholder="请输入反馈内容"
              show-word-limit
            />
          </div>
          <div class="section" style="padding: 0.8rem">
            <div class="title">活动照片</div>
            <van-uploader
              v-model="fileList"
              name="imageList"
              multiple
              :max-count="5"
              :max-size="1024 * 1024 * 2"
              @oversize="onOversize"
              :rules="[{ required: true, message: '请选择活动照片' }]"
            />
          </div>
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import yqpddApi from "api/yqpdd";
import { Field } from "mint-ui";
import { Toast } from 'vant';
export default {
  data() {
    return {
      orderId: null,
      title: "",
      description: "",
      fileList: [],
    };
  },
  components: {},
  mounted() {
    const { key, name } = this.$route.params;
    this.title = name;
    this.orderId = key;
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    onOversize(file) {
      console.log(file);
      Toast('文件大小不能超过2m');
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    uploadPictures(list, callback) {
      const formData = new FormData();
      list.forEach(element => {
        formData.append('files', element.file);
      });
      yqpddApi.uploadMultiFile(formData).then((res) => {
        if(callback) {
          callback(res.data);
        }
      });
    },
    onSubmit(values) {
      const params = {
        id: this.orderId,
        ...values,
      };
      if(this.description && this.description.length < 10) {
        Toast('反馈评价至少填写10个字');
        return;
      } else if(this.fileList && this.fileList.length > 0) {
        this.uploadPictures(this.fileList, (image) => {
          yqpddApi.addComment({ ...params, image }).then((res) => {
            if (res) {
              this.$messagebox("提示", '反馈评价提交成功！');
              this.$router.go(-1);
            }
          });
        })
      } else {
        yqpddApi.addComment(params).then((res) => {
          if (res) {
            this.$messagebox("提示", '反馈评价提交成功！');
            this.$router.go(-1);
          }
        });
      }
      
      
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  padding: 0 0.6rem;
  height: calc(100vh - 2.7rem);
  width: 100vw;
  margin-top: 3.5rem;
  .form-bar {
    .title-bar {
      margin: 0.5rem 0;
      font-size: 16px;
      line-height: 20px;
      color: #646566;
      width: 100%;
      // margin-top: 0.8rem;
      background: #ffffff;
      padding: 0.8rem;
      display: flex;
      justify-content: space-between;
      span:nth-child(2) {
        flex: 1;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: right;
        color: rgba(0,0,0,0.85);
        font-weight: 500;
      }
    }
    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
