<template>
  <div class="container">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>基础档案</h1>
    </div>
    <div class="main">
      <div class="form">
        <div class="sub-main">
          <label>健康档案</label>
          <div class="sub-content" v-if="fileInfo && fileInfo.xm">
            <div class="cont block">
              <div class="info">
                <span class="label">姓名</span
                ><span class="val"> {{ fileInfo.xm }}</span>
              </div>
              <div class="info">
                <span class="label">性别中文</span>
                <span class="val"> {{ fileInfo.xb }}</span>
              </div>
              <div class="info">
                <span class="label">责任医生</span>
                <span class="val"> {{ fileInfo.zrysxm }}</span>
              </div>
              <div class="info">
                <span class="label">建档机构</span>
                <span class="val"> {{ fileInfo.jdjgmc }}</span>
              </div>
              <div class="info">
                <span class="label">建档医生</span>
                <span class="val"> {{ fileInfo.jdysxm }}</span>
              </div>
              <div class="info">
                <span class="label">建档日期</span>
                <span class="val"> {{ fileInfo.jdrq }}</span>
              </div>
              <div class="info">
                <span class="label">出生日期</span>
                <span class="val"> {{ fileInfo.csrq }}</span>
              </div>
              <div class="info">
                <span class="label">血型</span>
                <span class="val"> {{ fileInfo.xxdm }}</span>
              </div>
              <div class="info">
                <span class="label">身份证号</span>
                <span class="val"> {{ fileInfo.sfzh }}</span>
              </div>
              <div class="info">
                <span class="label">本人电话</span>
                <span class="val"> {{ fileInfo.dhhm }}</span>
              </div>
              <!-- <div class="info">
                <span class="label">户口地址</span>
                <span class="val"> {{ fileInfo.hkdz }}</span>
              </div> -->
              <div class="info">
                <span class="label">现住地址</span
                ><span class="val"> {{ fileInfo.jzdz }}</span>
              </div>
              <div class="info">
                <span class="label">费用支付方式</span
                ><span class="val"> {{ fileInfo.yldy }}</span>
              </div>
              <div class="info">
                <span class="label">是否签约</span>
                <span class="val"> {{ fileInfo.sfqy }}</span>
              </div>
              <div class="info">
                <span class="label">签约日期</span>
                <span class="val"> {{ fileInfo.qyrq }}</span>
              </div>
              <!-- <div class="info">
                <span class="label">签约医生</span>
                <span class="val"> {{ fileInfo.qyysxm }}</span>
              </div>
              <div class="info">
                <span class="label">联系电话</span
                ><span class="val">{{ fileInfo.qyyslxdh }}</span>
              </div> -->
              <div class="info">
                <span class="label">签约机构</span>{{ fileInfo.qyjgmc }}
              </div>
            </div>
          </div>
          <div class="sub-content" v-if="!fileInfo || !fileInfo.xm">
            <div class="cont">无相关记录</div>
          </div>
        </div>

        <div class="sub-main">
          <label>手术史</label>
          <div class="sub-content">
            <div
              class="cont"
              v-if="fileInfo.dasss && fileInfo.dasss.length > 0"
            >
              <template v-for="item in fileInfo.dasss">
                <div class="info">
                  <span class="label">手术名称</span>
                  <span class="val"> {{ item.jbmc }}</span>
                </div>
                <div class="info">
                  <span class="label">日期</span>
                  <span class="val"> {{ item.xgrq }}</span>
                </div>
              </template>
            </div>
            <div
              class="cont"
              v-if="!fileInfo.dasss || fileInfo.dasss.length == 0"
            >
              无相关信息
            </div>
          </div>
        </div>

        <div class="sub-main">
          <label>疾病史</label>
          <div class="sub-content">
            <div
              class="cont"
              v-if="fileInfo.dajbs && fileInfo.dajbs.length > 0"
            >
              <template v-for="item in fileInfo.dajbs">
                <div class="info">
                  <span class="label">疾病名称</span>
                  <span class="val"> {{ item.jbmc }}</span>
                </div>
                <div class="info">
                  <span class="label">确诊日期</span>
                  <span class="val"> {{ item.qzrq }}</span>
                </div>
                <div v-if="item.bz" class="info">
                  <span class="label">备注</span>
                  <span class="val"> {{ item.bz }}</span>
                </div>
              </template>
            </div>
            <div
              class="cont"
              v-if="!fileInfo.dajbs || fileInfo.dajbs.length == 0"
            >
              无相关信息
            </div>
          </div>
        </div>

        <div class="sub-main">
          <label>外伤史</label>
          <div class="sub-content">
            <div
              class="cont"
              v-if="fileInfo.dawss && fileInfo.dawss.length > 0"
            >
              <template v-for="item in fileInfo.dawss">
                <div class="info">
                  <span class="label">外伤名称</span>
                  <span class="val"> {{ item.wsmc }}</span>
                </div>
                <div class="info">
                  <span class="label">外伤日期</span>
                  <span class="val"> {{ item.wsrq }}</span>
                </div>
              </template>
            </div>
            <div
              class="cont"
              v-if="!fileInfo.dawss || fileInfo.dawss.length == 0"
            >
              无相关信息
            </div>
          </div>
        </div>

        <div class="sub-main">
          <label>输血史</label>
          <div class="sub-content">
            <div
              class="cont"
              v-if="fileInfo.dasxs && fileInfo.dasxs.length > 0"
            >
              <template v-for="item in fileInfo.dasxs">
                <div class="info">
                  <span class="label">输血原因</span>
                  <span class="val"> {{ item.sxyymc }}</span>
                </div>
                <div class="info">
                  <span class="label">输血日期</span>
                  <span class="val"> {{ item.sxrq }}</span>
                </div>
              </template>
            </div>
            <div
              class="cont"
              v-if="!fileInfo.dasxs || fileInfo.dasxs.length == 0"
            >
              无相关信息
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileInfo: {},
      //fileInfo:{}
    };
  },
  components: {},
  mounted() {
     this.getBaseFileInfo();
  },
  methods: {
    getBaseFileInfo: function() {
      let _this = this;
      let userId = this.getSessionData("healthUserId");
      let token = this.getSessionData("healthtToken");
      this.axios
        .get("https://yyh.ikeqiao.net/app/health/file/" + userId, {
          headers: { token: token },
        })
        .then(function(res) {
          _this.fileInfo = res.data.fileInfo;
        })
        .catch(function(err) {});
    },
    gotoLink(path) {
      this.$router.push(path);
    }, goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/health.scss";
</style>
