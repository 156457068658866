import service from "utils/service";

const jystjApi = {
  getList: (params) => {
    return service.get("/dataProvide/getGoldKey", {params});
  },
  addJystj: params => {
    return service.post('/neighborGlodenKeyCommunity/add', params);
  }, // 新增申请
  getJystjRecord: params => {
    return service.get('/neighborGlodenKeyCommunity/list', { params });
  }, // 获取列表记录
  getMyJystjRecord: params => {
    return service.get('/neighborGlodenKeyCommunity/getMySubmit', { params });
  }, // 查找我的提交记录
  getJystjDetail: params => {
    return service.get('/neighborGlodenKeyCommunity/getProcessById', { params });
  }, // 查询流程信息
};
export default jystjApi;
