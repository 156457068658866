<template>
  <div>
    <van-nav-bar
      class="butler-nav-bar"
      title="健康记录"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <img class="banner" src="../../assets/images/health/banner.png" />
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="flex">
            <img src="../../assets/images/health/icon-bushu.png" />
            <div>
              <div>步数</div>
              <div class="gray">
                {{ healthData.bsTime }}
              </div>
            </div>
          </div>
        </template>
        <template #default>
          <div class="center">{{ healthData.bs || "无数据" }}</div>
        </template>
      </van-cell>

      <van-cell>
        <template #title>
          <div class="flex">
            <img src="../../assets/images/health/icon-shuimian.png" />
            <div>
              <div>睡眠</div>
              <div class="gray">
                {{ healthData.smTime }}
              </div>
            </div>
          </div>
        </template>
        <template #default>
          <div class="center">{{ healthData.sm || "无数据" }}</div>
        </template>
      </van-cell>

      <van-cell>
        <template #title>
          <div class="flex">
            <img src="../../assets/images/health/icon-xinlv.png" />
            <div>
              <div>心率</div>
              <div class="gray">
                {{ healthData.xlTime }}
              </div>
            </div>
          </div>
        </template>
        <template #default>
          <div class="center">
            {{ healthData.xl ? healthData.xl + "次/分" : "无数据" }}
          </div>
        </template>
      </van-cell>

      <van-cell>
        <template #title>
          <div class="flex">
            <img src="../../assets/images/health/icon-xueya.png" />
            <div>
              <div>血压</div>
              <div class="gray">
                {{ healthData.xueyaTime }}
              </div>
            </div>
          </div>
        </template>
        <template #default>
          <div class="center">
            {{
              healthData.xueya.length
                ? healthData.xueya[0] + "/" + healthData.xueya[1]
                : "无数据"
            }}
          </div>
        </template>
      </van-cell>

      <van-cell>
        <template #title>
          <div class="flex">
            <img src="../../assets/images/health/icon-xueyang.png" />
            <div>
              <div>血氧</div>
              <div class="gray">
                {{ healthData.xueyangTime }}
              </div>
            </div>
          </div>
        </template>
        <template #default>
          <div class="center">
            {{ healthData.xueyang ? healthData.xueyang + "%" : "无数据" }}
          </div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import moment from "moment";
import { getBraceletInfo } from "@/api/health";
export default {
  name: "healthHistory",
  data() {
    return {
      info: {},
      healthData: {
        bs: "",
        bsTime: "",
        sm: "",
        smTime: "",
        xl: "",
        xlTime: "",
        xueya: [],
        xueyaTime: "",
        xueyang: "",
        xueyangTime: "",
      },
    };
  },
  created() {
    // 先写死
    this.getInfo();
  },
  methods: {
    moment,
    handleTime(time) {
      if (!time) {
        return "";
      }
      return time.slice(4, 6) + "月" + time.slice(6, 8) + "日";
    },
    async getInfo() {
      try {
        const res = await getBraceletInfo({
          healthRuleId: "ALL",
          imei: "",
        });
        this.info = res.data.retData ? res.data.retData[0] : {};
        if (Object.keys(this.info).length && this.info.waterArrayList) {
          this.info.waterArrayList.forEach((i) => {
            if (i.healthRuleIdName == "心率") {
              this.healthData.xl = i.number;
              this.healthData.xlTime = this.handleTime(i.healthRuleTime);
            } else if (i.healthRuleIdName == "舒张压") {
              this.healthData.xueya[0] = i.number;
              this.healthData.xueyaTime = this.handleTime(i.healthRuleTime);
            } else if (i.healthRuleIdName == "收缩压") {
              this.healthData.xueya[1] = i.number;
            } else if (i.healthRuleIdName == "步数") {
              this.healthData.bs = i.number;
              this.healthData.bsTime = this.handleTime(i.healthRuleTime);
            }else if(i.healthRuleIdName == "血氧"){
              this.healthData.xueyang = i.number;
              this.healthData.xueyangTime = this.handleTime(i.healthRuleTime);
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  margin: 0.5rem 0;
}
.flex {
  display: flex;
  align-items: center;

  img {
    height: 2.2rem;
    width: 2.2rem;
  }

  > div {
    margin-left: 0.2rem;
  }

  .gray {
    color: #969799;
  }
}

.center {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>