<template>
  <div class="main-container flex flex-col">
    <header class="flex">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <div class="title flex-1">商品列表</div>
      <van-icon />
    </header>
    <section class="flex-1 flex flex-col">
      <div class="flex header">
        <div class="flex-1">
          <svg-icon icon-class="location" />
          <span class="location-text truncate">{{ location }}</span>
          <svg-icon icon-class="arrow-right" />
        </div>
        <div class="flex flex-col">
          <svg-icon icon-class="search" />
          <span>搜商品</span>
        </div>
        <div class="flex flex-col">
          <svg-icon icon-class="order" />
          <span>订单</span>
        </div>
      </div>
      <div class="flex flex-1  overflow-hidden">
        <van-sidebar v-model="activeKey">
          <van-sidebar-item
            v-for="menu in sidebarMenu"
            :key="menu.key"
            :title="menu.title"
          />
        </van-sidebar>
        <div class="flex-1 flex flex-col overflow-hidden">
          <van-tabs v-model="order">
            <van-tab title="综合" name="1" />
            <van-tab title="销量" name="2" />
            <van-tab name="3">
              <template #title>
                <van-dropdown-menu>
                  <van-dropdown-item
                    title="价格"
                    v-model="priceOrder"
                    :options="[
                      { text: '默认排序', value: '1' },
                      { text: '价格从低到高', value: '2' },
                      { text: '价格从高到低', value: '3' },
                    ]"
                  />
                </van-dropdown-menu>
              </template>
            </van-tab>
          </van-tabs>
          <div class="goods-box flex-1">
            <div
              class="goods-box-item"
              v-for="goods in typeGoodsList"
              :key="goods.id"
              @click="gotoDetail(goods.id)"
            >
              <img :src="goods.image" alt="" />
              <div class="goods-info">
                <span class="title truncate">{{ goods.name }}</span>
                <span class="remark truncate">
                  {{ goods.remark }}
                </span>
                <span class="price" @click.stop>
                  ￥{{ goods.price }}
                  <van-stepper
                    v-model="shoppingCar['goods_' + goods.id]"
                    theme="round"
                    :default-value="0"
                    :min="0"
                    :show-minus="showMinus(goods.id)"
                    :show-input="showMinus(goods.id)"
                    button-size="22"
                    @change="val => onStepperChange(val, goods)"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <van-goods-action>
      <van-goods-action-icon icon="cart-o" :badge="total.num" @click="showModal = true" />
      <div class="total-price">￥{{ total.price }}</div>
      <van-goods-action-button
        type="info"
        text="立即下单"
        :disabled="!total.num"
        @click="onOrders"
      />
    </van-goods-action>
    <van-popup v-model="showModal" position="bottom">
      <div class="gwcContent">
        <div class="title">
          <div>已选商品</div>
          <div class="clear" @click="clearCount">清空</div>
        </div>
        <div class="gwcList">
          <div v-for="(item, i) in (shoppingCarList || [])" class="item" :key="i">
            <div class="image">
              <img :src="item.image" alt="">
            </div>
            <div class="text">
              <div class="name">{{item.name}}</div>
              <div class="btnGroup">
                <!-- <div class="momeny">{{item.momeny || 0}}元/个</div> -->
                <img src="../../assets/images/axst/lessen.png" alt="" @click="onStepperChange(-1, item)">
                <div>{{item.num}}</div>
                <img src="../../assets/images/axst/add.png" alt="" @click="onStepperChange(1, item)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import llgApi from 'api/llg';
import haMiMelon from '@/assets/images/llg/hami_melon.png';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      location: '祥生君城1幢',
      activeKey: 0,
      sidebarMenu: [
        { key: '一元抢购', title: '一元抢购' },
      ],
      showModal: false,
      type: '',
      order: '',
      priceOrder: '',
      goodsList: [
        // {
        //   id: '12',
        //   img: haMiMelon,
        //   name: '新疆哈密瓜1斤',
        //   remark: '商品简介商品简介商品简介商品简介商品简介商品简介',
        //   price: 1.0,
        // },
        // {
        //   id: '13',
        //   img: haMiMelon,
        //   name: '新疆哈密瓜2.5斤',
        //   remark: '商品简介商品简介商品简介商品简介商品简介商品简介',
        //   price: 2.0,
        // },
        // {
        //   id: '14',
        //   img: haMiMelon,
        //   name: '新疆哈密瓜3斤',
        //   remark: '商品简介商品简介商品简介商品简介商品简介商品简介',
        //   price: 2.4,
        // },
      ],
      shoppingCar: {},
      shoppingCarList: [],
    };
  },
  mounted() {
    const { type } = this.$route.query;
    this.activeKey = type;
    this.getShopList();
    this.getMenuList();
    this.shoppingCarList = this.list;
  },
  computed: {
    ...mapState({
      list: (state) => state.llg.order,
    }),
    total() {
      return this.shoppingCarList.reduce(
        (prev, curr) => {
          return {
            num: prev.num + curr.num,
            price: prev.price + +(curr.num * curr.price).toFixed(2),
          };
        },
        { num: 0, price: 0 },
      );
    },
    typeGoodsList() {
      if(this.activeKey == 0) {
        return this.goodsList.filter(item => item.sellStatus);
      }
      return this.goodsList.filter(item => item.type == this.sidebarMenu[this.activeKey].title );
    }
  },
  watch: {
    activeKey(val) {
      // 侧边导航 索引值变化
      console.log(val);
    },
  },
  methods: {
    gotoDetail(id) {
      this.$store.commit('llg/setOrder', this.shoppingCarList)
      this.$router.push(`/llg/goodsDetail?id=${id}`)
    },
    clearCount() {
      this.shoppingCarList = [];
    },
    showMinus(goodsId) {
      return (
        !!this.shoppingCar[`goods_${goodsId}`] &&
        this.shoppingCar[`goods_${goodsId}`] !== 0
      );
    },
    onStepperChange(val, goods) {
      if (!goods.id) return;
      const _list = [...this.shoppingCarList];
      const index = _list.findIndex(o => o.id === goods.id);
      if (index >= 0 && +val > 0) {
        //已存在且数量大于0
        _list[index] = { num: val, ...goods };
      } else if (index >= 0 && +val <= 0) {
        //已存在且数量小等于0
        _list.splice(index, 1);
      } else if (index < 0 && +val > 0) {
        //不存在存在且数量大于0
        _list.push({ num: val, ...goods });
      }
      this.$set(this, 'shoppingCarList', _list);
    },
    onOrders() {
      this.$store.commit('llg/setOrder', this.shoppingCarList)
      this.$router.push('/llg/order');
    },
    getMenuList() {
      llgApi.getShopType().then(res => {
        if(res.flag) {
          this.sidebarMenu = this.sidebarMenu.concat(res.data.map(item => ({ key: item.dictLabel, title: item.dictLabel })));
        }
      })
    },
    getShopList() {
      llgApi.getShopList().then(res => {
        if(res.flag) {
          this.goodsList = res.data;
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/llg.scss';
.goods-box {
  overflow-y: auto;

  &-item {
    display: flex;
    align-items: center;
    padding: 10px;
    > img {
      width: 80px;
      height: 80px;
    }
    .goods-info {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      overflow: hidden;
      margin-left: 10px;
      .title {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 0;
        font-weight: 600;
      }
      .price {
        font-size: 16px;
        color: #ff6c2b;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .remark {
        padding: 10px 0;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}
.total-price {
  line-height: 100%;
  font-size: 20px;
  color: #ff6c2b;
  font-weight: 500;
  flex: 1.5;
}
::v-deep {
  .van-sidebar-item--select::before {
    background-color: #3e71f9;
  }
  .van-stepper--round .van-stepper__minus {
    color: #3e71f9;
    border: 1px solid #3e71f9;
  }
  .van-stepper--round .van-stepper__plus {
    background-color: #3e71f9;
  }
}
.gwcContent{
    padding: 10px;
    max-height: 300px;
    overflow: scroll;
    .title{
      font-size: 16px;
      color: #000000;
      line-height: 30px;
      display: flex;
      .clear{
        margin-left: auto;
        margin-right: 10px;
        font-size: 16px;
        color: #FF4535;
      }
    }
    .gwcList{
      .item{
        margin-bottom: 10px;
        display: flex;
        height: 50px;
        line-height: 50px;
        .image{
          width: 40px;
          height: 40px;
          margin: 5px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          display: flex;
          width: calc(100%);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          border-bottom: 1px solid rgba(0,0,0,0.1);
          .name{
            font-size: 16px;
            color: #000000;
            font-weight: 500;
          }
          .btnGroup{
            display: flex;
            margin-left: auto;
            margin-right: 10px;
            .momeny{
              font-size: 16px;
              color: #FF6C2B;
              margin-right: 10px;
            }
            >img{
              width: 16px;
              height: 16px;
              margin: 17px 5px;
            }
          }
        }
      }
    }
  }
</style>
