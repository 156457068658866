<template>
  <div id="index">
    <div class="page-container" style="height: auto !important">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我的分享</h1>
      </div>
    </div>
    <div style="height: 2.8rem"></div>
    <van-list :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="note-box-fxgj">
        <div class="list">
          <div v-for="item in list" :key="item.invitationId" class="card-con">
            <div class="card-con-left">
              <img :src="item.invitationContent || baseImg" class="image" />
              <div style="flex: 1">
                <div class="card-title">
                  <span>{{ item.invitationTitle }}</span>
                </div>
                <div class="row">
                  <span>数量：{{ item.toolNumber }}</span>
                </div>
              </div>
            </div>
            <div class="card-button" @click="handleCancelRentTool(item)">
              <span>取消分享</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <div
      v-if="list.length === 0"
      style="margin-top: 40%; text-align: center; font-size: 18px"
    >
      暂无分享
    </div>
    <div class="foot-button" @click="gotoLink('shareTool')">分享工具</div>
  </div>
</template>

<script>
import gjxApi from "api/gjx";
import { List, Toast } from "vant";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      page: 0,
      baseImg: require("../../assets/images/logo.png"),
      size: 10,
      total: 0,
      list: [],
      finished: false,
    };
  },
  components: {},
  mounted() {},
  methods: {
    handleCancelRentTool(item) {
      gjxApi.cancelRentTool({ invitationId: item.invitationId }).then((res) => {
        if (res.data == 1) {
          Toast.success("取消分享成功");
          this.page = 1;
          this.getList({ page: 1 });
          // this.list = this.list.filter(
          //   (v) => v.invitationId !== item.invitationId
          // );
        }
      });
    },
    ...mapMutations(["setQuestionInfo"]),
    getList({ page = this.page, size = this.size } = {}) {
      gjxApi
        .getToolCasePage({
          page,
          size,
          object: {
            shareTool: true,
          },
        })
        .then((res) => {
          const { records = [], total = 0 } = res.data || {};
          this.list = this.list.concat(records);
          this.total = total;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    gotoDetail(info) {
      this.$router.push("gjxxq");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/index.scss";

.note-box-fxgj {
  background: #ffffff;
  padding: 0.5rem 0.8rem 1rem;
  .tab {
    height: 1.2rem;
    font: 500 0.9rem/1.1rem "microsoft yahei";
    text-align: left;
    margin: 0.8rem 1rem 0.4rem 0.2rem;
    border-left: 0.4rem solid #4479ed;
    padding-left: 0.8rem;
  }
  .list {
    //padding: 0 0.6rem;
    li {
      padding: 0.7rem 0.5rem;
      font: 500 0.8rem/1.2rem "microsoft yahei";
      color: #878787;
      border-bottom: 1px solid #d1d1d1;
      span {
        margin-right: 1rem;
      }
    }
    .card-con {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.8rem;

      .card-con-left {
        display: flex;
        align-items: flex-start;
      }
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        height: 16px;
        margin-bottom: 0.1rem;

        .tag {
          background: #d5f4e2;
          border-radius: 4px 2px 8px 2px;
          font-size: 12px;
          color: #37d477;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 0.2rem 0.8rem;
          height: 1.2rem;
        }
      }
      .image {
        height: 4.6rem;
        width: 4.6rem;
        border-radius: 2px;
        margin-right: 0.5rem;
      }
      .row {
        font-size: 13px;
        line-height: 1.4rem;
        display: flex;

        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        .label {
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 18px;
        }
      }
    }
  }

  .card-button {
    width: 5rem;
    height: 2rem;
    background: #4479ed;
    color: #ffffff;
    text-align: center;
    line-height: 2rem;
    border-radius: 4px;
  }
}
</style>
