<template>
  <div class="jfdh-list-container">
    <van-nav-bar title="兑换商品" left-arrow @click-left="onClickLeft" />
    <div class="jfdh-list-body-container">
      <van-tabs v-model="selectedTab" @click="onTabClick" color="#4379ed">
        <van-tab v-for="tab in iconList" :key="tab.commodityTypeId" :title="tab.commodityTypeName" :name="tab.commodityTypeId" />
      </van-tabs>
      <van-dropdown-menu>
        <!-- <van-dropdown-item
          v-model="type"
          @change="handleChange($event, 'type')"
          :options="option1"
        /> -->
        <van-dropdown-item
          v-model="sortField"
          @change="handleChange($event, 'sortField')"
          :options="option3"
        />
      </van-dropdown-menu>
      <div class="class-source-con">
        <div class="class-source-list">
          <van-list
            v-model="loading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
            class="activity-list"
            v-if="dataList && dataList.length > 0"
          >
            <div class="list">
              <div class="list-content">
                <div class="card-nav"
                  v-for="item in dataList"
                  :key="item.commodityId"
                  @click="gotoLink(`dhDetail?id=${item.commodityId}`)"
                >
                  <div class="jfdh-status-tag jfdh-hot" v-if="item.commodityIsHot === 1">热门</div>
                  <div class="jfdh-status-tag" v-else-if="item.stockType === 3">已兑完</div>
                  <div class="jfdh-status-tag" v-else-if="item.expiredState === 1">已过期</div>
                  <img class="image"
                      :src="item.commodityImage"
                      alt="暂无图片" />
                  <div class="card-foot">
                    <div class="title van-ellipsis">{{item.commodityName}}</div>
                    <div class="jf">
                      <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/积分.png" />
                      <span>{{`${item.commodityNeedIntegral}积分`}}</span>
                    </div>
                    <div class="small-text gray">{{`${item.commoditySellNum}件已兑换`}}</div>
                    <!-- <div @click="gotoLink(`dhDetail?id=${item.id}`)"
                        class="button">立即兑换</div> -->
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <div class="no-data" v-else>
            <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/无数据.png" alt="">
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jfdhApi from 'api/ddya/jfdh';
import { mapMutations, mapState } from "vuex";
import {jfdhShoping} from 'assets/json/jfdh';
export default {
  data() {
    return {
      status: "",
      sortField: "",
      type: '',
      loading: false,
      listFinished: false,
      option1: [
        { text: "全部种类", value: "" },
        { text: "优惠券", value: "优惠券" },
        { text: "实物类", value: "实物类" },
        { text: "虚拟类", value: "虚拟类" },
      ],
      option3: [
        { text: "默认排序", value: "" },
        { text: "兑换数量倒序", value: 0 },
        { text: "兑换数量正序", value: 1 },
        { text: "兑换积分倒序", value: 2 },
        { text: "兑换积分正序", value: 3 },
      ],
      colors: {
        '0': { text: '#4479ED', background: '#E3EFFD' },
        '1': { text: '#E5B20A', background: '#FFF6D9' },
        '2': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
        '4': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
      dataList: [],
      page: 1,
      size: 10,
      total: 0,
      selectedTab: 13,
      iconList: [],
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { type = '' } = this.$route.query;
    console.log(type ? type * 1 : null);
    
    this.selectedTab = type ? type * 1 : null;
    this.getList({ commodityTypeId: type });
    // 获取物品类型列表
    jfdhApi.getGoodsTypeList().then(res => {
      this.iconList = [
        {
          commodityTypeName: '全部',
          commodityTypeIcon: 'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/全部类型.png',
          commodityTypeId: '',
        },
        ...(res.data || []),
      ];
    });
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    handleChange(e, type) {
      this[type] = e;
      this.getList();
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    onQuestionClick(item) {
      // this["setQuestionInfo"](item);
      this.gotoLink(`dhDetail?id=${item.id}`);
    },
    onTabClick(name, title) {
      this.selectedTab = name;
      this.getList();
    },
    // 0、报名中 1、进行中 2、已结束
    getList({ page = this.page, size = this.size, commodityTypeId = this.selectedTab, sorted = this.sortField } = {}) {
      jfdhApi.getScoreGoodList({
        page,
        size,
        commodityTypeId,
        isHot: null,
        sorted,
      }).then(res => {
        this.loading = false;
        const { records = [], total = 0 } = res.data || {};
        if(page === 1) {
          this.dataList = records;
        } else {
          this.dataList = Array.from(new Set(this.dataList.concat(records)))
        }
        this.total = total;
        if (this.dataList.length >= this.total) {
          // 数据全部加载完成
          this.listFinished = true;
        }

      })
    },
  },
};
</script>

<style lang="scss" scoped>
.jfdh-list-container {
  height: 100vh;
  width: 100vw;

  .jfdh-list-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: #f6f7fa;
    overflow-y: auto;
    overflow-x: hidden;

    .class-source-con {
      margin: 10px;
      width: calc(100% - 20px);
      .no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 24px;
        >img {
          height: 120px;
          width: 120px;
        }
      }
      .class-source-top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top-left {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          .top-left-btn {
            width: 48px;
            height: 28px;
            background: #ededed;
            border-radius: 4px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 28px;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
          }
          .selected {
            background: #e3effd;
            color: #4479ed;
          }
        }
        .top-right {
          width: 30%;
          box-shadow: none;
          ::v-deep .van-dropdown-menu__bar {
            box-shadow: none;
          }
        }
      }
    }
    .activity-list {
      .list {
        
        .list-title {
          border-left: 4px solid #4479ed;
          padding-left: 8px;
          font-size: 16px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.85);
        }
        .list-content {
          display: flex;
          flex-wrap: wrap;
          .card-nav {
            width: calc(50% - 1rem);
            margin: 0.5rem;
            background: #FFF;
            position: relative;
            .jfdh-status-tag {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 0.8rem;
              line-height: 1.2rem;
              color: rgba(0,0,0,0.45);
              background: #FFF;
              border-radius: 0 2px 6px 2px;
              padding: 0 8px;
            }
            .jfdh-hot {
              color: #e13f30;
              background: #f9dcd7;
            }
            .image {
              width: 100%;
              height: 8rem;
              border-radius: 8px;
              background: #f6f7fa;
            }
            .card-foot {
              padding: 12px;
              .title {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                letter-spacing: 0;
                line-height: 20px;
                font-weight: 500;
              }
              .jf {
                height: 24px;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #e5b20a;
                img {
                  height: 14px;
                  width: 14px;
                  margin-right: 4px;
                }
              }
              .button {
                background: #4479ed;
                border-radius: 4px;
                padding: 0.4rem;
                color: #ffffff;
                width: 5rem;
                font-size: 14px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>