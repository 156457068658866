<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>兑换记录</h1>
      </div>
      <van-tabs v-model="selectedTab" @click="onTabClick" color="#4479ED">
        <van-tab title="全部" name="">
        </van-tab>
        <van-tab title="待使用" :name="0">
        </van-tab>
        <van-tab title="已兑换" :name="1">
        </van-tab>
        <van-tab title="已过期" :name="2">
        </van-tab>
      </van-tabs>
      <div class="class-source-list">
          <van-list
            v-model="loading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
            class="activity-list"
            v-if="dataList && dataList.length > 0"
          >
            <div class="list">
              <ul class="list">
                <li v-for="item in dataList"
                    :key="item.id">
                  <div class="card-nav">
                    <img :src="item.orderCommodityImage" />
                    <div :style="{background: colors[item.orderCondition] ? colors[item.orderCondition].background : null,color: colors[item.orderCondition] ? colors[item.orderCondition].text : null}"
                        class="tag">{{item.orderConditionText}}</div>
                    <div class="card-nav-text" @click="useCode(item, item.orderId)">
                      <div class="title">
                        {{ item.orderCommodityName }}
                      </div>
                      <div class="row">
                        <span>数量：</span>
                        <span>{{ item.orderExchangeNum }}</span>
                      </div>
                      <div class="row">
                        <span>兑换时间：</span>
                        <span v-if="item.orderCommodityEffectiveType == 0">长期有效</span>
                        <span v-else-if="item.orderCommodityEffectiveType == 1">{{`${item.orderCommodityEffectiveStartTime || '暂无'} ~ ${item.orderCommodityEffectiveEndTime || '暂无'}`}}</span>
                        <span v-else-if="item.orderCommodityEffectiveType == 2">{{`${item.orderCommodityEffectiveEndTime}之前`}}</span>
                      </div>
                    </div>
                    <div class="dhjl-button"
                        v-if="item.orderCondition == 0"
                        @click.stop="useCode(item, item.orderId)">去使用</div>
                    <div class="dhjl-button plain" @click="handleDelete(item)" v-else>删除</div>
                  </div>
                </li>
              </ul>
            </div>
          </van-list>
          <div class="no-data" v-else>
            <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/无数据.png" alt="">
            <p>暂无数据</p>
          </div>
        </div>

    </div>

  </div>
</template>

<script>
import { Toast } from 'vant';
import jfdhApi from 'api/ddya/jfdh';
import { dhRecords } from 'assets/json/jfdh'
export default {
  data () {
    return {
      pageDataList: [],
      selectedTab: '',
      colors: {
        '0': { text: '#4479ED', background: '#E3EFFD' },
        '1': { text: '#E5B20A', background: '#FFF6D9' },
        '2': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
      dataList: [],
      page: 1,
      size: 10,
      total: 0,
    };
  },
  components: {},
  mounted () {
    this.getList();
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    onTabClick(name, title) {
      this.selectedTab = name;
      this.getList();
    },
    handleDelete(data) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认删除？',
      })
        .then(() => {
          // on confirm
          jfdhApi.deleteOrder({ orderId: data.orderId }) .then(res => {
            if (res.flag) {
              this.getList();
              this.$toast('删除成功')
            }
          })
          
        })
        .catch(() => {
          // on cancel
        });
    },
    onLoad() {
      this.page = this.page + 1;
      this.getList({ page: this.page })
    },
    // null全部,0:待兑换,1:已兑换,2:已过期
    getList({ page = this.page, size = this.size, orderCondition = this.selectedTab } = {}) {
      jfdhApi.getScoreOrderList({
        page,
        size,
        orderCondition,
      }).then(res => {
        this.loading = false;
        const { records = [], total = 0 } = res.data || {};
        if(page === 1) {
          this.dataList = records;
        } else {
          this.dataList = Array.from(new Set(this.dataList.concat(records)))
        }
        this.total = total;
        if (this.dataList.length >= this.total) {
          // 数据全部加载完成
          this.listFinished = true;
        }

      })
    },
    goBack () {
      this.$router.replace('/jfdh');
    },
    showAlert () {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getImage (key) {
      return key
    },
    useCode (item, id) {
      this.$router.push({ path: '/jfdhTicket', query: { ticketId: id }})
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    >img {
      height: 120px;
      width: 120px;
    }
  }
  .list {
    padding: 0.6rem;
    .card-nav {
      display: flex;
      margin-bottom: 0.8rem;
      position: relative;
      img {
        height: 6rem;
        width: 8rem;
        border-radius: 4px;
        margin-right: 10px;
      }
      .title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 12px;
        width: calc(100% - 60px);
      }
      &-text {
        flex: 1;
      }
      .row {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
        width: 100%;
        word-break: break-all;
      }
      .tag {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.2rem 0.5rem;
        border-radius: 4px;
        font-size: 12px;
      }
      .dhjl-button {
        position: absolute;
        top: 0.8rem;
        right: 0;
        border-radius: 20px;
        padding: 4px 8px;
        background: #4479ed;
        color: #ffffff;
        font-size: 12px;
      }
      .plain {
        background: none;
        border: 1px solid #4379ed;
        color: #4479ed;
      }
    }
  }
}
</style>