<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>邻里一家人</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/llyjr_banner.png" />
        </div>
        <div class="all-nav clearfix">
          <ul>
            <li @click="gotoLink('znt')">
              <i class="znt"></i>
              <span>智囊团</span>
            </li>
            <li @click="gotoLink('nrb')">
              <i class="nrb"></i>
              <span>能人帮</span>
            </li>
            <!-- <li @click="showAlert">
              <i class="jjh"></i><span>紧急呼</span>
            </li>-->
            <li @click="gotoLink('xlyz')">
              <i class="xlyz"></i>
              <span>心理援助</span>
            </li>
            <li @click="gotoLink('wysl')">
              <i class="wysl"></i>
              <span>外语沙龙</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-nav">
        <div class="card-title">
          <div class="title">最新提问</div>
          <div class="more" @click="gotoLink('znt')">
            <span>更多</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div
          v-for="(item) in zntList"
          :key="item.invitationId"
          class="question"
          @click="onQuestionClick(item)"
        >
          <div class="question-title">{{item.invitationTitle}}</div>
          <div class="question-little-title">
            <div class="piece">发布时间：{{item.createTime}}</div>
            <div class="piece">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/view.png" />
              <span>{{ item.visitsUp }}</span>
            </div>
            <div class="piece">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/message.png" />
              <span>{{item.commentsUp }}</span>
            </div>
          </div>
          <div class="comment-list" v-for="comment in item.commentList" :key="comment.commentId">
            <div class="person">
              <img v-if="comment.avatarUrl" :src="comment.avatarUrl" class="avatar" alt="头像" />
              <img v-else src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png" class="avatar" alt="头像" />
              <span>{{ comment.nickName }}</span>
            </div>
            <div class="paragraph">
              <p>{{ comment.commentContent }}</p>
            </div>
            <!-- <div class="question-foot">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/zan.png" />
              <span>{{ comment.thumbsUp }}</span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="card-nav">
        <div class="card-title">
          <div class="title">能人帮</div>
          <div class="more" @click="gotoLink('nrb')">
            <span>更多</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div
          v-for="item in nrbList"
          :key="item.invitationId"
          class="question"
          @click="onServiceClick(item)"
        >
          <div class="question-title">{{item.invitationTitle}}</div>
          <div class="paragraph">
            <p>发布人姓名：{{item.nickName}}</p>
            <p>发布时间：{{item.createTime}}</p>
          </div>
          <div class="question-foot">
            <div>
              <span>
                已服务
                <i style="color: #4479ed;">{{item.serviceUp}}</i>人
              </span>
            </div>
            <!-- <div>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/zan.png" />
              <span>{{item.thumbsUp}}</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="foot-nav">
      <div>如遇危险，请点击“一键求助”</div>
      <div class="button">一键呼救</div>
    </div>-->
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      zntList: [],
      nrbList: []
    };
  },
  components: {},
  mounted() {
    // 能人帮
    llyjrApi
      .getPeopleSharePage({
        page: 1,
        size: 2,
        object: {
          className: "2"
        }
      })
      .then(res => {
        const { records = [], total = 0 } = res.data || {};
        this.nrbList = records;
      });
    // 智囊团
    llyjrApi
      .getPeopleSharePage({
        page: 1,
        size: 1,
        object: {
          className: "1"
        }
      })
      .then(res => {
        const { data } = res;
        const { records = [], total = 0 } = res.data || {};
        this.zntList = records;
      });
  },
  methods: {
    ...mapMutations(["setQuestionInfo"]),
    onQuestionClick(item) {
      this["setQuestionInfo"](item);
      this.gotoLink("questionDetail");
    },
    onServiceClick(item) {
      this["setQuestionInfo"](item);
      this.gotoLink("serviceDetail");
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  // padding: 0.8rem 0rem;
  background: #fff;
  height: 100vh;
  overflow-y: auto;
  .park-main {
    padding-top: 3.2rem;
    .banner {
      padding: 0.8rem;
      // height: 5rem;
      img {
        // height: 5rem;
        width: 100%;
        border-radius: 6px;
      }
    }
    .all-nav {
      padding: 0rem 0rem 0.5rem;
      li {
        float: left;
        text-align: center;
        width: 4.2rem;
        padding: 0.5rem 0;
        margin: 0 0.8rem;
        i {
          display: inline-block;
          width: 2.7rem;
          height: 2.7rem;
        }
        .znt {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/icons/znt.png) no-repeat;
          background-size: 100%;
        }
        .nrb {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/icons/nrb.png) no-repeat;
          background-size: 100%;
        }
        .jjh {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/icons/jjh.png) no-repeat;
          background-size: 100%;
        }
        .xlyz {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/icons/xlyz.png) no-repeat;
          background-size: 100%;
        }
        .wysl {
          background: url(http://dssq.ikeqiao.net/data/image/h5/images/icons/wysl.png) no-repeat;
          background-size: 100%;
        }
        span {
          display: block;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
        }
      }
    }
  }
  .card-nav {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    background: #ffffff;
    padding: 0.8rem;
    .card-title {
      display: flex;
      justify-content: space-between;
      height: 2rem;
      align-items: center;
      .title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        border-left: 0.4rem solid #4479ed;
        padding-left: 0.8rem;
        font-weight: 600;
      }
      .more {
       
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        text-align: right;
        line-height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .question {
      .question-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 1.5rem;
        font-weight: 600;
      }
      .question-little-title {
        display: flex;
        color: rgba(0, 0, 0, 0.45);
        align-items: center;
        height: 1.5rem;
        .piece {
          margin-right: 0.4rem;
          vertical-align: middle;
          display: flex;
          align-items: center;
          img {
            height: 1rem;
            margin: 0 0.2rem;
          }
        }
      }
      .person {
        display: flex;
        align-items: center;
       
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
        height: 2.5rem;
        .avatar {
          height: 2rem;
          width: 2rem;
          border-radius: 1rem;
          margin-right: 5px;
        }
      }
      .paragraph {
       
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
        .long {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
      .question-foot {
        height: 1.5rem;
        margin: 0.4rem 0;
        color: rgba(0, 0, 0, 0.45);
        display: flex;
        align-items: center;
        div {
          margin-right: 0.4rem;
        }
        img {
          height: 1rem;
          margin-right: 0.2rem;
          vertical-align: bottom;
        }
      }
    }
  }
}
.foot-nav {
  height: 6rem;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0.8rem;
 
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  line-height: 30px;
  text-align: center;
  .button {
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;
   
    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
