<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>典型事件</h1>
      </div>
      <van-tabs @click="handleTabSelect" v-model="selectedItem" color="#4479ED">
        <van-tab v-for="item in typeList" :key="item"  :title="item" :name="item">
        </van-tab>
      </van-tabs>
      <van-list
        v-model="loading"
        :finished="listFinished"
        finished-text="没有更多了"
        @load="onLoad"
        class="event-list"
      >
        <ul>
          <li class="item" v-for="(item, i) in eventDataList" :key="i" @click="gotoLink('eventListDetail?id=' + item.id)">
            <div class="desc">{{item.content}}</div>
            <div class="time">{{item.reportTime}}</div>
            <div class="tag">{{item.reportStatus ? item.reportStatus : '待处理'}}</div>
          </li>
        </ul>
      </van-list>
      
    </div>
    <div class="addYs" @click="addEvent">事件上报</div>
  </div>
</template>

<script>
import { Toast } from "vant";
import api from 'api';
import { mapState, mapM} from 'vuex';

export default {
  data() {
    return {
      typeList: [],
      eventList: [
        { id: 1, desc: '消防通道门口经常停着几辆自行车，请问物业用不用管起来？盼答复，谢谢。', time: '2021-09-09 18:25:42', type: '安全隐患类', status: '已处理' },
        { id: 2, desc: '您好！架空层下面电瓶车停车充电有安全隐患，物业不管怎么办？谢谢！', time: '2021-09-09 17:24:11', type: '安全隐患类', status: '已处理', answer: '您好，架空层为小区的公共面积，不应用来给电瓶车充电，但是也要想办法找一块室外的区域给电瓶车充电，方能消除安全隐患。', answerPerson: '陈云' },
        { id: 3, desc: '您好！我们发现有不少居民电瓶车没上锁，不安全，如何提高他们的防范意识？', time: '2021-09-09 15:46:21', type: '安全隐患类', status: '已处理', answer: '我们环物委成员会适时举办一些活动，吸引群众来参加，宣传安全防范知识；同时我们也会上门走访，对不上锁的电瓶车主进行提醒。', answerPerson: '来思远' },
        { id: 4, desc: '您好！我家楼上咋天有陌生面孔的居民搬进来，想咨询他们有否办过暂住证？', time: '2021-09-09 15:22:08', type: '安全隐患类', status: '已处理', answer: '您好，我们会予以核实，请您放心。', answerPerson: '陈云' },
        { id: 5, desc: '您好！我听说诸暨城区购买40年产权的房子可以迁户口了吗？', time: '2021-09-09 18:21:08', type: '业务类', status: '已处理' },
        { id: 6, desc: '业委会使用资金需不需要征求业主意见？盼答复，谢谢！', time: '2021-09-09 13:44:11', type: '经费资金类', status: '已处理', answer: '业委会的日常工作开支由业委会决定，但涉及大额资金的使用应由业主大会决定。', answerPerson: '胡杰丰' },
        { id: 7, desc: '业委会选举可以请第三方吗？谢谢！', time: '2021-09-09 16:56:18', type: '业务类', status: '已处理', answer: '可以由业委会筹备小组决定是否聘请第三方协助开展选举投票工作。', answerPerson: '胡杰丰' },
        { id: 8, desc: '小区地下室路面破损严重，要求了n次请物业修理，物业说这个经费有难度，一直没落实，怎么办', time: '2021-09-09 20:12:47', type: '业务类', status: '已处理', answer: '如物业服务合同中未约定有物业负责维修的 ，是否维修以及维修经费来源由业主大会决定和筹集。', answerPerson: '胡杰丰' },
        { id: 9, desc: '公共地方收停车费，物业没公示，盼回答，谢谢！', time: '2021-09-09 14:33:21', type: '业务类', status: '已处理', answer: '如果是利用小区公共部位进行收费，应由业主大会决定并公示。', answerPerson: '胡杰丰' },
        { id: 10, desc: '环物委同志，楼下邻居装震楼器，可以帮我协调吗？谢谢！', time: '2021-09-13 9:44:56', type: '其他', status: '已处理' },
        // { id: 11, desc: '我是东盛社区的居民，我现在住的这幢房子外墙砖有部分脱落，房子已经交付多年，开发商已经撤离了，请问这个维修现在由谁负责？谢谢！', time: '2021-09-09 18:25:42', type: '安全隐患类' },
        // { id: 12, desc: '您好！电瓶车可以在架空层充电吗？', time: '2021-09-13 12:24:11', type: '安全隐患类' },
        { id: 13, desc: '请问物业灭火器每月检查一次吗？', time: '2021-09-13 15:46:21', type: '安全隐患类', status: '已处理' },
        { id: 14, desc: '您好！物业合同到期业委会可以代表业主签合同吗？', time: '2021-09-13 18:21:08', type: '业务类', status: '已处理', answer: '物业合同到期，业委会应召开业主大会来决定续聘原物业公司或选聘新物业公司，业委会才能根据业主大会决定签订物业服务合同。', answerPerson: '胡杰丰' },
        { id: 15, desc: '您好！不是物业纠纷，环物委可以帮助调解吗？', time: '2021-09-13 13:44:11', type: '业务类', status: '已处理', answer: '现在调解纠纷的组织很多，不属于物业纠纷的可以申请社区、街道人民调解委员会进行调解，环物委也可以帮助调解。', answerPerson: '胡杰丰' },
        { id: 16, desc: '您好！物业迀移大树须征求全体业主意见吗？', time: '2021-09-13 16:56:18', type: '业务类', status: '已处理', answer: '具体的绿化工作由物业公司根据物业服务合同进行，迁移大树应由业委会决定并征求受影响业主的意见。', answerPerson: '胡杰丰' },
        { id: 17, desc: '我们家楼上经常有水漏下来，邻居不同意修怎么办？', time: '2021-09-13 10:44:56', type: '其他', status: '已处理' },
        { id: 18, desc: '您好！业委会选举可以平台上投票吗？', time: '2021-09-13 11:24:56', type: '其他', status: '已处理' },
        { id: 19, desc: '您好！我们香林小区周边烧烤店有噪音污染影响我们休息。', time: '2021-09-13 19:33:56', type: '其他', status: '已处理', answer: '我们已与经营户沟通协调。', answerPerson: '陈云' },
        { id: 20, desc: '您好，我们小区9幢楼的架空层下面电瓶车乱停现象严重，影响正常出行，并且有安全隐患，请帮忙解决！', time: '2021-09-26 16:56:18', type: '安全隐患类', status: '', answer: '', answerPerson: '' },
        { id: 21, desc: '我们小区外墙体脱落严重，物业不管，说自己没这个责任，我们想用维修基金自行修缮，请问这样是否可以？', time: '2021-09-26 10:44:56', type: '经费资金类', status: '' },
        { id: 22, desc: '香林花园北门的景观池水质差，漂浮物多，影响美观，物业打捞了一次没多久又变差了，请问怎么才能防止类似的事情再次发生呢？', time: '2021-09-26 11:24:56', type: '业务类', status: '' },
        { id: 23, desc: '静水湾小区8幢大厅不让电瓶车停，也不让充电，那可以停到什么地方呢，望回复，谢谢!', time: '2021-09-26 19:33:56', type: '其他', status: '', answer: '', answerPerson: '' },
      ],
      eventDataList: [], // 接口获取的事件数据
      loading: false,
      listFinished: false,
      current: 0, // 当前页码
      size: 10,
      total: 0, // 总数
    };
  },
  components: {},
  mounted() {
    this.getTypeList();
  },
  computed: {
    ...mapState({
      selectedItem: (state) => state.butler.selectedItem,
    }),
  },
  methods: {
    addEvent(){
      this.$router.push('eventAdd');
    },
    handleTabSelect(name, title) {
      console.log(name, title);
      this.current = 0;
      this.$store.commit('butler/setSelectedItem', name);
      this.onLoad();
    },
    getTypeList() {
      api.getIncidentReportTypes().then(res => {
        if(res.flag) {
          this.typeList = res.data.map(item => item.dictLabel);
          if (!this.selectedItem) {
            this.$store.commit('butler/setSelectedItem', this.typeList[0]);
          }
        }
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    onLoad() {
      // console.log('loading')
      this.current = this.current + 1;
      this.getEventDataList();
    },
    getEventDataList({ page = this.current, size = this.size } = {}) {
      // console.log('page', page);

        this.loading = false;
      api.getClassicEventList({
        page,
        size,
        object: {
          incidentType: this.selectedItem,
        }
      }).then(res => {
        const { list = [], total = 0 } = res.data || {};
        if(page === 1) {
          this.eventDataList = list;
        } else {
          this.eventDataList = Array.from(new Set(this.eventDataList.concat(list)))
        }
        this.total = total;
        if (this.eventDataList.length >= this.total) {
          // 数据全部加载完成
          this.listFinished = true;
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  padding: 10px;
  .event-list{
    .item{
      background: #fff;
      margin-top: 10px;
      border-radius: 5px;
      height: 100px;
      padding: 10px;
      position: relative;
      .desc{
        line-height: 30px;
        font-size: 16px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: rgba(0,0,0,0.85);
      }
      .time{
          line-height: 20px;
          font-size: 12px;
          color: #707070;
      }
      .tag{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 50px;
        text-align: center;
        height: 20px;
        line-height: 20px;
        background: #08f;
        color: #fff;
      }
    }
  }
}
.addYs{
  position: fixed;
  bottom: 50px;
  right: 10px;
  width: 52px;
  height: 52px;
  line-height: 16px;
  padding: 10px 10px;
  text-align: center;
  background: #3E71F9;
  color: #fff;
  border-radius: 50%;
}
</style>
