<template>
  <div class="micro-energy-container">
    <van-nav-bar title="党组织介绍" left-arrow @click-left="goBack" />
    <div class="introduce-body-container">
      <!-- <div class="iframe-nav1">
        <iframe id="myiframe" scrolling="auto" frameborder="0" width="100%" height="100%" src="http://ddsq.ikeqiao.net:8071/demo/index.html#/wyDetail" ></iframe>
      </div> -->
      <div class="pulse-sub2">
        <!-- <div class="sub">浣东街道东盛社区党总支</div> -->
        <div class="pulse-info">
          <div class="addr"><i></i>润泽大院大厅</div>
          <div class="comminfo">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/wy.jpg" />
            <div>
              浙江宝业物业服务有限公司成立于1999年12月，注册资金5100万元，具有国家物业服务一级资质，隶属于宝业集团。
              公司接管物业的类型有别墅、多层公寓、高层公寓、写字楼、酒店式高层公寓、厂房、综合市场等等公用事业，接管物业的总建筑面积达1000余万平方米。
              公司遵循“以业主为本、创优质服务”的企业宗旨，坚持“倾情服务，永不止步”的质量方针，采取吸收和借鉴国内外优秀物业管理企业的经验，打造具有宝业特色的服务品牌，实现“服务社会，造福业主”的企业目标。
              公司已通过ISO9001、ISO14001、BS OHSAS18001管理体系认证，已取得AAA级信用等级认证证书，在具体业务管理上，宝业物业已建立了完善的企业内部运作制度和各项操作流程，人事管理、财务管理及客户服务的管理等各项工作都做到了有章可循。
              公司设有总经理办公室、财务部、综合部、质量管理部等部门和30多个管理处，并在浙江、安徽、江苏、湖北、河南等地设立分(子)公司。公司目前拥有员工1500多人，托管项目多次获得省市级优秀小区荣誉称号。
              公司积多年来的物业管理成功经验，在物业管理服务中奉行“维护精心、护卫严密、保洁勤奋、服务优质”准则，为客户提供全天候、全过程优质物业管理服务，以实现物业的保值增值。
              宝业物业将继续尽心尽力为业主提供高品质的服务，营造美好生活氛围，不断追求服务创新， 努力提升业主满意度，构建和谐、健康、舒适的现代化生活新社区。
            </div>
          </div>
        </div>
      </div>
      <div class="wy-manager">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/wzx.jpg" />
        <div>
          <p>物业经理<b>祝汉华</b></p>
          <p><span>13757501959</span></p>
          <div class="title-con"><i class="title-icon"></i>中共党员</div>
        </div>
      </div>
      <div class="wy-detail">
        <div class="bd-box">
          <div class="tab"><i></i><b>物业管家信息表</b></div>
          <div class="table">
            <table>
              <tr>
                <th>姓名</th>
                <th>联系方式</th>
              </tr>
              <tr>
                <td>钱金<i class="dangyuan"></i></td>
                <td>13867517828</td>
              </tr>
              <tr>
                <td>何涛</td>
                <td>15158298816</td>
              </tr>
              <tr>
                <td>许亚玲</td>
                <td>15167027972</td>
              </tr>
              <tr>
                <td>张亚娟</td>
                <td>18606755987</td>
              </tr>
              <tr>
                <td>王官良</td>
                <td>15067518501</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="bd-box">
          <div class="tab"><i></i><b>办公室人员休息排班表</b></div>
          <div class="table">
            <table>
              <tr>
                <th>姓名</th>
                <th>联系方式</th>
                <th>排休日</th>
              </tr>
              <tr>
                <td>高丽芳</td>
                <td>17757500713</td>
                <td>周一</td>
              </tr>
              <tr>
                <td>钱金</td>
                <td>13867517828</td>
                <td>周二</td>
              </tr>
              <tr>
                <td>许亚玲</td>
                <td>15167027972</td>
                <td>周三</td>
              </tr>
              <tr>
                <td>何涛</td>
                <td>15158298816</td>
                <td>周四</td>
              </tr>
              <tr>
                <td>朱燈辉</td>
                <td>18806850783</td>
                <td>周五</td>
              </tr>
              <tr>
                <td>罗文</td>
                <td>17771587031</td>
                <td>周六</td>
              </tr>
              <tr>
                <td>赵学者</td>
                <td>18305851063</td>
                <td>周日</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="bd-box">
          <div class="tab"><i></i><b>香水湾东南门岗早送晚迎排班表</b></div>
          <div class="table">
            <table>
              <tr>
                <th>日期</th>
                <th>东门早送</th>
                <th>西门早送</th>
                <th>东门晚迎</th>
                <th>西门晚迎</th>
              </tr>
              <tr>
                <td>周一</td>
                <td>何志力</td>
                <td>张杏</td>
                <td>何前</td>
                <td>王方明</td>
              </tr>
              <tr>
                <td>周二</td>
                <td>孙龙</td>
                <td>张杏</td>
                <td>王方明</td>
                <td>郑世海</td>
              </tr>
              <tr>
                <td>周三</td>
                <td>杜建波</td>
                <td>何志力</td>
                <td>谢云辉</td>
                <td>何文明</td>
              </tr>
              <tr>
                <td>周四</td>
                <td>陈发梅</td>
                <td>张杏</td>
                <td>何前</td>
                <td>郑世海</td>
              </tr>
              <tr>
                <td>周五</td>
                <td>何志力</td>
                <td>黎菊芳</td>
                <td>王方明</td>
                <td>何文明</td>
              </tr>
              <tr>
                <td>周六</td>
                <td>何国军</td>
                <td>孙龙</td>
                <td>何前</td>
                <td>郑世海</td>
              </tr>
              <tr>
                <td>周日</td>
                <td>何涛</td>
                <td>杜建波</td>
                <td>王方明</td>
                <td>谢云辉</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="bd-box">
          <div class="tab"><i></i><b>管家巡查楼道排班表</b></div>
          <div class="table">
            <table>
              <tr>
                <th>时间</th>
                <th>姓名</th>
              </tr>
              <tr>
                <td>周一</td>
                <td>王官良</td>
              </tr>
              <tr>
                <td>周二</td>
                <td>许亚玲</td>
              </tr>
              <tr>
                <td>周三</td>
                <td>钱金</td>
              </tr>
              <tr>
                <td>周四</td>
                <td>张亚娟</td>
              </tr>
              <tr>
                <td>周五</td>
                <td>于彩萍</td>
              </tr>
              <tr>
                <td>周六</td>
                <td>高丽芳</td>
              </tr>
              <tr>
                <td>周日</td>
                <td>何涛</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
  },
};
</script>

<style lang="scss" scope>
.micro-energy-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .introduce-body-container {
    height: calc(100% - 46px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    .iframe-nav1 {
      height: 100%;
      width: 100%;
    }
  }
}
.pulse-sub2 {
  width: 100%;
  padding: 0.5rem 0.8rem 0.8rem;
  background: url(http://dssq.ikeqiao.net/data/image/h5/images/pulse-bg2.png) no-repeat top #f7f7f7;
  background-size: 100% 10.7rem;
  .sub {
    margin-top: 1.5rem;
    font: 500 1rem/1.8rem "microsoft yahei";
    color: #fff;
  }
  .pulse-info {
    .addr {
      height: 1.8rem;
      font: 500 0.85rem/1.8rem "microsoft yahei";
      color: #fff;
      i {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        width: 0.86rem;
        height: 1.1rem;
        margin-right: 0.3rem;
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/icon-addr.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .comminfo {
      background: #fff;
      border-radius: 0.5rem;
      box-shadow: #cbcbcb 0px 0.3rem 0.3rem;
      text-align: center;
      padding: 1.2rem 1rem;
      margin-top: 0.6rem;
      img {
        // display: inline-block;
        // width: 9.6rem;
        // height: 10.2rem;
        width: 100%;
        vertical-align: top;
        margin-bottom: 10px;
      }
      div {
        // display: inline-block;
        margin-left: 0.8rem;
        color: #888;
        // width: 10.8rem;
        width: calc(100% - 0.8rem);
        text-align: left;
        text-indent: 2em;
        font: 500 0.7rem/1.2rem "Microsoft Yahei";
      }
    }
    .people {
      position: relative;
      background: #fff;
      border-radius: 0.5rem;
      box-shadow: #cbcbcb 0px 0.3rem 0.3rem;
      text-align: center;
      padding: 4rem 2rem 1rem;
      img {
        position: absolute;
        top: -2rem;
        width: 5.6rem;
        height: 5.6rem;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
      }
      p {
        color: #000;
        font: bold 1rem/1.6rem "Microsoft Yahei";
        span {
          font-weight: 500;
          font-size: 0.9rem;
          color: #888888;
        }
      }
      .contact {
        font: 500 0.9rem/1.4rem "Microsoft Yahei";
        color: #888888;
        margin-top: 1.4rem;
        span {
          display: inline-block;
          padding: 0 0.7rem;
          color: #d62927;
          border-radius: 0.7rem;
          font: 500 0.8rem/1.4rem "Microsoft Yahei";
          margin-left: 0.6rem;
          background: #f5d9d8;
          i {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.3rem;
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/icon-tel2.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
.wy-manager {
  background: #fff;
  padding: 0.6rem 2rem;
  position: relative;
  img {
    display: inline-block;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 50%;
    vertical-align: middle;
  }
  .title-con {
    position: absolute;
    right: 0px;
    top: 10px;
    width: 80px;
    margin: 0;
    color: #ce1a21;
    font-size: 13px;
    display: flex;
    align-items: center;
    .title-icon {
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/title.png') no-repeat;
      background-size: 100% 100%;
      height: 11px;
      width: 10px;
      margin-right: 2px;
    }
  }
  div {
    display: inline-block;
    padding-top: 0.8rem;
    vertical-align: middle;
    width: 12rem;
    margin-left: 3.2rem;
    font: 500 0.8rem/1.6rem "Microsoft Yahei";
    color: #888;
    b {
      color: #000;
      font-size: 1rem;
      margin-left: 0.6rem;
    }
    span {
      font-size: 1rem;
      font-family: tahoma;
    }
  }
}
.wy-detail {
  background: #f7f7f7;
  padding: 0.8rem 0;
  .bd-box {
    background: #fff;
    padding: 0.5rem 0.8rem;
    margin-bottom: 0.8rem;
    .tab {
      height: 1.8rem;
      i {
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background: #d62927;
        vertical-align: middle;
      }
      b {
        font: bold 1rem/1.8rem "Microsoft Yahei";
        color: #726060;
        margin-left: 0.5rem;
        vertical-align: middle;
        font-style: italic;
      }
    }
    .table {
      padding: 0.6rem 0.8rem;
      table {
        width: 100%;
        border-top: 1px solid #e3e3e3;
        border-left: 1px solid #e3e3e3;
        text-align: center;
        border-collapse: collapse;
        border-spacing: 0;
        background: #f7f7f7;
        th,
        td {
          height: 1.8rem;
          border-right: 1px solid #e3e3e3;
          border-bottom: 1px solid #e3e3e3;
          font: 500 0.8rem/1.8rem "Microsoft Yahei";
          .dangyuan {
            background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/title.png') no-repeat;
            background-size: 100% 100%;
            height: 11px;
            width: 10px;
            display: inline-block;
          }
        }
        th {
          background: #8498ff;
          color: #fff;
        }
        td {
          padding: 0 0.8rem;
          color: #888;
        }
      }
    }
  }
}
</style>
