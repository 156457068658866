<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>发布服务</h1>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          readonly
          clickable
          name="classChildrenName"
          :value="classChildrenName"
          label="板块"
          placeholder="请选择"
          @click="showPicker = true"
          input-align="right"
          :rules="[{ required: true, message: '请选择板块' }]"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
          v-model="createUserName"
          name="createUserName"
          label="姓名"
          placeholder="请输入"
          input-align="right"
        />
        <van-field
          v-model="createUserPhone"
          name="createUserPhone"
          label="联系电话"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写联系电话' }]"
        />
        <van-field
          class="cost-input"
          v-model="invitationMoney"
          name="invitationMoney"
          label="服务积分"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写服务积分' }]"
        />
        <div class="section-textArea">
          <div class="section-label">擅长</div>
          <van-field
            v-model="ability"
            name="ability"
            rows="2"
            autosize
            label
            type="textarea"
            maxlength="50"
            placeholder="请输入"
            show-word-limit
          />
        </div>
        <div class="foot-button">
          <van-button block type="info" native-type="submit">立即发布</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import xlyzApi from "api/llyjr/xlyz";
import { Toast } from "vant";
import { mapState } from "vuex";
import context from '@/main';

export default {
  data() {
    return {
      classChildrenName: "",
      createUserName: "",
      createUserPhone: "",
      invitationMoney: "",
      ability: "",
      columns: ["心理咨询", "家庭陪聊"],
      showPicker: false
    };
  },
  computed: {
    ...mapState({
      userInfo: state =>
        (state.login.userInfo.nickname ? state.login.userInfo : null) ||
        (context.getSessionData("userInfo"))
    })
  },
  components: {},
  mounted() {
    this.createUserName = this.userInfo ? this.userInfo.nickname : "";
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    onSubmit(values) {
      xlyzApi.pushMentalHealing(values).then(res => {
        if (res.data == 1) {
          Toast.success("提交成功");
          this.gotoLink("xlyz");
        }
      });
    },
    onConfirm(value) {
      this.classChildrenName = value;
      this.showPicker = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;

  // .van-field__error-message {
  //   text-align: right;
  // }

  .section-textArea {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
  .cost-input {
    padding-right: 55px;
    position: relative;
    &:before {
      content: "/次";
      display: inline-block;
      position: absolute;
      right: 16px;
    }
  }
}
</style>
