<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我要调解</h1>
      </div>
      <van-tabs v-model="active" @click="handleTabSelect" color="#4379ed">
        <van-tab title="发布调解" name="发布调解">
          <van-form @submit="onSubmit">
            <van-field
              v-model="type"
              name="keyType"
              label="调解类型"
              placeholder="请选择"
              input-align="right"
              is-link
              readonly
              @click="showType = true"
              :rules="[{ required: true, message: '请选择调解类型' }]"
            />
            <div class="section-textArea">
              <div class="section-label">问题描述</div>
              <van-field
                v-model="content"
                name="keyQuestion"
                rows="2"
                autosize
                label
                type="textarea"
                maxlength="400"
                placeholder="请输入"
                :rules="[{ required: true, message: '请填写问题描述' }]"
                show-word-limit
              />
            </div>
            <van-field
              v-model="sfsm"
              name="isComeDoor"
              label="是否上门"
              placeholder="请选择"
              input-align="right"
              is-link
              readonly
              @click="shangmenType = true"
              :rules="[{ required: true, message: '请选择是否上门' }]"
            />
            <van-field
              v-if="sfsm === '是'"
              v-model="address"
              name="location"
              label="所在位置"
              placeholder="请输入"
              input-align="right"
              :rules="[{ required: true, message: '请填写所在位置' }]"
            />
            <div class="foot-button">
              <van-button block type="info" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </van-tab>
        <van-tab title="提交记录" name="提交记录">
          <div class="tab-container">
            <div v-for="item in recordList" :key="item.id" class="record-card">
              <div class="record-card-top">
                <div class="record-card-top-time">
                  <!-- <img src="" alt=""> -->
                  <div>{{item.createTime}}</div>
                </div>
                <div :style="{'color': colors[item.dealStatus] || '#4479ED'}">{{item.dealStatus}}</div>
              </div>
              <div class="record-card-body">
                <div class="record-card-row">
                  <span class="record-card-row-label">帮助类型：</span>
                  <span>{{item.keyType}}</span>
                </div>
                <div class="record-card-row">
                  <span class="record-card-row-label">是否上门：</span>
                  <span>{{item.isComeDoor}}</span>
                </div>
                <div class="record-card-row">
                  <span class="record-card-row-label">所在位置：</span>
                  <span>{{item.location}}</span>
                </div>
              </div>
              <div class="record-card-foot" @click="gotoLink(`jystjInfo?id=${item.id}`)">
                <div class="record-card-foot-button">查看</div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup v-model="showType" position="bottom">
      <van-picker
        title="问题类型"
        show-toolbar
        :columns="typeList"
        @confirm="onTypeConfirm"
        @cancel="onCancel('showType')"
      />
    </van-popup>
    <van-popup v-model="shangmenType" position="bottom">
      <van-picker
        title="是否上门"
        show-toolbar
        :columns="['是', '否']"
        @confirm="onSfsmTypeConfirm"
        @cancel="onCancel('shangmenType')"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import jystjApi from 'api/jystj';

export default {
  data() {
    return {
      showType: false,
      shangmenType: false,
      type: '',
      sfsm: '',
      content: '',
      address: '',
      typeList: ['邻里矛盾', '社区问题', '其他'],
      recordList: [],
      colors: {
        '待处理': '#4479ED',
        '处理中': '#E5B20A',
        '不接收': 'rgba(0,0,0,0.45)',
      },
    };
  },
  computed: {
    active: {
      get() {
        return this.$store.state.allOther.jystjTab;
      },
      set(data) {
        this.$store.commit('allOther/setJystjTab', data)
      }
    }
  },
  components: {},
  mounted() {
    this.getMyJystjRecord();
  },
  methods: {
    gotoDirectLink(path) {
      this.$router.replace(path);
    },
    handleTabSelect(name, title) {
      this.$store.commit('allOther/setJystjTab', name);
    },
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    onTypeConfirm(value, index) {
      this.type = value;
      this.showType = false;
    },
    onSfsmTypeConfirm(value, index) {
      this.sfsm = value;
      this.shangmenType = false;
    },
    onCancel(type){
      this[type] = false;
    },
    getMyJystjRecord() {
      jystjApi.getMyJystjRecord().then(res => {
        if(res.flag) {
          this.recordList = res.data || [];
        } else {
          this.recordList = [];
        }
      })
    },
    onSubmit(values) {
      jystjApi.addJystj(values).then(res => {
        const { message,data, flag } = res;
          if (flag) {
            Toast.success('申请提交成功');
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            Toast(message);
          }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .white-page {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 80vw;
    }
  }
  // .van-field__error-message {
  //   text-align: right;
  // }

  .section-textArea {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .tab-container {
    width: 100%;
    height: 100%;
    padding: 12px;
    .record-card {
      background: #FFF;
      margin-bottom: 12px;
      border-radius: 4px;
      .record-card-top {
        width: 100%;
        height: 36px;
        border-bottom: 1px solid #f6f7fa;
        display: flex;
        align-items: center;
        padding: 12px;
        justify-content: space-between;
        .record-card-top-time {
          display: flex;
          color: rgba(0,0,0,0.45);
        }
        
      }
      .record-card-body {
        padding: 12px;
        width: 100%;
        .record-card-row {
          width: 100%;
          line-height: 18px;
          .record-card-row-label {
            color: rgba(0,0,0,0.45);
          }
        }
        
      }
      .record-card-foot {
        width: 100%;
        border-top: 1px solid #f6f7fa;
        .record-card-foot-button {
          width: 100%;
          height: 36px;
          line-height: 36px;
          text-align: center;
          color: #4379ed;
        }
      }
    }
  }

  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
