<template>
  <div :class="$style.urbanRuralLinkage">
    <van-nav-bar
      title="城乡联动"
      left-arrow
      @click-left="onClickLeft"
    />
    <div :class="$style.bodyContainer">
      <!-- imageBar -->
      <img :src="`${imageUrl}cxld-bar-bg.png`" :class="$style.imageBar" alt="">
      <!-- structure -->
      <div style="display: flex">
        <wx-open-launch-weapp
          id="launch-btn2"
          username="gh_3eafbda3e6d2"
        >
          <script type="text/wxtag-template">
            <div style="margin-left: 15px;width: 3.2rem;">
              <img src="http://dssq.ikeqiao.net/data/image/dongsheng/partyBuilding/dhxc-icon.png" style="height: 3.2rem;width: 3.2rem" />
              <p style="font-size: 0.8rem;text-align: center;">杜黄新村</p>
            </div>
          </script>
        </wx-open-launch-weapp>
        <div style="margin-left: 15px;width: 3.2rem" @click="gotoLink('yqpddPmPy')">
          <img style="height: 3.2rem;width: 3.2rem" src="../../assets/images/dsyx.png" alt="">
          <p style="font-size: 0.8rem;text-align: center; margin-top: 8px">共富联盟</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import { init } from 'utils/common'
export default {
  data() {
    return {
      imageUrl: 'http://dssq.ikeqiao.net/data/image/dongsheng/partyBuilding/',
      iconList: [
        // {
        //   name: '杜黄新村',
        //   icon: 'dhxc-icon'
        // }
      ]
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    init();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" module>
.urbanRuralLinkage {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  
  .bodyContainer {
    height: calc(100vh - 46px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    .imageBar {
      width: 100%;
      height: 160px;
    }

    .iconWrapper {
      width: calc(100% - 24px);
      margin: 12px;
      background: #FFFFFF;
      padding: 18px 9px;
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      .iconItem {
        display: flex;
        width: 25%;
        flex-direction: column;
        justify-content: center;
        font-size: 0.8rem;
        // margin: 0 18px;
      }
    }

  }
}
</style>
