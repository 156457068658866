<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>找专家</h1>
      </div>
      <van-tabs @click="handleTabSelect" v-model="selectedItem" color="#4479ED">
        <van-tab v-for="item in typeList" :key="item"  :title="item" :name="item">
        </van-tab>
      </van-tabs>
      <div class="event-list">
        <div class="item" v-for="(item, i) in eventList.filter(v => v.type === selectedItem)" :key="i">
          <div class="image">
            <img :src="item.icon" alt="">
          </div>
          <div class="text">
            <div class="name">{{item.name}}</div>
            <div class="desc">{{item.zw}}</div>
            <div class="desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      selectedItem: '律师',
      typeList: ['律师', '心理咨询师', '婚姻调解员', '社会工作者', '老娘嫂'],
      eventList: [
        {
          name: '胡杰丰',
          zw: '金诸暨律师事务所',
          desc: '擅长法治宣传教育、矛盾纠纷调解、法律援助帮扶等。',
          type: '律师',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/hjf.jpg',
        },
        {
          name: '袁胤',
          zw: '国学二级心理咨询师',
          desc: '擅长：她是一位爱家爱生活的妻子和妈妈，同时也是一位拥有十余年家庭教育经历的教育人。在多年的教育工作中，她接触了大量的孩子和家长，深知家庭教育对人一生的重要影响。',
          type: '心理咨询师',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/yy1.jpg',
        },
        {
          name: '邱琴琴',
          zw: '浣东街道东盛社区调解员',
          desc: '擅长调解社区矛盾纠纷、解答各类民生问题',
          type: '婚姻调解员',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/qqq.jpg',
        },
        {
          name: '吴亚红',
          zw: '浣东街道东盛社区调解员',
          desc: '擅长调解社区矛盾纠纷、解答各类民生问题。',
          type: '婚姻调解员',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/wyh1.jpg',
        },
        {
          name: '胡丽芳',
          zw: '亲民工作室社工',
          desc: '擅长为居民提供咨询、帮扶特困群体、设计文化娱乐活动。',
          type: '社会工作者',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/hlf.jpg',
        },
        {
          name: '茅美凤',
          zw: '邻阿姨社会组织成员',
          desc: '擅长调解邻里纠纷、促进和谐关系',
          type: '老娘嫂',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/mmf.png',
        },
        {
          name: '严文叶',
          zw: '邻阿姨社会组织成员',
          desc: '擅长调解邻里纠纷、促进和谐关系。',
          type: '老娘嫂',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/yyw.png',
        },
      ],
    };
  },
  components: {},
  mounted() {
  },
  methods: {
    handleTabSelect(name, title) {
      console.log(name, title);
      this.selectedItem = title;
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  padding: 10px;
  .event-list{
    .item{
      background: #fff;
      margin-top: 10px;
      border-radius: 5px;
      // height: 100px;
      padding: 10px;
      position: relative;
      display: flex;
      .image{
        width: 80px;
        margin-right: 10px;
        height: 80px;
        border-radius: 50%;
        >img{
          width: 100%;
          height: 100%;
        }
      }
      .text{
        width: calc(100% - 90px);
      }
      .name{
          line-height: 30px;
          font-size: 16px;
          color: #000;
      }
      .desc{
          line-height: 20px;
          font-size: 14px;
          color: rgba(0,0,0,0.65);
      }
      .tag{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 50px;
        text-align: center;
        height: 20px;
        line-height: 20px;
        background: #08f;
        color: #fff;
      }
    }
  }
}
</style>
