<template>
  <div class="zljgDetail" style="background: #fff; height: 100vh">
    <div class="page-container-zljg paddclose" style="height: calc(100vh);overflow-y: auto;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>{{title}}</h1>
      </div>
      <div class="zljg-main" style="width: 100%;">
        <div class="item" v-for="(v, i) in (list.length > 0 ? [...list.filter(v => v.value1 !== '其他'), ...list.filter(v => v.value1 === '其他')]: [])" :key="i" @click="choiceItem(v)">
          <div class="title">
            <div class="line-v"></div>
            <div>{{v.value1}}</div>
            <div style="color: rgba(0,0,0,0.5);">({{v.value3 || v.value2}})</div>
            <van-icon :name="selectItem.indexOf(v.value1) > -1 ? 'arrow-down' : 'arrow'" />
          </div>
          <div v-if="selectItem.indexOf(v.value1) > -1">
            <div class="person-item" v-for="(item, index) in (v.listData || [])" :key="index" @click="choiceItem(v)">
              <div class="image"><img src="../../assets/images/personBig.png" alt=""></div>
              <div class="text">
                <div>
                  <!-- 机关在职党员 -->
                  {{key==='100' ? item.value2 : ''}}
                  <!-- 业委会 -->
                  {{key==='101' ? item.value3 : ''}}
                  <span v-if="key==='101'" class="tag">{{item.value2}}</span>
                </div>
                <!-- 机关在职党员 -->
                <!-- <div v-if="key==='100'">
                  <span class="label">学历:</span>
                  <span class="value">{{item.value3}}</span>
                </div>
                <div v-if="key==='100'">
                  <span class="label">住址:</span>
                  <span class="value">{{item.value5}}</span>
                </div> -->
                <!-- 业委会 -->
                <div v-if="key==='101'">
                  <span class="label">政治面貌:</span>
                  <span class="value">{{item.value6}}</span>
                </div>
                <div v-if="key==='101'">
                  <span class="label">联系电话:</span>
                  <span class="value">{{handleHidden(item.value5)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import {mapState} from 'vuex';
import service from 'utils/service';
import homeApi from 'api/home';
export default {
  data() {
    return {
      selectItem: [],
      list: [],
      key: '',
      title: '',
    };
  },
  components: {},
  mounted(opt) {
      const { key, name } = this.$route.query;
      this.key = key;
      this.title = name;
      homeApi.getParty({ id: key }).then((res) => {
        const { data = [] } = res;
        this.list = data;
      });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleHidden(phone) {
      return phone.substr(0,3) + "********"
    },
    choiceItem(item){
      console.log(item)
      if (this.selectItem.indexOf(item.value1) > -1) {
        this.selectItem = this.selectItem.filter(v => v !== item.value1)
        console.log(1111, this.selectItem)
      } else {
        this.selectItem = [...this.selectItem, item.value1]
        console.log(22222, this.selectItem)
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";
.zljgDetail{
  .zljg-main{
    height: 100vh;
    padding: 10px;
    padding-top: 2.7rem;
    background: #f6f7fa;
    .item{
      padding: 10px;
      margin-top: 10px;
      background: #FFFFFF;
      border-radius: 8px;
      .title{
        line-height: 30px;
        // height: 30px;
        display: flex;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
        font-weight: 500;
        >div{
          line-height: 30px;
        }
        .line-v{
          width: 4px;
          height: 18px;
          background: #3E71F9;
          margin: 6px;
        }
        .van-icon{
          margin-left: auto;
          margin-right: 0px;
          margin-top: 6px;
          font-size: 18px;
          color: #a5aaaf
        }
      }
      .subTitle{
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        font-weight: 400;
        padding: 6px;
      }
      .person-item{
        padding: 10px;
        display: flex;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        .image{
          width: 120px;
          height: 100px;
          margin-right: 10px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          width: calc(100% - 130px);
          font-size: 14px;
          color: rgba(0,0,0,0.85);
          font-weight: 500;
          line-height: 24px;
          .label{
            font-weight: 400;
            color: rgba(0,0,0,0.45);
            margin-right: 10px;
          }
          .value{
            font-weight: 400;
          }
          .tag{
            font-size: 12px;
            margin-left: 10px;
            padding: 3px 6px;
            color: #3E71F9;
            background: rgba(62,113,249,0.20);
            border-radius: 10px;
          }
        }
      }
    }
  }
}
</style>
