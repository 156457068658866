<template>
  <div id="index">
    <div class="jfdhmx-page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>兑换明细</h1>
      </div>
      <div class="info-nav white-bg">
        <div style="position: relative;">
          <h3 class="title-text">{{data.orderCommodityName || '暂无名称'}}</h3>
          <span class="tag">{{data.orderConditionText}}</span>
        </div>
        
        <div class="card-con">
          <img :src="data.orderCommodityImage" class="image" />
          <div class="right-info">
            <div class="header">
              <!-- <span style="color: rgba(0,0,0,0.85);">积分：</span> -->
              <div class="jf">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jifen.png" />
                <span>{{`${data.orderCommodityIntegral}积分`}}</span>
              </div>
            </div>
            <div class="row">
              <span>{{`兑换数量：${data.orderExchangeNum || 0}`}}</span>
            </div>
          </div>
        </div>
        <div>
          <span class="gray">商品类型：</span>
          <span>{{data.commodityTypeName}}</span>
        </div>
        <!-- <div>
          <span class="gray">商品种类：</span>
          <span>{{data.position}}</span>
        </div> -->
        <div v-if="data.position !== '实物类'">
          <span class="gray">兑换时间：</span>
          <span v-if="data.orderCommodityEffectiveType == 0">长期有效</span>
          <span v-else-if="data.orderCommodityEffectiveType == 1">{{`${data.orderCommodityEffectiveStartTime || '暂无'} ~ ${data.orderCommodityEffectiveEndTime || '暂无'}`}}</span>
          <span v-else-if="data.orderCommodityEffectiveType == 2">{{`${data.orderCommodityEffectiveEndTime}之前`}}</span>
        </div>
        <div v-if="data.position !== '实物类'">
          <span class="gray">使用地点：</span>
          <span>{{data.orderCommodityRedemptionLocation}}</span>
        </div>
      </div>
      <div class="jfdh-ticket-divider"></div>
      <div v-if="data.position === '实物类'" style="position: relative;" class="padding-12 white-bg">
        <h3 class="title-text">商品状态</h3>
        <span class="tag">待发货</span>
        <div>
          <span class="gray">物流单号：</span>
          <span>暂无</span>
        </div>
      </div>
      <div v-else class="ticket-nav white-bg">
        <h3>券码：{{data.orderCouponCode}}</h3>
        <img :src="data.codeImage || 'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/QRCode.jpg'" alt="暂无券码">
        <div class="gray title-text">出示二维码或券码给工作人员</div>
        <div v-if="data.orderConditionText === '已兑换'" class="status-tag"><img src="http://dssq.ikeqiao.net/data/image/h5/images/ddya/ysy.png" alt=""></div>
        <div v-if="data.orderConditionText === '已过期'" class="status-tag"><img src="http://dssq.ikeqiao.net/data/image/h5/images/ddya/ygq.png" alt=""></div>
      </div>
      <div class="jfdh-ticket-divider"></div>
      <div class="padding-12 white-bg">
        <h3 class="title-text">订单信息</h3>
         <div v-if="data.position === '实物类'">
          <span class="gray">收货信息：</span>
          <span>{{data.address}}</span>
        </div>
        <div>
          <span class="gray">订单编号：</span>
          <span>{{data.orderSerialNumber}}</span>
        </div>
        <div>
          <span class="gray">下单时间：</span>
          <span>{{data.createTime	}}</span>
        </div>
        <div v-if="data.orderCondition !== 0">
          <span class="gray">兑换时间：</span>
          <span>{{data.orderExchangeTime}}</span>
        </div>
      </div>
      
    </div>
    <div class="jfdh-ticket-footer">
      <div class="jfdh-ticket-foot-button" @click="handleConsult(data)">
        我要咨询
      </div>
      <div v-if="data.orderCondition !== 0" class="jfdh-ticket-foot-button" @click="handleDelete(data)">
        删除订单
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import jfdhApi from 'api/ddya/jfdh';
import {jfdhShoping, dhRecords} from 'assets/json/jfdh';
export default {
  data() {
    return {
      data: {},
      ticketId: '',
    };
  },
  components: {},
  mounted() {
    const { ticketId } = this.$route.query;
    this.ticketId = ticketId;
    this.getdata({ commodityId: ticketId });
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    getdata({ orderId = this.ticketId }) {
      jfdhApi.getOrderDetail({ orderId }).then((res) => {
        this.data = res.data || {}
      })
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleDelete(data) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认删除？',
      })
        .then(() => {
          // on confirm
          jfdhApi.deleteOrder({ orderId: data.orderId }) .then(res => {
            if (res.flag) {
              this.$toast('删除成功');
              const timer = setTimeout(() => {
                this.$router.go(-1);
                clearTimeout(timer);
              }, 500)
            }
          })
          
          
        })
        .catch(() => {
          // on cancel
        });
    },
    handleConsult(data) {
      this.$dialog.confirm({
        title: '拨打电话',
        message: data.orderCommodityPeoplePhone || '暂无联系电话',
        confirmButtonText: data.orderCommodityPeoplePhone ? '确认拨打' : '确定',
        confirmButtonColor: '#4479ED',
      })
        .then(() => {
          // on confirm
          if(data.orderCommodityPeoplePhone) {
            window.location.href = `tel://${data.orderCommodityPeoplePhone}`
          }
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.jfdhmx-page-container {
  background: #f6f7fa;
  padding: 0.6rem 0;
  height: calc(100vh - 7.7rem);
  width: 100vw;
  margin-top: 40px;
  font-size: 0.9rem;
  color: rgba(0,0,0,0.85);
  line-height: 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  .white-bg {
    background: #FFF;
  }
  .gray {
    color: rgba(0,0,0,0.45);
  }
  .padding-12 {
    padding: 12px;
  }
  .title-text {
    font-size: 1.07rem;
    font-weight: 500;
    line-height: 1.6rem;
  }
  .info-nav {
    width: 100%;
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    .card-con {
      display: flex;
      align-items: flex-start;
      padding-bottom: 1rem;
      .image {
        height: 5rem;
        width: 5rem;
        border-radius: 10px;
        margin-right: 1rem;
      }
      .right-info {
        flex: 1;
        .header {
          display: flex;
          align-items: center;
          .title-con {
            display: flex;
            align-items: center;
            span {
              background: rgba(227,239,253,0.9);
              font-size: 12px;
              color: #4479ED;
              padding: 4px;
              border-radius: 4px;
              margin-left: 10px;
            }
          }
          .jf {
            height: 24px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #e5b20a;
            img {
              height: 14px;
              width: 14px;
              margin-right: 4px;
            }
          }
        }
        .row {
          color: rgba(0,0,0,0.65);
          line-height: 24px;
          .number{ 
            float: right;
          }
        }
      }
    }
    .info-footer {
      flex: 1;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0,0,0,0.65);
    }
  }
  .jfdh-ticket-divider {
    height: 0.8rem;
    background: #f6f7fa;
    width: 100%;
  }
  .tag {
    background: #E3EFFD;
    color: #4479ed;
    font-size: 0.65rem;
    border-radius: 4px 2px 4px 2px;
    padding: 2px 6px;
    position: absolute;
    top: 12px;
    line-height: 1rem;
    right: 12px;
  }
  .ticket-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    position: relative;
    img {
      height: 30vw;
      width: 30vw;
      margin: 1rem 0;
      border: 1px solid #f5f5f5;
    }
    .status-tag {
      position: absolute;
      top: 3.5rem;
      left: 50vw;
      width: 15vw;
      height: 15vw;
      >img {
        width: 100%;
        height: 100%;
      }
    }
  }

}
.jfdh-ticket-footer {
  position: fixed;
  height: 5rem;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  // box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: space-around;
  .foot-left {
    font-size: 14px;
    span:nth-child(2) {
      font-size: 24px;
      color: #e5b20a;
      font-weight: 500;
    }
    span:nth-child(3) {
      color: #e5b20a;
    }
  }
  .jfdh-ticket-foot-button {
    position: unset;
    flex: 1;
    height: 3rem;
    border-radius: 1.5rem;
    margin: 0 4px;
    line-height: 3rem;
    text-align: center;
    color: #4379ed;
    border: 1px solid #4379ed;
    font-size: 18px;
  }
}
</style>