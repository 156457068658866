<template>
  <div class="butler-container">
    <van-nav-bar
      class="butler-nav-bar"
      title="合·管家"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="butler-body-container">
      <div class="image-bar"></div>
      <!-- 环物委 -->
      <!-- <div class="card-bar">
        <div class="card-bar-title envoriment">
          <span class="text-title">环物委</span>
        </div>
        <div class="card-bar-content">
          <div class="card-bar-con">
            <div class="card-bar-left" @click="gotoLink('huanwuwei')">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/butler/page.png" alt="" />
              <span>环物委介绍</span>
            </div>
            <div class="card-bar-right">
              <div class="coin-bar-con">
                <div class="coin-bar" @click="gotoLink('recommendList')">
                  <h1>社区推荐</h1>
                </div>
                <div class="coin-bar map" @click="gotoLink('goldenIdea')">
                  <h1>社区金点子</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- 红色物业 -->
      <!-- <div class="card-bar">
        <div class="card-bar-title property">
          <span class="text-title">红色物业</span>
        </div>
        <div class="card-bar-content">
          <div class="card-bar-con">
            <div class="card-bar-left" @click="gotoLink('introduce')">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/butler/record.png" alt="" />
              <span>党组织简介</span>
            </div>
            <div class="card-bar-right">
              <div class="coin-bar-con">
                <div class="coin-bar property"></div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="card-bar">
        <div class="card-bar-title property">
          <span class="text-title">红色物业</span>
        </div>
        <div class="card-bar-content">
          <div class="icon-bar">
            <div
              v-for="(icon, index) in iconList1"
              :key="`${icon.name}_${index}`"
              @click="gotoLink(icon.path)"
            >
              <img class="icon" :src="icon.icon" :alt="icon.name" />
              <p>{{ icon.name }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 县乡一体 -->
      <!-- <div class="card-bar">
        <div class="card-bar-title country">
          <span class="text-title">县乡一体</span>
        </div>
        <div class="card-bar-content">
          <div class="icon-bar">
            <div
              v-for="(icon, index) in iconList"
              :key="`${icon.name}_${index}`"
              @click="gotoLink(icon.path)"
            >
              <img class="icon" :src="icon.icon" :alt="icon.name" />
              <p>{{ icon.name }}</p>
            </div>
          </div>
        </div>
      </div> -->

      <!-- 基层站所 -->
      <!-- <div class="card-bar">
        <div class="card-bar-title">
          <span class="text-title">基层站所</span>
        </div>
        <div class="card-bar-content">
          <van-tabs v-model="tabSelect" color="#4479ED">
            <van-tab title="综合执法" name="综合执法">
              <div class="card-bar-content">
                <div class="icon-bar">
                  <div
                    v-for="(icon, index) in jczsList"
                    :key="`${icon.name}_${index}`"
                    @click="gotoLink(icon.path)"
                  >
                    <img class="icon" :src="icon.icon" :alt="icon.name" />
                    <p>{{ icon.name }}</p>
                  </div>
                </div>
              </div>
              <departList type="综合执法"></departList>
            </van-tab>
            <van-tab title="市场监督" name="市场监督">
              <div class="card-bar-content">
                <div class="icon-bar">
                  <div
                    v-for="(icon, index) in marketList"
                    :key="`${icon.name}_${index}`"
                    @click="gotoLink(icon.path)"
                  >
                    <img class="icon" :src="icon.icon" :alt="icon.name" />
                    <p>{{ icon.name }}</p>
                  </div>
                </div>
              </div>
               <departList type="市场监督"></departList>
            </van-tab>
            <van-tab title="派出所" name="派出所">
               <departList type="派出所"></departList>
            </van-tab>
            <van-tab title="应急管理" name="应急管理">
               <departList type="应急管理"></departList>
            </van-tab>
          </van-tabs>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import { mapMutations, mapState } from "vuex";
import DepartList from "./departList.vue";
import MarketSuper from "./marketSuper.vue";
import PoliceStation from "./policeStation.vue";
import Contingency from "./contingency.vue";
export default {
  data() {
    return {
      iconList: [
        // {
        //   name: "基层联建",
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/setting.png",
        // },
        {
          name: "共治热力图",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/fire.png",
        },
        {
          name: "六包承诺",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/protect.png",
          path: 'liubaochengnuo'
        },
        {
          name: "红黑榜",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/collect.png",
        },
      ],
      stationList: ['综合执法', '市场监督', '派出所', '应急管理'],
      jczsList: [
        // {
        //   name: "基层联建",
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/setting.png",
        // },
        {
          name: "违停自助处理",
          path: 'wtzzcl',
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/wtzjcl.png",
        },
      ],
      marketList: [
        {
          name: "市场监管职责",
          path: 'scjgzz',
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/scjgzz.png",
        },
        {
          name: "信用信息公示",
          path: 'xyxxgs',
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/xyxxgs.png",
        },
        {
          name: "申请营业执照",
          path: 'sqyyzz',
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/sqyyzz.png",
        },
      ],
      iconList1: [
        // {
        //   name: "基层联建",
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/setting.png",
        // },
        {
          name: "我要上报",
          path: 'eventAdd',
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/woyaoshangbao-icon.png",
        },
        {
          name: "典型事件",
          path: 'eventList',
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/dianxingshijian-icon.png",
        },
        {
          name: "环物委",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/huanwuwei-icon.png",
          path: 'huanwuwei',
        },
        // {
        //   name: "业委会",
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/yeweihui-icon.png",
        //   path: 'yeweihui'
        // },
        // {
        //   name: "物业平台",
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/butler/wuyepingtai-icon.png",
        // },
      ],
      
    };
  },
  components: { DepartList, Contingency, PoliceStation, MarketSuper },
  mounted() {},
  watch: {},
  methods: {
    ...mapMutations('butler', [
        'setSelectedStation',
    ]),
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      } else {
        this.$toast('应用开发中')
      }
    },
  },
  computed: {
    ...mapState({
      selectedStation: (state) => state.butler.selectedStation,
    }),
    tabSelect: {
      get() {
        return this.selectedStation;
      },
      set(value) {
        this.setSelectedStation(value);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.butler-container {
  height: 100vh;
  width: 100vw;
  .butler-nav-bar {
    background-image: linear-gradient(
      to left,
      rgb(255, 225, 60),
      rgb(244, 176, 96),
      rgb(255, 120, 0)
    );
    ::v-deep .van-nav-bar__title {
      color: white;
    }
    ::v-deep .van-icon {
      color: white;
    }
  }
  .butler-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 176px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/butler/butler-bar.png") no-repeat;
      background-size: 100% 100%;
    }

    .report-bar {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 0 10px;
      height: 42px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/report-bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .report-title {
        font-size: 18px;
        color: #fff;
      }
      .report-arrow {
        color: #2a93ed;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }

    .coin-bar {
      width: calc(100% - 20px);
      margin: 10px;
      height: 100px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/coin-bg.png") no-repeat;
      background-size: 100% 100%;
      padding: 10px;
      color: #fff;
      > h1 {
        font-size: 18px;
        line-height: 32px;
      }
      > h3 {
        font-size: 12px;
      }
    }

    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      background-color: #fff;
      .card-bar-title {
        width: 100%;
        height: 33px;
        padding: 10px;
        background: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .text-title {
          font-size: 13px;
          color: #2ba6e1;
          width: 50%;
        }
        .text-more {
          color: #bfbfbf;
          font-size: 11px;
          float: right;
          display: flex;
          align-items: center;
        }
      }
      .country {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/butler/country.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        .text-title {
          font-size: 18px;
          color: #fff !important;
        }
      }
      .envoriment {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/butler/envoriment.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        .text-title {
          font-size: 18px;
          color: #fff !important;
        }
      }
      .property {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/butler/property.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        .text-title {
          font-size: 18px;
          color: #fff !important;
        }
      }
      .card-bar-content {
        width: 100%;
        background-color: #fff;
        .icon-bar {
          margin: 10px;
          width: calc(100% - 20px);
          // height: 108px;
          display: flex;
          align-items: center;
          // justify-content: space-around;
          flex-wrap: wrap;
          background: #fff;
          border-radius: 4px;
          > div {
            width: 33%;
            height: 88px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // padding-top: 15px;
            .icon {
              width: 47px;
              height: 47px;
              margin-bottom: 5px;
            }
            > p {
              text-align: center;
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
        .card-bar-con {
          width: 100%;
          background-color: #fff;
          display: flex;
          .card-bar-left {
            width: 30%;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            > img {
              width: 47px;
              height: 47px;
              margin-bottom: 15px;
            }
            > span {
              text-align: center;
              font-size: 10px;
              font-weight: 400;
            }
          }
          .card-bar-right {
            flex: 1;
            .coin-bar-con {
              display: flex;
              .coin-bar {
                width: 100%;
                margin: 10px;
                height: 100px;
                background: url("http://dssq.ikeqiao.net/data/image/h5/images/butler/good.png") no-repeat;
                background-size: 100% 100%;
                padding: 10px;
                color: #fff;
                > h1 {
                  font-size: 18px;
                  line-height: 32px;
                }
                > h3 {
                  font-size: 12px;
                }
              }

              .map {
                background: url("http://dssq.ikeqiao.net/data/image/h5/images/butler/point.png") no-repeat;
                background-size: 100% 100%;
                margin-left: 0;
              }
              .property {
                background: url("http://dssq.ikeqiao.net/data/image/h5/images/butler/platform.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
