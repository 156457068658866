<template>
  <div class="home" v-show="isShow"></div>
</template>

<script>
export default {
  name: "wxLogin",
  data() {
    return {
      isShow: false,
    };
  },
  created() {
    const { pageName, pageId } = this.$route.query;
    let time = new Date().getTime();
    let url = this.getUrl(time, pageName, pageId);
    console.log('url==>', url);
    console.log('pageName,pageId==>', pageName,pageId);
    window.location.replace(url);
  },
  methods: {
    getUrl(time, pageName, pageId) {
      if (pageName&&pageId) {
        return (
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1dae4e45be8a7f02&redirect_uri=" +
          encodeURIComponent(`http://dssq.ikeqiao.net/#/${pageName}?key=${pageId}&isToMain=true`) +
          "&response_type=code&scope=snsapi_userinfo&state=" +
          time +
          "#wechat_redirect"
        );
      }
      return (
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1dae4e45be8a7f02&redirect_uri=" +
        encodeURIComponent(`http://dssq.ikeqiao.net`) +
        "&response_type=code&scope=snsapi_userinfo&state=" +
        time +
        "#wechat_redirect"
      );
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  .white-bg {
    padding: 5px 10px;
    background: #fff;
  }
  .items-box {
    margin-bottom: 70px;
  }
}
</style>
