<template>
  <div class="co-building-container">
    <van-nav-bar title="共建活动" left-arrow @click-left="onClickLeft" />
    <div class="co-building-body-container">
      <van-dropdown-menu>
        <van-dropdown-item v-model="status"  @change="handleChange($event,'status')" :options="option1" />
        <van-dropdown-item v-model="sortField" @change="handleChange($event,'sortField')" :options="option2" />
      </van-dropdown-menu>
      <div class="class-source-con">
        <div class="class-source-list">
          <van-list
            v-model="loading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
            class="activity-list"
          >
            <ul>
              <li v-for="(item, index) in dataList" :key="index" @click="gotoLink('coBuildingActivityDetail?key='+item.activityId)">
                <div class="img">
                  <img :src="item.image" />
                  <!-- <van-tag v-if="item.status == 0" type="off">{{item.enrollStatus}}</van-tag> -->
                  <van-tag v-if="item.status == 0" type="off">报名中</van-tag>
                  <van-tag v-if="item.status == 1" type="on">进行中</van-tag>
                  <van-tag v-if="item.status == 2" type="finish">已结束</van-tag>
                  <div
                    v-if="item.status == '0' || item.status == '1'"
                    class="time"
                  >
                    {{ `截止日期：${item.deadlineTime}` }}
                  </div>
                  <div v-if="item.permitEnroll == '已截止报名'" class="end">报名已截止</div>
                </div>
                <div class="desc">
                  <div class="title">
                    <span class="type-tag">{{item.activityType}}</span>
                    <b>{{ item.title }}</b>
                    <!-- <span class="num">已报名 <i>{{ item.number }}</i></span> -->
                  </div>
                  <div class="info">
                    <label>活动地址：</label><span>{{ item.address }}</span>
                  </div>
                  <div class="info">
                    <label>活动时间：</label><span>{{ item.activityTime }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wyslApi from "api/llyjr/wysl";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      status: "",
      sortField: "",
      type: '',
      loading: false,
      listFinished: false,
      page: 0,
      size: 10,
      total: 0,
      option1: [
        { text: '全部活动', value: '' },
        { text: '报名中', value: 0 },
        { text: '进行中', value: 1 },
        { text: '已结束', value: 2 },
      ],
      option2: [
        { text: '默认排序', value: '' },
        { text: '最近开始', value: 'start_time' },
        { text: '报名人数高', value: 'number' },
      ],
      colors: {
        '0': { text: '#4479ED', background: '#E3EFFD' },
        '1': { text: '#E5B20A', background: '#FFF6D9' },
        '2': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
        '4': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
      dataList: [
      ],
    };
  },
  computed: {},
  components: {},
  mounted() {},
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    handleChange(e, type) {
      this[type] = e;
      this.getList();
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    getList({ page = this.page, size = this.size } = {}) {
      wyslApi
        .getGamePage({
          page,
          size,
          object: {
            activityType: '共建活动',
            status: this.status,
            sortField: this.sortField,
            sort: 'desc'
          }
        })
        .then(res => {
          const { list = [], total = 0 } = res.data || {};
          if(this.page === 1) {
            this.dataList = list;
          } else {
            this.dataList = this.dataList.concat(list);
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.co-building-container {
  height: 100vh;
  width: 100vw;

  .co-building-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .class-source-con {
      margin: 10px;
      width: calc(100% - 20px);
      .class-source-top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top-left {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          .top-left-btn {
            width: 48px;
            height: 28px;
            background: #ededed;
            border-radius: 4px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 28px;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
          }
          .selected {
            background: #e3effd;
            color: #4479ed;
          }
        }
        .top-right {
          width: 30%;
          box-shadow: none;
          ::v-deep .van-dropdown-menu__bar {
            box-shadow: none;
          }
        }
      }
    }
    .activity-list {
      li {
        margin-bottom: 0.8rem;
        .img {
          // height: 8rem;
          position: relative;
          img {
            width: 100%;
            height: 8rem;
            object-fit: cover;
          }
          .van-tag {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 0.4rem;
          }
          .van-tag--on {
            background: #e3effd;
            color: #4479ed;
          }
          .van-tag--off {
            background: #d3f1e3;
            color: #40c76d;
          }
          .van-tag--finish {
            background: #ededed;
            color: rgba(0, 0, 0, 0.45);
          }
          .time {
            background: rgba(0, 0, 0, 0.45);
            padding: 0 0.4rem;
            color: #fff;
            height: 1.6rem;
            line-height: 1.6rem;
            border-radius: 0.3rem;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 0.4rem;
          }
          .end {
            background: rgba(0, 0, 0, 0.45);
            padding: 0 0.4rem;
            color: #fff;
            height: 1.6rem;
            line-height: 1.6rem;
            border-radius: 0.3rem;
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 0.4rem;
          }
        }
        .desc {
          background: #fff;
          border-radius: 0 0 0.3rem 0.3rem;
          padding: 0.5rem 0.7rem;
          .title {
            height: 1.8rem;
            line-height: 1.8rem;
            overflow: hidden;
            .type-tag {
              float: left;
              line-height: 1rem;
              margin-top: 0.3rem;
              border: 0.5px solid #4479ed;
              color: #4479ed;
              font-size: 12px;
              border-radius: 10px;
              padding: 0 5px;
              margin-right: 5px;
            }
            b {
              float: left;
              color: rgba(0, 0, 0, 0.85);
              font-size: 1rem;
              width: 15.4rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .num {
              color: rgba(0, 0, 0, 0.65);
              font-size: 0.4rem;
              float: right;
              i {
                color: #4479ed;
                font-family: arial;
                font-style: initial;
              }
            }
          }
          .info {
            font-size: 0.9rem;
            line-height: 1.4rem;
            color: rgba(0, 0, 0, 0.85);
            label {
              color: rgba(0, 0, 0, 0.45);
            }
          }
        }
      }
    }
  }
}
</style>
