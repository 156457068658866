<template>
  <div class="home-index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>家头条</h1>
      </div>
      <div class="hot-main">
        <mt-navbar v-model="selected">
          <mt-tab-item id="homeFollow">家关注</mt-tab-item>
          <mt-tab-item id="homeStory">家故事</mt-tab-item>
          <mt-tab-item id="homeGift">家有礼</mt-tab-item>
          <mt-tab-item id="homeMempry">家记忆</mt-tab-item>
        </mt-navbar>
        <mt-tab-container v-model="selected">
          <mt-tab-container-item id="homeFollow">
            <div class="hotnewlist">
              <div class="lable_btn">
                <div :class="choicejgz === '笛扬新闻' ? 'selected' : null" @click="changeTags('笛扬新闻', 'choicejgz')">笛扬新闻</div>
                <div :class="choicejgz === '社区热搜' ? 'selected' : null" @click="changeTags('社区热搜', 'choicejgz')">社区热搜</div>
              </div>
              <iframe style="height: 80vh;" v-if="choicejgz === '笛扬新闻'" id="myiframe" scrolling="auto" frameborder="0" width="100%" height="100%" />
              <ul class="homeFollow-box" v-if="choicejgz === '社区热搜'">
                <li v-for="item in pageDataList" :key="item.key" @click="gotoLink('hotNewsEdit?key=' + item.key)">
                  <div class="desc">
                    <div class="title">{{item.title}}</div>
                    <div class="info">{{item.des}}</div>
                    <div class="time">
                      <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/time.png" />
                      <span>{{item.time}}</span>
                      <img class="see" src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/see.png" />
                      <span>{{item.num}}</span>
                    </div>
                  </div>
                  <div class="img">
                    <img :src="item.img" />
                  </div>
                </li>
              </ul>
            </div>
          </mt-tab-container-item>
          <mt-tab-container-item id="homeStory">
            <div class="hotnewlist">
              <div class="lable_btn">
                <div :class="choicejgs === '趣事' ? 'selected' : null" @click="changeTags('趣事', 'choicejgs')">趣事</div>
                <div :class="choicejgs === '喜事' ? 'selected' : null" @click="changeTags('喜事', 'choicejgs')">喜事</div>
                <div :class="choicejgs === '好事' ? 'selected' : null" @click="changeTags('好事', 'choicejgs')">好事</div>
              </div>
              <ul class="homeFollow-box">
                 <li v-for="item in pageDataList2.filter(v => v.type === choicejgs)" :key="item.key" @click="gotoLink('hotNewsEdit?key=' + item.key)">
                  <div class="desc">
                    <div class="title">{{item.title}}</div>
                    <div class="info">{{item.des}}</div>
                    <div class="time">
                      <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/time.png" />
                      <span>{{item.time}}</span>
                      <img class="see" src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/see.png" />
                      <span>{{item.num}}</span>
                    </div>
                  </div>
                  <div class="img">
                    <img :src="item.img" />
                  </div>
                </li>
              </ul>
            </div>
          </mt-tab-container-item>
          <mt-tab-container-item id="homeGift">
            <div class="hotnewlist">
              <div class="lable_btn">
                <div :class="choicejyl === '社区名家' ? 'selected' : null"  @click="changeTags('社区名家', 'choicejyl')">社区名家</div>
                <div :class="choicejyl === '文明家庭' ? 'selected' : null"  @click="changeTags('文明家庭', 'choicejyl')">文明家庭</div>
                <div :class="choicejyl === '社区好人' ? 'selected' : null"  @click="changeTags('社区好人', 'choicejyl')">社区好人</div>
                <div :class="choicejyl === '美丽楼道' ? 'selected' : null"  @click="changeTags('美丽楼道', 'choicejyl')">美丽楼道</div>
              </div>
              <ul class="homeGift—box" v-if="choicejyl === '社区名家' || choicejyl === '社区好人'">
                 <li v-for="item in pageDataList3.filter(v => v.type === choicejyl)" :key="item">
                  <div class="img" @click="gotoLink('hotNewsEdit?key=' + item.key)">
                    <img :src="item.img" />
                    <div class="img-title">{{item.title}}</div>
                  </div>
                </li>
              </ul>
              <ul class="homeFollow-box" v-if="choicejyl === '文明家庭' || choicejyl === '美丽楼道'">
                 <li v-for="item in pageDataList3.filter(v => v.type === choicejyl)" :key="item.key" @click="gotoLink('hotNewsEdit?key=' + item.key)">
                  <div class="desc">
                    <div class="title">{{item.title}}</div>
                    <div class="info">{{item.des}}</div>
                    <div class="time">
                      <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/time.png" />
                      <span>{{item.time}}</span>
                      <img class="see" src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/see.png" />
                      <span>{{item.num}}</span>
                    </div>
                  </div>
                  <div class="img">
                    <img :src="item.img" />
                  </div>
                </li>
              </ul>
            </div>
          </mt-tab-container-item>
          <mt-tab-container-item id="homeMempry">
            <div class="hotnewlist">
              <div class="lable_btn">
                <div :class="choicejjy === '老照片' ? 'selected' : null"  @click="changeTags('老照片', 'choicejjy')">家印象</div>
                <div :class="choicejjy === '小视频' ? 'selected' : null"  @click="changeTags('小视频', 'choicejjy')">家视频</div>
              </div>
              <ul class="homeGift—box" v-if="choicejjy === '老照片'">
                <li v-for="item in imgDataList" :key="item">
                  <div class="img" @click="gotoModal(item)">
                    <img :src="`http://124.91.150.6:8882/data/image/lzp/${item}.jpg`" />
                    <div class="img-des">
                      <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/see.png" />
                      <span>{{Math.floor(Math.random()*30+10)}}</span>
                      <img class="see" src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/good.png" />
                      <span>{{Math.floor(Math.random()*10+1)}}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <ul class="homeGift—box" v-if="choicejjy === '小视频'">
                <video controls style="width: 100%; height: 300; padding: 10px" src="http://124.91.150.6:8882/data/image/video/sp.mp4"></video>
                <video controls style="width: 100%; height: 300; padding: 10px" src="http://124.91.150.6:8882/data/image/video/xsp1.mp4"></video>
                <video controls style="width: 100%; height: 300; padding: 10px" src="http://124.91.150.6:8882/data/image/video/xsp2.mp4"></video>
                <video controls style="width: 100%; height: 300; padding: 10px" src="http://124.91.150.6:8882/data/image/video/sp4.mp4"></video>
                <video controls style="width: 100%; height: 300; padding: 10px" src="http://124.91.150.6:8882/data/image/video/sp5.mp4"></video>
              </ul>
            </div>
          </mt-tab-container-item>
        </mt-tab-container>
      </div>
    </div>
    <div v-if="isShowImg" class="imgShow">
      <div class="imgShow-box">
        <div class="bigImg">
          <img :src='isShowImg'>
        </div>
        <div class='good-des'>
          <img class="see" src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/goodBlue.png" />
          <span>为你点赞</span>
        </div>
        <div class='close-img' @click="cloneImg()">
           <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/delete.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageBox } from "mint-ui";
import { Toast } from 'vant';
import { openUrl } from 'utils/index'
export default {
  data() {
    return {
      selected: "homeFollow",
      isShowImg:null,
      tab:['家关注','家故事','家有礼','家记忆'],
      tab1:['笛扬新闻','社区热搜'],
      tab2:['趣事','喜事','好事'],
      choicejgs: '趣事',
      tab3:['社区名家','文明家庭','社区好人','美丽楼道'],
      choicejyl: '社区名家',
      tab4:['老照片','小视频'],
      choicejjy: '老照片',
      choicejgz: '社区热搜',
      imgDataList: [],
      pageDataList:[
        {
          key:'jgz001',
          title:'“家燕归巢”大学生暑期实习活动开始报名啦',
          des:'为进一步引导和帮助青年学生在社会实践中受教育、长才干、作贡献，鼓励引导优秀大学生反哺家乡，推进我区人才工作阵地前移，更好挖掘优秀在校学生人才，为青年人才来诸暨就业创业搭建平台，同时适当缓解部分单位短期人手不足的状况，团区委开展了2021年度“家燕归巢”大学生暑期实习活动。',
          time:'2021-6-21 08:22',
          num:54,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/jgz001.png"
        },
        {
          key:'jgz002',
          title:'注意啦！配套地铁，诸暨多条公交路线将调整，转给有需要的人',
          des:'1.线路调整走向：绍兴北站、北站东路、钱陶东路、站前大道、群贤路、湖东路、兴越路、双渎路、钱陶公路、湖东路、纺 都路、育才路、兴越路、笛扬路、群贤路、越州大道、之江学院。（因站前大道封道施工在未施工完毕前，临时绕道绿云路、兴越路。）2.站点设置：之江学院、兴越西区、兴越西区（南）、诸暨市建设局、华联商贸城、兴越农贸、万家人利、待驾桥、育才路钱陶路口、绸缎小区（游泳馆）、诸暨市公共服务大楼、服装家纺市场（北）、迎驾桥小区、万达广场（东）、钱陶路双渎路口、双渎路裕民路口、双渎路兴越路口、兴越路湖东路口、诸暨市计生指导站、诸暨市中心幼儿园、湖东路群贤路口、鲁中诸暨校区（北）、群贤路百舸路口、群贤路镜水路口、金川路口、越秀镜湖校区、高教园区、合心村（工业职业技术学院镜湖校区）、站前大道裕民路口、站前大道钱陶路口、立岱村口、绍兴北站',
          time:'2021-6-21 08:20',
          num:61,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/jgz002.png"
        },
        {
          key:'jgz00',
          title:'浣东街道2021年度公益创投项目征集开始啦',
          des:'为打造浣东街道“乐善码头”省级实验区，浣东街道在前两年公益创投的基础上，更有针对性地培育扶持辖区内的社会组织和优质公益项目，鼓励支持社会组织参与社区建设，不断创新社区服务形式，提高社区服务水平，推进社会治理创新，经街道党工委、办事处研究,决定向社会组织公开征集2021年度浣东街道公益创投项目。',
          time:'2021-6-20 18:22',
          num:128,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/ggz00.png"
        },
        {
          key:'jgz0',
          title:'疫苗接种登记预约攻略',
          des:'内容：亲爱的家人，为了您的健康和我们大家共同的健康，请您尽快到社区进行接种登记预约，接种新冠疫苗先进行建档，打开支付宝一搜索新冠疫苗接种一点击新冠疫苗预约绍兴市一自动跳转到浙里办→点击成人建档→填写工作单位编码0102。',
          time:'2021-6-15 17:03',
          num:119,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/gzz0.png"
        },
        {
          key:'jgz1',
          title:'诸暨市中心幼儿园招聘启事',
          des:'诸暨市中心幼儿园招保育老师，有双休、寒暑假，要求48周岁以下，初中学历及以上，能吃苦耐劳，有意者联系：周老师',
          time:'2021-6-15 08:40',
          num:23,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/yey.png"
        },
        {
          key:'jgz2',
          title:'瑞兴农贸市场最新菜价',
          des:'瑞兴农贸市场最新菜价',
          time:'2021-6-14 13:11',
          num:13,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/csc.png"
        },
        {
          key:'jgz3',
          title:'4050办理通知',
          des:'各位居民：2020年度灵活就业社保补贴开始办理，现将有关事项通知如下：以自谋职业参加职工社会保险，且无工商信息，女40-50周岁，男50-60周岁',
          time:'2021-6-13 12:59',
          num:32,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/sb.png"
        },
        {
          key:'jgz4',
          title:'兴越路拓宽改造通知',
          des:'因建设需要，自2021年3月9日起将对兴越路润泽大院小区门口段南侧路面及人行道进行改造升级施工。施工带来的不便敬请各位业主见谅。',
          time:'2021-6-13 12:22',
          num:11,
           img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/dl.png"
        },
        {
          key:'jgz5',
          title:'社保业务办理通知',
          des:'各位居民:您好！根据安排，社保协理员要去浣东街道便民中心轮岗一个月（5月1日到31日），要办理社保医保就业业务的居民请去浣东街道便民中心窗口取号办理，给大家带来的不便敬请谅解，请相互转告，谢谢',
          time:'2021-6-12 12:41',
          num:45,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/sbbt.png"
        },
        {
          key:'jgz6',
          title:'邻里车位倡议书',
          des:'各位居民，大家好！为缓解停车难问题，结合小区实际，社区联合物业正在开展“邻里爱心车位”登记，这些车位将提供给需要的邻里、外来访客等，',
          time:'2021-6-12 12:22',
          num:13,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jgz/cw.jpg"
        }
      ],
      pageDataList2:[
        {
          key: 'jgsqs1',
          type: '趣事',
          title:'狗狗的母爱',
          des:'润泽小区的张大姐家中养了两只狗狗，是一对可爱的母女。最近张大姐为它们添置了一个新的狗窝，本以为会造成争抢的场面，没想到狗妈妈虽然对新窝表现得十分喜爱，但一直趴在窝边迟迟没有动静，直到狗宝宝在上面安睡后离开',
          time:'2021-6-14 13:27',
          num:23,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/qs/qs1.png"
        },
        {
          key: 'jgsqs3',
          type: '趣事',
          title:'烘焙爱好者的失误',
          des:'小区里的蒋小姐是个烘焙爱好者，每周末都会利用闲暇时间尝试做一些甜品，但有时的成品并不完美，某次蒋小姐烤出了一个焦黑的蛋糕，场面一度让在场的所有人啼笑皆非，虽口味不佳，但用乐观的眼光去看待，也是一次有趣的生活经验呢。',
          time:'2021-6-13 13:12',
          num:42,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/qs/qs3.png"
        },
        {
          key: 'jgsqs4',
          type: '趣事',
          title:'迷你小龙虾',
          des:'香林花园小区王小姐的朋友圈常常是大家“看笑话”的地方，最近王小姐又更新了新的趣事，前几日王小姐买菜发现了便宜的小龙虾，便买回家烹饪了一番，刚要开动',
          time:'2021-6-12 13:44',
          num:81,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/qs/qs4.png"
        },
        {
          key: 'jgsqs2',
          type: '趣事',
          title:'家有小可爱',
          des:'社区居民胡小姐家中的布偶猫生了一窝小猫，从接生到现在全程都是胡小姐亲自照顾这一大家子，和它们的互动每次都能让胡小姐感到轻松愉快，即使忙碌了一天，看到这群活泼可爱的毛孩子，一身的疲惫也瞬间消散了。',
          time:'2021-6-12 11:51',
          num:9,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/qs/qs2.png"
        },
        {
          key: 'jgsqs5',
          type: '趣事',
          title:'钢琴风波',
          des:'香水湾的赵女士一直望女成凤，对孩子的教育从不放松，除了每天的学校功课以外，一有空就让孩子在家中练习钢琴，某天赵女士要短暂出门，嘱咐女儿在家好好练习，刚出门的赵女士发现东西落在家中回头去取',
          time:'2021-6-12 09:33',
          num:42,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/qs/qs5.png"
        },
        {
          key: 'jgsqs6',
          type: '趣事',
          title:'吃货的倒霉事',
          des:'润泽大院的李先生家有个小吃货，天气不错的时候李先生一家三口常常会在饭后出门遛弯，近日天气热，李先生的孩子出门后又吵着要买冰淇淋，拗不过孩子的李先生也只好答应了，结果买完没走几步就听见孩子一声尖叫，',
          time:'2021-6-12 09:18',
          num:11,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/qs/qs6.png"
        },
        {
          key: 'jgsqs7',
          type: '趣事',
          title:'水果箱的妙用',
          des:'住在香水湾的潘先生某天下班后在小区附近的水果店买了一整箱水果，吃完后泡沫箱也一直放在室内没来得及扔，一不注意，潘先生家的猫咪居然把泡沫箱当成了窝，并且每天都在上面睡得很沉，加上箱子上的“已售”字样，场面十分搞笑，于是这个箱子也一直留了下来，作为猫咪的新窝。',
          time:'2021-6-11 13:21',
          num:11,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/qs/qs7.png"
        },
        {
          key: 'jgsxs1',
          type: '喜事',
          title:'恭贺新婚之喜',
          des:'蝶庄小区黄建超、陈璐新婚之喜。主人家分发喜糖给小区邻居们，一起分享喜悦，和谐有爱的邻里之情，真的让人觉得幸福呢！',
          time:'2021-6-14 13:19',
          num:31,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/xs/xs1.png"
        },
        {
          key: 'jgsxs2',
          type: '喜事',
          title:'高三学子分享喜悦',
          des:'蝶庄小区高三学子分享取得好成绩喜事，溢于言表的喜悦挂在青春洋溢的脸上，祝福你，加油，少年，光明的未来一定属于你！',
          time:'2021-6-14 13:01',
          num:52,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/xs/xs2.png"
        },
        {
          key: 'jgsxs3',
          type: '喜事',
          title:'小小舞蹈家',
          des:'香水湾居民余女士分享了女儿获得10岁以下拉丁单人单项恰恰三等奖、10岁以下拉丁单人双项组三等奖、10岁以下拉丁单人单项牛仔三等奖得喜悦。恭喜小朋友，真的很优秀呢！用勤奋和汗水换来的奖牌一定特别珍贵',
          time:'2021-6-13 13:31',
          num:71,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/xs/xs3.png"
        },
        {
          key: 'jgsxs4',
          type: '喜事',
          title:'优秀物业人',
          des:'渡社区香水湾物业经理祝汉华和香林花园物业经理杜伟英被评为浣东街道优秀物业经理。',
          time:'2021-6-12 11:57',
          num:49,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/xs/xs4.png"
        },
        {
          key: 'jgsxs5',
          type: '喜事',
          title:'优秀大学生',
          des:'润泽大院小区学子钟茜，浙江大学大二学生，成绩优秀，文理见长，爱好写作，至今已公开发表文章200多篇，曾获第二届浙江省青少年科普征文大赛一等奖；高中时两次获得全国数学竞赛省赛区二等奖，全国生物竞赛省赛区二等奖，绍兴一中“英贤学生奖”等；就读浙大后，已获校学业优秀标兵',
          time:'2021-6-12 10:42',
          num:66,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/xs/xs5.png"
        },
        {
          key: 'jgsxs6',
          type: '喜事',
          title:'“未来”写作家',
          des:'香水湾小区学子陈乐伊，就读于浙江省诸暨中学，成绩优异，爱好广泛，曾在作文比赛中多次获奖，自著作文集《锦瑟华年》。琴棋书画犹喜文字墨香，享受徜徉在中国古典诗词文化韵味',
          time:'2021-6-12 10:10',
          num:24,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/xs/xs6.png"
        },
        {
          key: 'jgshs1',
          type: '好事',
          title:'走丢的狗狗失而复得',
          des:'香水湾小区的一名党员金汉东在小区散步时发现一只走丢的泰迪宠物犬，应该是附近居民饲养走丢的，为防止被其他陌生人抱走，他先把狗狗寄放在物业，再把消息发布到社区党员群，希望帮助狗狗找到主人。社区工作人员了解情况后，把这则寻狗启示转发到社区狗狗群里，不到20分钟',
          time:'2021-6-14 18:02',
          num:12,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/hs/hs1.png"
        },
        {
          key: 'jgshs2',
          type: '好事',
          title:'爱心大姐收养流浪犬',
          des:'蝶庄小区30幢的吴大姐是一位富有爱心、乐于助人的居民，前段时间她收养了三条流浪狗，给狗狗们清洗了身体、做了检查、打了疫苗，她表示狗狗也是一条生命，既然收养了，就会好好的养下去，直到狗狗终老。为吴大姐的爱心点赞！',
          time:'2021-6-14 16:45',
          num:33,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/hs/hs2.png"
        },
        {
          key: 'jgshs3',
          type: '好事',
          title:'助人为乐的业委会主任',
          des:'蝶庄小区的胡海锋是小区业委会主任，平时兼顾好自己的家庭和工作外，他把更多的时间花在帮助解决小区居民的困难和需求，支持和协助社区工作，一起探索小区居民自治新模式：比如发动楼道长，倡导居民清理楼道堆积物，共同建设美丽楼道。。。。。。蝶庄小区从前几年的“脏、乱、差”发生了',
          time:'2021-6-13 11:19',
          num:44,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/hs/hs3.png"
        },
        {
          key: 'jgshs4',
          type: '好事',
          title:'大爱奉献--无偿献血志愿者',
          des:'香水湾居民李小发，坚持十多年无偿献血，每月去采血小板，这种大爱奉献精神，值得让人敬佩。他的正能量带动了周边一些人，他是健身爱好者、游泳健将，经常组织一些体育爱好者一起做爱心公益，传递正能量。',
          time:'2021-6-12 14:21',
          num:32,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/hs/hs4.png"
        },
        {
          key: 'jgshs5',
          type: '好事',
          title:'勤劳敬业的小区保安',
          des:'香水湾小区物业保安于占江，工作认真负责，态度和蔼可亲，做事勤勤恳恳，吃苦耐劳，虽是保安，但只要他看到，什么都做，不分脏活累活、大事小事，从捡地面一张纸一根绳，到沟沟坎坎的淤泥，再到下班后帮业主搬运桌椅，扛电器设备等等，他做什么都是默默无闻，经常得到业主们的赞扬和肯定',
          time:'2021-6-12 12:23',
          num:11,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/hs/hs5.png"
        },
        {
          key: 'jgshs6',
          type: '好事',
          title:'小区的“农业部长”',
          des:'润泽大院小区36幢的居民李文农，在种植农作物方面非常有经验，并常年累月义务帮助左邻右舍修剪花草，被誉为小区里的“农业部长”。李文农是个热心肠，左邻右舍谁家有困难，他都会主动提供帮助。',
          time:'2021-6-11 19:18',
          num:52,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/hs/hs6.png"
        },
      ],
      pageDataList3:[
        {
          key: 'jylyxxx1',
          type: '社区名家',
          title:'顾亚红',
          des:'',
          time:'2021-6-14 13:21',
          num:23,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/gyh.jpg"
        },
        {
          key: 'jylyxxx2',
          type: '社区名家',
          title:'胡杰峰',
          des:'',
          time:'2021-6-14 13:09',
          num:23,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/hjf.jpg"
        },
        {
          key: 'jylyxxx3',
          type: '社区名家',
          title:'童峰',
          des:'',
          time:'2021-6-14 12:09',
          num:18,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/tf.jpg"
        },
        {
          key: 'jylyxxx4',
          type: '社区名家',
          title:'钟伟',
          des:'',
          time:'2021-6-13 11:52',
          num:27,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/zw.jpg"
        },
        {
          key: 'jylyxxx5',
          type: '社区名家',
          title:'申翠荣',
          des:'',
          time:'2021-6-13 09:34',
          num:33,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/scr.png"
        },
        {
          key: 'jylyxxx6',
          type: '社区名家',
          title:'宋芳萍',
          des:'',
          time:'2021-6-12 16:22',
          num:42,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/sfp.jpg"
        },
        {
          key: 'jylyxxx7',
          type: '社区名家',
          title:'任计花',
          des:'',
          time:'2021-6-14 13:09',
          num:55,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/rjh.jpg"
        },
        {
          key: 'jylyxxx8',
          type: '社区名家',
          title:'方秋香',
          des:'',
          time:'2021-6-11 12:31',
          num:19,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/fqx.jpg"
        },
        {
          key: 'jylwmjt1',
          type: '文明家庭',
          title:'润泽大院12-1102',
          des:'绿色代表生命，代表健康和活力。这是人类倡导的主题。环境保护是每个家庭应负的责任。杨璎家住润泽大院，环境优美，空气清新，从住进香水湾那天起，全家人就树立了一个共同的目标：创建绿色家庭，争做绿的使者。这个三口之家一直关心环保问题，把思想认识落到实处，努力做到知行合一。一、自制居家物品，变废为宝，美化家庭环境。杨璎一家，经常都会利用生活废旧材料，自制一些居家用品。整个家庭里的东西，很多都是自己制作或者“二手再利用”。但这都不乏品味和质量，家中还种有很多的植物，一进门即可感受到绿色、环保、生命、和阳光的气息，很具特色和品味。二、倡导“绿色消费”活动。日常生活中，家庭每一位成员都能使用“绿色”生活用品，养成健康环保生活习惯。从垃圾分类、节约用水、回收电池等具体生活细节做起，有较强的节水意识，能做到一水多用;不使用含磷洗涤剂，不使用一次性塑料制品和一次性筷子等等。三、注意言传身教，勇当绿色传播者。“一花独放不是春，万紫千红春满园“ 仅仅自己一家创建成绿色家庭是远远不够的，只有全社会成员都拥有了绿色观念，那么，整个社会才会更文明、更和谐。如何做一名绿色使者，把“绿”的种子播撒到每一个角落。我们家每个人都是宣传员，平时在社区里宣传有关环保的知识、废旧电池的危害和回收方法，建议小区里设置统一的废旧电池回收点，还向居民提出绿色消费的倡议。',
          time:'2021-6-13 13:14',
          num:24,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/wmjt1.jpg"
        },
        {
          key: 'jylwmjt2',
          type: '文明家庭',
          title:'润泽大院5-1107',
          des:'10多年前，宋芳萍与丈夫结婚后，一起努力打拼，用心经营着一家三口的幸福生活。两年前，一场突如其来的意外，打破了这个小家的宁静。宋芳萍的公公老朱在工地上晕倒近10小时后，被工友发现，紧急送往了医院进行抢救。当时，老朱被诊断为脊髓压迫，病情十分危急，需要连夜送往杭州。与死神整整搏斗了一天一夜后，老朱才被转入重症监护室，捡回了性命。宋芳萍始终陪伴、鼓励着老朱，无微不至，无怨无悔。去年回到家后，每天早晨5点，宋芳萍都要推着老朱去瓜渚湖散散心，练习走路。说起自己的媳妇，宋芳萍的丈夫夸赞地说，她实心实意、细心贴心地照顾着父亲，犹如亲生女儿一样，让他都有些自叹不如。',
          time:'2021-6-12 11:18',
          num:23,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/wmjt2.jpg"
        },
        // {
        //   key: 'jylwmjt3',
        //   type: '文明家庭',
        //   title:'润泽大院5-1107',
        //   des:'10多年前，宋芳萍与丈夫结婚后，一起努力打拼，用心经营着一家三口的幸福生活。两年前，一场突如其来的意外，打破了这个小家的宁静。宋芳萍的公公老朱在工地上晕倒近10小时后，被工友发现，紧急送往了医院进行抢救。当时，老朱被诊断为脊髓压迫，病情十分危急，需要连夜送往杭州。与死神整整搏斗了一天一夜后，老朱才被转入重症监护室，捡回了性命。宋芳萍始终陪伴、鼓励着老朱，无微不至，无怨无悔。去年回到家后，每天早晨5点，宋芳萍都要推着老朱去瓜渚湖散散心，练习走路。说起自己的媳妇，宋芳萍的丈夫夸赞地说，她实心实意、细心贴心地照顾着父亲，犹如亲生女儿一样，让他都有些自叹不如。',
        //   time:'2021-6-12 10:41',
        //   num:23,
        //   img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/wmjt3.jpg"
        // },
        {
          key: 'jylsqhr1',
          type: '社区好人',
          title:'丁成吉',
          des:'',
          time:'2021-6-12 08:36',
          num:16,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/dcj.jpg"
        },
        {
          key: 'jylsqhr2',
          type: '社区好人',
          title:'何敏',
          des:'',
          time:'2021-6-11 19:29',
          num:45,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/hm.jpg"
        },
        {
          key: 'jylsqhr3',
          type: '社区好人',
          title:'张冬飞',
          des:'',
          time:'2021-6-11 17:10',
          num:28,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/zdf.jpg"
        },
        {
          key: 'jylsqhr4',
          type: '社区好人',
          title:'周玲华',
          des:'',
          time:'2021-6-11 10:42',
          num:31,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/zlh.jpg"
        },
        {
          key: 'jylmljd1',
          type: '美丽楼道',
          title:'赞成香林小区17幢温馨楼道',
          des:'温馨楼道的由来，要从老章说起。一年前，老章见楼道堆积着废纸垃圾等，想着不美观，主动征求楼道32户居民意见，号召大家整理废纸，专门腾出一个角落收纳，将废纸卖钱来美化楼道。现在，原先的乱丢废纸垃圾现象荡然无存，一走进17幢楼道，便能看到书桌、便民角、绿植墙，楼道充满生机。在每层楼的每家每户业主家门口，没有任何杂物，地面干净，灯光明亮。楼道变成一个让人倍感温馨的场所，居民之间的关系也更近了，成为小区里一道新的风景线。',
          time:'2021-6-14 13:55',
          num:44,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/mlld1.png"
        },
        {
          key: 'jylmljd2',
          type: '美丽楼道',
          title:'蝶庄小区6幢邻里楼道',
          des:'蝶庄小区6幢的楼长祝师傅平时热心公益，积极参与社区建设，邻里楼道的建设正是由他和另外两位居民发起。2018年，这三位热心居民通过自身的热情感染其他居民。21户居民团结一心，每户居民捐赠几千元资金将原先一片水泥地楼道改造成小花园式楼道，楼道卫生自2018年以来，未出现脏乱差，靠的是邻里的自觉性。此外，还建了邻里互助群，大家互相帮助取快递、接小孩等。',
          time:'2021-6-13 12:41',
          num:19,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/mlld2.png"
        },
        {
          key: 'jylmljd3',
          type: '美丽楼道',
          title:'蝶庄小区9幢书香楼道',
          des:'蝶庄小区9幢的书香楼道极具特色。热心居民们自筹自建，在原来坑洼的楼道地面贴上了地砖，对脏污的墙面进行了粉刷，整个楼道焕然一新。更为有特色的是，墙上挂着一幅幅书法作品，一下子提升了整个楼道的颜值。这些书法作品是居民所写，他们充分发挥书法创作的特长，根据楼道特色书写温馨寄语，把“播种和谐 收获幸福”的理念传播给楼道邻里，整个楼道充满了书香、温暖的氛围。',
          time:'2021-6-12 09:32',
          num:21,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/mlld3.png"
        },
        {
          key: 'jylmljd4',
          type: '美丽楼道',
          title:'蝶庄小区23幢文化楼道',
          des:'蝶庄小区23幢楼道在热心居民季先生的带领下，焕然一新。季先生见楼道墙壁破烂不堪，并有塌陷情况，就在居民群里发起倡议，筹集资金修复楼道。在季先生的号召下，30户居民出资美化楼道，对楼道的围墙、楼道灯等进行粉刷及维修，用书法作品来打造文化墙和美丽楼道。通过打造主题楼道和美化楼道文化墙，让楼道换新颜，邻里间更融洽，用鲜明主题为迈进小康生活绘出一道美丽的风景线。',
          time:'2021-6-12 09:18',
          num:32,
          img: "http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jyl/mlld4.png"
        },
      ]
    };
  },
  components: {},
  mounted() {
    const list = [];
    for (let index = 1; index <= 80; index++) {
      list.push(index)
    }
    this.imgDataList = list;
    // this.getUrl();
    const value = this.getLocalData('jttSelect') || '';
    const value1 = this.getLocalData('jttSubKey') || '';
    const value2 = this.getLocalData('jttSubName') || '';
    if(value) {
      this.selected = value;
    }if(value1 && value2) {
      this[value1] = value2;
    }
  },
  watch: {
    selected(val, oldVal) {
      console.log(val);
      this.setLocalData('jttSelect', val);
    },
  },
  methods: {
    changeTags(type, key) {
      // console.log(1111, type, this.choicejgs);
      this[key] = type;
      if(type === '笛扬新闻') {
        this.getUrl();
      }
      this.setLocalData('jttSubKey', key);
      this.setLocalData('jttSubName', type);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoModal(item) {
      this.isShowImg= `http://124.91.150.6:8882/data/image/lzp/${item}.jpg`;
    },
    cloneImg(){
       this.isShowImg=null
    },
    getUrl() {
      // openUrl(21);
      const token = this.getSessionData('token') || 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRoX3VzZXIiOnsidG9rZW4iOm51bGwsInVzZXJuYW1lIjoia3F6Z3l4IiwiYWRtaW4iOmZhbHNlLCJ1c2VySWQiOiIxIn19.kwZ-diYXAsjv6sPWr4vuYT4R4nfTHYzAy-297FUMiZWaWv4OA_i9mtVWqFkb7W0Ec3bBAQ9KXSmpX4K2Ph9_rg';
      this.$store.dispatch('login/checkRealName', () => {
        this.axios({
          method: 'get',
          url: '/authApi/sdk-auth/getUrl?sign=21',
          // responseType: 'blob',
          headers: { 'Authorization': token }
        }).then(function(response) {
            // console.log(response)
          if(response.data.flag) {
            document.getElementById('myiframe').src = response.data.data;
            // console.log(response)
          } else {
            Toast.fail(response.data.message);
          }
        })
        .catch(function(err) {
          // eslint-disable-line no-unused-vars
          console.log('error===>',err)
          // Toast.fail("页面加载失败，3秒后为您返回上一页");
          // const timer = setTimeout(() => {
          //   clearTimeout(timer);
          //   this.$router.go(-1);
          // },3000);
        });
      })
    }
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";
.info{
  color: rgba(0, 0, 0, 0.75) !important;
}
.imgShow{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(74,74,74,.8);
    .imgShow-box{
      position: absolute;
      left: 10%;
      width: 80%;
      height: auto;
      top: 50%;
      z-index: 99;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      .bigImg{
        text-align: center;
        background-color:rgba(225,225,225,0.2);
        border:1px solid #fff;
        img{
          max-width: 100%;
          height: auto;
          width:auto;
          max-height: 20rem;
        }
      }
      .good-des{
        background-color: #fff;
        color: #4479ED;
        padding:0.6rem;
        font-size: 1.0rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width:1.2rem;
          height:1.2rem;
          margin-right: 0.5rem;
        }
      }
      .close-img{
        margin-top:1rem;
        text-align: center;
        img{
           width:2.5rem;
          height:2.5rem;
        }
      }
    }
}
.lable_btn {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 0.6rem;
  > div {
    padding: 0.4rem 0.8rem;
    background: #ededed;
    border-radius: 4px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.45);
    margin-right: 0.8rem;
  }
  > .selected {
    background: #e3effd;
    color: #4479ed;
  }
}
.homeFollow-box {
  .title {
    opacity: 0.85;
    font-size: 1rem;
    color: #000000;
    text-align: left;
    line-height: 1.2rem;
    font-weight: bold;
  }
  .title-info {
    min-height: 3.5rem;
    opacity: 0.85;
    font-size: 0.9rem;
    color: #000000;
    text-align: left;
    line-height: 1.2rem;
    padding-bottom: 0.5rem;
    font-weight: bold;
  }
  .time {
    display: flex;
    align-items: center;
    img {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.2rem;
    }
    img:nth-child(3) {
      margin-left: 0.6rem;
    }
  }
}

.homeGift—box {
  height: auto !important;
  li {
    display: inline-flex;
    align-items: center;
    width: 50% !important;
    height: auto !important;
    .img {
      height: auto !important;
      width:100% !important;
      text-align: center;
      img {
        width: auto !important;
        height: auto !important;
        max-height:10rem;
        max-width: 100%;
        padding: 0 0.6rem;
      }
      .img-title {
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.2rem;
        font-size: 1rem;
        font-weight: bold;
        padding-top: 0.5rem;
      }
      .img-des {
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.2rem;
        font-size: 0.8rem;
        padding-top: 0.5rem;
        display: flex;
        align-items: center;
        img {
          width: 1rem !important;
          height: 1rem !important;
          margin-right: 0.2rem;
          padding: 0;
        }
        img:nth-child(3) {
          margin-left: 0.6rem;
        }
      }
    }
  }
}
</style>
