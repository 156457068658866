<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      :title="title"
      left-arrow
      @click-left="$router.go(-1)"
    >
    </van-nav-bar>

    <div class="body">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="body-item"
            v-for="(item, index) in tableData"
            :key="index"
            @click="showDetailPage(item)"
          >
            <img :src="item.cover" />
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="right-info">
                <!-- <div class="num">{{ item.num }}浏览量</div> -->
                <div class="time">{{ item.publishTime }}</div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      type: null,
      title: "",
      loading: false,
      finished: false,
      isLoading: false,
      tableData: [],
    };
  },
  mounted() {
    const { key } = this.$route.query;
    homeApi
      .getArticleList({
        page: 1,
        pageSize: 5,
        type: 12,
        columnType: key,
      })
      .then((res) => {
        console.log(res);
        const { flag, data } = res;
        if (data && flag) {
          this.tableData = data.records || [];
        }
    });
  },
  methods: {
    ...mapMutations(['setDataInfo']),
    onLoad() {
      //请求
      // this.tableData = this.allData;
      this.finished = true;
    },
    onRefresh() {
      this.isLoading = false;
    },
    showDetailPage(data) {
      this.setDataInfo(data);
      if (data.contentType === 1) {
        window.location.href = data.content;
      } else {
        this.$router.push(`/zxzxDetail`);
      }
    },
  },
  created() {
    this.type = this.$route.query.type || "0";
  },
  watch: {
    type(val) {
      switch (val) {
        case "0":
          this.title = "新闻";
          break;
        case "1":
          this.title = "晒党务";
          break;
        case "2":
          this.title = "晒决策";
          break;
        case "3":
          this.title = "晒财务";
          break;
        case "4":
          this.title = "晒维修";
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  margin-top: 40px;
  padding: 0.8rem;
  ::v-deep .van-pull-refresh__track {
    min-height: calc(100vh - 150px);
  }

  .body-item {
    padding: 1rem 0;
    margin: 0 1rem;
    display: flex;
    border-top: 1px solid #ededed;
    &:nth-child(1) {
      border-top: none;
    }
    img {
      height: 5.6rem;
      width: 5.6rem;
    }
    .right {
      margin-left: 0.8rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      .title {
        font-size: 1.1rem;
        line-height: 1.3rem;
        letter-spacing: 0.1rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .right-info {
        opacity: 0.25;
        display: flex;
        .time {
          margin-left: 1.4rem;
        }
      }
    }
  }
}
</style>