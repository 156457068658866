<template>
  <div class="home-index" style="background: #fff; height: 100vh; padding-top: 2.7rem">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>晚间议事</h1>
    </div>
    <van-tabs color="#4379ed" v-model="selected" @click="handleSelect">
      <van-tab :title="`${item.name} ${selectData[item.key] || '0'}`" :name="item.id" v-for="(item, index) in selectList" :key="index"></van-tab>
    </van-tabs>
    <div class="eventYsListDetail">
      <van-list
        v-model="loading"
        :finished="listFinished"
        finished-text="没有更多了"
        @load="onLoad"
        class="meet-list"
        v-if="eventList && eventList.length > 0"
        height="100"
      >
        <div class="item-wjys" v-for="(item, index) in eventList" :key="index">
          <div class="text">
            <div class="title">{{item.title}}</div>
            <div class="time">会议时间：{{item.meetTime}}</div>
            <div class="time">会议地点：{{item.address}}</div>
            <div class="time">
              与会方：
              <span class="tag" v-for="(v, i) in (item.joinerPart && item.joinerPart.split(',') || [])" :key="i">{{v}}</span>
            </div>
            <span
              :style="`color: ${item.tag === '待开会' ? '#13BB49' :  (item.tag === '会议中' ? '#3E71F9' :  'rgba(0,0,0,0.45)')};background: ${item.tag === '待开会' ? '#D5F4E2' :  (item.tag === '会议中' ? '#E3EFFD' :  '#EDEDED')}`"
              class="status">{{item.tag}}</span>
          </div>
          <div class="footer-wjys">
            <div  @click="handleSign(item)" v-if="(item.tag === '待开会' || item.tag === '会议中') && !item.signStatus" class="btn" style="color: #3E71F9">签到</div>
            <div  @click="handleCancel(item)" v-if="item.tag === '审核中'" class="btn" style="color: #ce1a21">撤回议事</div>
            <div  @click="handleCancel(item)" v-if="item.tag === '未通过'" class="btn" style="color: #ce1a21">删除</div>
            <div v-if="(item.tag === '待开会' || item.tag === '会议中') && item.signStatus === 1" class="btn" style="color: rgba(0,0,0,0.45)">已签到</div>
            <div v-if="item.tag === '已结束' && !item.summary" class="btn" style="color: rgba(0,0,0,0.45)">会议纪要编写中</div>
            <div v-if="item.tag === '已结束' && item.summary" class="btn" style="color: #3E71F9" @click="gotoLink(`wjysHyjy?id=${item.meetId}&meetType=${selected}`)">查看会议纪要</div>
            <div  @click="gotoLink(`wjysDetail?id=${item.meetId}&meetType=${selected}`)" class="btn" style="color: #3E71F9;border-left: 1px solid rgba(0,0,0,0.1);">查看详情</div>
          </div>
        </div>
      </van-list>
      <van-empty v-else description="暂无议事数据，请先发起议事"></van-empty>
    </div>
    <div class="addYs" @click="gotoLink('wjysAdd')">发起议事</div>
    <van-dialog
      v-model="showSuccess"
      title="二维码信息"
      show-confirm-button
      show-cancel-button
      cancelButtonText="取消"
      @cancel="showSuccess = false"
    >
      <div style="padding: 10px;">
        <div style="width: 200px; height:200px;margin: 0 auto;">
          <img :src="require('../assets/erweima.png')" style="width: 100%; height:100%;" />
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant';
import wjysApi from 'api/wjys';
export default {
  data() {
    return {
      showSuccess: false,
      selected: '1',
      selectList: [
        { id: '1', name: '进程议事', key: 'processMeetCount'},
        { id: '2', name: '审核中', key: 'checkingMeetCount'},
        { id: '3', name: '未通过', key: 'unCheckedMeetCount'},
        { id: '4', name: '已结束', key: 'overMeetCount'},
      ],
      selectData: {},
      current: 1,
      pageSize: 100,
      loading: false,
      listFinished: false,
      eventList: [],
    };
  },
  components: {},
  mounted(opt) {
    this.getTypeCount();
    this.getEventList({});
  },
  methods: {
    showModal(){
      this.showSuccess = true;
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    onLoad() {
      this.getEventList({page: this.current + 1});
      this.current += 1;
    },
    handleSelect(name) {
      this.getEventList({ page: 1, meetType: name });
      this.current = 1;
    },
    getEventList({ page = this.current, pageSize = this.pageSize, meetType = this.selected}) {
      console.log('laile');
      
      wjysApi.getMeetList({
        page,
        pageSize,
        meetType,
        meetId: ''
      }).then(res => {
        if(res.flag) {
          this.loading = false;
          const { records = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.eventList = records;
          } else {
            this.eventList = Array.from(new Set(this.eventList.concat(records)))
          }
          this.total = total;
          if (this.eventList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        }
      }).catch((err) => {
        console.log('error====>', err);
        this.loading = false;
      });
    },
    getTypeCount() {
      wjysApi.getMeetCount().then(res => {
        if (res.flag) {
          this.selectData = res.data || {};
        }
      })
    },
    handleSign(data) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认到会议地点后签到',
        confirmButtonText: '立即签到'
      }).then(() => {
        wjysApi.meetSign({ meetId: data.meetId }).then(res => {
          if (res.data) {
            this.$toast('签到成功');
            this.getEventList({});
            this.getTypeCount();
          }
        })

      })
    },
    handleCancel(data) {
      const type = data.tag === '未通过' ? '删除' : '撤回';
      this.$dialog.confirm({
        title: '提示',
        message: `是否${type}本次议事`,
        confirmButtonText: `立即${type}`
      }).then(() => {
        wjysApi.cancelMeet({ meetId: data.meetId }).then(res => {
          if (res.data) {
            this.$toast(`${type}成功`);
            this.getEventList({ });
            this.getTypeCount();
          }
        })

      })
    }
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .home-index{
    height: 100vh !important;
    position: relative;
    .mint-tab-item-label{
      font-size: 16px;
      color: rgba(0,0,0,0.65);
      letter-spacing: 0;
      font-weight: 400;
    }
    .addYs{
      position: fixed;
      bottom: 50px;
      right: 10px;
      width: 52px;
      height: 52px;
      line-height: 16px;
      padding: 10px 10px;
      text-align: center;
      background: #3E71F9;
      color: #fff;
      border-radius: 50%;
    }
    .eventYsListDetail{
      padding: 10px;
      background: #f7f7f7;
      height: calc(100% - 50px);
      width: 100%;
      .meet-list {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 0.1px;
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 5px rgba(0,0,0,0);
          background: rgba(255, 255, 224,0);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0,0,0,0);
          border-radius: 10px;
          background: rgba(255, 255, 224,0);
        }
        .item-wjys{
          
          padding: 10px 10px 0;
          margin-bottom: 10px;
          background: #FFFFFF;
          border-radius: 8px;
          position: relative;
          .text{
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding-bottom: 10px;
            .title{
              font-size: 16px;
              color: rgba(0,0,0,0.85);
              line-height: 30px;
              font-weight: 500;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .time{
              font-size: 14px;
              color: rgba(0,0,0,0.85);
              line-height: 20px;
              font-weight: 400;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .tag{
              margin-left: 5px;
              padding: 3px 4px;
              background: rgba(62,113,249,0.15);
              border-radius: 2px;
              color: #3E71F9;
              font-size: 12px;
            }
            .status{
              position: absolute;
              right: 0px;
              top: 10px;
              padding: 3px 4px;
              background: rgba(62,113,249,0.15);
              border-bottom-left-radius: 4px;
              color: #3E71F9;
              font-size: 12px;
            }
          }
          .footer-wjys {
            width: 100%;
            display: flex;
            .btn{
              width: calc(50% - 0.5px);
              text-align: center;
              height: 50px;
              line-height: 50px;
              font-size: 16px;
              color: #3E71F9;
              font-weight: 400;
            }
          }
          
        }
      }
    }
  }
</style>
