<template>
  <div class="main-container flex flex-col">
    <header class="flex">
      <van-icon name="arrow-left" @click="goBack" />
      <div class="title flex-1">商品详情</div>
      <van-icon />
    </header>
    <section class="flex-1 flex flex-col">
      <div class="banner">
        <van-swipe :autoplay="3000" @change="index => (current = index)">
          <van-swipe-item>
            <img :src="goods.image" />
          </van-swipe-item>
          <van-swipe-item>
            <img :src="goods.image" />
          </van-swipe-item>
          <van-swipe-item>
            <img :src="goods.image" />
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">{{ current + 1 }}/3</div>
          </template>
        </van-swipe>
      </div>
      <div class="goods-info">
        <div class="title">{{ goods.name }}</div>
        <div class="remark">
          {{ goods.remark }}
        </div>
        <div class="flex other">
          <span class="price">￥{{ goods.price }}/份</span>
          <span class="">
            已售56，剩余
            <span class="text-blue">64</span>
            /120
          </span>
        </div>
        <div class="flex merchant-info">
          <div class="flex-1">
            <div>
              <span class="merchant-info-key">提供商家：</span>
              东盛超市
            </div>
            <div>
              <span class="merchant-info-key">自提地点：</span>
              祥生君城商铺103号
            </div>
            <div>
              <span class="merchant-info-key">自提时间：</span>
              周一至周五 9:00-20:00
            </div>
          </div>
          <span class="merchant-info-location">
            <svg-icon icon-class="location" />
            查看
          </span>
        </div>
      </div>
      <div class="goods-detail">
        <div>
          商品详情
        </div>
        <div>
          {{goods.introduce}}
        </div>
      </div>
    </section>
    <van-goods-action>
      <van-goods-action-icon icon="cart-o" :badge="total.num" @click="showCar"/>
      <div class="price flex-1">￥{{ total.price }}</div>
      <van-goods-action-button
        type="danger"
        text="加入购物车"
        @click="showPopup = true;"
      />
      <van-goods-action-button type="info" text="立即下单" @click="handleOrder" />
    </van-goods-action>
    <van-popup v-model="showPopup" closeable position="bottom">
      <div class="flex">
        <img :src="haMiMelon" alt="" />
        <div class="flex-1">
          <span>{{goods.name}}</span>
          <span class="price">￥{{ goods.price }}/份</span>
        </div>
      </div>
      <div>
        <span>
          数量 已售56，剩余
          <span class="text-blue">64</span>
          /120
        </span>
        <div class="flex">
          每人限购 2 件
          <van-stepper
            v-model="num"
            theme="round"
            :default-value="1"
            :min="1"
            :max="2"
            button-size="22"
          />
        </div>
      </div>
      <van-button round block type="info" @click="addToShoppingCar" v-if="!isCar">
        加入购物车
      </van-button>
    </van-popup>
    <van-popup v-model="showModal" position="bottom">
      <div class="gwcContent">
        <div class="title">
          <div>已选商品</div>
          <div class="clear" @click="clearCount">清空</div>
        </div>
        <div class="gwcList">
          <div v-for="(item, i) in (shoppingCarList || [])" class="item" :key="i">
            <div class="image">
              <img :src="item.image" alt="">
            </div>
            <div class="text">
              <div class="name">{{item.name}}</div>
              <div class="btnGroup">
                <!-- <div class="momeny">{{item.momeny || 0}}元/个</div> -->
                <img src="../../assets/images/axst/lessen.png" alt="" @click="onStepperChange(-1, item)">
                <div>{{item.num}}</div>
                <img src="../../assets/images/axst/add.png" alt="" @click="onStepperChange(1, item)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import llgApi from 'api/llg';
import haMiMelon from '@/assets/images/llg/hami_melon.png';
import SvgIcon from '../../components/svgIcon.vue';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      haMiMelon,
      current: 0,
      goods: {
        // id: '13',
        // name: '新疆哈密瓜2.5斤',
        // remark: '商品简介商品简介商品简介商品简介商品简介商品简介',
        // price: 2.0,
      },
      shoppingCarList: [],
      showPopup: false,
      isCar: false,
      showModal: false,
      shoppingCarNum: 0,
      num: 0,
    };
  },
  mounted() {
    const { id } = this.$route.query;
    this.getGoodInfo(id);
    console.log(this.shoppingCar);
    this.shoppingCarList = this.shoppingCar;
  },
  computed: {
    ...mapState({
      shoppingCar: (state) => state.llg.order,
    }),
    total() {
      return this.shoppingCarList.reduce(
        (prev, curr) => {
          return {
            num: prev.num + curr.num,
            price: prev.price + +(curr.num * curr.price).toFixed(2),
          };
        },
        { num: 0, price: 0 },
      );
    },
  },
  components: { SvgIcon },
  methods: {
    goBack() {
      this.$store.commit('llg/setOrder', this.shoppingCarList);
      this.$router.go(-1);
    },
    showCar() {
      this.showModal = true;
    },
    clearCount() {
      this.shoppingCarList = [];
    },
    onStepperChange(val, goods) {
      if (!goods.id) return;
      const _list = [...this.shoppingCarList];
      const index = _list.findIndex(o => o.id === goods.id);
      if (index >= 0 && +val > 0) {
        //已存在且数量大于0
        _list[index] = { num: val, ...goods };
      } else if (index >= 0 && +val <= 0) {
        //已存在且数量小等于0
        _list.splice(index, 1);
      } else if (index < 0 && +val > 0) {
        //不存在存在且数量大于0
        _list.push({ num: val, ...goods });
      }
      this.$set(this, 'shoppingCarList', _list);
    },
    addToShoppingCar() {
      this.onStepperChange(this.num, this.goods);
      this.showPopup = false;
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getGoodInfo(id) {
      llgApi.getShopList().then(res => {
        if(res.flag) {
          this.goods = res.data.filter(item => item.id == id)[0];
          
        }
      })
    },
    handleOrder() {
      if(this.shoppingCarList && this.shoppingCarList.length > 0) {
        this.$store.commit('llg/setOrder', this.shoppingCarList);
        this.gotoLink('order');
      } else {
        this.$toast('购物车为空！请选购商品再提交')
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/llg.scss';
.banner {
  margin-bottom: 10px;
  img {
    height: 18rem;
    width: 100%;
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 10px;
    border-radius: 71px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
.goods-info {
  margin: 0 10px 10px 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
  .title {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    letter-spacing: 0;
    font-weight: 600;
  }

  .remark {
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
  .other {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span:nth-child(2) {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
.price {
  font-size: 16px;
  color: #ff6c2b;
}
.merchant-info {
  padding: 10px;
  margin: 10px 0;
  align-items: center;
  line-height: 22px;
  font-size: 14px;
  opacity: 0.9;
  background: #f5f5f5;
  border-radius: 4px;
  &-key {
    opacity: 0.45;
  }
  &-location {
    font-size: 14px;
    color: #3e71f9;
  }
}
.goods-detail {
  padding: 0 20px 20px 20px;
  background-color: #fff;
  > div:first-child {
    display: flex;
    align-items: center;
    line-height: 2.7rem;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    &::before {
      content: '';
      display: inline-block;
      margin-right: 10px;
      width: 4px;
      height: 18px;
      background: #4479ed;
    }
  }
}
.van-popup {
  padding: 10px;
  > div:first-child {
    margin-bottom: 20px;

    > img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 16px;
      > span:first-child {
        color: #000000;
        line-height: 16px;
        font-weight: 600;
      }
    }
  }
  > div:nth-child(2) {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.45);
    > div {
      align-items: center;
    }
    .van-stepper {
      margin-left: 10px;
    }
  }
}
::v-deep {
  .van-stepper--round .van-stepper__minus {
    color: #3e71f9;
    border: 1px solid #3e71f9;
  }
  .van-stepper--round .van-stepper__plus {
    background-color: #3e71f9;
  }
}
.gwcContent{
    padding: 10px;
    max-height: 300px;
    overflow: scroll;
    .title{
      font-size: 16px;
      color: #000000;
      line-height: 30px;
      display: flex;
      .clear{
        margin-left: auto;
        margin-right: 10px;
        font-size: 16px;
        color: #FF4535;
      }
    }
    .gwcList{
      .item{
        margin-bottom: 10px;
        display: flex;
        height: 50px;
        line-height: 50px;
        .image{
          width: 40px;
          height: 40px;
          margin: 5px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          display: flex;
          width: calc(100%);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          border-bottom: 1px solid rgba(0,0,0,0.1);
          .name{
            font-size: 16px;
            color: #000000;
            font-weight: 500;
          }
          .btnGroup{
            display: flex;
            margin-left: auto;
            margin-right: 10px;
            .momeny{
              font-size: 16px;
              color: #FF6C2B;
              margin-right: 10px;
            }
            >img{
              width: 16px;
              height: 16px;
              margin: 17px 5px;
            }
          }
        }
      }
    }
  }

</style>
