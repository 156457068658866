import service from "utils/service";

const axstApi = {
  insertOrder: (params) => {
    return service.post("/neighborLoveCanteenOrder/insertOrder", params);
  }, // 预约订单
  updateOrderStatus: params => {
    return service.post('/neighborLoveCanteenOrder/updateOrderStatus', params);
  }, // 取消订单
  neighborLoveCanteenOrderList: params => {
    return service.get('/neighborLoveCanteenOrder/list', {params});
  }, // 查看订单
};
export default axstApi;
