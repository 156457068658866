export const jfdhShoping = [
  {
    id: '1400635316196343811',
    title: '环保垃圾袋1卷',
    score: 50.0,
    number: 182,
    deadline: '2021-06-30',
    describle: '垃圾分类环保垃圾袋，共同打造环保社区',
    type: '日用生活',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/垃圾袋.png',
    address: '东盛社区党群服务中心',
    createTime: '2021-06-04 10:07:36',
    updateTime: '2021-06-04 10:07:36',
  },
  {
    id: '1400635316196343811',
    title: '自助洗车50元代金券',
    score: 200.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '此锅煮出来的饭不可言语，二师兄老是来蹭饭，可想而知多美味',
    type: '日用生活',
    position: '优惠券',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/就医陪同-详情.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:07:36',
    updateTime: '2021-06-04 10:07:36',
  },
  {
    id: '1400635316196343812',
    title: '邻里场地使用1小时',
    score: 100.0,
    number: 232,
    deadline: '2021-06-30',
    describle: '此锅煮出来的饭不可言语，二师兄老是来蹭饭，可想而知多美味',
    type: '场地使用',
    position: '虚拟类',
    status: 1,
    image: 'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/位图.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:07:36',
    updateTime: '2021-06-04 10:07:36',
  },
  {
    id: '1400635316196343810',
    title: '电饭锅',
    score: 6000.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '此锅煮出来的饭不可言语，二师兄老是来蹭饭，可想而知多美味',
    type: '家用电器',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/06a8c6e81c6c4a909ed35c0df441dc20.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:07:36',
    updateTime: '2021-06-04 10:07:36',
  },
  {
    id: '1400635972806246402',
    title: '电风扇',
    score: 5000.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '此电风扇风力比起芭蕉扇有过之而无不及，欢迎抢购',
    type: '家用电器',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/1378af78b08e45a89f51c58127cb54e6.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:10:13',
    updateTime: '2021-06-04 10:10:13',
  },
  {
    id: '1400636519621853185',
    title: '电热水壶',
    score: 4000.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '此壶的功率比太上老君的八卦炉还厉害,分分钟烧开',
    type: '家用电器',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/09b21a0cd0a342ab909081ada0136b96.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:12:23',
    updateTime: '2021-06-04 10:12:23',
  },
  {
    id: '1400637296117547009',
    title: '保温壶',
    score: 3000.0,
    number: 10,
    deadline: '2021-06-02',
    describle: '此壶保温效果跟北极熊的体脂保温一样',
    type: '日用生活',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/631c776f0c624c2bb23717685f4b066c.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:15:28',
    updateTime: '2021-06-04 10:15:28',
  },
  {
    id: '1400638064400793602',
    title: '吹风机',
    score: 2000.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '瞬间吹干,让您的头发越吹越乌黑靓丽，丝滑柔顺',
    type: '家用电器',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/10c729854a1f42e4809a90f24c50268c.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:18:31',
    updateTime: '2021-06-04 10:18:31',
  },
  {
    id: '1400638962942345218',
    title: '洗衣液',
    score: 500.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '无污染,洗完衣服自带香气',
    type: '日用生活',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/d7cf96a4e3a9495e9b263084defa956f.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:22:06',
    updateTime: '2021-06-04 10:22:06',
  },
  {
    id: '1400639669351219201',
    title: '牙膏',
    score: 200.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '喜刷刷喜刷刷，此牙膏刷的你满牙洁白无暇,口气清新',
    type: '日用生活',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/64a991d9790e44eca9fb5d18e4613188.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:24:54',
    updateTime: '2021-06-04 10:24:54',
  },
  {
    id: '1400640331048812546',
    title: '纸巾',
    score: 100.0,
    number: 6,
    deadline: '2021-06-30',
    describle: '纸巾大甩卖了,丝滑柔爽,用完使你回味无穷',
    type: '日用生活',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/cf89c6539f9e416a93ba190127f61a3a.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:27:32',
    updateTime: '2021-06-04 10:27:32',
  },
]

export const ownScoreList = [
  {
    title: '签约社区公约',
    tag: '共享积分',
    score: 50,
    path: 'communityConvention',
  },
  {
    title: '发布共享工具',
    tag: '志愿积分',
    score: 30,
    path: 'gjx',
  },
  {
    title: '参与邻里活动',
    tag: '志愿积分',
    score: 20,
    path: 'hdzx',
  },
  {
    title: '回答邻里问题',
    tag: '贡献积分',
    score: 10,
    path: 'znt',
  },
]

export const jfmx = [
  {
    score: 10.0,
    createTime: '2021-12-16',
    flowId: 5597,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-12-14',
    flowId: 5591,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-10-18',
    flowId: 3582,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-10-18',
    flowId: 3581,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-10-18',
    flowId: 3580,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-09-14',
    flowId: 3253,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-09-06',
    flowId: 3064,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-09-06',
    flowId: 3063,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-09-04',
    flowId: 2980,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-09-03',
    flowId: 2979,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-09-03',
    flowId: 2967,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-09-03',
    flowId: 2966,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-09-02',
    flowId: 2869,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-31',
    flowId: 2096,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-29',
    flowId: 1690,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-29',
    flowId: 1689,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-27',
    flowId: 1687,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-27',
    flowId: 1665,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-27',
    flowId: 1664,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-27',
    flowId: 1640,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-27',
    flowId: 1638,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-24',
    flowId: 1477,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-24',
    flowId: 1473,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-10',
    flowId: 1246,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-10',
    flowId: 1245,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-08-03',
    flowId: 1199,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-07-21',
    flowId: 1184,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-30',
    flowId: 1117,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-30',
    flowId: 1116,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-29',
    flowId: 1115,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-24',
    flowId: 1111,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-24',
    flowId: 1110,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-24',
    flowId: 1109,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-24',
    flowId: 1108,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-24',
    flowId: 1107,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-23',
    flowId: 1072,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-23',
    flowId: 1071,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-23',
    flowId: 1070,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-23',
    flowId: 1065,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-23',
    flowId: 1050,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-23',
    flowId: 1049,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-22',
    flowId: 1048,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-22',
    flowId: 1047,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-22',
    flowId: 1046,
    flowDescribe: '评论获得积分',
    flowType: '获取',
  },
  {
    score: 10.0,
    createTime: '2021-06-04',
    flowId: 831,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: -200.0,
    createTime: '2021-06-04',
    flowId: 827,
    flowDescribe: '积分兑换',
    flowType: '支出',
  },
  {
    score: -200.0,
    createTime: '2021-06-04',
    flowId: 826,
    flowDescribe: '积分兑换',
    flowType: '支出',
  },
  {
    score: -100.0,
    createTime: '2021-06-04',
    flowId: 825,
    flowDescribe: '积分兑换',
    flowType: '支出',
  },
  {
    score: -200.0,
    createTime: '2021-06-04',
    flowId: 824,
    flowDescribe: '积分兑换',
    flowType: '支出',
  },
  {
    score: 10.0,
    createTime: '2021-06-03',
    flowId: 807,
    flowDescribe: '物品共享',
    flowType: '获取',
  },
  {
    score: -600.0,
    createTime: '2021-06-03',
    flowId: 806,
    flowDescribe: '积分兑换',
    flowType: '支出',
  },
  {
    score: -300.0,
    createTime: '2021-06-01',
    flowId: 805,
    flowDescribe: '积分兑换',
    flowType: '支出',
  },
  {
    score: -300.0,
    createTime: '2021-06-01',
    flowId: 804,
    flowDescribe: '积分兑换',
    flowType: '支出',
  },
  {
    score: -400.0,
    createTime: '2021-06-01',
    flowId: 802,
    flowDescribe: '积分兑换',
    flowType: '支出',
  },
  {
    score: -200.0,
    createTime: '2021-06-01',
    flowId: 801,
    flowDescribe: '积分兑换',
    flowType: '支出',
  },
]

export const dhRecords = [
  {
    id: '1400635316196343818',
    title: '自助洗车50元代金券',
    score: 200.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '此锅煮出来的饭不可言语，二师兄老是来蹭饭，可想而知多美味',
    type: '日用生活',
    position: '优惠券',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/就医陪同-详情.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:07:36',
    updateTime: '2021-06-04 10:07:36',
    count: 1,
    useStatus: '待使用',
  },
  {
    id: '1400635316196343811',
    title: '自助洗车50元代金券',
    score: 200.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '此锅煮出来的饭不可言语，二师兄老是来蹭饭，可想而知多美味',
    type: '日用生活',
    position: '优惠券',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/就医陪同-详情.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:07:36',
    updateTime: '2021-06-04 10:07:36',
    count: 1,
    useStatus: '已过期',
  },
  {
    id: '1400635316196343812',
    title: '邻里场地使用1小时',
    score: 100.0,
    number: 232,
    deadline: '2021-06-30',
    describle: '此锅煮出来的饭不可言语，二师兄老是来蹭饭，可想而知多美味',
    type: '场地使用',
    position: '虚拟类',
    status: 1,
    image: 'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/位图.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:07:36',
    updateTime: '2021-06-04 10:07:36',
    count: 1,
    useStatus: '已兑换',
  },
  {
    id: '1400635316196343810',
    title: '电饭锅',
    score: 6000.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '此锅煮出来的饭不可言语，二师兄老是来蹭饭，可想而知多美味',
    type: '家用电器',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/06a8c6e81c6c4a909ed35c0df441dc20.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:07:36',
    updateTime: '2021-06-04 10:07:36',
    count: 1,
    useStatus: '已兑换',
  },
  {
    id: '1400635972806246402',
    title: '电风扇',
    score: 5000.0,
    number: 10,
    deadline: '2021-06-30',
    describle: '此电风扇风力比起芭蕉扇有过之而无不及，欢迎抢购',
    type: '家用电器',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/1378af78b08e45a89f51c58127cb54e6.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:10:13',
    updateTime: '2021-06-04 10:10:13',
    count: 1,
    useStatus: '待使用',
  },
  {
    id: '1400637296117547009',
    title: '保温壶',
    score: 3000.0,
    number: 10,
    deadline: '2021-06-02',
    describle: '此壶保温效果跟北极熊的体脂保温一样',
    type: '日用生活',
    position: '实物类',
    status: 1,
    image:
      'http://dssq.ikeqiao.net/data/image/20210604/631c776f0c624c2bb23717685f4b066c.png',
    address: '诸暨广电',
    createTime: '2021-06-04 10:15:28',
    updateTime: '2021-06-04 10:15:28',
    count: 1,
    useStatus: '已过期',
  },
]

export const addressList = [
  {
    id: '1',
    name: '华先生',
    tel: '18888888888',
    address: '浙江省 杭州市 西湖区 双浦镇 云栖小镇六和金座9幢1单元901室',
    isDefault: true,
  },
  {
    id: '3',
    name: '张三',
    tel: '13000000000',
    address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
  },
  {
    id: '2',
    name: '李四',
    tel: '1310000000',
    address: '浙江省杭州市拱墅区莫干山路 50 号',
  },
]

export default { jfdhShoping, ownScoreList, dhRecords, jfmx }
