<template>
  <div class="mrqd-container">
    <van-nav-bar
      title="每日签到"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <img class="mrqd-top-image" :src="`${imageUrl}ds-jfdh-mrqd-top-bg.png`" alt="">
      <!-- top bar -->
      <div class="mrqd-top-bar padding-12">
        <img :src="`${imageUrl}ds-jfdh-mrqd-sign.png`" class="top-bar-image" alt="">
        <div class="flex between">
          <div class="top-left">
            <div class="label">我的积分：</div>
            <div class="number">{{currentUserScore}}</div>
            <!-- <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/arrow-right.png" alt=""> -->
          </div>
          <div class="sign-button"><span>连续签到</span><span class="count-text">{{signDay}}</span><span>天</span></div>
        </div>
        
        <div class="date-list">
          <div class="date-item" v-for="item in dateList || []" :key="item.day">
            <div v-if="item.isFuture" class="circle"></div>
            <img style="vertical-align: bottom;" v-else :src="item.signTime ? 'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/签到.png' : 'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/未签到.png'" alt="">
            <p>{{item.day}}</p>
          </div>
        </div>
        <van-notice-bar style="margin: 0 -12px -12px" scrollable text="温馨提示：每日签到加1积分，未签到不加分" />
      </div>
      <!-- 积分卡片 -->
      <!-- <div class="flex margin-12 padding-12 around" style="background: #FFF;"> -->
        <!-- <div class="myCodeCount" v-for="item in scoreData" :key="item.label">
          <div>今日已获取</div>
          <div class="codeRight">
            <span class="number">{{item.number}}</span>
            <span>积分</span>
          </div>
        </div> -->
        <!-- <div style="text-align: center;" v-for="item in scoreData" :key="item.label">
          <div class="orange">
            <span class="title-text">{{item.number}}</span>
            <span class="small-text">积分</span>
          </div>
          <div class="label-text">{{item.label}}</div>
        </div> -->
      <!-- </div> -->
      <!-- 获取积分 -->
      <div class="white-bg margin-sider-12" style="height: 12px"></div>
      <div class="list-title margin-sider-12 white-bg flex between">
        <span>获取积分</span>
        <!-- <div class="blue font-14" @click="showIntegralType = true">
          {{integralTypeName}}<van-icon name="arrow-down" color="#4479ed" />
        </div> -->
      </div>
      <van-list
        v-model="loading"
        :finished="listFinished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <section
          class="mrqd-card margin-sider-12"
          v-for="item in list"
          :key="item.id"
        >
          <div class="card-left">
            <h4 class="custom-title">{{item.integralTypeName}}</h4>
            <p class="small-text gray" v-if="item.integralTypeIsLimit === 1">{{`${limitTypeObj[item.integralTypeLimitType]}最多获取${item.integralTypeTopNum}积分`}}</p>
          </div>
          <div class="card-center orange">
            {{`${item.integralTypeOnceNum}积分/${typeCategoryObj[item.integralTypeCategory]}`}}
          </div>
          <div class="card-right">
            <van-button v-if="item.integralTypeLimitType === 2 && item.userLimit" style="height: 28px;width: 100%;" round disabled>已完成</van-button>
            <van-button v-else type="info" style="height: 28px;width: 100%;" round @click="gotoLink(item.integralTypeUrl)">前往</van-button>
            <p v-if="item.integralTypeLimitType === 0 && item.userLimit" class="small-text red">今日已上限</p>
          </div>
        </section>
      </van-list>
      <!-- <van-cell class="activity-item" v-for="item in list" :key="item.integralTypeName" center :label="`获取${item.integralTypeOnceNum || 0}积分`">
        <template #title>
          <span class="custom-title">{{item.integralTypeName}}</span>
          <van-tag round color="rgba(68,121,237,0.10)" text-color="#4379ed">{{item.tag}}</van-tag>
        </template>
        <template #default>
          <van-button type="info" style="height: 28px;" round @click="gotoLink(item.integralTypeKey)">前往</van-button>
        </template>
      </van-cell> -->
    </div>
    <van-popup v-model="showIntegralType" position="bottom">
      <van-picker
        show-toolbar
        :columns="integralTypeIdList"
        @confirm="onTypeConfirm"
        value-key="integralTypeName"
        @cancel="showIntegralType = false"
      />
    </van-popup>
  </div>
</template>

<script>
import jfdhApi from 'api/ddya/jfdh';
import { mapMutations, mapState } from "vuex";
import {jfdhShoping, ownScoreList} from 'assets/json/jfdh';
import moment from 'moment';
import 'moment/locale/zh-cn';
export default {
  data() {
    return {
      signList: [],
      scoreData: [
        {
          label: '今日已获取',
          number: 80
        },
        // {
        //   label: '每日获取上限',
        //   number: 100
        // },
      ],
      limitTypeObj: {
        0: '每日',
        1: '每月',
        2: '单次'
      },
      typeCategoryObj: {
        0: '每次',
        1: '每小时'
      },
      list: [],
      signDay: 1,
      current: 0,
      pageSize: 10,
      loading: false,
      listFinished: false,
      dateList: [],
      integralTypeId: '',
      integralTypeName: '全部类型',
      integralTypeIdList: [],
      showIntegralType: false,
      currentUserScore: 0,
      imageUrl: 'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/',
    };
  },
  computed: {
    // dateList() {
    //   const result = [];
    //   const today = moment().format('MM.DD');
    //   for(let i = 0;i < 7; i += 1) {
    //     const startDay = moment().week(moment().week()).startOf('week');
    //     const current = startDay.add(i, 'days');
    //     const day = current.format('MM.DD');
    //     // console.log(current, moment(), moment().diff(moment(), 'days'))
    //     result.push({ 
    //       day: day === today ? '今日' : day,
    //       sign: 1, 
    //       isFuture: moment().isBefore(current), 
    //       score: current.diff(moment().startOf('week'), 'days') + 1 
    //     });
    //   }
    //   return result;
    // },
    // signDay() {
    //   return Math.abs(moment().diff(moment().startOf('week'), 'days') + 1)
    // }
  },
  components: {},
  mounted() {
    // const signScore = sessionStorage.getItem('signScore');
    // if(!signScore) {
      
    //   sessionStorage.setItem('signScore', 1)
    // }
    // 查询我的积分
    jfdhApi.getUsableIntegral().then(res => {
      this.currentUserScore = res.data || 0;
    })
    // 签到
    jfdhApi.scoreSign().then(res => {
      if (res.flag && res.data) {
        this.$toast(`签到成功，积分+${res.data}`);
      }
      // 获取连续签到天数
      jfdhApi.getMaxSignDay().then(res => {
        if (res.data) {
          this.signDay = res.data || 1;
        }
      });
      // 查询我的积分
      jfdhApi.getTodayIntergral().then(res => {
        if (res.data) {
          this.scoreData[0].number = res.data;
        }
      });
      // 查询连续签到列表
      jfdhApi.getMySign().then(res => {
        if (res.flag) {
          this.dateList = res.data || []
        }
      });
    });
    // 获取积分列表
    // this.getIntergralWay();
    // 获取积分类型列表
    this.getIntergralType();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onTypeConfirm(value) {
      this.integralTypeId = value.integralTypeId;
      this.integralTypeName = value.integralTypeName;
      this.current = 1;
      this.getIntergralWay({ integralTypeId: value.integralTypeId, page: 1 });
      this.showIntegralType = false;
    },
    getIntergralType() {
      jfdhApi.getIntegralType().then(res => {
        this.integralTypeIdList = [{integralTypeId: '', integralTypeName: '全部类型'}, ...res.data] || [];
      })
    },
    onLoad() {
      this.getIntergralWay({ page: this.current + 1 });
      this.current = this.current + 1;
    },
    // 获取列表数据
    getIntergralWay({ page = this.current, pageSize = this.pageSize, integralTypeId = this.integralTypeId } = {}) {
      jfdhApi.getIntergralWay({
        page,
        pageSize,
        integralTypeId
      }).then(res => {
        this.loading = false;
        const { records = [], total = 0 } = res.data || {};
        if(page === 1) {
          this.list = records;
        } else {
          this.list = Array.from(new Set(this.list.concat(records)))
        }
        this.total = total;
        if (this.list.length >= this.total) {
          // 数据全部加载完成
          this.listFinished = true;
        }

      })
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'assets/scss/common.scss';
.mrqd-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  color: rgba(0,0,0,0.85);
  line-height: 1.2rem;
  
  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: #E2F2FF;
    overflow-y: auto;
    overflow-x: hidden;
    .mrqd-top-image {
      width: 100%;
      height: 12rem;
    }
    .mrqd-top-bar {
      background: #FFF;
      margin: -8rem 12px 12px;
      border-radius: 4px;
      position: relative;
      z-index: 99;
      .top-bar-image {
        position: absolute;
        top: -26px;
        width: 11.5rem;
        height: 48px;
        left: calc(50% - 5.75rem);
      }
      .top-left {
        text-align: center;
        display: flex;
        align-items: center;
        .number {
          font-size: 24px;
          letter-spacing: 0;
          line-height: 20px;
          font-weight: 500;
          margin-right: 6px;
        }
        .label {
          font-size: 16px;
          font-weight: 500;
        }
        >img {
          height: 12px;
        }
      }
      .sign-button {
        color: #FFAA36;
        line-height: 50px;
        font-size: 0.9rem;
        font-weight: 500;
        .count-text {
          font-size: 1.87rem;
          margin: 0 6px;
        }
      }
      .date-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .date-item {
          width: 2.2rem;
          margin: 8px 6px;
          >img {
            height: 2rem;
            width: 2rem;
          }
          .circle {
            width: 2rem;
            height: 2rem;
            border-radius: 1rem;
            border: 1px solid #4379ed;
            color: #4379ed;
            text-align: center;
            line-height: 2rem;
          }
        }
      }
    }
    .mrqd-card {
      background: #FFF;
      padding: 12px;
      border-radius: 4px;
      display: flex;
      margin-bottom: 10px;
      justify-content: space-between;
      .card-left {
        flex: 1;
      }
      .card-center {
        width: 30%;
      }
      .card-right {
        width: 25%;
      }
    }
    .activity-item {
      width: calc(100% - 24px);
      margin: 0 12px 12px 12px;
      .van-cell__title {
        flex: 2;
      }
    }
  }
  .myCodeCount{
    width: 100%;
    display: flex;
    line-height: 30px;
    height: 30px;
    color: rgba(0,0,0,0.65);
    .codeRight{
      margin-left: auto;
      color: #000;
      .number{
        color: #08f;
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
}
</style>