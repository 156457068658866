<template>
  <div class="more-instructor-container">
    <van-nav-bar
      title="优秀楼道指导员"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="more-instructor-body-container">
      <div style="margin-bottom: 10px;" v-for="item in instructorList" :key="item.id">
        <officer-card :data="item"></officer-card>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import OfficerCard from './officerCard.vue';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      instructorList: [],
    };
  },
  computed: {
  },
  components: { OfficerCard },
  mounted() {
    this.getInstructorList();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getInstructorList() {
      api.getInstructor({}).then(res => {
        if(res.flag) {
          this.instructorList = res.data;
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.more-instructor-container {
  height: 100vh;
  width: 100vw;
  
  .more-instructor-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;


  }
}
</style>
