<template>
  <div class="news-detail-container">
    <van-nav-bar
      title="详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="news-detail-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <h1 class="title">{{data.title}}</h1>
        <div class="info-bar">
          <span class="info-item">
            <i class="date"></i>
            {{data.time}}
          </span>
          <span class="info-item">
            <i class="view"></i>
            {{data.num}}
          </span>
        </div>
        <p class="info-subtitle">{{data.subTitle}}</p>
        <p class="info-desc">{{data.desc}}</p>
        <img class="info-image" :src="data.picture" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      data: {
        title: '深入学习贯彻党的十九大精神全面开创教育改革发展新局面',
        subTitle: '副标题摘要',
        // tag: '党章党规',
        num: 342,
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/videoDefault.png',
        time: '2021-08-15 12:00',
        desc: '详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍详情介绍',
      },
    };
  },
  computed: {
  },
  components: { },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.news-detail-container {
  height: 100vh;
  width: 100vw;
  
  .news-detail-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .top-bar {
      width: 100%;
      background: #FFF;
      margin-bottom: 10px;
      padding: 12px;
      .title {
        font-size: 16px;
        color: #000000;
        line-height: 26px;
        font-weight: 500;
      }
      .info-bar {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        height: 48px;
        .info-item {
          display: flex;
          align-items: center;
          margin-right: 10px;
          font-size: 14px;
          color: rgba(0,0,0,0.45);
          >i {
            width: 16px;
            height: 16px;
          }
          .date {
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/date.png) no-repeat;
            background-size: 100% 100%;
          }
          .view {
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/view.png) no-repeat;
            background-size: 100% 100%;
          }
        }
        .info-tag {
          position: absolute;
          right: 12px;
          width: 70px;
          height: 24px;
          background: rgba(252,235,234,0.9);
          font-size: 14px;
          color: #E13F30;
          line-height: 24px;
          text-align: center;
          border-radius: 8px 2px 8px 2px;
        }
      }
      .info-subtitle {
        font-size: 16px;
        color: rgba(0,0,0,0.45);
        margin-bottom: 16px;
        line-height: 24px;
      }
      .info-desc {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        line-height: 24px;
        margin-bottom: 16px;
      }
      .info-image {
        width: 100%;
      }
    }

    .middle-bar {
      width: 100%;
      flex: 1;
      background: #FFF;
      padding: 12px;
      .middle-title {
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      display: flex;
      background: #FFF;
      align-items: center;
      .send-input {
        background: #F4F5F8;
        font-size: 15px;
        color: rgba(0,0,0,0.35);
        display: flex;
        align-items: center;
        border-radius: 20px;
        width: 82%;
        height: 36px;
        .send-image {
          height: 18px;
          width: 18px;
        }
      }
      .send-btn {
        flex: 1;
        // margin-left: 10px;
        border: none;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        text-align: center;
      }
    }
  }
}
</style>
