<template>
  <div class="kegongzhendi-container">
    <van-nav-bar
      title="柯工·阵地"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="kegongzhendi-body-container">
     <!-- <h1>柯工·阵地</h1>
     <p>浣东街道总工会成立于2005年，目前下辖基层工会68家，其中单建工会47家、联合工会21家，涵盖企业107家，职工约2.4万人，会员约2.3万人。2018年，作为浙江省基层工会改革重点培育单位，街道总工会积极打造“微匠力”工会服务品牌，创新建立楼宇企业联合工会，由专职工作者常驻办公服务。截至2020年底，已成功创建省级职工书屋4家、省级劳动关系和谐企业1家；创建市级职工之家4家、市级劳动关系和谐企业7家、市级妈咪暖心小屋4个、市级工人先锋号1个、市“强五力、增三性”先进基层工会2个、市区域性党建带工建示范联合工会3个；创建区级工人先锋号7个、区级劳模创新工作室1个；成立职工培训学院10所，建立工人文化宫21个。2021年，街道总工会致力于打造“云上工会”，以“慧工会、惠职工、汇幸福”为服务宗旨，依托“智慧云”社区平台建设契机，开创“柯工慧”智慧服务平台，以柯工之“慧”汇职工之“惠”，打造更普惠、更精准、更温暖的智慧工会。为更好服务广大职工群众，街道总工会于2021年初在东盛社区创建“志愿有爱”线下志愿服务站、在玉兰社区创建“工晴驿站”线下心理咨询室，实现线上线下服务无缝对接。</p > -->
     <!-- <img style="width: 100%;" src="https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/fFnTBPoUjs6a.jpg" alt=""> -->
     <h1>东盛社区联合工会</h1>
     <p>东盛社区联合工会成立于2018年4月，现有工会会员1320人，其中，工会委员会委员5人，经费审查委员会委员3人。东盛社区联合工会先后荣获“绍兴市区域性党建带工建示范联合工会”、“诸暨市十佳工人先锋号”、“诸暨市工人文化宫”等荣誉。</p>
     <p>2021年初，“柯工慧”志愿服务中心在东盛社区正式启动，东盛站作为“柯工慧”启航地，于2021年3月成立“东盛E家”邻里志愿服务联盟，汇聚各方力量，为职工打造温馨有爱的“职工之家”。同年9月，诸暨市首个社区职工驿站在东盛社区正式建立。</p>
     <h1>东盛社区联合工会委员会</h1>
     <ul>
        <li>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/zhangxiaohong.jpg" />
          <div>张晓鸿<br />主  席</div>
        </li>
        <li>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/qiuqinqin.jpg" />
          <div>邱琴琴<br />副主席</div>
        </li>
        <li>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/liyinman.jpg" />
          <div>李银曼<br />组织委员</div>
        </li>
        <li>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/wuyahong.jpg" />
          <div>吴亚红<br />生活委员</div>
        </li>
        <li>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/gaojiayin.jpg" />
          <div>高佳音<br />财务委员</div>
        </li>
      </ul>
      <h1>东盛社区联合工会经费审查委员会</h1>
      <ul>
        <li>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/gaojiayin.jpg" />
          <div>高佳音<br />主  任</div>
        </li>
        <li>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/chenhengchao.jpg" />
          <div>陈恒超<br />委  员</div>
        </li>
        <li>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/fangyuan.jpg" />
          <div>方  圆<br />委  员</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      list1: [
        {
          name: ''
        }
      ]
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.kegongzhendi-container {
  height: 100vh;
  width: 100vw;
  
  .kegongzhendi-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    >h1 {
      padding: 15px 15px 0 15px;
      line-height: 36px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
      background-color: #FFF;
      border-radius: 4px 4px 0 0;
      text-align: center;
    }
    >p {
      padding: 0 15px;
      font-size: 14px;
      color: rgba(0,0,0,0.85);
      line-height: 18px;
      background-color: #FFF;
      text-indent: 28px;
    }
    >img {
      padding: 10px 15px;
      background-color: #FFF;
    }
    >ul {
      width: 100%;
      padding: 0 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      background-color: #FFF;
      >li {
        width: 33%;
        text-align: center;
        line-height: 20px;
        margin-bottom: 10px;
        >img {
          width: 95%;
          height: 130px;
        }
      }
    }
  }
}
</style>
