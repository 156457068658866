<template>
  <div id="index">
    <div class="wjys-add-page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>发起议事</h1>
      </div>
      <van-form @submit="onSubmit">
        <!-- <van-field
          v-model="name"
          name="name"
          label="姓名"
          placeholder="请选择上报类型"
          input-align="right"
          is-link
          readonly
          @click="showType = true"
          :rules="[{ required: true, message: '' }]"
        /> -->
        <van-field
          v-model="title"
          name="title"
          label="议事标题"
          required
          placeholder="请填写"
          input-align="right"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="meetDate"
          name="meetDate"
          label="议事日期"
          placeholder="请选择"
          input-align="right"
          is-link
          readonly
          required
          @click="showDate = true"
          :rules="[{ required: true, message: '' }]"
        />
        <div style="display: flex;">
          <van-field
            v-model="time1"
            name="time1"
            label="开始时间"
            placeholder="请选择"
            input-align="right"
            is-link
            readonly
            required
            @click="showTime1 = true"
          :rules="[{ required: true, message: '' }]"
          />
          <div style="width: 1px;height: 100%;color: #fefefe;"></div>
          <van-field
            v-model="time2"
            name="time2"
            label="结束时间"
            placeholder="请选择"
            input-align="right"
            is-link
            readonly
            required
            @click="handleTime2"
            :rules="[{ required: true, message: '' }]"
          />
        </div>
        <van-field
          v-model="address"
          name="address"
          label="会议地点"
          placeholder="请选择"
          required
          is-link
          readonly
          @click="showAddress = true"
          input-align="right"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field name="joinerPart" label="与会方" required>
          <template #input>
            <van-checkbox-group v-model="joinerPart" direction="horizontal">
              <van-checkbox style="margin-bottom: 5px;" v-for="(item) in partList" :key="item" :name="item" shape="square">{{item}}</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <div class="section-textArea">
          <div class="section-label">会议说明</div>
          <van-field
            v-model="desc"
            name="instruction"
            rows="2"
            autosize
            label
            type="textarea"
            maxlength="400"
            placeholder="请输入"
            show-word-limit
          />
        </div>
        <div class="section" style="padding: 0.8rem;">
          <div class="image-title">附件图片</div>
          <van-uploader v-model="invitationImage" name="invitationImage" multiple :max-count="4" />
        </div>
        <div class="foot-button">
          <van-button block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        type="date"
        title="选择议事日期"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="handleCancle('showDate')"
        @confirm="onDateConfirm($event, 'meetDate', handleCancle('showDate'))"
      />
    </van-popup>
    <van-popup v-model="showTime1" position="bottom">
      <van-datetime-picker
        type="time"
        title="选择开始时间"
        @cancel="handleCancle('showTime1')"
        @confirm="onDateConfirm($event, 'time1', handleCancle('showTime1'))"
      />
    </van-popup>
    <van-popup v-model="showTime2" position="bottom">
      <van-datetime-picker
        type="time"
        :min-hour="time1.substring(0,2)"
        title="选择结束时间"
        @cancel="handleCancle('showTime2')"
        @confirm="onDateConfirm($event, 'time2', handleCancle('showTime2'))"
      />
    </van-popup>
    <van-popup v-model="showAddress" position="bottom">
      <van-picker
        title="选择议事地点"
        show-toolbar
        :columns="addressList"
        value-key="dictLabel"
        @confirm="onConfirm"
        @cancel="handleCancle('showAddress')"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import wjysApi from 'api/wjys';
import commonApi from "api/common";

export default {
  data() {
    return {
      title: '',
      time1: '',
      time2: '',
      meetDate: '',
      address: "",
      joinerPart: [],
      desc: "",
      invitationImage: [],
      showDate: false,
      showTime1: false,
      showTime2: false,
      showAddress: false,
      minDate: new Date(),
      maxDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 60), // 议事最大日期为六个月后
      addressList: [],
      partList: ['街道', '物业', '居委会']
    };
  },
  components: {},
  mounted() {
    this.getAddressList();
  },
  methods: {
    gotoLink(path) {
      this.$router.replace(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    onSubmit(values) {
      if (this.invitationImage.length) {
        const formData = new FormData();
        this.invitationImage.forEach(file => {
          formData.append("files", file.file);
        });
        commonApi.uploadMultiFile(formData).then(res => {
          if (res.flag) {
            values.attachment = res.data;
            this.addMeet(values);
          }
        });
      } else {
        this.addMeet(values);
      }
    },
    addMeet(values) {
      wjysApi.addMeet({ ...values, joinerPart: values.joinerPart.join(',')}).then(res => {
        if (res.flag) {
          Toast.success('提交成功');
          setTimeout(() => {
            this.$router.go(-1);
          }, 500);
        }
      })
    },
    handleCancle(type) {
      this[type] = false;
    },
    onDateConfirm(value, name, callback = () => {}) {
      if(name === 'meetDate') {
        this.meetDate = this.formatDateTime(value);
      } else {
        this[name] = value;
      }
      callback();
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d;
    },
    getAddressList() {
      wjysApi.getAddressList({ type: 'night_meet_address' }).then(res => {
        if (res.data) {
          this.addressList = res.data || [];
        }
      })
    },
    onConfirm(value) {
      this.address = value.dictLabel;
      this.showAddress = false;
    },
    handleOpen() {
      window.open('http://navo.top/Bn6NNj')
    },
    handleTime2() {
      if (!this.time1) {
        this.$toast('请先选择开始时间');
      } else {
        this.showTime2 = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wjys-add-page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .white-page {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 80vw;
    }
  }
  // .van-field__error-message {
  //   text-align: right;
  // }

  .section-textArea {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
