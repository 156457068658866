<template>
  <div class="shared-print-container">
    <van-nav-bar
      title="共享打印"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="shared-print-body-container">
      <div class="top-bar">
        <!-- tip -->
        <div class="tip-bar">
          <i class="notice-icon"></i>
          <p>上传成功后，请前往【东盛社区邻里中心】打印获取</p>
        </div>
        <!-- print -->
        <input style="display: none;" type="file" id="uploader" accept="image/*,.pdf" multiple="multiple" @change="handleFile">
        <div class="print-bar" @click="handleUploader">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/sharedPrint/uploader-icon.png" alt="">
          <h4>上传照片或pdf格式文件</h4>
          <p>一次最多上传10个文件，单个不超过20Mb</p>
        </div>
      </div>
      <!-- 文件列表 -->
      <div class="file-list-bar">
        <div class="file-list-bar-title">
          <h1>上传历史</h1>
          <span @click="handleDeleteAll">全部删除</span>
        </div>
        <div v-if="fileList && fileList.length > 0" class="file-list">
          <div class="file-list-card" v-for="item in fileList" :key="item.date">
            <h2>{{item.date}}</h2>
            <div v-for="file in item.data" :key="file.id" class="file-card">
              <img :src="getImage(file)" alt="">
              <div class="file-card-right">
                <p>{{file.fileName}}</p>
              </div>
              <i @click="handleDelete(file)" class="close"></i>
            </div>
          </div>
        </div>
        <div class="no-data-con" v-else>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/sharedPrint/no-data.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import { getQueryString } from '@/utils/index';
export default {
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    this.getHistoryFiles();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleUploader() {
      const upInput = document.getElementById('uploader');
      upInput.click();
    },
    handleFile() {
      if(window.File && window.FileList) {
        const fileCount = document.getElementById("uploader").files.length;
        const sharedFiles = document.getElementById("uploader").files;
        const max_size = 1024 * 1024 * 20;
        const errArray = []; // 文件大小报错记录
        // console.log('files', sharedFiles);
        if(fileCount > 10) {
            window.alert('文件数不能超过10个，你选择了' + fileCount + '个');
            return false;
        } else {
          const formData = new FormData();
          Array.from(sharedFiles).forEach((item) => {
            if(item.size < max_size) {
              formData.append("files", item);
            } else {
              errArray.push(item.name);
            }
          });
          if(errArray.length > 0) {
            this.$toast(`${errArray.join('、')}文件过大，已取消上传。`)
          } else if (errArray.length < sharedFiles.length) { // 有可上传的文件
            homeApi.uploadSharedFiles(formData).then(res => {
              if(res.flag) {
                this.$dialog({
                  title: '上传成功',
                  message: '上传成功，请前往东盛社区邻里中心打印获取'
                }).then(() => {
                  this.getHistoryFiles();
                  const ele = document.getElementById("uploader");
                  ele.value = ""; // 清除文件
                })
                
              }
            })
          }
        }

      } else {
          window.alert('抱歉，你的浏览器不支持FileAPI，请升级浏览器！');
      }
    },
    getHistoryFiles() {
      homeApi.getSharedFiles({}).then(res => {
        if(res.flag) {
          this.fileList = res.data;
        }
      })
    },
    getImage(data) {
      const name = data.fileName;
      if(name.indexOf('jpg') > 0 || name.indexOf('png') > 0 || name.indexOf('jpeg') > 0) {
        return data.fileUrl;
      } else if(name.indexOf('pdf') > 0) {
        return 'http://dssq.ikeqiao.net/data/image/h5/images/sharedPrint/pdf-icon.png';
      }
      return 'http://dssq.ikeqiao.net/data/image/h5/images/sharedPrint/picture-icon.png';
    },
    handleDelete(data) {
      homeApi.deleteSharedFile({ id: data.id }).then(res =>  {
        if(res.flag) {
          // this.$toast('文件删除成功！');
          this.getHistoryFiles();
        }
      })
    },
    handleDeleteAll() {
      homeApi.deleteAllSharedFile({}).then(res =>  {
        if(res.flag) {
          // this.$toast('文件删除成功！');
          this.getHistoryFiles();
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.shared-print-container {
  height: 100vh;
  width: 100vw;
  
  .shared-print-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      background-color: #FFF;
      width: 100vw;
      padding: 8px 20px 20px 20px;
      margin-bottom: 1rem;
      .tip-bar {
        height: 16px;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .notice-icon {
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/sharedPrint/notice.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .print-bar {
        width: 100%;
        background-color: #F7F8FA;
        padding: 20px 15px;
        text-align: center;
        >img {
          width: 48px;
          height: 36px;
          margin-top: 1rem;
        }
        >h4 {
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          line-height: 24px;
          font-weight: 400;
        }
        >p {
          font-size: 13px;
          color: rgba(0,0,0,0.45);
          line-height: 24px;
        }
      }
    }
    .file-list-bar {
      background-color: #FFF;
      width: 100vw;
      padding: 10px 20px;
      .file-list-bar-title {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        >h1 {
          font-size: 16px;
          color: rgba(0,0,0,0.85);
        }
        >span {
          font-size: 14px;
          color: #4479ED;
        }
      }
      .file-list {
        .file-list-card {
          padding: 10px 0;
          >h2 {
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            font-weight: 400;
          }
          .file-card {
            display: flex;
            align-items: center;
            margin: 10px 0;
            >img {
              height: 60px;
              width: 60px;
              margin-right: 10px;
            }
            .file-card-right {
              display: block;
              width: calc(100% - 96px);
              >p {
                width: 100%;
                font-size: 16px;
                color: rgba(0,0,0,0.85);
                white-space: wrap;
                word-break: break-all;
              }
            }
            .close {
              width: 16px;
              height: 16px;
              display: block;
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/sharedPrint/delete-icon.png') no-repeat;
              background-size: 100% 100%;
              margin-left: 10px;;
            }
          }
        }
      }
    }
    .no-data-con {
      width: 100%;
      background-color: #FFF;
      padding: 2rem 0;
      text-align: center;
      >img {
        width: 120px;
        height: 120px;
      }
    }
  }
}
</style>
