
<template>
  <div class="parent-room-container">
    <van-nav-bar
      title="家长课堂"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="parent-room-body-container">
      <!-- 数字家长课堂 -->
      <div class="coin-bar-con">
        <div class="coin-bar">
          <h1>数字家长课堂</h1>
          <h3>学堂一句话介绍</h3>
        </div>
      </div>
      <!-- 家长学习 -->
      <div class="card-nav">
        <div class="card-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">家长学习</span>
          </div>
          <div>
            <span class="more">更多</span>
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt="">
          </div>
        </div>
        <div class="card-content">
          <!-- <van-tabs @click="handleTab" color="#4479ED">
            <van-tab title="教育理念"> -->
              <ul class="study-list">
                <li v-for="(item, index) in studyList" :key="index" @click="gotoLink('studyDetail?key='+index)">
                  <div class="left">
                    <div class="tit">{{item.title}}</div>
                    <div class="reply-info">
                      <span>{{`${item.num}浏览量`}}</span>
                      <span>{{item.date}}</span>
                    </div>
                  </div>
                  <div class="right">
                    <img :src="item.picture" alt="">
                  </div>
                </li>
              </ul>
            <!-- </van-tab>
            <van-tab title="教育方法">
              <ul class="study-list">
                <li v-for="(item, index) in studyList" :key="index" @click="gotoLink('studyDetail?key='+index)">
                  <div class="left">
                    <div class="tit">{{item.title}}</div>
                    <div class="reply-info">
                      <span>{{`${item.num}浏览量`}}</span>
                      <span>{{item.date}}</span>
                    </div>
                  </div>
                  <div class="right">
                    <img :src="item.picture" alt="">
                  </div>
                </li>
              </ul>
            </van-tab>
            <van-tab title="教育知识">
              <ul class="study-list">
                <li v-for="(item, index) in studyList" :key="index" @click="gotoLink('studyDetail?key='+index)">
                  <div class="left">
                    <div class="tit">{{item.title}}</div>
                    <div class="reply-info">
                      <span>{{`${item.num}浏览量`}}</span>
                      <span>{{item.date}}</span>
                    </div>
                  </div>
                  <div class="right">
                    <img :src="item.picture" alt="">
                  </div>
                </li>
              </ul>
            </van-tab>
          </van-tabs> -->
        </div>
      </div>
      <!-- 亲子互动 -->
      <div class="card-nav">
        <div class="card-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">亲子互动</span>
          </div>
          <div>
            <span class="more">更多</span>
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt="">
          </div>
        </div>
        <div class="card-content">
          <!-- <van-tabs @click="handleTab" color="#4479ED">
            <van-tab title="参观体验"> -->
              <ul class="interaction-list">
                <li v-for="(item, index) in dataList" :key="index" @click="gotoLink('activityDetail?key='+index)">
                  <div class="img">
                    <img :src="item.imgUrl" />
                    <van-tag v-if="item.state == '0'" type="off">报名中</van-tag>
                    <van-tag v-if="item.state == '1'" type="on">进行中</van-tag>
                    <van-tag v-if="item.state == '2'" type="finish">已完成</van-tag>
                    <div
                      v-if="item.state == '0' || item.state == '1'"
                      class="time"
                    >
                      {{ item.time }}
                    </div>
                    <div v-if="item.state == '1'" class="end">报名已截止</div>
                  </div>
                  <div class="desc">
                    <div class="title">
                      <b>{{ item.title }}</b><span>已报名 <i>{{ item.applyNum }}</i></span>
                    </div>
                    <div class="info">
                      <label>发布单位：</label><span>{{ item.dep }}</span>
                    </div>
                    <div class="info">
                      <label>活动时间：</label><span>{{ item.date }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            <!-- </van-tab>
            <van-tab title="专题调查">
              <ul class="interaction-list">
                <li v-for="(item, index) in dataList" :key="index" @click="gotoLink('activityDetail?key='+index)">
                  <div class="img">
                    <img :src="item.imgUrl" />
                    <van-tag v-if="item.state == '0'" type="off">报名中</van-tag>
                    <van-tag v-if="item.state == '1'" type="on">进行中</van-tag>
                    <van-tag v-if="item.state == '2'" type="finish">已完成</van-tag>
                    <div
                      v-if="item.state == '0' || item.state == '1'"
                      class="time"
                    >
                      {{ item.time }}
                    </div>
                    <div v-if="item.state == '1'" class="end">报名已截止</div>
                  </div>
                  <div class="desc">
                    <div class="title">
                      <b>{{ item.title }}</b><span>已报名 <i>{{ item.applyNum }}</i></span>
                    </div>
                    <div class="info">
                      <label>发布单位：</label><span>{{ item.dep }}</span>
                    </div>
                    <div class="info">
                      <label>活动时间：</label><span>{{ item.date }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </van-tab>
            <van-tab title="研学旅行">
              <ul class="interaction-list">
                <li v-for="(item, index) in dataList" :key="index" @click="gotoLink('activityDetail?key='+index)">
                  <div class="img">
                    <img :src="item.imgUrl" />
                    <van-tag v-if="item.state == '0'" type="off">报名中</van-tag>
                    <van-tag v-if="item.state == '1'" type="on">进行中</van-tag>
                    <van-tag v-if="item.state == '2'" type="finish">已完成</van-tag>
                    <div
                      v-if="item.state == '0' || item.state == '1'"
                      class="time"
                    >
                      {{ item.time }}
                    </div>
                    <div v-if="item.state == '1'" class="end">报名已截止</div>
                  </div>
                  <div class="desc">
                    <div class="title">
                      <b>{{ item.title }}</b><span>已报名 <i>{{ item.applyNum }}</i></span>
                    </div>
                    <div class="info">
                      <label>发布单位：</label><span>{{ item.dep }}</span>
                    </div>
                    <div class="info">
                      <label>活动时间：</label><span>{{ item.date }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </van-tab>
            <van-tab title="红色旅游">
              <ul class="interaction-list">
                <li v-for="(item, index) in dataList" :key="index" @click="gotoLink('activityDetail?key='+index)">
                  <div class="img">
                    <img :src="item.imgUrl" />
                    <van-tag v-if="item.state == '0'" type="off">报名中</van-tag>
                    <van-tag v-if="item.state == '1'" type="on">进行中</van-tag>
                    <van-tag v-if="item.state == '2'" type="finish">已完成</van-tag>
                    <div
                      v-if="item.state == '0' || item.state == '1'"
                      class="time"
                    >
                      {{ item.time }}
                    </div>
                    <div v-if="item.state == '1'" class="end">报名已截止</div>
                  </div>
                  <div class="desc">
                    <div class="title">
                      <b>{{ item.title }}</b><span>已报名 <i>{{ item.applyNum }}</i></span>
                    </div>
                    <div class="info">
                      <label>发布单位：</label><span>{{ item.dep }}</span>
                    </div>
                    <div class="info">
                      <label>活动时间：</label><span>{{ item.date }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </van-tab>
            <van-tab title="志愿服务">
              <ul class="interaction-list">
                <li v-for="(item, index) in dataList" :key="index" @click="gotoLink('activityDetail?key='+index)">
                  <div class="img">
                    <img :src="item.imgUrl" />
                    <van-tag v-if="item.state == '0'" type="off">报名中</van-tag>
                    <van-tag v-if="item.state == '1'" type="on">进行中</van-tag>
                    <van-tag v-if="item.state == '2'" type="finish">已完成</van-tag>
                    <div
                      v-if="item.state == '0' || item.state == '1'"
                      class="time"
                    >
                      {{ item.time }}
                    </div>
                    <div v-if="item.state == '1'" class="end">报名已截止</div>
                  </div>
                  <div class="desc">
                    <div class="title">
                      <b>{{ item.title }}</b><span>已报名 <i>{{ item.applyNum }}</i></span>
                    </div>
                    <div class="info">
                      <label>发布单位：</label><span>{{ item.dep }}</span>
                    </div>
                    <div class="info">
                      <label>活动时间：</label><span>{{ item.date }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </van-tab>
            <van-tab title="文体活动">
              <ul class="interaction-list">
                <li v-for="(item, index) in dataList" :key="index" @click="gotoLink('activityDetail?key='+index)">
                  <div class="img">
                    <img :src="item.imgUrl" />
                    <van-tag v-if="item.state == '0'" type="off">报名中</van-tag>
                    <van-tag v-if="item.state == '1'" type="on">进行中</van-tag>
                    <van-tag v-if="item.state == '2'" type="finish">已完成</van-tag>
                    <div
                      v-if="item.state == '0' || item.state == '1'"
                      class="time"
                    >
                      {{ item.time }}
                    </div>
                    <div v-if="item.state == '1'" class="end">报名已截止</div>
                  </div>
                  <div class="desc">
                    <div class="title">
                      <b>{{ item.title }}</b><span>已报名 <i>{{ item.applyNum }}</i></span>
                    </div>
                    <div class="info">
                      <label>发布单位：</label><span>{{ item.dep }}</span>
                    </div>
                    <div class="info">
                      <label>活动时间：</label><span>{{ item.date }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </van-tab>
          </van-tabs> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      iconList: [
        {
          name: '政策机构',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/policy.png',
        },
        {
          name: '学习资源',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/study.png',
          // path: 'wiseMan',
        },
        {
          name: '数字小社员',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/digital.png',
        },
      ],
      studyList: new Array(4).fill({
        title: '深入学习贯彻党的十九大精神全面开创教育改革发展新局面',
        num: 342,
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/default.png',
        date: '2021-08-15'
      }),
      dataList: [
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展家长孩子教育活动",
          state: "0",
          time: "剩5日20小时开始",
          applyNum: "10",
          dep: "双梅小区幼儿园",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展家长孩子教育活动",
          state: "1",
          time: "剩20分钟结束",
          applyNum: "200",
          dep: "双梅小区幼儿园",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
        {
          imgUrl: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/activity.png",
          title: "开展家长孩子教育活动",
          state: "2",
          time: "",
          applyNum: "30",
          dep: "双梅小区幼儿园",
          date: "2021-07-08 12:00至2021-07-08 14:00",
        },
      ]
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.parent-room-container {
  height: 100vh;
  width: 100vw;
  .parent-room-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .coin-bar-con {
      // display: flex;
      // background-color: #FFF;
      margin-bottom: 10px;
      .coin-bar {
        width: 100%;
        height: 121px;
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/jiazhang-top.png') no-repeat;
        background-size: 100% 100%;
        padding: 20px;
        color: #FFF;
        >h1 {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 32px;
          text-shadow: 0 2px 4px rgba(0,0,0,0.13);
          font-weight: 600;
        }
        >h3 {
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: left;
          font-weight: 200;
        }
      }
    }
    
    .card-nav {
      width: 100vw;
      background: #FFF;
      padding: 10px;
      margin-top: 10px;
      .card-title {
        width: 100%;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-bar {
            height: 18px;
            width: 4px;
            background: #4479ED;
            margin-right: 10px;
          }
          .title-text {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
          }
          .more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
          i {
            color: #4479ED;
          }
        }
      }
      .card-content {
        
      }
    }
    .study-list {
      width: 100%;
      margin: 0 auto;
      li {
        padding: 0.3rem;
        // box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
        border-radius: 4px;
        display: flex;
        align-items: flex-start;
        border-bottom: 0.5px solid rgba($color: #000000, $alpha: 0.1);
        .right {
          width: 120px;
          height: 86px;
          margin-left: 10px;
          img {
            width: 120px;
            height: 86px;
          }
        }
        .left {
          flex: 1;
          height: 86px;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .tit {
            font-weight: 600;
            color: #333;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 0.3rem;
          }
          .reply-info {
            span {
              color: #bbb;
              margin-right: 10px;
            }
            
          }
          .state {
            padding-top: 0.4rem;
            span {
              display: inline-block;
              height: 1.4rem;
              padding: 0 0.7rem;
              line-height: 1.4rem;
              border-radius: 0.2rem;
              font-style: inherit;
              font-size: 0.8rem;
              background: #e8e8e8;
              color: #101010;
            }
            i {
              display: inline-block;
              vertical-align: middle;
              width: 1rem;
              height: 1rem;
              margin-right: 0.3rem;
              background: url(http://dssq.ikeqiao.net/data/image/h5/images2/icon-zan.png) no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }

    .interaction-list {
      width: 100%;
      margin: 0 auto;
      li {
        margin-top: 0.8rem;
        box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
        border-radius: 4px;
        .img {
          height: 8rem;
          position: relative;
          img {
            width: 100%;
            height: 8rem;
          }
          .van-tag {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 0.4rem;
          }
          .van-tag--on {
            background: #e3effd;
            color: #4479ed;
          }
          .van-tag--off {
            background: #d3f1e3;
            color: #40c76d;
          }
          .van-tag--finish {
            background: #ededed;
            color: rgba(0, 0, 0, 0.45);
          }
          .time {
            background: rgba(0, 0, 0, 0.45);
            padding: 0 0.4rem;
            color: #fff;
            height: 1.6rem;
            line-height: 1.6rem;
            border-radius: 0.3rem;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 0.4rem;
          }
          .end {
            background: rgba(0, 0, 0, 0.45);
            padding: 0 0.4rem;
            color: #fff;
            height: 1.6rem;
            line-height: 1.6rem;
            border-radius: 0.3rem;
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 0.4rem;
          }
        }
        .desc {
          background: #fff;
          border-radius: 0 0 0.3rem 0.3rem;
          padding: 0.5rem 0.7rem;
          .title {
            width: 100%;
            height: 1.4rem;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            align-items: center;
            b {
              color: rgba(0, 0, 0, 0.85);
              font-size: 1rem;
              width: 16.4rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            span {
              color: rgba(0, 0, 0, 0.65);
              font-size: 0.4rem;
              i {
                color: #4479ed;
                font-family: arial;
                font-style: initial;
              }
            }
          }
          .info {
            font-size: 0.9rem;
            line-height: 1.4rem;
            color: rgba(0, 0, 0, 0.85);
            label {
              color: rgba(0, 0, 0, 0.45);
            }
          }
        }
      }
    }
  }
}
</style>
