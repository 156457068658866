<template>
  <div id="index">
    <div class="sdbxt-detail-page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>社区学堂</h1>
      </div>
      <div class="sdb-banner">
        <img src="http://dssq.ikeqiao.net/data/image/dongsheng/sdbxt/学堂详情.png" />
      </div>
      <div class="org-elegant">
        <h3>社区学堂</h3>
        <h4>1、简介</h4>
        <div class="bd">
          学龄前教育是孩子的启蒙阶段，是家庭、社会的共同责任，为更好的服务辖区居民，发挥社区邻里休闲文化中心的作用，社区引入“金宝贝”早教中心，将优质的科学早教资源引入社区，启动东盛社区“蓓蕾计划”。0-3岁是宝宝大脑成长的黄金期，因此早教对于孩子的成长发展具有重要作用。“蓓蕾计划”针对学龄前儿童开设内容丰富、专业有趣的公益早教课。让孩子们在多元的环境中成长，更自由地探索世界、拓展眼界，家长们更能在家门口就获取高质量的育儿知识，做智慧父母科学育儿和孩子一起长大。
        </div>
        <h4>2、合作机构：萤火虫教育集团</h4>
        <div class="bd">
          第一期：金宝贝儿童多元成长中心、UBTV小主播、潮童星、美数乐儿童创艺思维中心
          第二期：金宝贝儿童多元成长中心、UBTV小主播、潮童星、童创思维力乐高机器人中心
        </div>
        <h4>3、师资情况</h4>
        <div class="bd">
          全部为机构中的优质教师
        </div>
        <h4>第一期课程表</h4>
        <img width="100%" src="http://dssq.ikeqiao.net/data/image/dongsheng/sdbxt/课程表一.png" />
        <h4>第二期课程表</h4>
        <img width="100%" src="http://dssq.ikeqiao.net/data/image/dongsheng/sdbxt/课程表二.png" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    }
  }
};
</script>

<style lang="scss" scoped>
.sdbxt-detail-page-container {
  height: 100vh;
  padding-top: 3rem;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
  font-size: 0.9rem;
  line-height: 1.3rem;
  .sdb-banner {
    width: 23.4rem;
    margin: 0.7rem auto 0;
    height: 14rem;
    img {
      height: 14rem;
      width: 100%;
      border-radius: 0.5rem;
    }
  }
  .org-elegant {
    background-color: #fff;
    padding: 0.6rem 0.7rem;
    h3 {
      color: #333;
      font: 500 1rem/1.6rem "microsoft yahei";
      text-align: center;
    }
    .bd {
      color: #888;
      font: 500 0.9rem/1.6rem "microsoft yahei";
      padding: 0.2rem 0.7rem;
      margin-bottom: 0.5rem;
    }
    .bd-img {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
        margin: 0.3rem 0.15rem;
        width: 10.1rem;
        height: 7.3rem;
        display: inline-block;
      }
    }
  }
}
</style>
