<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="基本医疗保险参保人员登记"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="wxxd-body-container">
      <div class="report-bar" @click="routerpush">
        <span class="report-title">关于城乡医保缴费的具体通知</span>
        <!--        <span class="report-arrow" @click="gotoLink('developPartyMember')">
          查看
          <van-icon name="arrow" color="#FFF" size="16px" />
        </span> -->
      </div>
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <div class="form-title">
            参保人基本信息
          </div>
          <van-cell
            title-class="cell"
            title="户籍或配偶户籍是否在本社区"
            size="large"
          >
            <template #default>
              <span
                :class="['radio', { selected: sex === '是' }]"
                @click="changeSex('是')"
                >是</span
              >
              <span
                :class="['radio', { selected: sex === '否' }]"
                @click="changeSex('否')"
                >否</span
              >
            </template>
          </van-cell>
          <van-field
            size="large"
            v-model="name"
            name="name"
            label="参保人姓名"
            placeholder="请填写姓名"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系方式"
            placeholder="请填写联系电话"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="idCard"
            name="idCard"
            label="身份证号码"
            placeholder="请填写身份证号码"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="zb"
            name="nation"
            label="民族"
            placeholder="请填写加入的民族"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="nation"
            name="registerAddress"
            label="户籍地址"
            placeholder="请填写户籍地址"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <div class="form-title">
            户主信息
          </div>
          <van-field
            size="large"
            v-model="jiguan"
            name="householderName"
            label="户主姓名"
            placeholder="请填写户主姓名"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="zhiye"
            name="householderIdCard"
            label="身份证号码"
            placeholder="请填写身份证号码"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="relationship"
            name="householderRelation"
            label="与户主关系"
            placeholder="请填写与户主关系"
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
            @click="openModal"
            readonly
          />
          <van-field
            v-model="bz"
            name="note"
            autosize
            label="备注"
            type="textarea"
            placeholder="请输入备注"
            show-word-limit
            input-align="right"
            maxlength="200"
            size="large"
          />
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
            >
              提交
            </van-button>
          </div>
        </van-form>
      </div>
      <van-popup v-model="showType" round position="bottom">
        <van-picker
          title="与户主关系"
          show-toolbar
          :columns="livingTypeData"
          @confirm="onConfirm"
          @cancel="onCancel"
          value-key="dictLabel"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home'
import { Dialog } from 'vant'
import service from 'utils/service'
import { mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {
      type: '全血',
      sex: '否',
      name: '',
      phone: '',
      idCard: '',
      date: '',
      showDate: false,
      showDepart: false,
      depart: '',
      departList: [],
      zb: '',
      nation: '',
      jiguan: '',
      zhiye: '',
      hangye: '',
      xueli: '',
      yuanxiao: '',
      juzhudi: '',
      qita: '',
      show: false,
      showType: false,
      bz: '',
      relationship: '',
    }
  },
  components: {},
  mounted() {
    const obj = JSON.parse(sessionStorage.getItem('userInfo'));
    // console.log(obj)
    const { userName = '', phone = '' } = obj || {};
    this.name = userName;
    this.phone = phone;
    this.$store.dispatch('login/getlivingTypeData')
    // this.$dialog.alert({
    //   title: '入党须知',
    //   message: '弹窗内容',
    //   theme: 'round-button',
    // })
  },
  computed: {
    ...mapState({
      livingTypeData: (state) => state.login.livingTypeData,
      // userInfo: (state) => (state.login.userInfo.nickname ? state.login.userInfo : null) || context.getSessionData('userInfo'),
    }),
  },
  watch: {},
  methods: {
    openModal() {
      this.showType = true
    },
    onCancel() {
      this.showType = false
    },
    onConfirm(e) {
      this.relationship = e.dictLabel
      // this.householdType = e.dictValue;
      this.showType = false
    },
    testTouch(e) {
      e.stopPropagation()
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    handleTab() {},
    gotoLink(path) {
      if (path) {
        this.$router.push(path)
      }
    },
    changeSex(val) {
      this.sex = val
    },
    routerpush() {
      window.location.href = 'https://mp.weixin.qq.com/s/1GH6Tz2WItu0pZsztwHQvQ'
    },
    onSubmit(values) {
      const obj = JSON.parse(sessionStorage.getItem('userInfo'))
      console.log(obj)
      const { userId } = obj || {};
      console.log(
        'values',
        Object.assign(values, { inSocial: this.sex, id: userId })
      )
      service.post('/new/regInsurance/add', values).then((res) => {
        console.log(res)
        const { flag } = res
        if (flag) {
          Dialog.confirm({
            title: '提交成功',
            message: '您已成功提交登记，请于五个工作日后进行缴费（如在11月30日仍无法缴费，请联系社区）',
            confirmButtonText: '缴费流程',
            cancelButtonText: '返回',
            messageAlign: 'left'
          })
            .then(() => {
              // on confirm
              window.location.href =
                'https://mp.weixin.qq.com/s/1GH6Tz2WItu0pZsztwHQvQ'
            })
            .catch(() => {
              // on cancel
              this.$router.go(-1)
            })
        } else {
          Dialog.alert({
            title: '提交失败',
          })
        }
      })
      // this.$toast({
      //   message: '申请提交成功！',
      //   position: 'top',
      // });
      // this.$router.go(-1);
    },
    onOk() {
      this.show = false
    },
  },
}
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  .form-title {
    align-items: center;
    line-height: 40px;
    padding-left: 10px;
    color: dimgray;
    background: lightgray;
  }
  .wxxd-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    // padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png')
      no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .report-bar {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 0 10px;
      height: 50px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/wxxd-top-bar.png')
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .report-title {
        font-size: 18px;
        color: #fff;
      }
      .report-arrow {
        color: #fff;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }
    .form-bar {
      .cell {
        color: rgba(0, 0, 0, 0.65);
      }
      .radio {
        height: 2rem;
        border-radius: 1rem;
        // background: #4479ED;
        color: rgba(0, 0, 0, 0.35);
        border: 1px solid rgba(0, 0, 0, 0.35);
        padding: 0.1rem 1.2rem;
        margin-left: 8px;
      }
      .selected {
        background: #4479ed;
        color: #ffffff;
        border: 1px solid #4479ed;
      }
      .divide {
        height: 0.8rem;
        background: #f6f7fa;
      }
      .footer-button {
        position: fixed;
        bottom: 0.8rem;
        right: 0.8rem;
        left: 0.8rem;
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10vh;

      .pay-window {
        background-color: #fff;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75vw;
        .pay-window-top {
          width: 100%;
          height: 48px;
          background-color: #4479ed;
          font-size: 16px;
          color: #fff;
          line-height: 48px;
          border-radius: 8px 8px 0 0;
          position: relative;
          .close {
            position: absolute;
            top: 16px;
            right: 10px;
          }
        }
        .pay-window-content {
          width: 100%;
          text-align: center;
          line-height: 20px;
          padding: 16px;
          // .image-con {
          //   width: 60vw;
          //   height: 60vw;
          //   padding: 16px;
          > img {
            width: 100%;
            height: 100%;
          }
          // }
          .text {
            height: 60vh;
            overflow-y: auto;
            overflow-x: hidden;
            text-align: left;
          }
          .button-wxxd {
            width: 100%;
            background-color: #4479ed;
            color: #fff;
            font-size: 18px;
            line-height: 44px;
            border: none;
            border-radius: 4px;
            margin: 10px 0;
          }
        }
      }
    }
  }
}
</style>
