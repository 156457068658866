<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>问答详情</h1>
      </div>
      <div class="card-nav">
        <div class="question">
          <div class="question-title">{{ questionInfo.invitationTitle }}</div>
          <div class="person">
            <img
              v-if="questionInfo.avatarUrl"
              :src="questionInfo.avatarUrl"
              class="avatar"
              alt="头像"
            />
            <img
              v-else
              src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png"
              class="avatar"
              alt="头像"
            />
            <span>{{ questionInfo.nickName }}</span>
          </div>
          <div class="question-little-title">
            <div class="piece">发布时间：{{ questionInfo.createTime }}</div>
            <div class="piece">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/view.png" />
              <span>{{ questionInfo.visitsUp }}</span>
            </div>
            <div class="piece">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/message.png" />
              <span>{{ list.length }}</span>
            </div>
          </div>
          <div class="paragraph">
            <p>{{ questionInfo.invitationContent }}</p>
          </div>
          <div class="image-list">
            <img
              v-for="image in invitationImage"
              :key="image"
              :src="image"
              class="image"
              alt="暂无图片"
            />
          </div>
        </div>
      </div>
      <div class="card-nav">
        <div class="card-title">
          <div class="title">全部回答（{{ total }}）</div>
        </div>
        <van-list
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            v-for="item in list || []"
            :key="item.commentId"
            class="question"
          >
            <div class="question-title">
              <div class="person">
                <img :src="item.image || 'http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png'" class="avatar" alt="头像" />
                <span>{{ item.userName }}</span>
              </div>
              <!-- <div class="right-icon" @click="handleAddThumbsUp(item)">
                <img
                  v-if="item.hasThumbsUp === '未点赞'"
                  src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/zan.png"
                />
                <img v-else src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/zan-active.png" />
                <span>{{ item.thumbsUp }}</span>
              </div> -->
            </div>
            <div class="paragraph">
              <p>{{ item.commentContent }}</p>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <div class="foot">
      <div class="input-bar">
        <input type="text" v-model="commentContent" placeholder="回答一下" />
      </div>
      <img src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/send.png" @click="handleAddComment" />
    </div>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import zntApi from "api/llyjr/znt";
import { mapState } from "vuex";
import { Toast, List } from "vant";

export default {
  data() {
    return {
      page: 0,
      size: 10,
      total: 0,
      list: [],
      finished: false,
      commentContent: "",
      invitationImage: [],
    };
  },
  computed: {
    ...mapState({
      questionInfo: state => {
        return state.llyjr.questionInfo;
      }
    })
  },
  mounted() {
    llyjrApi
      .addVisitUp({ invitationId: this.questionInfo.invitationId })
      .then(res => {});
  },
  methods: {
    getList({
      page = this.page,
      size = this.size,
      invitationId = this.questionInfo.invitationId
    } = {}) {
      zntApi
        .getCommentPage({ page, size, object: { invitationId } })
        .then(res => {
          const { result = [], total = 0 } = res.data || {};
          this.list = this.list.concat(result);
          this.total = total;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    onLoad() {
      this.invitationImage = this.questionInfo.invitationImage
        ? this.questionInfo.invitationImage.split("#")
        : [];
      this.page += 1;
      this.getList();
    },
    handleAddComment() {
      this.$store.dispatch('login/checkRealName', () => {
        const { invitationId } = this.questionInfo;
        const params = {
          invitationId,
          commentContent: this.commentContent
          // commentPid: "null", // 根评论为null,对评论进行回复，值为评论的commentId
          // commentRootId: null // 根评论，所有评论的根评论
        };
        zntApi.addComment(params).then(res => {
          if (res.data == 1) {
            Toast.success("回答成功");
            this.commentContent = "";
            this.page = 1;
            this.list = [];
            this.getList();
          }
        });
      })
    },
    // 手动更新
    updateList(commentId, flag) {
      this.list = this.list.map(v => ({
        ...v,
        hasThumbsUp:
          v.commentId === commentId
            ? flag === "addThumbsUp"
              ? "已点赞"
              : "未点赞"
            : v.hasThumbsUp,
        thumbsUp:
          v.commentId === commentId
            ? flag === "addThumbsUp"
              ? v.thumbsUp + 1
              : v.thumbsUp - 1
            : v.thumbsUp
      }));
    },
    addThumbsUp(commentId) {
      zntApi.addThumbsUp({ commentId }).then(res => {
        if (res.data == 1) {
          Toast.success("点赞成功");
          this.updateList(commentId, "addThumbsUp");
        }
      });
    },
    cancelThumbsUp(commentId) {
      zntApi.cancelThumbsUp({ commentId }).then(res => {
        if (res.data == 1) {
          Toast.success("取消点赞成功");
          this.updateList(commentId, "cancelThumbsUp");
        }
      });
    },
    // 评论点赞
    handleAddThumbsUp(item) {
      const { hasThumbsUp, commentId } = item;
      this.$store.dispatch('login/checkRealName', () => {
        if (hasThumbsUp === "已点赞") {
          this.cancelThumbsUp(commentId);
        }
        if (hasThumbsUp === "未点赞") {
          this.addThumbsUp(commentId);
        }
      })
      
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  // padding: 0.8rem 0rem;
  padding-top: 2rem;
  background: #f6f7fa;
  height: calc(100vh - 4rem);
  overflow-y: auto;
  .card-nav {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    background: #ffffff;
    // padding: 0.8rem;
    .card-title {
      display: flex;
      justify-content: space-between;
      height: 2rem;
      align-items: center;
      padding: 0 0.8rem;
      .title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        border-left: 0.4rem solid #4479ed;
        padding-left: 0.8rem;
        font-weight: 600;
      }
      .more {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        text-align: right;
        line-height: 22px;
      }
    }
    .question {
      // margin-bottom: 0.8rem;
      background: #ffffff;
      padding: 0.8rem;
      .question-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        .right-icon {
          color: rgba(0, 0, 0, 0.45);
          font-size: 12px;
          text-align: left;
          line-height: 12px;
          img {
            height: 1rem;
            margin-right: 0.2rem;
          }
        }
      }
      .question-little-title {
        display: flex;
        color: rgba(0, 0, 0, 0.45);
        align-items: center;
        height: 1.5rem;
        .piece {
          margin-right: 0.4rem;
          vertical-align: middle;
          display: flex;
          align-items: center;
          img {
            height: 1rem;
            margin: 0 0.2rem;
          }
        }
      }
      .person {
        display: flex;
        align-items: center;

        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
        height: 2.5rem;
        .avatar {
          height: 2rem;
          width: 2rem;
          border-radius: 1rem;
          margin-right: 5px;
        }
      }
      .paragraph {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
      }
      .image-list {
        display: flex;
        margin: 0.8rem 0;
        .image {
          height: 4rem;
          width: 4rem;
          border-radius: 4px;
          margin-right: 0.4rem;
        }
      }
      .question-foot {
        height: 1.5rem;
        margin: 0.4rem 0;
        color: rgba(0, 0, 0, 0.45);
        display: flex;
        align-items: center;
        div {
          margin-right: 0.4rem;
        }
        img {
          height: 1rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
}
.foot {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 4rem;
  background: #ffffff;

  font-size: 13px;
  color: #ffffff;
  letter-spacing: -0.31px;
  text-align: center;
  line-height: 15px;
  padding: 1rem;
  display: flex;
  align-items: center;
  .input-bar {
    flex: 1;
    input {
      width: 100%;
      background: #f4f5f8;
      border-radius: 18px;
      border: none;
      height: 2rem;
      line-height: 2rem;
      padding: 0 1rem;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  img {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 1rem;
  }
}
</style>
