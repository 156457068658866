<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我要上报</h1>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="invitationType"
          name="invitationType"
          label="上报类型"
          placeholder="请选择"
          input-align="right"
          is-link
          readonly
          @click="showType = true"
          :rules="[{ required: true, message: '请选择上报类型' }]"
        />
        <van-field
          v-model="invitationPhone"
          name="invitationPhone"
          label="联系人"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写联系人' }]"
        />
        <van-field
          v-model="invitationName"
          name="invitationName"
          label="联系电话"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写联系电话' }]"
        />
        <div class="section-textArea">
          <div class="section-label">上报内容</div>
          <van-field
            v-model="invitationContent"
            name="invitationContent"
            rows="2"
            autosize
            label
            type="textarea"
            maxlength="400"
            placeholder="请输入"
            show-word-limit
          />
        </div>
        <div class="section" style="padding: 0.8rem;">
          <div class="image-title">图片</div>
          <van-uploader
            v-model="invitationImage"
            name="invitationImage"
            multiple
            :max-count="4"
          />
        </div>
        <div class="foot-button">
          <van-button block type="info" native-type="submit"
            >立即上报</van-button
          >
        </div>
      </van-form>
    </div>
    <van-popup v-model="showType" position="bottom">
      <van-picker
        title="问题类型"
        show-toolbar
        :columns="typeList"
        @confirm="onTypeConfirm"
        @cancel="onCancel('showType')"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      invitationType: "",
      invitationName: "",
      invitationPhone: "",
      invitationContent: "",
      invitationImage: [],
      showType: false,
      typeList: [
        "持有可用于训练或行凶的器具",
        "关注人员搬离",
        "持有极端宗教物品",
        "穿着打扮可疑",
        "存有高危物资",
        "关注人员新搬入",
        "人员有不正常聚集",
        "违法犯罪线索",
        "警务事件-其他",
        "不稳定因素",
        "矛盾纠纷信息",
        "安全隐患信息",
        "关注人员网格事件",
        "关注人员登记",
        "关注人员注销",
      ],
      selected: "question",
      anonymous: false,
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    onTypeConfirm(value, index) {
      this.invitationType = value;
      this.showType = false;
    },
    onCancel(type) {
      this[type] = false;
    },
    pushThinkTank(values) {},
    onSubmit(values) {
      console.log(this.invitationImage);
      Toast.success("上报成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .white-page {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 80vw;
    }
  }
  // .van-field__error-message {
  //   text-align: right;
  // }

  .section-textArea {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
