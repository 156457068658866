<template>
  <div class="publish-need-container">
    <van-nav-bar
      title="发布需求"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="publish-need-body-container">
      <div class="image-bar">
        发布需求
        <p>提交后会进行审核，通过后展示在手机端</p>
      </div>
      <van-form @submit="onSubmit">
        <div class="section" style="padding: 0.8rem;">
          <div class="title"><span class="required">*</span>需求内容</div>
          <van-field
            size="large"
            v-model="remark"
            rows="3"
            autosize
            name="remark"
            type="textarea"
            maxlength="200"
            placeholder="请填写您的需求内容"
            show-word-limit 
            :rules="[{ required: true, message: '请填写您的需求内容' }]"
          />
        </div>
        <div class="section" style="padding: 0.8rem">
          <div class="title">相关图片</div>
          <van-uploader
            v-model="imageList"
            name="imageList"
            multiple
            :max-count="5"
          />
        </div>
        <van-checkbox class="checkbox" name="anonymous" v-model="anonymous">匿名发布</van-checkbox>
        <div class="footer-button">
          <van-button
            block
            type="info"
            style="font-size: 16px"
            native-type="submit"
            >提交审核</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      remark: '',
      imageList: [],
      anonymous: false,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit(values) {
      console.log('values', values);
    }
  }
};
</script>

<style lang="scss" scoped>
.publish-need-container {
  height: 100vh;
  width: 100vw;
  .publish-need-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 92px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/form-top-bg.png') no-repeat;
      background-size: 100% 100%;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 500;
      text-align: center;
      padding: 20px;
      line-height: 24px;
      >p {
        font-size: 14px;
      }
    }
    .section {
      width: 100%;
      margin-bottom: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        .required {
          color: #e13f30;
        }
      }
    }
    .checkbox {
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      margin-left: 0.8rem;
      margin-top: 0.8rem;
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
