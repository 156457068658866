<template>
  <div class="axst" style="height: 100vh; padding-top: 2.7rem">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>爱心食堂</h1>
    </div>
    <van-notice-bar color="#FF4535" background="#FFEFEE" left-icon="info-o">
      <template #left-icon>
        <img src="../../assets/images/axst/tongzhi.svg" alt="">
      </template>
      2021-12-24开始恢复堂食，就餐时间为11:00-13:00，欢迎电话预定！
    </van-notice-bar>
    <!-- <div class="tipsTitle">
      <img src="../../assets/images/axst/tongzhi.svg" alt="">
      <span>本周2021-12-24开始恢复堂食，就餐时间为10:30-13:00，欢迎电话预定！</span>
    </div> -->
    <div class="topBanner"></div>
    <div class="choiceBar" id='axstScollTime'>
      <div :style="`color: ${item.color}`" :class="activeItem === i ? 'choice-nav-item activeItem' : 'choice-nav-item'"  v-for="(item, i) in dateList" :key="i" @click="choiceDay(i, item)">
        <div class="time">{{item.week}}</div>
        <div class="title">{{item.day}}</div>
      </div>
    </div>
    <div class="imageList">
      <div v-for="(item, i) in imageList" class="item" :key="i">
          <div class="image"><img :src="item.image" alt=""></div>
          <div class="text">
            <div class="title">{{item.name}}</div>
            <div class="desc">{{item.time}}</div>
            <!-- <div class="desc">{{item.count}}</div> -->
          </div>
          <div class="btn" @click="showDetail(item)">去预订</div>
      </div>
    </div>
    <div class="myPhoneContent">
      <div>订餐热线</div>
      <div class="num" @click="callPhone('0575-87595337')">0575-87595337</div>
    </div>
    <div class="tab-flex-bottom" @click="gotoLink('payOrderList')">我的订单</div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import service from 'utils/service';
import moment from 'moment';
import 'moment/locale/zh-cn';
import axstApi from 'api/axst';
export default {
  data() {
    return {
      dateInfo: [],
      activeItem: 0,
      commentList: [
        { day: '星期一', date: '2021-12-22' },
        { day: '星期二', date: '2021-12-23' },
        { day: '星期三', date: '2021-12-24' },
        { day: '星期四', date: '2021-12-25' },
        { day: '星期五', date: '2021-12-26' },
        { day: '星期六', date: '2021-12-27' },
        { day: '星期日', date: '2021-12-28' },
      ],
      imageList: [
        // { name: '早餐', time: '预定截止时间:当日6点前', count: '约27种', image: require('../../assets/images/axst/zaochan.svg')},
        { name: '3素10元', time: '预定截止时间:11点前', count: '约29种', image: require('../../assets/images/axst/lunch1.png'), type: '早餐', momey: 10},
        { name: '1荤2素13元', time: '预定截止时间:11点前', count: '约29种', image: require('../../assets/images/axst/lunch2.png'), type: '午餐', momey: 13},
        { name: '1荤3素15元', time: '预定截止时间:11点前', count: '约29种', image: require('../../assets/images/axst/lunch3.png'), type: '午餐', momey: 15},
        { name: '2荤2素20元', time: '预定截止时间:11点前', count: '约29种', image: require('../../assets/images/axst/lunch4.png'), type: '午餐', momey: 20},
        // { name: '晚餐', time: '预定截止时间:当日16点前', count: '约23种', image: require('../../assets/images/axst/wanchan.svg')},
      ],
    };
  },
  components: {},
  mounted(opt) {
      document.getElementById('axstScollTime').scrollLeft = this.activeItem * 90;
  },
  computed: {
    dateList() {
      const result = [];
      for(let i = 0;i < 7; i += 1) {
        const startDay = moment().week(moment().week()).startOf('week');
        const current = startDay.add(i, 'days');
        const day = current.format('YYYY-MM-DD');
        const week = current.format('dddd');
        console.log(current.valueOf(), moment().valueOf());
        const color = current.valueOf() >= moment(moment().format('YYYY-MM-DD 00:00:00')).valueOf() ? '#000' : '#ccc';
        result.push({ day, week, color });
      }
      this.activeItem = result.findIndex(v => v.day === moment().format('YYYY-MM-DD'));
      this.dateInfo =  result;
      return result;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gotoLink(path){
      this.$router.push(path);
    },
    showDetail(item){
        const timeStr = `${this.dateInfo[this.activeItem].day}（${this.dateInfo[this.activeItem].week}）11:00-13:00`
        Dialog.confirm({
          title: "订餐提示",
          message: "确定预定套餐吗？",
        }).then(() => {
          axstApi.insertOrder({
            discountMoney: 0,
            orderMoney: item.momey,
            orderNum: `${moment().valueOf()}`.substring(`${moment().valueOf()}`.length - 8),
            orderStatus: 1,
            payMoney: item.momey,
            setMeal: item.name,
            takeMealTime: timeStr,
          }).then(res => {
            if (res.flag) {
              Toast.success('预约成功');
              const time = moment().format('YYYY-MM-DD HH:mm');
              this.$router.push(`/orderList?type=${item.type}&money=${item.momey}&meet=${item.name}&time=${time}`)
            }
          })
        })
      // this.$router.push(`/axstDetail?key=${item.type}&name=${item.name}&momey=${item.momey}`)
    },
    choiceDay(type, item){
      if(item.color === '#000') {
        this.activeItem = type;
      }
    },
    callPhone(number) {
      window.location.href = `tel://${number}`;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .axst{
    height: 100% !important;
    position: relative;
    .tipsTitle{
      padding: 10px ;
      line-height: 20px;
      font-size: 15px;
      color: #FF4535;
      background: #FFEFEE;
      letter-spacing: 0;
      display: flex;
      img{
        width: 20px;
        margin: 15px;
      }
    }
    .topBanner{
      width: 100%;
      height: 160px;
      background-image: url(../../assets/images/axst/stBanner.png);
      background-size: 100%;
      background-repeat: no-repeat;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .choiceBar{
      width: calc(100% - 20px);
      margin: 10px;
      margin-top: -35px;
      background: #FFFFFF;
      border-radius: 8px;
      border-radius: 8px;
      height: 70px;
      overflow: scroll;
      display: flex;
      .choice-nav-item{
        margin-right: 10px;
        width: 100px;
        min-width: 100px;
        padding: 10px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .title{
          line-height: 30px;
          height: 30px;
          font-size: 16px;
        }
        .time{
          line-height: 20px;
          height: 20px;
          font-size: 14px;
        }
      }
      .activeItem{
        border-bottom: 2px solid #08f;
      }
    }
    
      .imageList{
        width: calc(100% - 20px);
        background: #FFFFFF;
        border-radius: 8px;
        border-radius: 8px;
        margin: 10px;
        padding: 10px;
        .item{
          margin-bottom: 10px;
          display: flex;
          height: 80px;
          position: relative;
          .image{
            width: 120px;
            >img{
              width: 100%;
              height: 100%;
            }
          }
          .text{
            width: calc(100% - 130px);
            margin-left: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .title{
              line-height: 30px;
              font-size: 16px;
              color: #000000;
              font-weight: 500;
            }
            .desc{
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
              line-height: 25px;
              font-weight: 400;
            }
          }
          .btn{
            position: absolute;
            right: 10px;
            top: 20px;
            border: 1px solid #08f;
            border-radius: 5px;
            line-height: 24px;
            padding: 0 5px;
            color: #08f;
          }
        }
      }
      .myPhoneContent{
        background-image: url(../../assets/images/axst/phone.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        text-align: center;
        width: calc(100% - 20px);
        border-radius: 8px;
        border-radius: 8px;
        margin: 10px;
        padding: 10px;
        height: 70px;
        line-height: 25px;
        .num{
          font-size: 20px;
          color: #3E71F9;
          letter-spacing: 0;
          text-align: center;
        }
      }

    .tab-flex-bottom {
      position: fixed;
      bottom: 3rem;
      right: 10px;
      background: #4479ed;
      box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      color: #fff;
      padding: 0.8rem;
      font-size: 0.85rem;
      letter-spacing: 0;
      text-align: center;
      line-height: 1.2rem;
    }
  }
</style>
