<template>
  <div class="building-card">
    <div class="tag" v-if="index === 1 && showFlag">
      <svg-icon icon-class="gold" />
    </div>
    <div class="tag" v-else-if="index === 2 && showFlag">
      <svg-icon class="depart-icon" icon-class="silver" />
    </div>
    <div class="tag" v-else-if="index === 3 && showFlag">
      <svg-icon class="depart-icon" icon-class="copper" />
    </div>
    <div class="tag" v-else-if="showFlag">
      {{index}}
    </div>
    <div class="left">
      <img :src="data.cover" alt="">
      <div v-if="showFlag" class="gjzs-con">
        <div class="gjzs-number">
          <span>{{data.grade}}</span>
          <svg-icon v-if="data.gradeTarget === 1" icon-class="up" />
          <svg-icon v-else icon-class="down" />
        </div>
        <h4>共建指数</h4>
      </div>
    </div>
    <div class="right">
      <div class="tit">{{data.name}}</div>
      <div class="introduce">
        {{data.description}}
      </div>
      <div class="icon-text">
        <svg-icon class="depart-icon" icon-class="depart" />
        <span>{{data.jointTypeText}}</span>
      </div>
      <div class="icon-text">
        <i class="address"></i>
        <span>{{data.place}}</span>
      </div>
      <!-- <div class="icon-text">
        <i class="date"></i>
        <span>{{data.operationTime}}</span>
      </div> -->
      <!-- <div class="foot-btn">
        <div class="call btn">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/building/call.png" alt="">
          <span>联系他</span>
        </div>
        <div class="navigate btn">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/building/navigate.png" alt="">
          <span>到这去</span>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'buildingCard',
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/default.png',
          name: '至爱国际早教中心',
          introduce: '引进国外最新早教系统，为3-6岁孩子提供专业高效的早教体验，欢迎居民到来',
          address: '诸暨市万商路409号',
          date: '周一至周五 9:00-21:30',
          phone: '88390023',
        };
      }
    },
    showFlag: {
      type: Boolean,
      default() {
        return false;
      }
    },
    index: {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  mounted() {
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
  }
}
</script>
<style lang="scss" scoped>
.building-card {
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  background: #FFF;
  border: none;
  font: 500 0.9rem/1.4rem "microsoft yahei";
  display: flex;
  border-bottom: 0.5px solid rgba($color: #000000, $alpha: 0.1);
  position: relative;
  .tag {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
    color: #a2a2a2;
    >svg {
      width: 18px;
      height: 18px;
    }
  }
  .left {
    width: 110px;
    // height: 106px;
    margin-right: 10px;
    img {
      width: 100%;
    }
    .gjzs-con {
      text-align: center;
      .gjzs-number {
        color: #0a6fff;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        >svg {
          height: 14px;
          width: 14px;
        }
      }
      h3 {
        
      }
    }
  }
  .right {
    flex: 1;
    .tit {
      font-weight: 600;
      color: #333;
      font-size: 18px;
      margin-bottom: 0.3rem;
    }
    .introduce {
      color: #a2a2a2;
      font-size: 9px;
      // overflow : hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
    }
    .icon-text {
      font-size: 9px;
      display: flex;
      align-items: flex-start;
      .depart-icon {
        height: 12px;
        width: 12px;
        margin-top: 5px;
        margin-right: 5px;
      }
      .address {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/address1.png') no-repeat;
        background-size: 100% 100%;
        height: 12px;
        width: 12px;
        margin-right: 5px;
        margin-top: 5px;
        display: inline-block;
      }
      .date {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/date.png') no-repeat;
        background-size: 100% 100%;
        height: 12px;
        width: 12px;
        margin-right: 5px;
        margin-top: 5px;
        display: inline-block;
      }
      >span {
        flex: 1;
      }
    }
    .foot-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
        border-radius: 10px;
        color: #FFF;
        font-size: 8px;
        padding: 0 5px;
        margin-right: 9px;
        >img {
          width: 10px;
          height: 10px;
          margin-right: 2px;
        }
      }
      .call {
        background-color: #81cef2;
      }
      .navigate {
        background-color: #49d975;
      }
    }
    
  }
}
</style>