<template>
  <div class="add-family-container">
    <van-nav-bar
      title="新增家人"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="add-family-body-container">
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="name"
            name="name"
            label="姓名"
            placeholder="请填写"
            :rules="[{ required: true, message: '请填写姓名' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="age"
            name="age"
            label="年龄"
            type="digit"
            placeholder="请输入年龄"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="relation"
            name="relation"
            is-link
            readonly
            label="和您的关系"
            placeholder="请选择"
            @click="show = true"
            class="input-bar"
          />
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
            >
              提交
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-picker
        title="关系"
        show-toolbar
        :columns="options"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      show: false,
      age: '',
      name: '',
      relation: '',
      options: ['儿子', '女儿', '父亲', '母亲', '爷爷', '奶奶', '外公', '外婆']
    };
  },
  computed: {
    ...mapState({
      digitalMembers: state => state.digitalMember.digitalMembers,
    })
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onSubmit(values) {
      this.$store.commit('digitalMember/setDigitalMembers',[...this.digitalMembers,values]);
      this.$router.go(-1);
    },
    onConfirm(value) {
      this.relation = value;
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.add-family-container {
  height: 100vh;
  width: 100vw;
  
  .add-family-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .form-bar {
      .section {
        width: 100%;
        margin-top: 0.8rem;
        background: #ffffff;
        .title {
          margin: 0.5rem 0;
          font-size: 16px;
          color: #646566;
          letter-spacing: 0;
        }
      }
      .footer-button {
        position: fixed;
        bottom: 0.8rem;
        right: 0.8rem;
        left: 0.8rem;
      }
    }

  }
}
</style>
