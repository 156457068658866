<template>
  <div class="officer-card">
    <div class="left">
      <img :src="data.image" alt="" />
    </div>
    <div class="right">
      <div class="tit">
        {{ data.userName }}<i class="phone" @click="callPhone('')"></i>
      </div>
      <div class="icon-text">
        <i class="depart"></i>
        {{ data.buildStr }}
      </div>
      <div class="icon-text">
        <i class="info"></i>
        {{ data.content }}
      </div>
      <!-- <div class="foot-btn">
        <div class="call btn" @click="callPhone(data.phone)">
          <img
            src="http://dssq.ikeqiao.net/data/image/h5/images/building/call.png"
            alt=""
          />
          <span>联系我</span>
        </div>
      </div> -->
      <div v-if="data.hasParty === 1" class="title-con">
        <i class="title-icon"></i>中共党员
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "officerCard",
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {
          userName: "竹欣宇",
          buildStr: "蝶庄6幢楼道指导员",
          content: "从小事做起，从身边做起，从点滴做起，奉献社会，服务大家",
          title: "中共党员",
          image:
            "http://dssq.ikeqiao.net/data/image/h5/images/hong/zhuxinyu.jpeg",
        };
      },
    },
  },
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    callPhone(phoneNumber) {
      window.location.href = `tel://${phoneNumber}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.officer-card {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
  background: #fff;
  border: none;
  font: 500 0.9rem/1.4rem "microsoft yahei";
  display: flex;
  .left {
    width: 44px;
    //min-height: 145px;
    margin-right: 10px;
    margin-left: 10px;
    img {
      width: 100%;
      border-radius: 4px;
      height: 64px;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .tit {
      font-weight: 600;
      color: #333;
      font-size: 14px;
      //margin-bottom: 0.3rem;
      line-height: 24px;
      .phone {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("~assets/images/phone.png") no-repeat;
        background-size: 100% 100%;
        vertical-align: middle;
        margin-left: 6px;
      }
    }
    .icon-text {
      font-size: 13px;
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      color: #818181;
      .depart {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/depart.png")
          no-repeat;
        background-size: 100% 100%;
        height: 14px;
        width: 9px;
        margin-right: 7px;
        margin-top: 4px;
      }
      .info {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/info.png")
          no-repeat;
        background-size: 100% 100%;
        height: 13px;
        width: 12px;
        margin-right: 5px;
        margin-top: 5px;
      }
    }
    .foot-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .btn {
        border-radius: 10px;
        color: #fff;
        font-size: 8px;
        padding: 0 5px;
        margin-right: 9px;
        > img {
          width: 10px;
          height: 10px;
          margin-right: 2px;
        }
      }
      .call {
        background-color: #4379ed;
      }
      .navigate {
        background-color: #49d975;
      }
    }
    .title-con {
      position: absolute;
      right: 10px;
      top: 0;
      color: #ce1a21;
      font-size: 13px;
      display: flex;
      align-items: center;
      .title-icon {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/title.png")
          no-repeat;
        background-size: 100% 100%;
        height: 11px;
        width: 10px;
        margin-right: 2px;
      }
    }
  }
}
</style>
