<template>
  <div class="address-list-container">
    <van-nav-bar
      title="选择收货地址"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <van-address-list
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        @select="onSelect"
      />
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import { addressList } from 'assets/json/jfdh';
export default {
  data() {
    return {
      chosenAddressId: '',
      list: addressList,
      
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onAdd() {
      this.gotoLink('editAddress')
    },
    onEdit(item, index) {
      this.gotoLink(`editAddress?id=${item.id}`)
    },
    onSelect() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.address-list-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  background: #f6f7fa;
  
  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;

    overflow-y: auto;
    overflow-x: hidden;

    .van-button, .van-tag {
      background: #4379ed;
      border: 1px solid #4379ed;
    }
    ::v-deep .van-address-item .van-radio__icon--checked .van-icon {
      background-color: #4379ed;
      border-color: #4379ed;
    }
  }
}
</style>
