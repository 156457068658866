<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>积分兑换</h1>
      </div>
      
      
    </div>
    
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #FFFFFF;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  
}
</style>
