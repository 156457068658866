<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="reply-list"
  >
    <ul>
      <li v-for="(item, index) in dataList" :key="index">
        <div class="left">
          <img :src="item.cover" alt="">
        </div>
        <div class="right">
          <div class="tit">{{item.name}}</div>
          <div class="icon-text">
            <i class="depart"></i>
            <span>{{item.identity}}</span>
          </div>
          <div class="icon-text">
            <i class="info"></i>
            <span>{{item.description}}</span>
          </div>
          <div class="foot-btn">
            <div class="call btn" @click="makeCall(item.phone)">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/building/call.png" alt="">
              <span>联系我</span>
            </div>
          </div>
          <div v-if="item.title" class="title-con"><i class="title-icon"></i>{{item.title}}</div>
        </div>
      </li>
    </ul>
  </van-list>
</template>
<script>
import api from "api";
import { mapState } from 'vuex';
import data from 'assets/json/departList';
export default {
  name: 'departList',
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    }
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    this.getDataList();
  },
  props: {
    type: {
      type: String,
      default: function() {
        return '';
      }
    },
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    makeCall(number) {
      if(number) {
        window.location.href = `tel://${number}`;
      }
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList({ page = this.current, pageSize = this.pageSize } = {}) {
      this.loading = false;
      api
        .getCommunityList({
          page,
          pageSize,
          jointType: this.type,
          name: ''
        })
        .then((res) => {
          console.log('res', res);
          
          this.loading = false;
          const { records = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.dataList = records;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(records)))
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
      // this.dataList = data.filter(item => item.type === this.type) || [];
      // this.total = 4;
      // this.listFinished = true;
    },
  }
}
</script>
<style lang="scss" scoped>
.reply-list {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
  background: #FFF;
  li {
    padding: 0.8rem 0;
    // border-bottom: 1px solid #ddd;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    display: flex;
    margin-bottom: 10px;
    .left {
      width: 110px;
      min-height: 145px;
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 4px;
      img {
        width: 100%;
        // height: 100%;s
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      .tit {
        font-weight: 600;
        color: #333;
        font-size: 22px;
        margin-bottom: 0.3rem;
      }
      .icon-text {
        font-size: 13px;
        display: flex;
        align-items: flex-start;
        color: #818181;
        .depart {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/depart-icon.png') no-repeat;
          background-size: 100% 100%;
          height: 14px;
          width: 14px;
          margin-right: 5px;
          margin-top: 5px;
          display: inline-block;
        }
        .info {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/desc-icon.png') no-repeat;
          background-size: 100% 100%;
          height: 14px;
          width: 14px;
          margin-right: 5px;
          margin-top: 5px;
          display: inline-block;
        }
        >span {
          flex: 1;
        }
      }
      .foot-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .btn {
          border-radius: 10px;
          color: #FFF;
          font-size: 8px;
          padding: 0 5px;
          margin-right: 9px;
          >img {
            width: 10px;
            height: 10px;
            margin-right: 2px;
          }
        }
        .call {
          background-color: #4379ed;
          margin-top: 5px;
        }
        .navigate {
          background-color: #49d975;
        }
      }
      .title-con {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #ce1a21;
        font-size: 13px;
        display: flex;
        align-items: center;
        .title-icon {
          background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/title.png') no-repeat;
          background-size: 100% 100%;
          height: 11px;
          width: 10px;
          margin-right: 2px;
        }
      }
    }
    
  }
}
</style>