<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>车位预约</h1>
      </div>
      <div class="info-bar">
        <div class="card-con">
          <img
            src="http://dssq.ikeqiao.net/data/image/h5/images/car1.png"
            class="image"
          />
          <div>
            <div class="card-title">
              <span>车位号：{{ parkingInfo.parkingCode }}</span>
            </div>
            <div class="row">
              <div>时间：</div>
              <div>
                {{ parkingInfo.startTime }} - {{ parkingInfo.overTime }}<br />{{
                  parkingInfo.week
                }}
              </div>
            </div>
            <div class="row">
              <span>位置：{{ parkingInfo.location }}</span>
            </div>
            <div class="row">
              <span>小区：{{ parkingInfo.communityName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="search-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="startTime"
            name="startTime"
            is-link
            readonly
            label="开始时间"
            placeholder="请选择"
            @click="showStartTime = true"
            :rules="[{ required: true, message: '请选择开始时间' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="duration"
            name="duration"
            is-link
            readonly
            label="预约时长"
            placeholder="请选择"
            @click="show = true"
            :rules="[{ required: true, message: '请选择预约时长' }]"
            class="input-bar"
          />
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >立即预约</van-button
            >
          </div>
        </van-form>
      </div>
      <!-- <div class="list">
        <div class="list-title">预约列表</div>
        <div v-for="item in recordList"
             :key="item.id"
             class="card-con">
          <div>
            <div class="card-title">
              <span>车牌号：{{item.parkingCode || ''}}</span>
            </div>
            <div class="row">
              <div>预约时长：</div>
              <div>{{item.stayTime || 0}}60min</div>
            </div>
            <div class="row">
              <span>预约时间：{{`${item.orderDate} ${item.startTime}~${item.overTime}`}}</span>
            </div>
            <div class="image">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png" alt="">
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <van-popup v-model="showStartTime" position="bottom">
      <van-datetime-picker
        type="datetime"
        title="选择开始时间"
        :filter="filter"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onDateConfirm"
        @cancel="onDateCancel"
        :formatter="formatter"
      />
    </van-popup>
    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        type="time"
        title="选择时间"
        @cancel="onCancel"
        @confirm="onConfirm"
        :formatter="formatter"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data() {
    return {
      parkingInfo: {},
      selectItem: {},
      recordList: [],
      columns: [1, 2, 3, 4, 5, 6, 7, 8],
      show: false,
      showStartTime: false,
      duration: '',
      startTime: '',
      value: '',
      minDate: new Date(),
      maxDate: new Date(2025, 10, 1),
    }
  },
  components: {},
  mounted() {
    this.initInfo()
    this.getRecordList({
      page: 1,
      size: 10,
      shareParkingId:
        (this.$route.params.info && this.$route.params.info.parkingCode) || '',
    })
  },
  methods: {
    initInfo() {
      this.parkingInfo = this.$route.params.info // 车位信息
      this.selectItem = this.$route.params.item // 预约信息
      var dateString
      if (this.selectItem.date && this.selectItem.date != '') {
        var date = this.selectItem.date
        var year = date.getFullYear() //获取完整的年份(4位,1970-????)
        var month = date.getMonth() + 1 //获取当前月份(0-11,0代表1月)
        var day = date.getDate() //获取当前日(1-31)
        if (month < 10) {
          month = '0' + month
        }
        if (day < 10) {
          day = '0' + day
        }
        dateString = year + '-' + month + '-' + day
      }
      // console.log('data',dateString);
      // console.log('info',this.parkingInfo);
      // console.log('item',this.selectItem);
    },
    gotoLink(path) {
      this.$router.push(path)
    },
    goBack() {
      this.$router.go(-1)
    },
    showAlert() {
      this.$messagebox('提示', '系统正在建设中，尽情期待~')
    },
    getRecordList(params) {
      this.$store.dispatch('llcw/getRecords', { params, obj: this })
    },
    gotoDetail(info) {
      this.$router.push('gjxxq')
    },
    onConfirm(value, index) {
      this.duration = value
      this.show = false
    },
    onCancel() {
      this.show = false
    },
    onDateConfirm(value, index) {
      this.startTime = this.formatDateTime(value)
      this.showStartTime = false
    },
    onDateCancel() {
      this.showStartTime = false
    },
    onSubmit(values) {
      console.log('submit', values)
      this.$messagebox('提示', '预约成功')
      this.$router.go(-1);
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    formatDateTime(date) {
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute
    },
    filter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 30 === 0)
      }
      return options
    },
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .info-bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .card-con {
      display: flex;
      align-items: center;
      padding: 0.4rem 0.6rem;
      position: relative;
      .tag {
        position: absolute;
        top: 0.6rem;
        left: 0.6rem;
        padding: 0.2rem 0.8rem;
        border-radius: 4px;
      }
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 1.8rem;
        font-weight: 600;
        .tag {
          background: #d5f4e2;
          border-radius: 4px 2px 8px 2px;
          font-size: 12px;
          color: #37d477;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 0.2rem 0.8rem;
          height: 1.2rem;
        }
      }
      .image {
        height: 7rem;
        width: 7rem;
        border-radius: 2px;
        margin-right: 0.5rem;
      }
      .row {
        font-size: 13px;
        line-height: 1.4rem;
        display: flex;

        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        .label {
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 18px;
          margin-right: 0.6rem;
        }
      }
      .card-button {
        position: absolute;
        top: 1rem;
        right: 0.8rem;
        width: 5rem;
        height: 2rem;
        background: #4479ed;
        color: #ffffff;
        text-align: center;
        line-height: 2rem;
        border-radius: 4px;
      }
    }
  }
  .search-bar {
    border-top: 0.8rem solid #f6f7fa;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    .input-bar {
      font-size: 16px !important;
      color: rgba(0, 0, 0, 0.85);
      .van-cell__value {
        .van-field__body {
          .van-field__control {
            text-align: right !important;
          }
        }
      }
    }
  }
  .list {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    padding: 0.5rem 0.2rem;
    background: #ffffff;
    .list-title {
      border-left: 4px solid #4479ed;
      padding-left: 0.4rem;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0.4rem;
      font-weight: 600;
    }
    .card-con {
      display: flex;
      align-items: center;
      padding: 0.4rem 0.6rem;
      position: relative;
      .tag {
        position: absolute;
        top: 0.6rem;
        left: 0.6rem;
        padding: 0.2rem 0.8rem;
        border-radius: 4px;
      }
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 1.8rem;
        font-weight: 600;
        .tag {
          background: #d5f4e2;
          border-radius: 4px 2px 8px 2px;
          font-size: 12px;
          color: #37d477;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 0.2rem 0.8rem;
          height: 1.2rem;
        }
      }
      .image {
        position: absolute;
        right: 2rem;
        top: 1rem;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .row {
        font-size: 13px;
        line-height: 1.4rem;
        display: flex;

        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        .label {
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 18px;
          margin-right: 0.6rem;
        }
      }
      .card-button {
        position: absolute;
        top: 1rem;
        right: 0.8rem;
        width: 5rem;
        height: 2rem;
        background: #4479ed;
        color: #ffffff;
        text-align: center;
        line-height: 2rem;
        border-radius: 4px;
      }
    }
  }
}
</style>
