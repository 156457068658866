<template>
  <div class="study-source-container">
    <van-nav-bar
      title="学习资源"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="study-source-body-container">
      <!-- 之江汇教育广场 -->
      <div class="card-nav">
        <div class="card-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">之江汇教育广场</span>
          </div>
        </div>
        <div class="card-content">
          <div class="special-nav">
            <ul>
              <li v-for="(item, index) in iconList" :key="index" @click="gotoLink(item.path)" class="item">
                <h4>{{item.title}}</h4>
                <p>{{item.desc}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 学分银行 -->
      <div class="card-nav">
        <div class="card-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">学分银行</span>
          </div>
        </div>
        <div class="card-content">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      iconList: [
        {
          title: 'XXX教育系统资源一',
          path: 'studySourceStore?title=XXX教育系统资源一'
        },
        {
          title: 'XXX教育系统资源二',
          path: 'studySourceStore?title=XXX教育系统资源二'
        },
        {
          title: 'XXX教育系统资源三',
          path: 'studySourceStore?title=XXX教育系统资源三'
        },
        {
          title: 'XXX教育系统资源四',
          path: 'studySourceStore?title=XXX教育系统资源四'
        }
      ]
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.study-source-container {
  height: 100vh;
  width: 100vw;
  
  .study-source-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .card-nav {
      width: 100vw;
      background: #FFF;
      padding: 10px;
      margin-bottom: 10px;
      .card-title {
        width: 100%;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-bar {
            height: 18px;
            width: 4px;
            background: #4479ED;
            margin-right: 10px;
          }
          .title-text {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
          }
          .more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
          i {
            color: #4479ED;
          }
        }
      }
      .card-content {
        .special-nav {
          width: 100%;
          > ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .item {
              height: 5rem;
              width: calc(50% - 20px);
              margin: 10px;
              background: #EDF5FF;
              border-radius: 4px;
              text-align: center;
              padding-top: 1.2rem;
              > h4 {
                font-size: 14px;
                color: rgba(0,0,0,0.85);
              }
              > p {
                font-size: 10px;
                line-height: 20px;
                color: rgba(0,0,0,0.45);
              }
            }
          }
        }
      }
    }


  }
}
</style>
