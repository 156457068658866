<template>
  <div class="container" style="background: #fff; height: 100vh; padding-top: 2.7rem">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>我要咨询</h1>
    </div>
    <van-field
      v-model="message"
      autosize
      label="留言"
      type="textarea"
      maxlength="500"
      placeholder="请输入留言"
      show-word-limit
    />
    <van-button type="info" class="btn" @click="onSubmit()">提交</van-button>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  data() {
    return {
      message: '',
    };
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onSubmit() {
      Dialog.alert({
        message: '提交成功',
      }).then(() => {
        this.$router.go('-1');
      });
    },
  },
}
</script>

<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
      height: 100% !important;
  position: relative;
  .btn {
    margin-top: 10px;
  }
  }
</style>