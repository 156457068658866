<template>
  <div
    class="page-container"
    style="padding-top: 2.7rem !important;margin-top: 0 !important;"
  >
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>发布服务</h1>
    </div>
    <div class="form-bar">
      <van-form @submit="onSubmit">
        <van-field
          size="large"
          v-model="name"
          name="Name"
          label="姓名"
          placeholder="请输入"
          class="input-bar"
          :rules="[{ required: true, message: '姓名' }]"
        />
        <van-field
          size="large"
          v-model="phone"
          name="Phone"
          type="tel"
          label="手机号"
          placeholder="请输入"
          :rules="[{ pattern, message: '请输入正确的联系电话' }]"
          class="input-bar"
        />
        <van-field
          size="large"
          v-model="title"
          name="marry"
          label="婚姻状况"
          placeholder="请输入"
          class="input-bar"
          maxlength="20"
          :rules="[{ required: true, message: '请输入婚姻状况' }]"
        />
        <van-field
          size="large"
          v-model="phone"
          name="address"
          label="家庭地址"
          placeholder="请输入"
          :rules="[{ pattern, message: '请输入正确的家庭地址' }]"
          class="input-bar"
        />
        <div class="section" style="padding: 0.8rem">
          <div class="title">维权需求</div>
          <van-field
            size="large"
            v-model="description"
            name="content"
            rows="3"
            autosize
            label=""
            type="textarea"
            maxlength="200"
            placeholder="请输入需求内容"
            show-word-limit
            :rules="[{ required: true, message: '请输入具体内容' }]"
          />
        </div>
        <div class="footer-button">
          <van-button
            round
            block
            type="info"
            style="font-size: 16px"
            native-type="submit"
            >立即发布</van-button
          >
        </div>
      </van-form>
    </div>
    <van-popup v-model="showType" position="bottom">
      <van-picker
        title="问题类别"
        show-toolbar
        :columns="classNameList"
        @cancel="showType = false"
        @confirm="handlePick"
      />
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      address: "",
      name: "",
      marry: "",
      phone: "",
      showDate: false,
      description: "",
      fileList: [],
      pattern: /^[1]([3-9])[0-9]{9}$/,
    };
  },
  components: {},
  mounted() {},
  created() {
    this.getCategoryList();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handlePick(data) {
      this.className = data;
      this.showType = false;
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "~assets/scss/commit.scss";
.page-container {
  padding-top: 3.2rem;
  height: calc(100vh - var(--window-top));
  margin-top: calc(var(--window-top));
  // background: #f6f7fa;
  .form-bar {
    height: 100%;

    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
