<template>
  <div class="wjys-detail-container">
    <van-nav-bar
      title="会议纪要"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="wjys-detail-body-container">
      <van-cell-group>
        <van-cell title="议事标题" :value="data.title" />
        <van-cell title="应到/实到人数" :value="`${data.shouldArriveNum}/${data.factArriveNum}`" />
      </van-cell-group>
      <div class="item">
        <div class="text">
          <!-- <div class="title">{{data.title}}</div> -->
          <div class="time" style="color: rgba(0,0,0,0.45);">会议纪要</div>
          <div class="time" v-html="data.summary"></div>
          <div class="wjys-detail-card-row" style="display: flex;width: 100%;flex-wrap: wrap;">
            <van-image
              width="30%"
              height="28vw"
              fit="cover"
              position="center"
              v-for="image in (data.attachment ? data.attachment.split('#') : [])"
              :key="image"
              style="margin: 0 1.5% 10px"
              :src="image"
            />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="text">
          <!-- <div class="title">{{data.title}}</div> -->
          <div class="time">
            <span style="color: rgba(0,0,0,0.45);">会议附件</span>
            <!-- <span style="color: #3E71F9;float: right;">全部下载</span> -->
          </div>
          <div style="margin: 6px 0;" v-for="item in (data.summaryAttachment ? data.summaryAttachment.split('#') : [])" :key="item">
            <span style="word-break: break-all;">{{item ? item.split('/')[item.split('/').length - 1] : ''}}</span>
            <span @click="ImagePreview(0, item)" style="color: #3E71F9;float: right;">查看</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wjysApi from 'api/wjys';
import { mapMutations, mapState } from "vuex";
import { Toast, ImagePreview, Dialog } from 'vant';
export default {
  data() {
    return {
      id: '',
      meetType: '',
      colors: {
        '审核中': '#E5B20A',
        '已驳回': '#ce1a21',
        '已通过': '#4479ED',
      },
      data: {},
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { id = '', meetType } = this.$route.query;
    this.getData({ id, meetType });
    this.id = id;
    this.meetType = meetType;
  },
  watch: {
   
  },
  methods: {
    ImagePreview(index, image) {
      ImagePreview({
        images: [image],
        closeable: true,
        startPosition: index,
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getData({ id = this.id, meetType = this.meetType }) {
      wjysApi.getMeetList({
        meetId: id,
        page: 1,
        pageSize: 1,
        meetType
      }).then(res => {
        if(res.data) {
          const { records = [] } = res.data;
          this.data = records[0] || {};
        }
      })
    },
    handleSign(data) {
      this.$dialog({
        title: '提示',
        message: '确认到会议地点后签到',
        confirmButtonText: '立即签到'
      }).then(() => {
        wjysApi.meetSign({ meetId: this.id }).then(res => {
          if (res.data) {
            this.$toast('签到成功');
            this.getData({});
          }
        })

      })
    },
    handleCancel(data) {
      const type = data.tag === '未通过' ? '删除' : '撤回';
      this.$dialog({
        title: '提示',
        message: `是否${type}本次议事`,
        confirmButtonText: `立即${type}`
      }).then(() => {
        wjysApi.meetSign({ meetId: this.id }).then(res => {
          if (res.data) {
            this.$toast(`${type}成功`);
            this.$router.go(-1);
          }
        })

      })
    },
    download(data) {
      // 创建一个a标签；
      // const link = document.createElement('a');
      // // 编辑a标签的href属性；
      // link.href = data;
      // // 编辑a标签的download属性(下载文件时生成的默认文件名);
      // link.setAttribute('download', 'file');
      // // 将a标签添加到body中；
      // document.body.appendChild(link);
      // // 点击事件；
      // link.click();
      // // 移除a标签；
      // document.body.removeChild(link);
    }
  }
};
</script>

<style lang="scss">
.wjys-detail-container {
  height: 100vh;
  width: 100vw;
  
  .wjys-detail-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .item {
      padding: 10px 10px 0;
      background: #FFFFFF;
      position: relative;
      margin-top: 10px;
      .text{
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding: 10px 0;
        .title{
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          line-height: 30px;
          font-weight: 500;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .time{
          font-size: 14px;
          color: rgba(0,0,0,0.85);
          line-height: 20px;
          font-weight: 400;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          img{
            width: 100%;
          }
        }
        .tag{
          margin-left: 5px;
          padding: 3px 4px;
          background: rgba(62,113,249,0.15);
          border-radius: 2px;
          color: #3E71F9;
          font-size: 12px;
        }
        .status{
          position: absolute;
          right: 0px;
          top: 0px;
          padding: 3px 4px;
          background: rgba(62,113,249,0.15);
          border-bottom-left-radius: 4px;
          color: #3E71F9;
          font-size: 12px;
        }
      }
      .footer {
        width: 100%;
        display: flex;
        .btn{
          width: calc(50% - 0.5px);
          text-align: center;
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          color: #3E71F9;
          font-weight: 400;
        }
      }
      
    }
    .wjys-detail-footer {
      display: flex;
      padding: 10px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: 10px;
      justify-content: space-around;
      .refuse-btn, .agree-btn, .delete-btn {
        flex: 1;
        height: 40px;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #ce1a21;
        color: #ce1a21;
        background: #FFF;
      }
      .delete-btn {
        border: 1px solid rgba(0,0,0,0.45);
        color: rgba(0,0,0,0.45);
      }
      .agree-btn {
        border: 1px solid #4479ed;
        color: #4479ed;
      }
    }
  }
}
</style>
