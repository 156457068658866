<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="reply-list"
  >
    <ul>
      <li v-for="(item, index) in dataList" :key="index">
        <div class="left">
          <img :src="item.baseImage" alt="" />
        </div>
        <div class="right">
          <div class="tit">{{ item.baseName }}</div>
          <div class="icon-text">
            <!-- <i class="address"></i> -->
            {{ item.baseLocation }}
          </div>
          <div class="icon-text">
            <!-- <i class="date"></i> -->
            {{ `联系人：${item.baseContacts} ` }}
          </div>
          <div class="foot-btn">
            <div class="call btn" @click="callPhone(item.basePhone)">
              <img
                src="http://dssq.ikeqiao.net/data/image/h5/images/building/call.png"
                alt=""
              />
              <span>联系我</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </van-list>
</template>
<script>
import api from "api";
import { mapState } from "vuex";
export default {
  name: "departList",
  data() {
    return {
      current: 0,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    };
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === "我的发布" ? "yes" : "no";
    },
  },
  mounted() {
    // this.getDataList();
    this.current = 0;
  },
  props: {
    type: {
      type: String,
      default: function() {
        return "综合执法";
      },
    },
  },
  methods: {
    onLoad() {
      this.current += 1;
      this.getDataList();
    },
    gotoLink(path) {
      this.$router.push(path);
    },

    callPhone(phone) {
      window.location.href = `tel://${phone}`;
    },
    getDataList({
      page = this.current,
      size = this.pageSize,
      baseType = this.type,
    } = {}) {
      this.loading = false;
      api
        .getPartyBaseStation({
          page,
          size,
          object: {
            baseType,
          },
        })
        .then((res) => {
          const { list = [], total = 0 } = res.data || {};
          if (page === 1) {
            this.dataList = list;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(list)));
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.reply-list {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
  background: #fff;
  li {
    padding: 0.8rem 0 0.8rem 0.8rem;
    // border-bottom: 1px solid #ddd;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    display: flex;
    width: 100%;
    .left {
      width: 100px;
      height: 106px;
      margin-right: 10px;
      img {
        width: 100px;
        height: 100%;
      }
    }
    .right {
      flex: 1;

      .tit {
        font-weight: 600;
        color: #333;
        font-size: 15px;
        margin-bottom: 0.3rem;
      }
      .introduce {
        color: #a2a2a2;
        font-size: 14px;
      }
      .icon-text {
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        white-space: normal;
        word-break: break-all;
        align-items: center;
        .address {
          background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/address1.png")
            no-repeat;
          background-size: 100% 100%;
          height: 12px;
          width: 8px;
          margin-right: 5px;
        }
        .date {
          background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/date.png")
            no-repeat;
          background-size: 100% 100%;
          height: 10px;
          width: 10px;
          margin-right: 5px;
        }
      }
      .foot-btn {
        width: 100%;
        display: flex;
        align-items: center;
        //justify-content: flex-end;
        .btn {
          border-radius: 10px;
          color: #fff;
          font-size: 8px;
          padding: 0 5px;
          margin-right: 9px;
          > img {
            width: 10px;
            height: 10px;
            margin-right: 2px;
          }
        }
        .call {
          background-color: #81cef2;
        }
        .navigate {
          background-color: #49d975;
        }
      }
    }
  }
}
</style>
