<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>发起拼单</h1>
      </div>
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="type"
            name="type"
            is-link
            readonly
            label="拼单类型"
            placeholder="请选择"
            @click="showType = true"
            :rules="[{ required: true, message: '请选择拼单类型' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="title"
            name="title"
            label="活动标题"
            placeholder="请输入"
            class="input-bar"
            maxlength="20"
            :rules="[{ required: true, message: '请输入活动标题' }]"
          />
          <van-field
            size="large"
            v-model="name"
            name="name"
            label="发起人"
            placeholder="请输入"
            class="input-bar"
            :rules="[{ required: true, message: '请输入发起人' }]"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            type="tel"
            label="联系电话"
            placeholder="请输入"
            :rules="[{ pattern, message: '请输入正确的联系电话' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="deadline"
            name="startTime"
            is-link
            readonly
            label="活动时间"
            placeholder="请选择"
            @click="showDate = true"
            :rules="[{ required: true, message: '请选择活动时间' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="deadDate"
            name="deadline"
            is-link
            readonly
            label="截止日期"
            placeholder="请选择"
            @click="showDeadLine = true"
            :rules="[{ required: true, message: '请选择日期' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="address"
            name="address"
            label="活动地址"
            placeholder="请输入"
            :rules="[{ required: true, message: '请输入活动地址' }]"
            class="input-bar"
          />
          <div class="section" style="padding: 0.8rem">
            <div class="title">具体内容</div>
            <van-field
              size="large"
              v-model="description"
              name="content"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              placeholder="请输入具体内容"
              show-word-limit
              :rules="[{ required: true, message: '请输入具体内容' }]"
            />
          </div>
          <div class="section" style="padding: 0.8rem">
            <div class="title">活动照片</div>
            <van-uploader
              v-model="fileList"
              name="imageList"
              multiple
              :max-count="5"
              :rules="[{ required: true, message: '请选择活动照片' }]"
            />
          </div>
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              :disabled="btnVisible"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
      <van-calendar v-model="showDeadLine" @confirm="onDeadLineConfirm" />
      <van-calendar v-model="showDate" type="range" @confirm="onDateConfirm" />
      <van-popup v-model="showType" position="bottom">
        <van-picker
          title="拼单类型"
          show-toolbar
          :columns="typeList"
          @confirm="onTypeConfirm"
          @cancel="onCancel('showType')"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import yqpddApi from "api/yqpdd";
import { DatetimePicker } from "mint-ui";
import { Uploader, Toast } from "vant";
import { Field } from "mint-ui";
import { mapState } from 'vuex';
import context from '@/main';
export default {
  data() {
    return {
      btnVisible: false,
      type: "",
      showType: false,
      typeList: [],
      name: "",
      title: "",
      phone: "",
      deadline: "",
      showDate: false,
      deadDate: '',
      startTime: '',
      endTime: '',
      showDeadLine: false,
      description: "",
      address: '',
      fileList: [],
      pattern: /^[1]([3-9])[0-9]{9}$/,
      orderId: '',
    };
  },
  components: {},
  mounted() {
    this.getOrderType();
    this.name = this.userInfo.userName || this.userInfo.nickname || '';
    const { id } = this.$route.params;
    if(id) {
      yqpddApi.getOrderInfo({ orderId: id }).then(res => {
        if(res.flag) {
          const result = res.data;
          ['type', 'title', 'phone', 'deadline', 'address'].forEach(item => {
            this[item] = result[item];
          });
          this.orderId = result.orderId || '';
          this.startTime = result.startTime || '';
          this.endTime = result.endTime || '';
          this.deadline = result.endTime ? `${result.startTime}~${result.endTime}` : '';
          this.deadDate = result.deadline || '';
          this.name = result.own;
          this.fileList = result.image ? result.image.split('#').filter(v => v).map(v => ({ url: v })) : [];
          this.description = result.content;
          // this.fileList = result.image.split('#') || [];
        }
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.login.userInfo || (context.getSessionData('userInfo')),
    }),
  },
  methods: {
    uploadImg() {
      this.pickerVisible = true;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    onCancel(type) {
      this[type] = false;
    },
    onDateConfirm(date) {
      const [start, end] = date;
      this.startTime = this.formatDateTime(start);
      this.endTime = this.formatDateTime(end);
      this.deadline = `${this.formatDateTime(start)}~${this.formatDateTime(end)}`;
      this.showDate = false;
    },
    onDeadLineConfirm(date) {
      this.deadDate = this.formatDateTime(date);
      this.showDeadLine = false;
    },
    onTypeConfirm(value, index) {
      this.type = value;
      this.showType = false;
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d;
    },
    onSubmit(values) {
      const { id } = this.$route.params;
      if(this.fileList && this.fileList.length < 1) {
        Toast('请选择活动图片后提交');
        return ;
      }
      const formData = new FormData();
      const arr = [];
      this.fileList.forEach(element => {
        console.log('element', element);
        if (element.file) {
          formData.append("files", element.file)
        } else {
          arr.push(element.url);
        }
      });
      // this.fileList.map((item) => formData.append("files", item.file));
      yqpddApi.uploadMultiFile(formData).then((res) => {
        const params = {
          ...values,
          startTime: this.startTime,
          endTime: this.endTime,
          image: `${arr.join('#')}${arr && arr.length ? '#' : ''}${res.data || ''}`,
          orderId: this.orderId,
        };
        if (this.orderId) {
          yqpddApi.updateOrderManage({
            ...params,
            access: '审核中',
            status: -1,
          }).then((res) => {
            const { message, flag } = res;
            if(flag) {
              this.btnVisible = true;
              Toast('修改拼单成功！请耐心等待审核');
              setTimeout(() => {
                this.$router.go(-1);
              }, 2000);
            } else {
              Toast(message);
            }
            
          });
        } else {
          yqpddApi.addOrder(params).then((res) => {
            const { message, flag } = res;
            if(flag) {
              this.btnVisible = true;
              Toast('发起拼单成功！请耐心等待审核');
              setTimeout(() => {
                this.$router.go(-1);
              }, 2000);
            } else {
              Toast(message);
            }
            
          });
        }
      });
    },
    getOrderType(){
      yqpddApi.getOrderType().then(res=>{
        const {data}=res;
        const list = data.map(item=>{return item.type})
        this.typeList=list;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  padding: 0.6rem 0;
  height: calc(100vh - 6.7rem);
  width: 100vw;
  margin-top: 2.7rem;
  overflow-y: auto;
  overflow-x: hidden;
  .form-bar {
    height: 100%;
    
    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
