<template>
  <div class="homeEdit-index">
    <div class="page-container" style="padding-top: 0px; margin-top: 0px !important; height: 100vh !important">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>详情</h1>
      </div>
      <div class="zntDbDetail">
        <div class="zntItem">
          <div class="zntItemTitle">{{showData.invitationTitle}}</div>
          <div class="zntItemName">
            <img :src="showData.avatarUrl" alt="">
            <span>{{showData.nickName}}</span>
            <!-- <span class="tags" v-for='v in (showData.manage ? showData.manage.split(",").filter(v1 => v1) : [])' :key="v">{{v}}</span> -->
          </div>
          <div class="zntItemTime">
            <div>{{showData.issueTime}}</div>
            <div class="edit">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/fwl.png" alt="">
              <div style="margin-right: 10px; margin-left: 5px">{{showData.visitsUp}}</div>
            </div>
          </div>
          <!-- <div class="zntItemImage">
            <img v-for='v in (showData.invitationImage ? showData.invitationImage.split(",").filter(v1 => v1) : [])' :key="v" :src="v" alt="">
          </div> -->
          <div class="zntItemDesc">
            <div v-html="showData.invitationContent"></div>
          </div>
        </div>
        <div class="plContent">
          <div class="title">
            <div />
            <div>
              全部评论（
              <span style="color: #26a2ff;">{{plList.length || 0}}</span>
              ）
            </div>
          </div>
          <div class="plList">
            <div class="item" v-for="(item,i) in (plList || [])" :key='i'>
              <div class="itemName">
                <div class="image">
                  <img :src="item.image" alt="">
                </div>
                <div class="text">
                  <div style="color: rgba(0,0,0,0.85)">
                    {{item.userName}}
                    <!-- <span class="tags" v-for='v in (item.manage ? item.manage.split(",").filter(v1 => v1) : [])' :key="v">{{v}}</span> -->
                  </div>
                  <div>{{item.createTime}}</div>
                </div>
              </div>
              <div class="itemDesc">
                {{item.commentContent}}
              </div>
              <!-- <div class="dzIcon">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/dz.png" alt="">
                <div style="margin-left: 10px">{{item.thumbsUp}}</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <footer class="zntDbFooter">
        <van-field
          style="height: 1.8rem; width: 80%;padding: 0px 16px;"
          v-model="inputItem"
          placeholder="我要评论"
          shape="round"
        />
        <div class="fs" @click="addComment">发送</div>
        <!-- <img @click="addComment" src="http://dssq.ikeqiao.net/data/image/h5/images/znt/send.png" alt="">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/speak.png" alt=""> -->
      </footer>
    </div>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      showData: {},
      plList: [],
      page: 1,
      size: 20,
      total: 0,
      finished: false,
      dataList: [],
      inputItem: '',
      zntDblist: [1,2,3,4],
    };
  },
  components: {},
  mounted(opt) {
    const { key } = this.$route.query;

    this.getDtail({ invitationId: key });
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack(){
      this.$router.go(-1);
    },
    addComment() {
      if (!this.inputItem) {
        Toast('请输入评论内容！');
        return;
      }
      llyjrApi
        .addComment({ invitationId: this.showData.invitationId, commentContent: this.inputItem })
        .then(res => {
          const result = res.data || 0;
          if (result) {
            Toast('评论成功！');
            this.getPLList({ invitationId: this.showData.invitationId });
            this.inputItem = "";
          }
        });
    },
    // addThumbsUp(item) {
    //   if (type !== '已点赞') {
    //     llyjrApi
    //       .addThumbsUp({ commentId: item.commentId })
    //       .then(res => {
    //         const { flag } = res || {};
    //         if (flag) {
    //           Toast('点赞成功！');
    //           this.getPLList({ invitationId: this.showData.invitationId });
    //         }
    //       });
    //   } else {
    //     llyjrApi
    //       .cancelThumbsUp({ commentId: item.commentId })
    //       .then(res => {
    //         const { flag } = res || {};
    //         if (flag) {
    //           Toast('取消成功！');
    //           this.getPLList({ invitationId: this.showData.invitationId });
    //         }
    //       });
    //   }
    // },
    getDtail(obj) {
      llyjrApi
        .getDetail(obj)
        .then(res => {
          this.showData = res.data || {};
          this.getPLList({ invitationId: this.showData.invitationId });
        });
    },
    getPLList({ page = this.page, size = this.size, ...obj } = {}) {
      llyjrApi
        .getCommentPage({
          page,
          size,
          object: obj,
        })
        .then(res => {
          const { result = [], total = 0 } = res.data || {};
          console.log(result);
          this.plList = page === 1 ? result : this.plList.concat(result);
          this.total = total;
          if (this.plList.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    refreshParkingData(){

    },
  },
};
</script>

<style lang="scss">
  .zntDbDetail{
    width:  100%;
    height: 100vh;
    padding-top: 2.7rem;
    padding-bottom: 4rem;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #f7f7f7;
    .zntItem{
      padding: 10px;
      margin-bottom: 10px;
      background: #fff;
      position: relative;
      .zntItemTitle{
        line-height: 30px;
        color: #000;
        font-size: 17px;
        font-weight: bold;
      }
      .zntItemName{
        line-height: 24px;
        color: #000;
        font-size: 12px;
        display: flex;
        img{
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
      .zntItemDesc{
        line-height: 24px;
        font-size: 14px;
        color: rgba(0,0,0,0.85);
        img{
          width: 100% !important;
        }
      }
      .zntItemImage{
        display: flex;
        img{
          width: 60px;
          height: 60px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
      .zntItemTime{
        line-height: 24px;
        font-size: 12px;
        color: #707070;
        display: flex;
        .edit{
          margin-left: auto;
          margin-right: 10px;
          display: flex;
          >div{
            line-height: 20px;
          }
          img{
            width: 12px;
            height: 12px;
            margin-top: 3.5px;
          }
        }
      }
      .tags{
        margin-left: 5%;
        background: rgba(0,0,0,0.15);
        border-radius: 5px;
        color: #000;
        padding: 0px 5px;
      }
    }
    .plContent{
      padding: 10px;
      background: #fff;
      .title{
        display: flex;
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
        >div{
          &:first-child{
            height: 16px;
            width: 3px;
            margin-right: 5px;
            margin-top: 3px;
            background: #26a2ff;
          }
        }
      }
      .item{
        position: relative;
        padding: 10px;
        border-bottom: 1px solid rgba(0,0,0,0.05);
        .itemName{
          display: flex;
          height: 50px;
          padding: 5px;
          .image{
            width: 40px;
            height: 40px;
            margin-right: 10px;
            >img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .text{
            width: calc(100% - 50px);
            height: 100%;
            line-height: 20px;
            color: #707070;
          }
        }
        .itemDesc{
          color: rgba(0,0,0,0.85);
          line-height: 24px;
          font-size: 14px;
        }
        .dzIcon{
          position: absolute;
          right: 10px;
          top: 15px;
          height: 24px;
          line-height: 24px;
          text-align: left;
          display: flex;
          >img{
            height: 20px;
            width: 20px;
          }
          color: rgba(0,0,0,0.65);
        }
        .tags{
          margin-left: 5%;
          background: rgba(0,0,0,0.15);
          border-radius: 5px;
          color: #000;
          padding: 0px 5px;
        }
      }
    }
  }

  .zntDbFooter {
    height: 3.2rem;
    border-top: 2px solid #f1f2f5;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0.6rem;
    display: flex;
    >img{
      margin-top: .2rem;
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
    .fs{
      text-align: center;
      line-height: 18px;
      height: 24px;
      font-size: 14px;
      background: #26a2ff;
      padding: 3px 10px;
      color: #fff;
      border-radius: 4px;
    }
  }
</style>
