<template>
  <div class="wise-man-container">
    <van-nav-bar
      title="社区小匠人"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="wise-man-body-container">
      <!-- 日期选择 -->
      <div class="date-tab">
        <div
          :class="['date-item', {'select': selectedWeekDay === item.week}, { 'disabled': item.flag }]"
          v-for="(item, index) in dateList"
          :key="`${item.day}_${index}`"
          @click="handleTab(item)"
        >
          <p class="date">{{item.day}}</p>
          <p class="week">{{item.week}}</p>
        </div>
      </div>
      <!-- 今日维修 -->
      <!-- <div class="today-service">
        <span>{{data && data.service}}</span>
      </div> -->
      <!-- 卡片区 -->
      <serviceCard v-for="(item, index) in dataList" :data="item" :key="`${item.content}_${index}`"></serviceCard>
      <div class="tab-flex-bottom" @click="gotoLink('publishWiseManNeed')">发布需求</div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
import moment from 'moment';
import 'moment/locale/zh-cn';
import ServiceCard from './serviceCard';
import data from 'assets/json/wiseMan';
export default {
  data() {
    return {
      selectedDate: moment().format('MM.DD'), // 所选日期
      selectedWeekDay: moment().format('dddd').replace('星期','周'), // 所选星期
      dataList: [],
    };
  },
  computed: {
    dateList() {
      const result = [];
      const today = moment();
      for(let i = 0;i < 7; i += 1) {
        const startDay = moment().week(moment().week()).startOf('week');
        const current = startDay.add(i, 'days');
        const day = current.format('MM.DD');
        const week = current.format('dddd').replace('星期','周');
        const flag = current.isBefore(today) && !current.isSame(today, 'day');
        result.push({ day, week, flag });
      }
      return result;
    },
    data() {
      return data.find(item => item.weekday === this.selectedWeekDay);
    },
  },
  components: { ServiceCard },
  mounted() {
    this.getDataList({});
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab(item) {
      if (!item.flag) {
        this.selectedDate = item.day;
        this.selectedWeekDay = item.week;
        this.getDataList();
      }
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getDataList() {
      api.getWiseManList({ date: this.selectedDate, serviceTypes: '' }).then(res => {
        this.dataList = res.data.serviceList || [];
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.wise-man-container {
  height: 100vh;
  width: 100vw;
  
  .wise-man-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .date-tab {
      width: 100%;
      display: flex;
      .date-item {
        flex: 1;
        padding: 8px 0;
        .date {
          font-size: 12px;
          color: rgba(0,0,0,0.85);
          letter-spacing: 0;
          text-align: center;
          line-height: 16px;
          font-weight: 400;
        }
        .week {
          font-size: 15px;
          color: rgba(0,0,0,0.85);
          letter-spacing: 0;
          text-align: center;
          line-height: 16px;
          font-weight: 400;
        }
      }
      .select {
        background-color:#4479ED;
        .week {
          color: #FFF;
        }
        .date {
          color: #FFF;
        }
      }
      .disabled {
        .week {
          color: rgba(0,0,0,0.45);
        }
        .date {
          color: rgba(0,0,0,0.45);
        }
      }
    }

    .today-service {
      width: calc(100% - 20px);
      height: 73px;
      margin: 10px;
      border-radius: 4px;
      background: url(http://dssq.ikeqiao.net/data/image/h5/images/building/today-service.png) no-repeat;
      background-size: 100% 100%;
      font-size: 24px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      text-align: center;
      line-height: 73px;
    }

    .tab-flex-bottom {
      position: fixed;
      bottom: 3rem;
      right: 10px;
      background: #4479ed;
      box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      color: #fff;
      padding: 0.8rem;
      font-size: 0.85rem;
      letter-spacing: 0;
      text-align: center;
      line-height: 1.2rem;
    }
  }
}
</style>
