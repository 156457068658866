<template>
  <div id="index">
    <div class="jfmx-page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>积分明细</h1>
      </div>
      <div class="tabs-header"> 
          <div :class="['tabs-item',{active: selectTab === ''}]" @click="onClick('')">全部</div>
          <div :class="['tabs-item',{active: selectTab === '0'}]" @click="onClick('0')">获取</div>
          <div :class="['tabs-item',{active: selectTab === '1'}]" @click="onClick('1')">支出</div>
          <div class="icon" @click="showDate = true">
            <span>{{date}}</span>
            <img class="image" src="http://dssq.ikeqiao.net/data/image/h5/images/arrow_down.png" alt="">
          </div>
      </div>
      <van-list
        v-if="scoreDetails && scoreDetails.length > 0"
        :loading="loading"
        :finished="listFinished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <div class="jdmx-card"
              v-for="(item, index) in scoreDetails"
              v-bind:key="index">
          <div class="left-info">
            <h3>{{item.integralTypeName}}</h3>
            <div>{{item.createTime}}</div>
          </div>
          <div class="score-nav" :style="{color: item.runningType === 0 ? '#E5B20A' : 'rgba(0,0,0,0.45)'}">
            <h1>{{item.runningType === 0 ? '+' : '-'}}</h1>
            <span>{{item.integralTypeOnceNum || 0}}</span>
            <span>积分</span>
          </div>
        </div>
      </van-list>
      <div class="no-data" v-else>
        <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/无数据.png" alt="">
        <p>暂无数据</p>
      </div>
    </div>
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        type="year-month"
        title="选择日期"
        @cancel="handleCancle"
        @confirm="onDateConfirm"
        :formatter="formatter"
        :min-date="minDate"
        :max-date="maxDate"
        v-model="currentDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import jfdhApi from 'api/ddya/jfdh';
import { jfmx } from 'assets/json/jfdh';
export default {
  data () {
    return {
      scoreDetails: [],
      current: 1,
      pageSize: 10,
      loading: false,
      listFinished: false,
      selectTab: '',
      date: '选择日期',
      showDate: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
    };
  },
  components: {},
  mounted () {
    //查询积分明细
    this.getData({});
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },
    showAlert () {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    onClick(title) {
      this.selectTab = title;
      this.current = 1;
      this.getData();
    },
    onLoad() {
      this.current = this.current + 1;
      this.getData({ page: this.current });
    },
    onDateConfirm(value, index) {
      this.date = this.formatDateTime(value);
      this.showDate = false;
      this.current = 1;
      this.getData({
        startTime: this.formatDateTime(value),
      })
    },
    handleCancle() {
      this.showDate = false;
      this.date = '选择日期';
      this.getData()
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m;
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    getData({ page = this.current, pageSize = this.pageSize, runningType = this.selectTab, startTime = this.date === '选择日期' ? '' : this.date } = {}) {
      jfdhApi.getIntegralDetail({
        page,
        pageSize,
        runningType,
        startTime
      }).then(res => {
        this.loading = false;
        const { records = [], total = 0 } = res.data || {};
        if(page === 1) {
          this.scoreDetails = records;
        } else {
          this.scoreDetails = this.scoreDetails.concat(records)
        }
        this.total = total;
        console.log(this.scoreDetails, total, this.scoreDetails.length >= total);
        
        if (this.scoreDetails.length >= total) {
          // 数据全部加载完成
          this.listFinished = true;
        }
      })
      // this.scoreDetails = params.flowType ? jfmx.filter(item => item.flowType === params.flowType) : jfmx;
    },
  },
};
</script>

<style lang="scss" scoped>
.jfmx-page-container {
  background: #f5f5f5;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  overflow-x: hidden;
  font-size: 1.07rem;
  color: rgba(0,0,0,0.85);
  .tabs-header {
    display: flex;
    width: 100%;
    height: 3rem;
    align-items: center;
    justify-content: space-around;
    background: #FFFFFF;
    .tabs-item, .icon {
      min-width: 4rem;;
      text-align: center;
      line-height: 3rem;
      font-size: 16px;
    }
    .active {
      border-bottom: 2px solid #4479ED;
    }
    .icon{
      display: flex;
      align-items: center;
      .image {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
        
  }
  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    >img {
      height: 120px;
      width: 120px;
    }
  }
  .jdmx-card {
    background: #FFFFFF;
    margin: 1rem;
    padding: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-info {
      font-size: 12px;
      color: rgba(0,0,0,0.45);
      text-align: left;
      line-height: 24px;
      h3 {
        font-size: 1.07rem;
        color: rgba(0,0,0,0.85);
      }
    }
    .score-nav {
      font-size: 1.2rem;
      font-weight: 500;
      span:nth-child(3) {
        font-size: 0.9rem;
      }
      >h1 {
        display: inline-block;
        margin-right: 6px;
        font-size: 1.2rem;
      }
    }
  }
}
</style>