<template>
  <div class="notice-list-container">
    <van-nav-bar
      title="党建通知"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="notice-list-body-container">
      <van-list
        v-model="loading"
        :finished="listFinished"
        finished-text="没有更多了"
        @load="onLoad"
        class="notice-list"
      >
        <ul>
          <li
            v-for="(item, index) in dataList"
            :key="index"
          >
            <p>{{item.publishTime}}</p>
            <div class="card-con">
              <div class="card-title-con">
                <h1>{{item.title}}</h1>
                <!-- <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt=""> -->
              </div>
              <div class="card-content-con" v-html="item.content">
                <!-- {{item.content}} -->
              </div>
            </div>
          </li>
        </ul>
      </van-list>
    </div>
  </div>
</template>

<script>
import api from "api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      current: 0,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onLoad() {
      this.current += 1;
      this.getDataList();
    },
    getDataList({ page = this.current, size = this.pageSize } = {}) {
      const { status = "", category = "" } = this;
      this.loading = false;
      api
        .getHongArticleList({
          page,
          pageSize: size,
          type: 2,
        })
        .then((res) => {
          this.loading = false;
          const { records = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.dataList = records;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(records)))
          }
          this.total = total;
          // console.log('num', this.dataList.length >= this.total, this.total, this.dataList)
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
    },
  }
};
</script>

<style lang="scss">
.card-content-con {
  word-break: break-all;
}
</style>
<style lang="scss" scoped>
.notice-list-container {
  height: 100vh;
  width: 100vw;
  
  .notice-list-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .notice-list {
      width: 100%;
      margin: 0 auto;
      padding: 0.6rem 0;
      border: none;
      li {
        >p {
          color: rgba(0,0,0,0.45);
          text-align: center;
        }
        .card-con {
          width: calc(100% - 20px);
          margin: 10px;
          background-color: #FFF;
          border-radius: 4px;
          padding: 10px;
          .card-title-con {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            >h1 {
              font-size: 14px;
              font-weight: 500;
            }
            >img {
              width: 14px;
              height: 14px;
            }
          }
          .card-content-con {
            padding: 10px 0;
            font-size: 13px;
            color: rgba(0,0,0,0.65);
            line-height: 18px;
            overflow-y: hidden;
          }
        }
      }
    }

  }
}
</style>
