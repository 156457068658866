<template>
  <div id="index">
    <div
      class="page-container"
      style="margin-top: 0px !important;height: auto !important;"
    >
      <div class="topBanner">
        <div class="title">
          <img src="http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/point.svg" />
          <span>东盛社区</span>
        </div>
      </div>
      <div class="contentList">
        <div class="vedio" style="background: #FFF;position: relative;">
          <video
            v-if="showVideo"
            src="http://dssq.ikeqiao.net/data/image/20211223/3ae7a1634d44427b87d2b0efaa41458b.mp4"
            controls
            width="100%;"
            autoplay
          >
            您的浏览器不支持 video 标签。
            </video>
            <!-- <van-swipe :autoplay="3000" class="banner-nav">
              <van-swipe-item
                v-for="(item, index) in bannerList"
                :key="index"
                class="banner1"
              >
                <img v-lazy="item" style="width: 100%; height: 100%" />
              </van-swipe-item>
            </van-swipe> -->
            <img v-if="!showVideo" src="http://dssq.ikeqiao.net/data/image/dongsheng/video_bg.png" style="width: 100%; height: 100%" />
            <img v-if="!showVideo" src="http://dssq.ikeqiao.net/data/image/dongsheng/video_play.svg" @click="showVideo=true" style="position:absolute;left: calc(50% - 24px);top:calc(50% - 24px); width: 48px;height: 48px;" />
        </div>
        <div class="nav">
          <div class="nav-list">
            <div class="item" @click="gotoLink(item.path)" v-for="(item, index) in iconList" :key="index">
              <div class="image">
                <img :src="item.icon" />
              </div>
              <div class="text">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="jttContent" @click="router('24')">
          <div class="image"><img src="http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/jtt.png" /></div>
          <div class="text">
            <div v-for="(item, index) in newsList.filter((v, i) => i < 2)" :key="index">
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="cardContent">
          <div class="item" :style="`background-image: url(http://dssq.ikeqiao.net/data/image/dongsheng/zsb.png)`">
            <wx-open-launch-weapp
              id="launch-btn1-main"
              username="gh_f220c14e6658"
            >
              <script type="text/wxtag-template">
                <div style="width:100%;font-size:14px;font-weight: 500;color:rgba(255,255,255,0.65);line-height:20px;" class="title">诸事帮</div>
                <div style="width:100%;font-size:12px;color:rgba(255,255,255,0.65);line-height:20px;" class="desc">数字家庭生活服务</div>
                <div style="width:100%;line-height:20px;display: flex">
                  <div style="font-size:12px;color:rgba(255,255,255,0.65);display: inline-block;">寻求服务</div>
                  <img style="height:14px;width:14px;margin:3px;" src="http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/arrow.svg" />
                </div>
              </script>
            </wx-open-launch-weapp>
          </div>
          <div class="item" :style="`background-image: url(http://dssq.ikeqiao.net/data/image/dongsheng/zhbd.png)`">
            <wx-open-launch-weapp
              id="launch-btn2-main"
              username="gh_043383071eca"
            >
              <script type="text/wxtag-template">
                <div style="width:100%;font-size:14px;font-weight: 500;color:rgba(255,255,255,0.65);line-height:20px;" class="title">智慧步道</div>
                <div style="width:100%;font-size:12px;color:rgba(255,255,255,0.65);line-height:20px;" class="desc">智能锻炼健康你我</div>
                <div style="width:100%;line-height:20px;display: flex;">
                  <div style="font-size:12px;color:rgba(255,255,255,0.65);display: inline-block;">快速参与</div>
                  <img style="height:14px;width:14px;margin:3px;" src="http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/arrow.svg" />
                </div>
              </script>
            </wx-open-launch-weapp>
          </div>
        </div>
        <div class="comment-nav">
          <div class="comment-nav-title">
            <div class="line"></div>
            <div>社区邻里活动</div>
            <div  @click="gotoLink('hdzx')">
              <span class="more">更多</span>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt="">
            </div>
          </div>
          <div class="comment-nav-scroll" v-if="commentList.length > 0">
            <div  @click="onQuestionClick(item)" v-for="(item, i) in (commentList || []).filter(v => v.activityType !== '假日课堂')" class="comment-nav-item" :key="i">
              <div class="image"><img :src="item.bannerImg" alt=""></div>
              <div class="title">{{item.title}}</div>
              <div class="count">已报名<span style="color: #3E71F9;margin-left: 5px;">{{item.number}}</span>人</div>
            </div>
          </div>
          <div class="no-data" v-else>
            <div class="image">
              <img src="http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/noData.png" alt="">
            </div>
            <div class="text">暂无数据</div>
          </div>
        </div>
        <div class="appear-nav">
          <van-tabs color="#5278e5" @click="tabClick">
            <van-tab
              v-for="(item, i) in selectList"
              :title="item.dictLabel"
              :name="item.dictValue"
              :key="i"
            >
            </van-tab>
          </van-tabs>
          <div class="appear-nav-scroll" v-if="newsDataList.length > 0">
            <div v-for="(item, i) in newsDataList" class="appear-nav-item" :key="i" @click="showDetailPage(item)">
                <div class="text">
                  <div class="title van-ellipsis">{{item.title}}</div>
                  <div class="desc">{{item.publishTime}}</div>
                </div>
                <div class="image"><img :src="item.cover" alt=""></div>
            </div>
          </div>
          <div class="no-data" v-else>
            <div class="image">
              <img src="http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/noData.png" alt="">
            </div>
            <div class="text">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-index">
      <div class="copyright-con">
        <b>本服务由浣东街道东盛社区提供</b>
        <!-- <p>技术支持：中广（绍兴诸暨）有限公司</p> -->
      </div>
      <footer>
        <ul>
          <li class="catalog">
            <a @click="gotoLink('catalog')"
              ><i></i>
              <p>场景</p>
            </a>
          </li>
          <li class="home selected">
            <a href=""
              ><i></i>
              <p>长乐东盛</p>
            </a>
          </li>
          <li class="mine">
            <a @click="showPage()"
              ><i></i>
              <p>我的</p>
            </a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
  <!-- <div v-else class="errPage">
    微信登录失败，请退出重试
  </div> -->
</template>

<script>
import { Toast } from 'vant'
import { mapActions, mapState, mapMutations } from 'vuex'
import { openUrl, getQueryString } from '@/utils/index'
import yqpddApi from 'api/yqpdd'
import luckyApi from 'api/lucky'
import llcwApi from 'api/llcw/llcw'
import context from '../main';
import homeApi from "api/home";
import wyslApi from "api/llyjr/wysl";
import moment from 'moment';
import axios from 'axios'
import wx from 'weixin-js-sdk'
import { init } from 'utils/common'
export default {
  data() {
    return {
      showVideo: false,
      city: '绍兴',
      weatherList: [],
      showAllDj: false,
      carCountList: {},
      activeNewsItem: '1',
      selectList: [],
      iconList: [
        {
          name: '时间银行',
          icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/sqdr.svg',
          path: 'volunteerism',
        },
        {
          name: '社区学堂',
          icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/gxkt.svg',
          path: 'sdbxt',
        },
        {
          name: '爱心食堂',
          icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/axst.svg',
          path: 'axst',
        },
        // {
        //   name: '何姐来帮忙',
        //   icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/hjlbn.svg',
        //   path: 'hjlbn',
        // },
        {
          name: '东盛党建',
          icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/dsdj.svg',
          path: '/newPartyBuilding',
        },
        {
          name: '积分商城',
          icon: 'http://dssq.ikeqiao.net/data/image/moudle/lljf.png',
          path: 'jfdh',
        },
        {
          name: '事件上报',
          icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/sjsb.svg',
          path: 'eventAdd',
        },
        {
          name: '城乡联动',
          icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/cxld.svg',
          path: 'urbanRuralLinkage',
        },
        // {
        //   name: '物业报修',
        //   icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/bsbx.svg',
        //   path: 'repair',
        // },
        // {
        //   name: '邻里公约',
        //   icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/llgy.png',
        //   path: 'communityConvention',
        // },
        {
          name: '全部应用',
          icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/gd.png',
          path: '/catalog',
        },
      ],
      newsList: [
        {
          name: '我市组织收看全省数字化改革推进会',
        },
        {
          name: '市人民法院：推进“一件事”改革 90%执行事项线上办理',
        },
      ],
      bannerList: [
        'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/banner.png',
        'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/banner1.png',
      ],
      cardList: [
        {
          name: '邻里积分',
          desc: '赢得社区积分',
          background: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/lljf.png',
          path: 'jfdh',
        },
        {
          name: '东盛地图',
          desc: '查看地图点位信息',
          background: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/dsdt.png',
          path: 'zjy',
        },
      ],
      commentList: [],
      pageDataList: [],
      newsDataList: [],
    }
  },
  beforeCreate() {
    // 解决微信浏览器缓存机制
    // const timeStamp = new Date();
    // window.location.href = window.location.href + '?timeStamp=' + timeStamp.getTime();
  },
  mounted() {
    // console.log('mounted1111111');
    const { pageName, pageId, activeKey } = this.$route.query
    // console.log('pageName, pageId', pageName, pageId, window.location.href)
    if (pageName && pageId) {
      this.$router.push(`/${pageName}?key=${pageId}&isToMain=true&activeKey=${activeKey}`)
    }
    // this.serchWeather();
    // this.login();
    const code = getQueryString('code')
    const token = this.getSessionData('token')

    if (code && !token) {
      // 登录接口拿到token
      this.getUserInfo(code)
      console.log('index')
    }
    if (this.getSessionData('moveDown')) {
      const height = this.getSessionData('moveDown')
      // console.log('height', height);
      document.documentElement.scrollTop = height
      window.pageYOffset = height
      document.body.scrollTop = height
    }
    this.getPddList({ page: 1, size: 10 })
    this.getCarCountList()
    init()
    // this.$store.dispatch('login/checkRealName', () => {
    // })
    luckyApi.getBanners({}).then((res) => {
      this.activeList = res.data ? res.data.filter(v => moment().isBefore(moment(v.publictyTime))) : [];
    });
    homeApi.getAllDictByType({ type: "column_type" }).then((res) => {
      const { data, flag } = res;
      if (flag) {
        this.selectList = (data || []).filter(item => item.dictLabel !== '健康宣教' && item.dictLabel !== '晒党务' && item.dictLabel !== '晒决策' && item.dictLabel !== '晒财务') || [];
      }
      console.log(this.selectList);
    });
    homeApi
      .getArticleList({
        page: 1,
        pageSize: 5,
        type: 12,
        columnType: '1',
      })
      .then((res) => {
        console.log(res);
        const { flag, data } = res;
        if (data && flag) {
          this.newsDataList = data.records || [];
          console.log('this.newsDataList', this.newsDataList);
        }
    });
      wyslApi
        .getGamePage({
          page: 1,
          size: 5,
          object: {
            activityType: '',
            status: '',
            sortField: '',
            sort: 'desc'
          }
        })
        .then(res => {
          const { list = [], total = 0 } = res.data || {};
          this.commentList = list || [];
        });
  },
  components: {
    // weather,
  },
  methods: {
    ...mapMutations(["setLuckyActiveItem", 'setDataInfo']),
    ...mapActions({
      // getUserInfo: 'login/getUserInfo',
    }),
  showDetailPage(data) {
    this.setDataInfo(data);
    if (data.contentType === 1) {
      window.location.href = data.content;
    } else {
      this.$router.push(`/zxzxDetail`);
    }
  },
  onQuestionClick(item) {
    this.gotoLink("huodongzhongxinDetail?key="+item.activityId);
  },
	ybdj() {
		console.log(JSON.parse(sessionStorage.getItem('userInfo')));
		this.$router.push('/ylbxdj');
	},
    getPddList(params) {
      yqpddApi.getHotOrders(params).then((res) => {
        const { records = [] } = res.data
        records &&
          records.length &&
          records.map((item) => (item.img = item.image.split('#')[0]))
        this.pageDataList = records
      })
    },
    getCarCountList(params = {}) {
      llcwApi.getCarCountList(params).then((res) => {
        this.carCountList = res.data || {}
      })
    },
    getUserInfo(code) {
      this.$store.dispatch('login/getUserInfo', { code })
    },
    handleDj() {
      this.showAllDj = !this.showAllDj
    },
    showPage() {
      this.$router.push('/userInfo')
    },
    showToast(title) {
      Toast(title);
    },
    serchWeather() {
      // console.log('天气查询');
      //console.log(this.city)
      // 调用接口
      //保存this
      var that = this
      this.axios
        .get('/authApi/sdk-auth/getWeather')
        .then(function(response) {
          // console.log(response.data.data.forecast)
          that.weatherList = response.data.data.forecast[0]
        })
        .catch(function(err) {
          // eslint-disable-line no-unused-vars
        })
    },
    showAlert() {
      this.$messagebox('提示', '系统正在建设中，尽情期待')
    },
    // login() {
    //   this.axios
    //     .get("http://124.91.150.6:8071/app/login?openId=test001", {
    //       headers: {},
    //     })
    //     .then(function(response) {
    //       let data = response.data;
    //       let openId = "test001";
    //       let token = data.token;
    //       sessionStorage.setItem("openId", openId);
    //       sessionStorage.setItem("token", token);
    //     })
    //     .catch(function(err) {});
    // },
    gotoLink(path) {
      const scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop
      console.log(scrollTop)
      this.setSessionData('moveDown', scrollTop)
      if (path) {
        this.$router.push(path)
      } else {
        this.$toast('应用开发中……')
      }
    },
    handleLink(data) {
      if (data.path) {
        this.$router.push(data.path)
      }
      if (data.url) {
        openUrl(data.url)
      }
    },
    tabClick(value, name) {
      console.log(value, name);
      homeApi
        .getArticleList({
          page: 1,
          pageSize: 5,
          type: 12,
          columnType: value,
        })
        .then((res) => {
          console.log(res);
          const { flag, data } = res;
          if (data && flag) {
            this.newsDataList = data.records || [];
          }
      });
    },
    router(vote_url) {
      openUrl(vote_url)
    },
    routerDirect(url) {
      window.location.href = url
    },
    showLuckPage(v){
      this.$store.commit('lucky/setLuckyActiveItem', v);
      this.$router.push('/luckTurnTable?key=' + v.lid)
    },
    setActiveNewsItem(type){
      this.activeNewsItem = type;
    },
  },
  computed: {
    ...mapState({
      isLogin: (state) =>
        state.login.isLogin || context.getSessionData('isLogin'),
    }),
  },
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/index_copy.scss';
body {
  background-color: #f7f7f7;
}
.page-container{
  padding: 0px !important;
  background: rgba(0, 0, 0, 0);
}
.topBanner {
  background: url('http://dssq.ikeqiao.net/data/image/dongsheng/topBanner.png')
    no-repeat;
  background-size: 100%;
  height: 130px;
  width: 100%;
  .title{
    // padding-top: 10px;
    line-height: 40px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 500;
    display: flex;
    img{
      height: 16px;
      width: 16px;
      // margin-right: 10px;
      margin: 12px;
    }
  }
}
.jttContent{
  background: #FFFFFF;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  margin-top: 10px;
  .image{
    width: 60px;
    height: 50px;
    padding-right: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    img{
      width:50px;
      height:50px;
    }
  }
  .text{
    width: calc(100% - 60px);
    padding-left: 10px;
    >div{
      line-height: 25px;
      height: 25px;font-size: 14px;
      color: rgba(0,0,0,0.65);
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.cardContent{
  height: 100px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .item{
    height: 100%;
    width: 160px;
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 8px;
    padding: 10px;
    line-height: 20px;
    .title{
      font-size: 14px;
      color: #FFFFFF;
      font-weight: 500;
    }
    .desc{
      font-size: 12px;
      color: rgba(255,255,255,0.65);
      font-weight: 500;
    }
    .btn{
      font-size: 12px;
      color: rgba(255,255,255,0.65);
      font-weight: 500;
      width: 100%;
      // display: flex;
      img{
        width: 14px;
        height: 14px;
        margin-top: 3px;
        margin-left: 5px;
      }
    }
  }
}
.no-data{
  width: 126px;
  height: 126px;
  margin: 5px auto;
  .image{
    width: 106px;
    height: 106px;
    >img{
      width: 100%;
      height: 100%;
    }
  }
  .text{
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    text-align: center;
    font-weight: 400;
  }
}
.contentList{
  margin-top: -100px;
  padding: 10px;
}
.copyright-con {
  margin-top: 10px;
  padding-bottom: 40px;
  text-align: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  line-height: 18px;
}
</style>
