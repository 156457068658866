<template>
  <div class="main-container flex flex-col">
    <header class="flex">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <div class="title flex-1">订单信息</div>
      <van-icon />
    </header>
    <section class="flex-1 flex flex-col">
      <div class="distribution-type">
        <van-button
          :type="distributionType === 1 ? 'info' : 'default'"
          @click="distributionType = 1"
        >
          送货上门
        </van-button>
        <van-button
          :type="distributionType !== 1 ? 'info' : 'default'"
          @click="distributionType = 2"
        >
          线下自提
        </van-button>
      </div>
      <div class="flex shipping-address">
        <template v-if="distributionType === 1">
          <span>收</span>
          <span class="flex-1 truncate">祥生君城1幢二单元1104</span>
          <svg-icon icon-class="arrow-right" />
        </template>
        <template v-else>
          <div class="text-gray">自提地点：</div>
          <span class="truncate">祥生君城商铺103号</span>
        </template>
      </div>
      <div class="box shopping-car">
        <div>
          <img src="~assets/images/llg/shop.svg" alt="" />
          东盛超市
        </div>
        <div>
          <div class="flex goods" v-for="goods in shoppingCar" :key="goods.id">
            <img :src="goods.image" alt="" />
            <span class="flex-1">{{ goods.name }}</span>
            <span>X{{ goods.num }}</span>
            <span class="text-pink">￥{{ goods.price }}</span>
          </div>
        </div>
      </div>
      <div class="box">
        <div>备注</div>
        <div>
          <van-field
            v-model="remark"
            :autosize="{ minHeight: 50 }"
            type="textarea"
            placeholder="订单备注"
            maxlength="100"
            show-word-limit
          />
        </div>
      </div>
    </section>
    <van-submit-bar
      :price="total.price * 100"
      :loading="loading"
      text-align="left"
      button-type="info"
      button-text="提交订单"
      @submit="onSubmit"
    />
  </div>
</template>
<script>
import haMiMelon from '@/assets/images/llg/hami_melon.png';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      distributionType: 1,
      haMiMelon,
      // shoppingCar: [
        // {
        //   id: '12',
        //   name: '新疆哈密瓜1.5斤',
        //   price: 15.9,
        //   num: 1,
        // },
        // {
        //   id: '13',
        //   name: '新疆哈密瓜1.5斤新疆哈密瓜1.5斤新疆哈密瓜1.5斤',
        //   price: 2.3,
        //   num: 2,
        // },
        // {
        //   id: '134',
        //   name: '新疆哈密瓜1.5斤',
        //   price: 3.0,
        //   num: 3,
        // },
      // ],
      remark: '',
      loading: false,
    };
  },
  computed: {
    ...mapState({
      shoppingCar: (state) => state.llg.order,
    }),
    total() {
      return this.shoppingCar.reduce(
        (prev, curr) => {
          return {
            num: prev.num + curr.num,
            price: prev.price + +(curr.num * curr.price).toFixed(2),
          };
        },
        { num: 0, price: 0 },
      );
    },
  },
  methods: {
    onSubmit() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$router.replace('/llg/orderDetail');
      }, 2000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/llg.scss';
.distribution-type {
  display: flex;
  justify-content: space-around;
  margin: 10px;
  > .van-button {
    flex: 1;
    border-radius: 8px;
  }
  > .van-button:first-child {
    margin-right: 20px;
  }
}
.shipping-address {
  margin: 0 10px 10px 10px;
  padding: 0 10px;
  height: 56px;
  line-height: 36px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  background-color: #fff;
  border-radius: 8px;
  > span:first-child {
    margin-right: 10px;
    height: 36px;
    width: 36px;
    text-align: center;
    background-color: #3e71f9;
    border-radius: 50%;
    color: #fff;
  }
}
.box {
  margin: 0 10px 10px 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  > div:first-child {
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0;
  }
}
.shopping-car {
  > div:first-child {
    > img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
  .goods {
    padding: 5px 0;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    > img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }
    > span {
      opacity: 0.85;
    }
    > span:not(:nth-child(2)) {
      min-width: 50px;
      text-align: right;
    }
  }
}
</style>
