<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>详情</h1>
      </div>
      <div class="card-con">
        <div
          class="tag"
          v-if="state"
          :style="{color: (colors[state] && colors[state].text) || '#E5B20A', background: (colors[state] && colors[state].background) || '#FFF6D9'}"
        >{{state || '空闲'}}</div>
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/gxcw_banner.png" class="image" />
        <div style="position: relative;">
          <div class="card-title">
            <span>车位号：{{data.parkingCode}}</span>
          </div>
          <div class="row">
            <span class="label">所属小区：</span>
            <span>{{data.communityName}}</span>
          </div>
          <div class="row">
            <span class="label">车位地址：</span>
            <span>{{data.location}}</span>
          </div>
          <div class="row">
            <span class="label">联系电话：</span>
            <span>{{data.registerPhone}}</span>
          </div>
          <div class="row">
            <div class="label">共享时间：</div>
            <div class="shar-time">
              {{`${data.startTime}-${data.overTime}`}}
              <br />{{getWeekText}}
            </div>
          </div>
          <div class="card-button">{{data.stopSign == 0 ? '取消共享' : '共享车位'}}</div>
        </div>
      </div>
      <div class="bg-bar"></div>
        <div class="tab-nav">
         <div class="list">
           <div class="list-title">预约动态</div>
          <van-pull-refresh
            v-model="isLoading"
            success-text="刷新成功"
            @refresh="onRefresh"
          >
            <div v-for="item in recordList" :key="item.parkingCode" class="card-con">
              <div class="contact">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/联系他@2x.png" alt />
                <a href="#">联系他</a>
              </div>
              <div
                class="tab-tag"
                :style="{color: colors[item.useStatus].text, background: colors[item.useStatus].background}"
              >{{item.useStatus}}</div>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png" class="image" />
              <div class="reservation">
                <div class="card-title">
                  <span>{{item.registerName}}</span>
                </div>
                <div class="row">
                  <div>预约车牌：{{item.carLicense}}</div>
                </div>
                <div class="row">
                  <span>预约时间：{{`${item.startTime}~${item.overTime}`}}</span>
              </div>
              <div class="row">
                <span>预约时长：{{item.stayTime}}min</span>
              </div>
            </div>
          </div>
          </van-pull-refresh>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      data: {},
      state: '',
      recordList: [],
      colors: {
        空闲: { text: "#13BB49", background: "#D5F4E2" },
        已驶离: { text: "#E5B20A", background: "#FFF6D9" },
        已失效: { text: "rgba(0,0,0,0.45)", background: "#EDEDED" },
        已预定: { text: "#E5B20A", background: "#FFF6D9" },
        未开放: { text: "rgba(0,0,0,0.45)", background: "#EDEDED" }
      },
      buttons: ["取消共享", "取消共享", "重新共享"],
      current: 1,
      isLoading: false,
    };
  },
  components: {},
  mounted() {
    const key = this.$store.state.llcw.park;
    this.$store.dispatch('llcw/getParkInfo', { key, obj: this });
    this.$store.dispatch('llcw/getParkStatus', { key, obj: this });
    this.getRecords({ page: 1 });
  },
  computed: {
    getWeekText() {
      let result = '';
      const week = { mon: '星期一',tue: '星期二', thur: '星期三', wedn: '星期四', fri: '星期五', sat: '星期六', sun: '星期天'};
      return Object.keys(week).reduce((result, current) => {
        // console.log('getWeek', current, result)
        if(this.data[current] === '1') { return result += `  ${week[current]}`}
        return result;
      },'');
    }
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getRecords(params) {
      const pms = {
        page: this.current,
        size: 10,
        shareParkingId: this.$store.state.llcw.park,
        ...params
      }
      this.$store.dispatch('llcw/getRecords', { params:pms, obj: this});
    },
    onRefresh() {
      this.getRecords({page: this.current + 1});
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;

  .card-con {
    align-items: center;
    position: relative;
    background: #ffffff;
    margin-bottom: 0.6rem;
    .tag {
      position: absolute;
      top: 0.6rem;
      right: 0.8rem;
      padding: 0.2rem 0.8rem;
      border-radius: 4px;
    }
    .card-title {
      display: flex;
      justify-content: space-between;
      
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      text-align: left;
      line-height: 1.8rem;
      margin: 0.4rem 1rem;
      .tag {
        background: #d5f4e2;
        border-radius: 4px 2px 8px 2px;
       
        font-size: 12px;
        color: #37d477;
        letter-spacing: 0;
        text-align: center;
        line-height: 12px;
        padding: 0.2rem 0.8rem;
        height: 1.2rem;
      }
    }
    .image {
      width: 100%;
    }
    .row {
      font-size: 13px;
      line-height: 1.4rem;
      // display: flex;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      text-align: left;
      margin: 0.2rem 1rem;
      .label {
        color: rgba(0, 0, 0, 0.45);
        text-align: left;
        line-height: 18px;
        margin-right: 0.6rem;
      }
      .shar-time {
        margin-top: -1.2rem;
        margin-left: 5.33rem;
      }
    }
    .card-button {
      position: absolute;
      top: 0.2rem;
      right: 0.8rem;
      width: 5rem;
      height: 2rem;
      background: #4479ed;
      color: #ffffff;
      text-align: center;
      line-height: 2rem;
      border-radius: 4px;
    }
  }
  .bg-bar {
    background-color: #f6f7fa;
    height: 0.8rem;
  }
  .list {
    width: 100%;
    position: relative;
    padding: 0.8rem;
    overflow-y: auto;
    overflow-x: hidden;
    .list-title {
      border-left: 4px solid #4479ED;
      padding-left: 8px;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0,0,0,0.85);
    }
    .card-con {
      align-items: center;
      padding: 0.3rem 1rem;
      border-bottom: 2px solid #ecedee;
      position: relative;
      display: flex;
      background: #ffffff;
      margin-bottom: 0;
      .reservation {
        margin-left: -1rem;
      }
      .contact {
        position: absolute;
        top: 1.2rem;
        right: 6.5rem;
        img {
          top: 4rem;
          left: -1rem;
          position: absolute;
          display: inline-block;
          height: 1rem;
          width: 1rem;
          // margin-top: 2rem;
        }
        a {
          display: inline-block;
          padding-left: 0.2rem;
          color: #4479ed;
          text-decoration: underline;
        }
      }
      .tab-tag {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0.2rem 0.8rem;
        border-radius: 4px;
      }
      img {
        margin-top: -4rem;
      }
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 1.8rem;
      }
      .image {
        width: 3.6rem;
        border-radius: 2px;
        margin-right: 0.5rem;
        padding-top: 1.5rem;
      }
      .row {
        font-size: 13px;
        // height: 1.4rem;
        line-height: 1.4rem;
        display: flex;
       
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        .label {
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 18px;
          margin-right: 0.6rem;
        }
      }
      .card-button {
        position: absolute;
        top: 1rem;
        right: 0.8rem;
        width: 5rem;
        height: 2rem;
        background: #4479ed;
        color: #ffffff;
        text-align: center;
        line-height: 2rem;
        border-radius: 4px;
      }
    }
  }
}
</style>
