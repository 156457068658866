<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>发布闲置</h1>
      </div>
      <div class="form-bar">
        <van-form @submit="onSubmit">
			<van-field
			  size="large"
			  v-model="name"
			  name="name"
			  label="发起人"
			  placeholder="请输入"
			  class="input-bar"
			  :rules="[{ required: true, message: '请输入发起人' }]"
			/>
			<van-field
			  size="large"
			  v-model="phone"
			  name="phone"
			  type="tel"
			  label="联系电话"
			  placeholder="请输入"
			  :rules="[{ required: true, pattern, message: '请输入正确的联系电话' }]"
			  class="input-bar"
			/>
          <van-field
            size="large"
            v-model="type"
            name="type"
            is-link
            readonly
            label="物品类型"
            placeholder="请选择"
            @click="showType = true"
            :rules="[{ required: true, message: '请选择物品类型' }]"
            class="input-bar"
          />
		  <van-field
		    size="large"
		    v-model="thingName"
		    name="thingName"
		    type="tel"
		    label="物品名称"
		    placeholder="请输入"
		    :rules="[{ required: true, message: '请输入物品名称' }]"
		    class="input-bar"
		  />
		  <van-field name="tj" label="置换条件" size="large" :rules="[{required: true, message: '请选择'}]">
		    <template #input>
		      <van-radio-group v-model="tj" direction="horizontal">
		        <van-radio name="以物换物">以物换物</van-radio>
		        <van-radio name="免费赠送">免费赠送</van-radio>
		      </van-radio-group>
		    </template>
		  </van-field>
		  <van-field name="zhfs" label="置换方式" size="large" :rules="[{required: true, message: '请选择'}]">
		    <template #input>
		      <van-radio-group v-model="zhfs" direction="horizontal">
		        <van-radio name="你来拿">你来拿</van-radio>
		        <van-radio name="我去取">我去取</van-radio>
				<van-radio name="第三方置换">第三方置换</van-radio>
		      </van-radio-group>
		    </template>
		  </van-field>
		  <van-field
		    size="large"
		    v-model="address"
		    name="address"
		    type="tel"
		    label="我的地址"
		    placeholder="请输入"
		    :rules="[{ required: true, message: '请输入地址' }]"
		    class="input-bar"
		  />
          <!-- <van-field
            size="large"
            v-model="deadline"
            name="startTime"
            is-link
            readonly
            label="活动时间"
            placeholder="请选择"
            @click="showDate = true"
            :rules="[{ required: true, message: '请选择活动时间' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="deadDate"
            name="deadline"
            is-link
            readonly
            label="截止日期"
            placeholder="请选择"
            @click="showDeadLine = true"
            :rules="[{ required: true, message: '请选择日期' }]"
            class="input-bar"
          /> -->
		  <div class="section" style="padding: 0.8rem">
		    <div class="title">物品图片</div>
		    <van-uploader
		      v-model="fileList"
		      name="imageList"
		      multiple
		      :max-count="3"
		      :rules="[{ required: true, message: '请选择物品图片' }]"
		    />
		  </div>
		  <van-field name="yxq" label="有效期" size="large" :rules="[{required: true, message: '请选择'}]">
		    <template #input>
		      <van-radio-group v-model="yxq" direction="horizontal">
		        <van-radio name="12小时">12小时</van-radio>
		        <van-radio name="24小时">24小时</van-radio>
				<van-radio name="48小时">48小时</van-radio>
				<van-radio name="不限">不限</van-radio>
		      </van-radio-group>
		    </template>
		  </van-field>
          <div class="section" style="padding: 0.8rem">
            <div class="title">共享宣言</div>
            <van-field
              size="large"
              v-model="description"
              name="content"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              placeholder="请输入共享宣言"
              show-word-limit
            />
          </div>
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
      <van-calendar v-model="showDeadLine" @confirm="onDeadLineConfirm" />
      <van-calendar v-model="showDate" type="range" @confirm="onDateConfirm" />
      <van-popup v-model="showType" position="bottom">
        <van-picker
          title="物品类型"
          show-toolbar
          :columns="typeList"
          @confirm="onTypeConfirm"
          @cancel="onCancel('showType')"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import yqpddApi from "api/yqpdd";
import { DatetimePicker } from "mint-ui";
import { Uploader, Toast, Radio, RadioGroup } from "vant";
import { Field } from "mint-ui";
import { mapState } from 'vuex';
import context from '@/main';
export default {
  data() {
    return {
      type: "",
      showType: false,
      typeList: [
		'家电', '器具'
	  ],
      name: "",
      title: "",
      phone: "",
      deadline: "",
      showDate: false,
	  thingName: '',
      deadDate: '',
      startTime: '',
      endTime: '',
      showDeadLine: false,
      description: "",
      address: '',
	  tj: '',
	  zhfs: '',
      fileList: [],
	  yxq: '',
      pattern: /^[1]([3-9])[0-9]{9}$/,
    };
  },
  components: {},
  mounted() {
    this.getOrderType();
    // this.name = this.userInfo.userName || this.userInfo.nickname || '';
    // const { id } = this.$route.params;
    // if(id) {
    //   yqpddApi.getOrderInfo({ orderId: id }).then(res => {
    //     if(res.flag) {
    //       const result = res.data;
    //       ['type', 'title', 'phone', 'deadline'].forEach(item => {
    //         this[item] = result[item];
    //       });
    //       this.name = result.own;
    //       this.description = result.content;
    //       // this.fileList = result.image.split('#') || [];
    //     }
    //   })
    // }
  },
  computed: {
    // ...mapState({
    //   userInfo: (state) => state.login.userInfo || (context.getSessionData('userInfo')),
    // }),
  },
  methods: {
    uploadImg() {
      this.pickerVisible = true;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    onCancel(type) {
      this[type] = false;
    },
    onDateConfirm(date) {
      const [start, end] = date;
      this.startTime = this.formatDateTime(start);
      this.endTime = this.formatDateTime(end);
      this.deadline = `${this.formatDateTime(start)}~${this.formatDateTime(end)}`;
      this.showDate = false;
    },
    onDeadLineConfirm(date) {
      this.deadDate = this.formatDateTime(date);
      this.showDeadLine = false;
    },
    onTypeConfirm(value, index) {
      this.type = value;
      this.showType = false;
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d;
    },
    onSubmit(values) {
		this.$router.go(-1);
      const { id } = this.$route.params;
      if(this.fileList && this.fileList.length < 1) {
        Toast('请选择活动图片后提交');
        return ;
      }
      const formData = new FormData();
      this.fileList.map((item) => formData.append("files", item.file));
      yqpddApi.uploadMultiFile(formData).then((res) => {
        const params = {
          ...values,
          startTime: this.startTime,
          endTime: this.endTime,
          image: res.data,
          orderId: id,
        };
        // yqpddApi.addOrder(params).then((res) => {
        //   const { message, flag } = res;
        //   if(flag) {
        //     Toast('拼单发起成功！');
        //     this.$router.go(-1);
        //   } else {
        //     Toast(message);
        //   }
          
        // });
      });
    },
    getOrderType(){
      // yqpddApi.getOrderType().then(res=>{
      //   const {data}=res;
      //   const list = data.map(item=>{return item.type})
      //   this.typeList=list;
      // })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  padding: 0.6rem 0;
  height: calc(100vh - 6.7rem);
  width: 100vw;
  margin-top: 2.7rem;
  overflow-y: auto;
  overflow-x: hidden;
  .form-bar {
    height: 100%;
    
    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
