<template>
  <div class="building-container">
    <van-nav-bar
      class="hong-nav-bar"
      title="党务工具"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="hong-body-container">
      <!-- banner -->
      <div class="image-bar"></div>
      <!-- 菜单 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <span class="text-title">党务工具箱</span>
        </div>
        <div class="card-bar-content">
          <div class="icon-bar">
            <div
              v-for="(icon, index) in iconList1"
              @click="gotoLink(icon.path)"
              :key="`${icon.name}_${index}`"
            >
              <img class="icon" :src="icon.icon" :alt="icon.name" />
              <p>{{ icon.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "api";
import { mapMutations, mapState } from "vuex";
import OfficerCard from "./officerCard.vue";
import data from "assets/json/resou";
import axios from 'axios';
import { Toast, Dialog } from 'vant';
import context from '@/main';
export default {
  data() {
    return {
      iconList: [
        {
          name: "柯工慧",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/hong/smart.png",
          path: "kgh",
        },
        {
          name: "微动力",
          icon: "http://dssq.ikeqiao.net/data/image/h5/images/hong/strong.png",
          path: "microEnergy",
        },
        {
          name: "伊柯有约",
          icon:
            "http://dssq.ikeqiao.net/data/image/h5/images/hong/preview.png",
          path: "yikeyouyue",
        },
      ],
      iconList1: [
        {
          name: "入党申请",
          icon:
            "http://dssq.ikeqiao.net/data/image/h5/images/coordination/heart.png",
          path: "wxxd",
        },
        {
          name: "党费缴纳",
          icon:
            "http://dssq.ikeqiao.net/data/image/h5/images/coordination/party-pay.png",
          path: "duesPayment",
        },
        {
          name: "邻里党员",
          icon:
            "http://dssq.ikeqiao.net/data/image/h5/images/coordination/linlidangyuan.png",
          path: "neighborMember",
        },
        // {
        //   name: "先锋指数",
        //   icon:
        //     "http://dssq.ikeqiao.net/data/image/h5/images/coordination/xianfengzhishu.png",
        //   path: "memberManage",
        // },
        // {
        //   name: "红小二义工",
        //   icon:
        //     "http://dssq.ikeqiao.net/data/image/h5/images/coordination/hongxiaoer.png",
        //   path: "hongxiaoer",
        // },
        // {
        //   name: "东盛先锋圈",
        //   icon:
        //     "http://dssq.ikeqiao.net/data/image/h5/images/coordination/daduxianfeng.png",
        //   path: "ddvanguard",
        // },
      ],
      instructorList: [],
      myInstructor: {},
      communityList: ["润泽党支部", "香水湾党支部", "香林党支部"],
      resouList: [],
      // resouList: data.splice(0,4),
      listData: [],
      checkStatus: '', // 实名检验结果
      isRealName: '', // 是否实名
      hasHouse: '', // 是否房屋验证
      partyBuildingData: {}, // 党组织数据
    };
  },
  computed: {
    optionSetting() {
      return {
        step: 0.15, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 43, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  components: { OfficerCard },
  mounted() {
    // this.getInstructorList();
    // this.getMyInstructor();
    // this.getResouList();
    // this.getListData();
    // 党组织权限验证
    // const token = sessionStorage.getItem('token') || '';
    // // console.log('token', token)
    // axios({
    //   url: '/authApi/baseHousePerson/checkRealName',
    //   method: 'get',
    //   headers: {
    //     "Content-Type": "application/json;charset=utf-8",
    //     Authorization: token
    //     ? `Bearer ${token}`
    //     : ''
    //     // : "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRoX3VzZXIiOnsidG9rZW4iOm51bGwsInVzZXJuYW1lIjoia3F6Z3l4IiwiYWRtaW4iOmZhbHNlLCJ1c2VySWQiOiIxIn19.kwZ-diYXAsjv6sPWr4vuYT4R4nfTHYzAy-297FUMiZWaWv4OA_i9mtVWqFkb7W0Ec3bBAQ9KXSmpX4K2Ph9_rg"
    //   },
    //   data: {},
    // }).then(res => {
    //   if(res.data.flag) {
    //     const { isRealName, checkStatus, hasHouse } = res.data.data;
    //     this.isRealName = isRealName;
    //     this.checkStatus = checkStatus;
    //     this.hasHouse = hasHouse;
    //     if(hasHouse) {
    //       // 获取党组织、党小组数据
    //       this.getPartyBuildingData();
    //     }
    //     // console.log('name', isRealName, checkStatus, hasHouse)
    //   }
    // }).catch(err => {
    //   console.log('error===>', err);
    // });
  },
  watch: {},
  methods: {
    gotoCheck() {
      if (this.checkStatus === '您的实名信息正在审核中!') {
          Toast.fail(this.checkStatus);
        } else {
          Dialog.confirm({
            title: '提示',
            message: '认证房屋后可查看更多组织架构，前往认证？',
            confirmButtonText: '前往认证'
          }).then(() => {
            if(this.isRealName && !this.hasHouse) {
              this.$router.push('editUserInfo');
            } else {
              this.$router.push('loginPhone');
            }
          }).catch((e) => {
            // this.$router.go(-1);
            console.log('link',e)
          })
        }
    },
    getPartyBuildingData() {
      api.MyAttribution().then(res => {
        if(res.flag) {
          console.log('data', res)
          this.partyBuildingData = res.data;
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    callPhone(phone) {
      window.location.href = `tel://${phone}`;
    },
    handleTab() {},
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
    getResouList() {
      api
        .getHongArticleList({
          page: 1,
          pageSize: 4,
          type: 1,
        })
        .then((res) => {
          if (res.flag) {
            this.resouList = res.data.records;
          }
        });
    },
    getListData() {
      api
        .getHongArticleList({
          page: 1,
          pageSize: 4,
          type: 2,
        })
        .then((res) => {
          if (res.flag) {
            this.listData = res.data.records;
          }
        });
    },
    openUrl(data) {
      if (data.content && data.contentType === 1) {
        // 增加浏览量
        api.addArticleVisit({ id: data.id }).then((res) => {});
        window.location.href = data.content;
      } else if (data.contentType === 0) {
        this.$router.push(`resouArticleDetail?id=${data.id}`);
      }
    },
    getInstructorList() {
      api.getInstructor({}).then((res) => {
        if (res.flag) {
          this.instructorList = res.data.splice(0, 4);
        }
      });
    },
    getMyInstructor() {
      api.getMyInstructor({}).then((res) => {
        if (res.flag && res.data) {
          this.myInstructor = res.data[0];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.building-container {
  height: 100vh;
  width: 100vw;
  .hong-nav-bar {
    background-image: linear-gradient(
      to left,
      rgb(255, 0, 0),
      rgb(232, 45, 93),
      rgb(206, 26, 33)
    );
    ::v-deep .van-nav-bar__title {
      color: white;
    }
    ::v-deep .van-icon {
      color: white;
    }
  }
  .hong-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png")
      no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 10rem;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/hong/hong-bar.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      padding-top: 2.6rem;
      justify-content: center;
      > img {
        width: 4rem;
        height: 4rem;
      }
    }

    .area-party-building-con {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 10px;
      background-color: #fff;
      border-radius: 4px;
      > h1 {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        line-height: 32px;
      }
      .decoration-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        margin-top: 10px;
        position: relative;
        .more-bar {
          display: flex;
          align-items: center;
          position: absolute;
          right: 0;
          .more {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
        }
        > h1 {
          font-size: 18px;
          color: #4479ed;
          font-weight: 600;
          margin: 0 10px;
        }
        h2 {
          font-size: 16px;
          color: rgba(0, 0, 0, 1);
          font-weight: 500;
          text-align: left;
          margin-left: 6px;
        }
        > img {
          height: 14px;
          margin-left: 4px;
        }
      }
      .party-title {
        &::before {
          content: "";
          border-radius: 50%;
          background: #e13f30;
          width: 7px;
          height: 7px;
          display: inline-block;
          position: absolute;
          left: -4px;
          top: 13px;
        }
      }
      .party-building-none {
        width: 100%;
        padding: 20px;
        text-align: center;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        >button {
          background: none;
          border: 1px solid #4479ed;
          color: #4479ed;
          font-size: 12px;
          border-radius: 10px;
          padding: 2px 5px;
          margin: 10px;
        }
      }
      .party-building-card {
        width: 100%;
        border-left: 1px dashed rgba(226, 63, 48, 0.45);
        position: relative;
        &:last-child {
          border-left: 0;
        }
        &::before {
          content: "";
          border-radius: 50%;
          background: #e13f30;
          width: 7px;
          height: 7px;
          display: inline-block;
          position: absolute;
          left: -4px;
          top: 3px;
        }
        .party-bulding-card-title {
          &::before {
            content: "";
            background: #e13f30;
            width: 16px;
            height: 1px;
            display: inline-block;
          }
          > h1 {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            margin-left: 6px;
          }
          > img {
            height: 14px;
            margin-left: 4px;
          }
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          > div {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            .more {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.45);
            }
            > img {
              height: 12px;
              width: 12px;
            }
            i {
              color: #4479ed;
            }
          }
        }
        .party-building-card-content {
          width: 100%;
          //border-left: 1px dashed rgba(226, 63, 48, 0.45);
          //border-bottom: 1px dashed rgba(226, 63, 48, 0.45);
          padding-left: 36px;
          //margin-left: 6px;
          .ahead-index-top {
            width: 100%;
            margin-top: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-image: linear-gradient(0deg, #f9fcff 0%, #d9edff 100%);
            border-radius: 4px;
            .award-con {
              display: flex;
              align-items: flex-end;
              flex-wrap: wrap;
              margin-left: 10px;
              .award-item {
                width: 50%;
                display: flex;
                align-items: flex-end;
                margin-bottom: 10px;
                .avatar-first {
                  width: 44px;
                  height: 64px;
                  position: relative;
                  .img-head {
                    width: 100%;
                    height: 100%;
                  }
                }
                > div {
                  margin-left: 5px;
                  .icon-con {
                    display: flex;
                    align-items: center;
                    .phone {
                      display: inline-block;
                      width: 16px;
                      height: 16px;
                      background: url("~assets/images/phone.png") no-repeat;
                      background-size: 100% 100%;
                    }
                    .comment {
                      display: inline-block;
                      width: 16px;
                      height: 16px;
                      background: url("~assets/images/comment.png") no-repeat;
                      background-size: 100% 100%;
                      margin-left: 10px;
                    }
                  }
                  .name {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                    letter-spacing: 0;
                    font-weight: 500;
                    line-height: 24px;
                  }
                  .tip {
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.45);
                    letter-spacing: 0;
                    font-weight: 400;
                    line-height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .organization-bar {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 10px;
      height: 80px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/hong/organization.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .organization-title {
        font-size: 22px;
        color: #29abee;
      }
      .organization-btn {
        color: #1aaaf1;
        font-size: 13px;
        background-color: #d3f1fe;
        border-radius: 5px;
        padding: 4px;
      }
    }
    .say {
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/hong/say.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .notice-bar {
      min-height: 61px;
      width: calc(100% - 20px);
      margin: 10px;
      padding: 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      background-color: #fff;
      .left {
        width: 41px;
        height: 41px;
        > img {
          width: 41px;
          height: 41px;
        }
      }
      .right {
        width: calc(100% - 46px);
        padding: 0 10px;
        li {
          color: #a6a6a6;
          line-height: 1.4rem;
          font-size: 0.85rem;
          word-break: break-all;
          h4 {
            color: #000;
          }
        }
      }
      .arrow {
        > img {
          height: 20px;
        }
      }
    }

    .icon-bar {
      margin: 16px;
      width: calc(100% - 20px);
      // height: 108px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      background: #fff;
      border-radius: 4px;
      > div {
        width: 26%;
        margin-bottom: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
          width: 48px;
          height: 48px;
          margin-bottom: 5px;
        }
        > p {
          text-align: center;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      background-color: #fff;
      .card-bar-title {
        width: 100%;
        height: 33px;
        padding: 10px;
        background: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .text-title {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          width: 50%;
        }
        .text-more {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          float: right;
          display: flex;
          align-items: center;
        }
      }
      .card-bar-content {
        width: 100%;
        .icon-bar {
          margin: 16px;
          width: calc(100% - 20px);
          // height: 108px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          background: #fff;
          border-radius: 4px;
          > div {
            width: 26%;
            margin-bottom: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon {
              width: 48px;
              height: 48px;
              margin-bottom: 5px;
            }
            > p {
              text-align: center;
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
        .resou-list {
          width: 100%;
          margin: 0 auto;
          padding: 0.6rem 0;
          background: #fff;
          border: none;
          li {
            padding: 0.3rem 0;
            // border-bottom: 1px solid #ddd;
            font: 500 0.9rem/1.4rem "microsoft yahei";
            display: flex;
            .left {
              width: 140px;
              height: 86px;
              margin-right: 10px;
              img {
                width: 140px;
                height: 86px;
              }
            }
            .right {
              flex: 1;
              .tit {
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
              }
              .info {
                color: rgb(200, 201, 203);
                font-size: 10px;
              }
              .date {
                color: #999;
              }
              p {
                color: #7a7a7a;
              }
              .reply-info {
                margin-bottom: 0.4rem;
                position: relative;
                height: 2rem;
                img {
                  width: 2rem;
                  height: 2rem;
                  float: left;
                  border-radius: 50%;
                  margin-right: 0.5rem;
                  vertical-align: middle;
                }
                div {
                  float: left;
                  font: 500 0.8rem/1rem "microsoft yahei";
                  color: #333;
                  span {
                    color: rgb(200, 201, 203);
                    font-size: 8px;
                  }
                }
              }
            }
          }
        }
        .instructor-list-con {
          width: 100%;
          overflow-y: hidden;
          overflow-x: auto;
          display: flex;
          .instructor-item {
            display: inline-block;
            width: 375px !important;
          }
        }

        .community-bar {
          width: 100%;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > div {
            width: 30%;
            height: 94px;
            background: url("http://dssq.ikeqiao.net/data/image/h5/images/hong/community.png")
              no-repeat;
            background-size: 100%;
            display: flex;
            align-items: center;
            > p {
              width: 100%;
              line-height: 26px;
              background-color: rgba(255, 0, 0, 0.61);
              color: #fff;
              font-size: 14px;
              text-align: center;
            }
          }
        }
      }
    }
    .officers {
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/hong/officer-bg.png")
        no-repeat;
      background-size: 100%;
      width: calc(100% - 20px);
    }
  }
}
.seamless-warp {
  height: 2.8rem;
  overflow: hidden;
}
</style>
