<template>
  <div class="jystj-info-container">
    <van-nav-bar
      title="处理详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <van-steps direction="vertical" :active="step">
        <van-step v-for="(item, index) in eventDetail.processList || []" :key="index">
          <div class="step-title" style="display: flex;justify-content: space-between;align-items: center;">
            <span>{{item.createTime}}</span>
            <span v-if="index == step">{{item.dealStatus}}</span>
          </div>
          <div class="event-list-detial" v-if="item.dealStatus === '待处理'">
            <div class="record-card-body">
              <div class="record-card-row">
                <span class="record-card-row-label">帮助类型：</span>
                <span>{{eventDetail.keyType || '-'}}</span>
              </div>
              <div class="record-card-row">
                <span class="record-card-row-label">是否上门：</span>
                <span>{{eventDetail.isComeDoor || '-'}}</span>
              </div>
              <div class="record-card-row">
                <span class="record-card-row-label">所在位置：</span>
                <span>{{eventDetail.location || '-'}}</span>
              </div>
              <div class="record-card-row">
                <span class="record-card-row-label">问题描述：</span>
                <span>{{eventDetail.keyQuestion || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="event-list-detial-answer" v-if="item.dealStatus === '处理中'">
            <div class="event-list-detial-answer-title">金钥匙回复</div>
            <div class="desc">{{item.replyContent || '-'}}</div>
            <!-- <div class="time">{{eventDetail.answerPerson}}</div> -->
          </div>
          <div class="event-list-detial-answer" v-if="item.dealStatus === '已退回'">
            <div class="event-list-detial-answer-title">社区回复</div>
            <div class="desc">{{item.replyContent || '-'}}</div>
            <!-- <div class="time">{{eventDetail.answerPerson}}</div> -->
          </div>
          <div class="event-list-detial-answer" v-if="item.dealStatus === '已办结'">
            <div class="event-list-detial-answer-title">处理结果</div>
            <div class="desc">{{item.finishIntroduce || '-'}}</div>
            <div class="event-list-detial-answer-title">完成时间</div>
            <div class="desc">{{item.finishTime || '-'}}</div>
            <div class="event-list-detial-answer-title">完成后照片</div>
            <div class="image-list"  v-if="item.finishImage">
              <van-image @click="ImagePreview((item.finishImage || '').split('#'),i)" v-for="(img, i) in (item.finishImage || '').split('#')" :key="i" :src="img" width="50" height="50" fit="cover" />
            </div>
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import jystjApi from 'api/jystj';
import { mapMutations, mapState } from "vuex";
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      eventDetail: [],
      step: 0,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    this.getJystjInfo({ cId: id });
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    ImagePreview(arr, i) {
      ImagePreview({
        images: arr,
        closeable: true,
        startPosition: i,
      });
    },
    getJystjInfo(params) {
      jystjApi.getJystjDetail(params).then(res => {
        if (res.flag) {
          this.eventDetail = res.data || {};
          this.step = res.data && res.data.processList ? res.data.processList.length - 1 : 0;
        } else {
          this.eventDetail = {};
          this.step = 0;
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.jystj-info-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  
  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    // padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
      .event-list-detial{
      padding: 16px;
      background: #fff;
      .desc{
        line-height: 40px;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
      }
      .time{
          line-height: 20px;
          font-size: 12px;
          color: #707070;
      }
      .image-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
      .record-card-body {
        // padding: 12px;
        width: 100%;
        color: rgba(0,0,0,0.85);
        .record-card-row {
          width: 100%;
          line-height: 24px;
          .record-card-row-label {
            color: rgba(0,0,0,0.45);
          }
        }
        
      }
      .type {
        // border-top: 1px solid rgba(0,0,0,0.05);
        padding-top: 16px;
        display: flex;
        align-items: center;
        .tag {
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url('~assets/images/tag.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .event-list-detial-answer {
      padding: 16px;
      background: #fff;
      margin-top: 16px;
      .event-list-detial-answer-title {
        width: 100%;
        height: 33px;
        // padding: 10px;
        background: #fff;
        font-size: 16px;
        font-weight: 500;
        color: #4379ed;
      }
      .desc{
        line-height: 30px;
        padding-bottom: 16px;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
      }
      .time{
          line-height: 20px;
          font-size: 12px;
          color: #707070;
          text-align: right;
      }
    }
  }
}
</style>
