<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>兑换详情</h1>
      </div>
      <div class="top-nav">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(item, index) in (detailInfo.commodityImage ? detailInfo.commodityImage.split('#') : [])" :key="index" >
            <!-- <img v-lazy="item.image" /> -->
            <img class="banner" v-lazy="item" alt="暂无图片" />
          </van-swipe-item>
        </van-swipe>
        <!-- <img class="banner"
             :src="detailInfo.commodityImage"
             alt="暂无图片" /> -->
        <div class="column">
          <div>
            <span class="title">{{detailInfo.commodityName}}</span>
            <span class="tag">物品</span>
          </div>
          <div>
            <span>剩余数量</span>
            <span class="number">{{detailInfo.commodityNum}}</span>
          </div>
        </div>
        <div class="column">
          <div class="jf">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jifen.png" />
            <span>{{`${detailInfo.commodityNeedIntegral}积分`}}</span>
          </div>
        </div>
      </div>
      <div class="content-nav">
        <div class="card-nav">
          <div class="card-title">
            使用有效期
          </div>
          <div class="card-con">
            <span v-if="detailInfo.commodityEffectiveType == 0">长期有效</span>
            <span v-else-if="detailInfo.commodityEffectiveType == 1">{{`${detailInfo.commodityEffectiveStartTime || '暂无'} ~ ${detailInfo.commodityEffectiveEndTime || '暂无'}`}}</span>
            <span v-else-if="detailInfo.commodityEffectiveType == 2">{{`${detailInfo.commodityEffectiveEndTime}之前`}}</span>
            </div>
          </div>
        <div class="card-nav">
          <div class="card-title">
            使用地点
          </div>
          <div class="card-con">
            <p>{{detailInfo.commodityRedemptionLocation}}</p>
          </div>
        </div>
        <div class="card-nav">
          <div class="card-title">
            使用方法
          </div>
          <div class="card-con">
            <p>{{detailInfo.commodityInstruction}}</p>
          </div>
        </div>
        <div class="card-nav">
          <div class="card-title">
            详情介绍
          </div>
          <div class="card-con" v-html="detailInfo.commodityDetail">
            <!-- <p>{{detailInfo.commodityDetail}}</p> -->
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <div class="foot-button"
           @click="showExChange = true">
        确定兑换
      </div>
    </div>
    <van-popup
      v-model="showExChange"
      closeable
      position="bottom"
    >
      <div class="padding-16">
        <div class="flex">
          <van-image :src="detailInfo.commodityImage" width="80" height="80" alt="" />
          <div class="right">
            <h1 class="title-text">{{detailInfo.commodityName}}</h1>
            <!-- <ul class="tag-list">
              <li class="tag">{{detailInfo.type}}</li>
              <li class="tag">{{detailInfo.position}}</li>
            </ul> -->
            <div>
              <p class="orange label-text">{{`${detailInfo.commodityNeedIntegral * count}积分`}}</p>
            </div>
          </div>
        </div>
        <div class="flex between" style="margin: 12px 0;height: 28px;align-items: center;">
          <div>
            <span class="label-text" style="margin-right: 6px;">兑换数量</span>
            <span class="small-text gray">{{`剩余${detailInfo.commodityNum}件`}}</span>
          </div>
          <div class="flex input-con">
            <span v-if="detailInfo.commodityLimitCondition !== 0" class="samll-text gray" style="margin-right: 6px;">{{`${detailInfo.commodityLimitConditionText}${detailInfo.commodityLimitCount}`}}件</span>
            <div class="count">
              <div :class="['count-btn', count <= 1 ? 'under-gray' : '']" @click="handleReduce">-</div>
              <div class="count-input">{{count}}</div>
              <div :class="['count-btn', detailInfo.commodityLimitCount && count >= detailInfo.commodityLimitCount ? 'under-gray' : '']" @click="handleAdd">+</div>
            </div>
          </div>
        </div>
        <van-button round @click="exchange" type="info" style="width: 100%;">立即兑换</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import jfdhApi from 'api/ddya/jfdh';
import {jfdhShoping} from 'assets/json/jfdh';
export default {
  data () {
    return {
      list: [],
      count: 1,
      detailInfo: {},
      commodityId: this.$route.query.id,
      showExChange: false,
      commodityLimitConditionObj: {
        0: '',
        1: '每人限购',
        2: '每日限购',
        3: '每人每日限购'
      }
    };
  },
  components: {},
  mounted () {
    const { id = '' } = this.$route.query;
    this.commodityId = id;
    this.getDetailInfo({ commodityId: id });
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },
    getDetailInfo({ commodityId = this.commodityId }) {
      jfdhApi.getGoodDetail({ commodityId }).then((res) => {
        this.detailInfo = res.data || {}
      })
    },
    handleReduce() {
      if(this.count > 1) {
        this.count -= 1;
      } else {
        // this.$toast({ message: '已经不能再减了', className: 'higher-toast'})
      }
    },
    handleAdd() {
      if(this.detailInfo.commodityLimitCount && this.count >= this.detailInfo.commodityLimitCount) {
        // this.$toast({ message: '已经不能再加了', className: 'higher-toast'})
      } else {
        this.count += 1;
      }
    },
    getImage (key) {
      return key
    },

    exchange (id, count) {
      if(count < 1) {
        Toast({ message: '兑换数量不能小于1！', className: 'higher-toast'});
        return;
      }
      else {
        this.$router.push(`dhDetailSure?id=${this.detailInfo.commodityId}&count=${this.count}`)
      }
    }
  },
};
</script>
<style lang="scss">
  .page-container {
    img {
      max-width: 100%;
    }
  }
</style>
<style lang="scss" scoped>
@import 'assets/scss/common.scss';
.page-container {
  background: #ffffff;
  padding: 0.6rem 0 1rem 0;
  height: calc(100vh - 6rem);
  width: 100vw;
  margin-top: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.9rem;
  color: rgba(0,0,0,0.85);
  .top-nav {
    // height: 10rem;
    .banner {
      height: 16rem;
      width: 100%;
      object-fit: contain;
      // height: 100%;
    }
    .column {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
      padding: 0rem 0.8rem;
      margin: 0.8rem 0;
      .title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        margin-right: 10px;
      }
      .tag {
        background: #e3effd;
        border-radius: 4px;
        font-size: 12px;
        color: #4479ed;
        text-align: center;
        padding: 2px 10px;
      }
      .number {
        color: #4479ed;
        margin-left: 4px;
      }
      .jf {
        height: 24px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #e5b20a;
        img {
          height: 14px;
          width: 14px;
          margin-right: 4px;
        }
      }
      .count {
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        line-height: 24px;
        padding: 0 10px;
      }
      .countInput {
        width: 40px;
        height: 20px;
      }
    }
  }
  .content-nav {
    border-top: 0.8rem solid #f6f7fa;
    margin-bottom: 1rem;
    .card-nav {
      color: rgba(0, 0, 0, 0.65);
      padding: 0.8rem;
      .card-title {
        line-height: 20px;
        border-left: 4px solid #4479ed;
        padding-left: 8px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        margin: 0.4rem;
      }
      .card-con {
        font-size: 14px;
        // text-indent: 2em;
        p {
          line-height: 24px;
        }
      }
    }
  }
}
.footer {
  position: fixed;
  height: 5rem;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: space-around;
  .foot-left {
    font-size: 14px;
    span:nth-child(2) {
      font-size: 24px;
      color: #e5b20a;
      font-weight: 500;
    }
    span:nth-child(3) {
      color: #e5b20a;
    }
  }
  .foot-button {
    position: unset;
    width: 80%;
    height: 3rem;
    background: #4479ed;
    border-radius: 4px;
    line-height: 3rem;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
  }
}
  .right {
    margin-left: 12px;
    color: rgba(0,0,0,0.85);
    line-height: 1.2rem;
    font-size: 0.9rem;
  }
  .input-con {
    align-items: center;
    .count {
      width: 90px;
      line-height: 28px;
      display: flex;
      text-align: center;
      justify-content: space-around;
      .count-btn {
        width: 28px;
        height: 28px;
        background:  #F2F3F5;
      }
      .under-gray {
        color: rgba(0, 0, 0, 0.15);
      }
      .count-input {
        width: 32px;
        height: 28px;
        background:  #F2F3F5;
      }
    }
  }

</style>