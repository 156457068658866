
<template>
  <div class="page-container">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>我要加入</h1>
    </div>
    <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="enrollName"
            name="enrollName"
            label="姓名"
            placeholder="请输入"
            class="input-bar"
            maxlength="20"
            :rules="[{ required: true, message: '请输入姓名' }]"
          />
          <van-field
            size="large"
            v-model="enrollPhone"
            name="enrollPhone"
            label="联系电话"
            placeholder="请输入"
            class="input-bar"
            :rules="[{ required: true, message: '请输入联系电话' }]"
          />
          <div class="section" style="padding: 0.8rem">
            <div class="title">个人介绍</div>
            <van-field
              size="large"
              v-model="enrollDesc"
              name="content"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              placeholder="请输入您的个人介绍，比如擅长领域、爱好等等"
              show-word-limit
              :rules="[{ required: true, message: '请输入个人介绍' }]"
            />
          </div>
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >提交申请</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      enrollName: "",
      enrollPhone: "",
      enrollDesc: '',
      chatId: '',
    };
  },
  components: {},
  created() {
    const { chatId = '' } = this.$route.query;
    const { userName = '', phone = '' } = this.getSessionData("userInfo") || {};
    this.chatId = chatId;
    this.enrollName = userName;
    this.enrollPhone = phone;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onSubmit(values) {
      console.log('values', values);
      const { enrollName, enrollPhone, enrollDesc, chatId } = this;
      this.$api.applyChatTeam({
        chatId,
        enrollName,
        enrollPhone,
        enrollDesc,
        ...values
      }).then(res => {
        if(!res.flag) {
          // this.$toast('申请提交失败，请稍后重拾');
          return;
        }
        this.$toast('提交成功！')
        this.$router.go(-1);
      })
      .catch(error => {
        console.log('error=====>', error);
        // this.$toast('申请提交失败，请稍后重拾');
      })
    }
  },
};
</script>

<style lang="scss">
// @import "~assets/scss/commit.scss";
.page-container {
  padding-top: 2.7rem;
  // height: calc('100vh - 3rem');
  // background: #f6f7fa;
  .form-bar {
    height: 100%;
    
    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
