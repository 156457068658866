const data = [
  {
    activity: [
      {
        time: '9:00-11:00',
        address: '鲍文珍',
        content: '修补衣服',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/xiubuyifu.jpg',
        phone: '13735285531',
      },
      {
        time: '下午2:00-4:00',
        address: '王建军',
        content: '修自行车电瓶车',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/xiuzixingche.jpg',
        phone: '17369399759',
      },
      {
        time: '随时',
        address: '冯昌健',
        content: '疏通管道',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/shutongguandao.jpg',
        phone: '13095688586',
      },
      {
        time: '随时',
        address: '金求福',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13486532081',
      },
      {
        time: '随时',
        address: '施庆祥',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13957530104',
      },
      {
        time: '随时',
        address: '刘志刚',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15067513455',
      },
      {
        time: '随时',
        address: '王旭光',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '18367587345',
      },
      {
        time: '随时',
        address: '马文奇',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15925846993',
      },
      {
        time: '随时',
        address: '李小喜',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13989502952',
      },
      {
        time: '随时',
        address: '张叶明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15956249822',
      },
      {
        time: '随时',
        address: '潘大林',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13575552687',
      },
      {
        time: '随时',
        address: '罗文',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '17771587031',
      },
      {
        time: '随时',
        address: '应志明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13858453711',
      },
    ],
    service: '周一',
    weekday: '周一'
  },
  {
    activity: [
      {
        time: '9:00-11:00',
        address: '陈百富',
        content: '磨剪刀',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/mojiandao.jpg',
        phone: '13456565677',
      },
      {
        time: '下午2:00-4:00',
        address: '马利',
        content: '配钥匙',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/peiyaoshi.jpg',
        phone: '15967586669',
      },
      {
        time: '随时',
        address: '冯昌健',
        content: '疏通管道',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/shutongguandao.jpg',
        phone: '13095688586',
      },
      {
        time: '随时',
        address: '金求福',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13486532081',
      },
      {
        time: '随时',
        address: '施庆祥',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13957530104',
      },
      {
        time: '随时',
        address: '刘志刚',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15067513455',
      },
      {
        time: '随时',
        address: '王旭光',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '18367587345',
      },
      {
        time: '随时',
        address: '马文奇',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15925846993',
      },
      {
        time: '随时',
        address: '李小喜',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13989502952',
      },
      {
        time: '随时',
        address: '张叶明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15956249822',
      },
      {
        time: '随时',
        address: '潘大林',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13575552687',
      },
      {
        time: '随时',
        address: '罗文',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '17771587031',
      },
      {
        time: '随时',
        address: '应志明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13858453711',
      },
    ],
    service: '周二',
    weekday: '周二'
  },
  {
    activity: [
      {
        time: '9:00-11:00',
        address: '欧阳恩庭',
        content: '修鞋',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/xiuxie.jpg',
        phone: '15167033356',
      },
      {
        time: '下午2:00-4:00',
        address: '王梓',
        content: '理发',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/haircut.jpg',
        phone: '8403539',
      },
      {
        time: '随时',
        address: '冯昌健',
        content: '疏通管道',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/shutongguandao.jpg',
        phone: '13095688586',
      },
      {
        time: '随时',
        address: '金求福',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13486532081',
      },
      {
        time: '随时',
        address: '施庆祥',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13957530104',
      },
      {
        time: '随时',
        address: '刘志刚',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15067513455',
      },
      {
        time: '随时',
        address: '王旭光',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '18367587345',
      },
      {
        time: '随时',
        address: '马文奇',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15925846993',
      },
      {
        time: '随时',
        address: '李小喜',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13989502952',
      },
      {
        time: '随时',
        address: '张叶明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15956249822',
      },
      {
        time: '随时',
        address: '潘大林',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13575552687',
      },
      {
        time: '随时',
        address: '罗文',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '17771587031',
      },
      {
        time: '随时',
        address: '应志明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13858453711',
      },
    ],
    service: '周三',
    weekday: '周三'
  },
  {
    activity: [
      {
        time: '9:00-11:00',
        address: '唐国君',
        content: '堵漏、泥工',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/dulounigong.jpg',
        phone: '18248642009',
      },
      {
        time: '下午2:00-4:00',
        address: '沈建刚',
        content: '美术教育咨询',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/artEducationConsult.jpg',
        phone: '13567209373',
      },
      {
        time: '随时',
        address: '冯昌健',
        content: '疏通管道',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/shutongguandao.jpg',
        phone: '13095688586',
      },
      {
        time: '随时',
        address: '金求福',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13486532081',
      },
      {
        time: '随时',
        address: '施庆祥',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13957530104',
      },
      {
        time: '随时',
        address: '刘志刚',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15067513455',
      },
      {
        time: '随时',
        address: '王旭光',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '18367587345',
      },
      {
        time: '随时',
        address: '马文奇',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15925846993',
      },
      {
        time: '随时',
        address: '李小喜',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13989502952',
      },
      {
        time: '随时',
        address: '张叶明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15956249822',
      },
      {
        time: '随时',
        address: '潘大林',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13575552687',
      },
      {
        time: '随时',
        address: '罗文',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '17771587031',
      },
      {
        time: '随时',
        address: '应志明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13858453711',
      },
    ],
    service: '周四',
    weekday: '周四'
  },
  {
    activity: [
      
      {
        time: '随时',
        address: '冯昌健',
        content: '疏通管道',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/shutongguandao.jpg',
        phone: '13095688586',
      },
      {
        time: '随时',
        address: '金求福',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13486532081',
      },
      {
        time: '随时',
        address: '施庆祥',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13957530104',
      },
      {
        time: '随时',
        address: '刘志刚',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15067513455',
      },
      {
        time: '随时',
        address: '王旭光',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '18367587345',
      },
      {
        time: '随时',
        address: '马文奇',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15925846993',
      },
      {
        time: '随时',
        address: '李小喜',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13989502952',
      },
      {
        time: '随时',
        address: '张叶明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15956249822',
      },
      {
        time: '随时',
        address: '潘大林',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13575552687',
      },
      {
        time: '随时',
        address: '罗文',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '17771587031',
      },
      {
        time: '随时',
        address: '应志明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13858453711',
      },
    ],
    service: '周五',
    weekday: '周五'
  },
  {
    activity: [
      
      {
        time: '随时',
        address: '冯昌健',
        content: '疏通管道',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/shutongguandao.jpg',
        phone: '13095688586',
      },
      {
        time: '随时',
        address: '金求福',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13486532081',
      },
      {
        time: '随时',
        address: '施庆祥',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13957530104',
      },
      {
        time: '随时',
        address: '刘志刚',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15067513455',
      },
      {
        time: '随时',
        address: '王旭光',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '18367587345',
      },
      {
        time: '随时',
        address: '马文奇',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15925846993',
      },
      {
        time: '随时',
        address: '李小喜',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13989502952',
      },
      {
        time: '随时',
        address: '张叶明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15956249822',
      },
      {
        time: '随时',
        address: '潘大林',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13575552687',
      },
      {
        time: '随时',
        address: '罗文',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '17771587031',
      },
      {
        time: '随时',
        address: '应志明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13858453711',
      },
    ],
    service: '周六',
    weekday: '周六'
  },
  {
    activity: [
      
      {
        time: '随时',
        address: '冯昌健',
        content: '疏通管道',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/shutongguandao.jpg',
        phone: '13095688586',
      },
      {
        time: '随时',
        address: '金求福',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13486532081',
      },
      {
        time: '随时',
        address: '施庆祥',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13957530104',
      },
      {
        time: '随时',
        address: '刘志刚',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15067513455',
      },
      {
        time: '随时',
        address: '王旭光',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '18367587345',
      },
      {
        time: '随时',
        address: '马文奇',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15925846993',
      },
      {
        time: '随时',
        address: '李小喜',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13989502952',
      },
      {
        time: '随时',
        address: '张叶明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '15956249822',
      },
      {
        time: '随时',
        address: '潘大林',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13575552687',
      },
      {
        time: '随时',
        address: '罗文',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '17771587031',
      },
      {
        time: '随时',
        address: '应志明',
        content: '公共维修',
        picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/gonggongweixiu.jpg',
        phone: '13858453711',
      },
    ],
    service: '周日',
    weekday: '周日'
  },
];

export default data;
