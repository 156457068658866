<template>
  <div class="suggestion-container">
    <van-nav-bar
      title="我对组织有话说"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="suggestions-body-container">
      <div class="image-bar">
        我对组织有话说
      </div>
      <van-form @submit="onSubmit">
        <div class="section" style="padding: 0.8rem;">
          <div class="title"><span class="required">*</span>留言</div>
          <van-field
            size="large"
            v-model="remark"
            rows="3"
            autosize
            name="content"
            type="textarea"
            maxlength="200"
            placeholder="请填写你对组织的建议"
            show-word-limit 
            :rules="[{ required: true, message: '' }]"
          />
        </div>
        <div class="section" style="padding: 0.8rem">
          <div class="title">添加图片</div>
          <van-uploader
            v-model="imageList"
            name="imageList"
            multiple
            :max-count="5"
          />
        </div>
        <div class="footer-button">
          <van-button
            block
            type="info"
            style="font-size: 16px"
            native-type="submit"
            >立即提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Api from 'api';
import commonApi from 'api/common';
import { mapMutations, mapState } from "vuex";
import { Toast } from 'vant';
import context from '@/main';
export default {
  data() {
    return {
      remark: '',
      imageList: [],
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit(values) {
      const { phone = '' } = context.getSessionData('userInfo') || {};
      console.log('values', values, phone, this.imageList);
      if(this.imageList && this.imageList.length > 0) {
       
        const formData = new FormData();
        this.imageList.map((item) => formData.append("files", item.file));
        commonApi.uploadMultiFile(formData).then((res) => {
          const params = {
            ...values,
            phone,
            photoDTOList: res.data.split('#').map(item => ({ image: item }))
          };
          Api.addSomethingSay(params).then((res) => {
            const { message,data, flag } = res;
            if (flag) {
              Toast('提交成功！');
              const timer = setTimeout(() => {
                this.$router.go(-1);
                clearTimeout(timer);
              }, 1000);
            } else {
              Toast(message);
            }
          });
        });
      } else {
        Api.addSomethingSay({
          ...values,
            phone,
            photoDTOList: []
        }).then((res) => {
            const { message,data, flag } = res;
            if (flag) {
              Toast('提交成功！');
              const timer = setTimeout(() => {
                this.$router.go(-1);
                clearTimeout(timer);
              }, 1000);
            } else {
              Toast(message);
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.suggestion-container {
  height: 100vh;
  width: 100vw;
  .suggestions-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 92px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/suggestion/bar.png') no-repeat;
      background-size: 100% 100%;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 500;
      text-align: center;
      line-height: 92px;
    }
    .section {
      width: 100%;
      margin-bottom: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
        .required {
          color: #e13f30;
        }
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
