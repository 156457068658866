<template>
  <div class="list-container">
    <van-dropdown-menu>
      <van-dropdown-item v-model="status"  @change="handleChange($event,'status')" :options="option1" />
      <van-dropdown-item v-model="sortField" @change="handleChange($event,'sortField')" :options="option2" />
    </van-dropdown-menu>
    <van-list :finished="finished" finished-text="没有更多了" @load="onLoad">
      <ul class="detail-img-box-two">
        <li v-for="item in list" :key="item.activityId">
          <div class="img" @click="onQuestionClick(item)">
            <div class="img-top">
              <img :src="item.image" />
              <div class="img-status" :style="{background: colors[item.status].background,color: colors[item.status].text}"><p class="img-text">{{ item.activityStatus }}</p></div>
            </div>
            <div class="img-title">{{ item.title }}</div>
            <div class="img-des">
              <span>
                已有
                <span class="blue">{{ item.number }}</span>人报名
              </span>
            </div>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import wyslApi from "api/llyjr/wysl";
import { List } from "vant";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      page: 0,
      size: 10,
      total: 0,
      list: [],
      finished: false,
      status: '',
      sortField: '',
      option1: [
        { text: '全部活动', value: '' },
        { text: '报名中', value: 0 },
        { text: '进行中', value: 1 },
        { text: '已结束', value: 2 },
      ],
      option2: [
        { text: '默认排序', value: '' },
        { text: '最近开始', value: 'start_time' },
        { text: '报名人数高', value: 'number' },
      ],
      colors: {
        '0': { text: '#4479ED', background: '#E3EFFD' },
        '1': { text: '#E5B20A', background: '#FFF6D9' },
        '2': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
        '4': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
    };
  },
  props: {
    activityType: {
      type: String,
      default: function() {
        
        return '党建活动';
      }
    },
  },
  components: {},
  mounted() {},
  methods: {
    ...mapMutations(["setQuestionInfo"]),
    // 0、报名中 1、进行中 2、已结束
    getList({ page = this.page, size = this.size } = {}) {
      wyslApi
        .getGamePage({
          page,
          size,
          object: {
            activityType: this.activityType,
            status: this.status,
            sortField: this.sortField,
            sort: 'desc'
          }
        })
        .then(res => {
          const { list = [], total = 0 } = res.data || {};
          if(this.page === 1) {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          this.total = total;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    handleChange(e, type) {
      this[type] = e;
      this.getList();
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    onQuestionClick(item) {
      this.gotoLink("huodongzhongxinDetail?key="+item.activityId);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-img-box-two {
  height: auto !important;
  margin: 1rem 0;
  width: 100%;
  li {
    display: inline-flex;
    align-items: center;
    width: 50% !important;
    height: auto !important;
    // position: relative;
    // overflow: hidden;
    // .tag {
    //     position: absolute;
    //     top: 0;
    //     right: 0.5rem;
    //     padding: 0.2rem 0.5rem;
    //     border-radius: 4px;
    //     font-size: 12px;
    // }
    .img {
      height: auto !important;
      width: 100% !important;
      text-align: center;
      img {
        width: auto !important;
        height: auto !important;
        max-height: 10rem;
        max-width: 100%;
      }
      .img-top {
        margin: 0 0.6rem;
        position: relative;
        overflow: hidden;
      }
      .img-status {
        width: 20rem;
        height: 6rem;
        max-width: 50%;
        background-color: #fff;
        transform: rotate(45deg);
        position: absolute;
        right: -3rem;
        top: -3.1rem;
      }
      .img-text {
        width: 20rem;
        height: 5.5rem;
        padding: 0;
        margin: 0;
        display:table-cell;      /*按照单元格的样式显示元素*/
        vertical-align:bottom;   /*底对齐*/
      }
      .img-title {
        text-align: left;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.2rem;
        font-size: 1rem;
        font-weight: bold;
        padding: 0.5rem 0.6rem 0 0.6rem;
      }
      .img-des {
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.2rem;
        font-size: 0.8rem;
        padding: 0.6rem;
        display: flex;
        align-items: center;

        .blue {
          color: #4479ed;
          margin: 0 2px;
        }
        img {
          width: 1rem !important;
          height: 1rem !important;
          margin-right: 0.2rem;
          padding: 0;
        }
        img:nth-child(3) {
          margin-left: 0.6rem;
        }
      }
    }
  }
}
</style>
