import service from "utils/service";

const wjysApi = {
  getEventList: (params) => {
    return service.get("/dataProvide/getWjys", {params});
  },
  getMeetList: params => {
    return service.post('/nightMeet/list', params);
  }, // 查询议事
  addMeet: params => {
    return service.post('/nightMeet/addMeet', params);
  }, // 新增议事
  getAddressList: params => {
    return service.get('/common/getAllDictByType', { params });
  }, // 议事地点列表
  cancelMeet: params => {
    return service.get('/nightMeet/cancelMeet', { params });
  }, // 删除或撤回议事
  getMeetCount: params => {
    return service.get('/nightMeet/meetCount', { params });
  }, // 议事统计
  meetSign: params => {
    return service.get('/nightMeet/sign', { params });
  }, // 签到
};
export default wjysApi;
