<template>
  <div class="micro-energy-container">
    <van-nav-bar title="环物委介绍" left-arrow @click-left="goBack" />
    <div class="container">
      <!-- <div class="iframe-nav1">
        <iframe id="myiframe" scrolling="auto" frameborder="0" width="100%" height="100%" src="http://ddsq.ikeqiao.net:8071/demo/index.html#/communtiy" ></iframe>
      </div> -->
      <div class="comm-main">
        <div class="commtit">
          <div class="statistic-item">
            <div class="statistic-number">17</div>
            <!-- <div class="statistic-number">3467</div> -->
            <div class="statistic-label">上报数</div>
          </div>
          <div class="divider1"></div>
          <div class="statistic-item">
            <!-- <div class="statistic-number">456</div> -->
            <div class="statistic-number">17</div>
            <div class="statistic-label">已处理</div>
          </div>
          <div class="divider1"></div>
          <div class="statistic-item">
            <div class="statistic-number">0</div>
            <!-- <div class="statistic-number">3000</div> -->
            <div class="statistic-label">处理中</div>
          </div>
          <div class="divider1"></div>
          <div class="statistic-item">
            <div class="statistic-number">12</div>
            <!-- <div class="statistic-number">234</div> -->
            <div class="statistic-label">参与人数</div>
          </div>
        </div>
        <div class="comminfo">
          <p>
            2018年8月9日，在街道领导的指导带领下，社区成立了环境与物业管理委员会，这是“环物委”在诸暨市乃至绍兴市的首批试点之一。
          </p>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hwf-pic4.jpg" />
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hwf-pic2.jpg" />
        </div>
        <div class="comm-btn">
          <el-button @click="gotoLink('eventList')"
            >典型事件</el-button
          >
          <el-button type="primary" @click="gotoLink('eventAdd')"
            >我要上报</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
  },
};
</script>

<style lang="scss" scope>
.micro-energy-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .container {
    height: calc(100% - 46px);
    width: 100vw;
    background: url(http://dssq.ikeqiao.net/data/image/h5/images/butler/bottom.png) no-repeat bottom;
    background-size: 100% 6.2rem;
    overflow-y: auto;
    overflow-x: hidden;
    .comm-main {
      .commtit {
        text-align: center;
        width: 100%;
        padding: 1rem 0rem;
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/butler/pulse-bg1.png) no-repeat;
        background-size: 100% 100%;
        font: bold 1.2rem/4rem "Microsoft Yahei";
        height: 6rem;
        color: #fff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .statistic-item {
          text-align: center;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          .statistic-number {
            font-size: 24px;
            color: #fff;
            line-height: 22px;
            font-weight: 500;
          }
          .statistic-label {
            font-size: 12px;
            color: #fff;
            line-height: 30px;
          }
        }
        .divider1 {
          height: 3rem;
          width: 2px;
          // background-color: rgba(68,121,237,0.50);
          background: #fff;
        }
      }
      .comminfo {
        height: 70vh;
        margin: 0 auto;
        //width: 23.4rem;
        overflow-y: auto;
        p {
          text-indent: 2em;
          color: #333;
          font: 500 0.9rem/1.6rem "Microsoft Yahei";
          margin: 0.5rem 0.8rem;
        }
        img {
          width: 100%;
        }
      }
    }
    .comm-btn {
      padding: 1rem 0;
      text-align: center;
      .el-button {
        width: 35vw;
        font-weight: bold;
      }
    }
  }
}
</style>
