<template>
  <div :class="$style.newPartyBuildingContainer">
    <van-nav-bar
      title="东盛党建"
      left-arrow
      @click-left="onClickLeft"
    />
    <div :class="$style.bodyContainer">
      <!-- imageBar -->
      <div :class="$style.imageBar" />
      <!-- cardList -->
      <div :class="$style.cardList">
        <div :class="$style.card" @click="gotoPath(item.path)" v-for="item in cardList" :key="item.title" :style="{ background: `url(${imageUrl}${item.backgroundKey}.png)`, 'background-size': '100% 100%' }">
          <p :class="$style.flex">
            <span :class="$style.titleText">{{item.value}}</span>
            <span>{{item.unit}}</span>
          </p>
          <p :class="$style.flex">
            <span :class="$style.smallText">{{item.title}}</span>
            <img v-if="item.active" height="12px" :src="`${imageUrl}right-arrow.svg`" alt="">
          </p>
        </div>
         <!-- <div :class="$style.cardLong">
          <p :class="$style.flex">
            <span :class="$style.titleText">1211</span>
            <span>个</span>
          </p>
          <p :class="$style.flex">
            <span :class="$style.smallText">居民党员</span>
          </p>
        </div> -->
      </div>
      <!-- roundImageBar -->
      <img :class="$style.roundImageBar" :src="`${imageUrl}zljg-bar-bg.png`" alt="" @click="$router.push('/governanceStructure')">
      <!-- articleBar -->
      <div :class="$style.articleBar">
        <div :class="$style.articleBarHeader">
          <div :class="$style.tabWrapper">
            <div 
              v-for="item in tabList"
              :key="item.key"
              :class="[$style.tabItem, articleType === item.key ? $style.selectedTabItem : '' ]"
              @click="handleTabChange(item.key)"
            >
              {{item.title}}
            </div>
          </div>
          <div :class="$style.moreIcon" @click="handleArticleMore">
            <span>更多</span>
            <img :class="$style.moreIconImage" src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt="">
          </div>
        </div>
        <div :class="$style.articleList">
          <van-list
            v-model="loading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <section
              :class="$style.articleCard"
              v-for="item in dataList"
              :key="item.id"
              @click="handleArticleClick(item)"
            >
              <!-- <div :class="$style.artcleCard"> -->
                <div :class="$style.cardInfo">
                  <h1 :class="$style.cardInfoTitle">{{item.title || ''}}</h1>
                  <p v-html="item.summary" :class="['van-ellipsis', $style.articleDescription]"></p>
                  <div :class="$style.infoBar">
                    <span :class="$style.infoItem">
                      <i :class="$style.date"></i>
                      {{item.publishTime || item.createTime}}
                    </span>
                    <span :class="$style.infoItem">
                      <i :class="$style.view"></i>
                      {{item.visitsUp || 0}}
                    </span>
                  </div>
                </div>
                <img :src="item.cover || item.image" :class="$style.cardImage" alt="">
              <!-- </div> -->
            </section>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import wyslApi from "api/llyjr/wysl";
import hongApi from 'api/partyBuilding/hong';
import { Toast, Dialog } from 'vant';
import { mapMutations } from 'vuex';
export default {
  name: 'new-party-building',
  data() {
    return {
      imageUrl: 'http://dssq.ikeqiao.net/data/image/dongsheng/partyBuilding/',
      articleType: 1,
      dataList: [],
      current: 1,
      pageSize: 10,
      loading: false,
      listFinished: false,
      tabList: [
        {
          title: '党建资讯',
          key: 1
        }, {
          title: '党建活动',
          key: 2
        }
      ],
      cardList: [
        {
          title: '党总支',
          // value: '1',
          backgroundKey: 'dzz-bg',
          // unit: '个',
          path: '/zljgPeople?key=98&name=党总支',
          active: true
        }, {
          title: '小区党支部',
          // value: 9,
          backgroundKey: 'dzb-bg',
          path: '/zljgGs?key=99&name=小区党支部',
          // unit: '个',
          active: true
        },
        {
          title: '功能性党组织',
          // value: 3,
          backgroundKey: 'gnxdzz-bg',
          // unit: '个',
          path: '/zljgGs?key=97&name=功能性党组织',
          active: true
        }, {
          title: '楼道党小组',
          // value: 40,
          backgroundKey: 'dxz-bg',
          path: '/zljgGs?key=96&name=红色楼道长小组',
          // unit: '个',
          active: true
        },
        {
          title: '在册党员',
          // value: 72,
          backgroundKey: 'zcdy-bg',
          // unit: '人'
        }, {
          title: '机关在职党员',
          // value: 656,
          backgroundKey: 'zzdy-bg',
          path: '/zljgYwh?key=100&name=机关在职党员',
          // unit: '人',
          active: true
        }, {
          title: '居民党员',
          // value: 0,
          backgroundKey: 'jmdy-bg',
          // unit: '人',
          active: false
        },
      ]
    };
  },
  computed: {
  },
  components: {  },
  mounted() {
    homeApi.getNewPartyBuildingNumbers().then(res => {
      const { community: { voList = [] } = {} } = res.data || {};
      this.cardList = this.cardList.map((item, index) => ({ ...item, ...voList[index]}));

      console.log(this.cardList);
    })
  },
  watch: {},
  methods: {
    ...mapMutations(['setDataInfo']),
    onClickLeft() {
      this.$router.go(-1)
    },
    onLoad() {
      this.getData({ page: this.current });
      this.current += 1;
    },
    gotoPath(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    async getData({
      page = this.current, 
      pageSize = this.pageSize,
      articleType = this.articleType
    } = {} ) {
      let res = {};
      let data = [];
      let total = 0;
      if (articleType === 1) {
        res = await homeApi.getArticleList({
          page,
          pageSize,
          type: 12,
          columnType: '2',
        });
        const { records = [], total: resTotal = 0 } = res.data || {};
        data = records;
        total = resTotal;
      } else {
        res = await wyslApi.getGamePage({
          page,
          size: pageSize,
          object: {
            activityType: '党建活动',
            status: '',
            sortField: '',
            sort: 'desc'
          }
        });
        const { list = [], total: resTotal = 0 } = res.data || {};
        data = list;
        total = resTotal;
        
      }
      this.loading = false;
      if(page === 1) {
        this.dataList = data;
      } else {
        this.dataList = Array.from(new Set(this.dataList.concat(data)))
      }
      this.total = total;
      if (this.dataList.length >= total) {
        // 数据全部加载完成
        this.listFinished = true;
      }
    },
    handleTabChange(value) {
      this.articleType = value;
      this.current = 1;
      this.getData({ page: 1, articleType: value });
    },
    handleArticleClick(data) {
      if(this.articleType === 1) {
        this.setDataInfo(data);
        if (data.contentType === 1) {
          window.location.href = data.content;
        } else {
          this.$router.push(`/zxzxDetail`);
        }
      } else {
        this.$router.push(`/huodongzhongxinDetail?key=${data.activityId}`);
      }
    },
    handleArticleMore() {
      if (this.articleType === 1) {
        this.$router.push('/moreArticleList?type=2&name=党建资讯')
      } else {
        this.$router.push('/partyActive')
      }
    }
  },
};
</script>

<style lang="scss" module>
.newPartyBuildingContainer {
  height: 100vh;
  width: 100vw;
  background: #F6F7FA;
  .flex {
    display: flex;
    align-items: center;
  }
  .titleText {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6rem;
    margin-right: 6px;
  }
  .smallText {
    font-size: 0.8rem;
    font-weight: 500;
    margin-right: 6px;
  }
  .bodyContainer {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;

    .imageBar {
      width: 100%;
      height: 10.67rem;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/hong/hong-bar.png") no-repeat;
      background-size: 100% 100%;
    }

    .cardList {
      width: 100%;
      background: #FFF;
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .card, .cardLong {
        min-width: 7.4rem;
        flex: 1;
        margin: 0 2px 8px;
        height: 4.8rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        // align-items: flex-start;
        padding-left: 1.6rem;
        // margin-bottom: 8px;
        color: #FFFFFF;
      }
      .cardLong {
        width: 100%;
        background: url('http://dssq.ikeqiao.net/data/image/dongsheng/partyBuilding/jmdy-bg.png') no-repeat;
        background-size: 100% 100%;
        align-items: flex-start;
        padding-left: 1.6rem;
      }
    }

    .roundImageBar {
      margin: 12px;
      width: calc(100% - 24px);
    }

    .articleBar {
      margin: 0 12px 12px;
      width: calc(100% - 24px);
      background: #FFFFFF;
      padding: 12px 6px;
      border-radius: 4px;
      .articleBarHeader {
        width: 100%;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        .tabWrapper {
          display: flex;
          .tabItem {
            font-size: 1.06rem;
            color: rgba(0,0,0,0.85);
            margin: 0 6px 6px;
            font-weight: 500;
            line-height: 1.8rem;
          }
          .selectedTabItem {
            font-size: 1.2rem;
            position: relative;
            &::after {
              content: '';
              display: block;
              position: absolute;
              bottom: -4px;
              width: 2.4rem;
              height: 4px;
              background: #3E71F9;
              border-radius: 10px;
              transform: translate(50%);
            }
          }
        }
        .moreIcon {
          color: rgba(0,0,0,0.45);
          width: 20%;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          line-height: 0.9rem;
          .moreIconImage {
            height: 0.8rem;
            margin-left: 2px;
          }
        }
      }
      .articleList {
        
        .articleCard {
          display: flex;
          margin: 10px 6px;
          .cardInfo {
            flex: 1;
            .cardInfoTitle {
              font-size: 1.06rem;
              font-weight: 500;
              color: rgba(0,0,0,0.85);
              line-height: 1.3rem;
            }
            .infoBar {
              width: 100%;
              display: flex;
              position: relative;
              align-items: center;
              height: 48px;
              .infoItem {
                display: flex;
                align-items: center;
                margin-right: 10px;
                font-size: 14px;
                color: rgba(0,0,0,0.45);
                >i {
                  width: 16px;
                  height: 16px;
                  margin-right: 2px;
                }
                .date {
                  background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/date.png) no-repeat;
                  background-size: 100% 100%;
                }
                .view {
                  background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/view.png) no-repeat;
                  background-size: 100% 100%;
                }
              }
              .infoTag {
                position: absolute;
                right: 12px;
                width: 70px;
                height: 24px;
                background: rgba(252,235,234,0.9);
                font-size: 14px;
                color: #E13F30;
                line-height: 24px;
                text-align: center;
                border-radius: 8px 2px 8px 2px;
              }
            }
          }
          .cardImage {
            width: 8rem;
            height: 5.34rem;
            margin-left: 6px;
          }
        }
      }
    }
    
    
  }
}
</style>
