<template>
  <div id="index">
    <div class="page-container" style="height: 100vh; padding-top: 0px !important; margin-top: 0px !important;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>需求详情</h1>
        <span v-if="dataInfo.my === 'yes'" @click="handleClaim('已删除')" class="delete">删除需求</span>
      </div>
      <div class="nrb-box">
        <div class="reply-detail">
          <div class="reply-info">
            <img :src="dataInfo.avatarUrl || 'http://dssq.ikeqiao.net/data/image/h5/images2/sdb-banner.jpg'" />
            <div>{{dataInfo.needName}}<br /><span>{{`发布于${dataInfo.issueTime}`}}</span></div>
          </div>
          <div class="tit">{{dataInfo.title}}</div>
          <p>
            {{dataInfo.content}}
          </p>
          <div class="img">
            <img v-for="(item, index) in (dataInfo.image ? dataInfo.image.split('#') : [])" :key="index" :src="item || 'http://dssq.ikeqiao.net/data/image/h5/images2/sdb-banner.jpg'" />
          </div>
          <div class="state"><span>{{dataInfo.className}}</span></div>
        </div>
        <div>
          <div class="main-table">
            认领情况
          </div>
          <div class="nr-list">
            <ul>
              <li v-for="item in dataInfo.enrollList" :key="item.infoId">
                <img :src="item.avatarUrl" />
                <div>
                  <p>{{item.nickname}}<i>{{item.enrollStatus}}</i></p>
                  <span>{{`发布于${item.enrollTime}`}}</span>
                </div>
                <span v-if="dataInfo.my === 'yes'" class="contact" @click="callPhone(item.phone)">联系ta</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <van-button
        v-if="status === '已解决'"
        type="info"
        class="button"
        color="#C5CBD2"
      >
        需求已解决
      </van-button>
      <van-button
        v-else-if="status === '已删除'"
        type="info"
        class="button"
        color="#C5CBD2"
      >
        需求已删除
      </van-button>
      <div class="det" v-else-if="dataInfo.my === 'yes'">
        <van-button
          v-if="status === '已认领'"
          type="" 
          class="button" 
          color="" 
          @click="handleClaim('未认领')"
        >
          开启认领
        </van-button>
        <van-button
          v-if="status === '未认领'"
          class="button red-btn"
          @click="handleClaim('已认领')"
        >
          关闭认领
        </van-button>
        <van-button
          v-if="status !== '已删除'"
          type=""
          class="button"
          color="#4479ED"
          @click="handleClaim('已解决')"
        >
          需求已解决
        </van-button>
      </div>
      <div v-else> 
        <van-button
          v-if="dataInfo.hasEnroll === '可认领'"
          type="info"
          plain
          class="button"
          color="#4479ED"
          @click="addClaim(dataInfo.abilityId)"
        >
          认领需求
        </van-button>
        <van-button
          v-if="dataInfo.hasEnroll === '已认领'"
          type="info"
          class="button"
          color="#4479ED"
          @click="cancelClaim(dataInfo.abilityId)"
        >
          取消认领
        </van-button>
        
        <van-button
          v-if="status === '已认领'"
          type="info"
          class="button"
          color="#C5CBD2"
        >
          需求已被认领
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import nrbApi from "api/llyjr/nrb";
import { mapMutations } from "vuex";
import { List, Toast } from "vant";

export default {
  data() {
    return {
      dataInfo: {},
      status: ''
    };
  },
  mounted() {
    const { id = '' } = this.$route.query;
    this.getDataInfo({ abilityId: id });
  },
  methods: {
    ...mapMutations(["setQuestionInfo"]),
    gotoLink(path) {
      this.$router.push(path);
    },
    callPhone(phone) {
      if(phone) {
        window.location.href = `tel://${phone}`;
      }
    },
    getDataInfo(params) {
      nrbApi
        .getNrbDetail(params)
        .then((res) => {
          this.dataInfo = res.data || {};
          this.status = res.data.issueStatus || '';
        });
    },
    // 取消认领
    cancelClaim(id) {
      nrbApi.cancelEnroll({ abilityId: id }).then(res => {
        if(res.flag) {
        Toast.success('取消认领成功');
        this.getDataInfo({ abilityId: id});
        }
      })
    },
    // 认领需求
    addClaim(id) {
      nrbApi.addEnroll({ abilityId: id }).then(res => {
        if(res.flag) {
        Toast.success('认领成功');
        this.getDataInfo({ abilityId: id});
        }
      })
    },
    handleClaim(data) {
      
      const { abilityId = '' } = this.dataInfo;
      nrbApi.updateNrbInfo({abilityId, issueStatus: data}).then(res => {
        if(res.flag) {
          Toast.success('操作成功');
          const timer = setTimeout(() => {
            if(data === '已删除') {
              this.$router.go(-1);
            } else {
              this.getDataInfo({ abilityId });
            }
            clearTimeout(timer);
          }, 1000);
          
        }
      })
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleService() {
      this.$store.dispatch("login/checkRealName", () => {
        this.gotoLink("nrbPublishService");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  // padding: 0.8rem 0rem;
  background: #fff;
  .nrb-box {
    margin-top: 2.8rem;
    .van-dropdown-menu__bar {
      height: 3rem;
      .van-dropdown-menu__title {
        font-size: 0.9rem;
        line-height: 3rem;
      }
    }
  }
  .main-table {
    padding: 0 0 0 0.5rem;
    border-left: 0.2rem solid #3291f8;
    text-align: left;
    color: #101010;
    width: 23.4rem;
    margin: 0.3rem auto;
    font: 600 1rem/1.2rem "microsoft yahei";
  }
  .reply-detail {
    width: 23.4rem;
    margin: 0 auto;
    padding: 1.5rem 0;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    .tit {
      font-weight: 600;
      color: #333;
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
    p {
      color: #7a7a7a;
    }
    .img {
      margin-top: 0.4rem;
      img {
        border-radius: 0.3rem;
        height: 5.4rem;
        width: 5.4rem;
        margin-right: 0.4rem;
      }
    }
    .reply-info {
      margin-bottom: 0.4rem;
      position: relative;
      height: 2rem;
      img {
        width: 2rem;
        height: 2rem;
        float: left;
        border-radius: 50%;
        margin-right: 0.5rem;
        vertical-align: middle;
      }
      div {
        float: left;
        font: 500 0.8rem/1rem "microsoft yahei";
        color: #333;
        span {
          color: #bbb;
        }
      }
    }
    .state {
      padding-top: 0.4rem;
      span {
        display: inline-block;
        height: 1.4rem;
        padding: 0 0.7rem;
        line-height: 1.4rem;
        border-radius: 0.2rem;
        font-style: inherit;
        font-size: 0.8rem;
        background: #e8e8e8;
        color: #101010;
      }
    }
  }
  .nr-list {
    width: 23.4rem;
    margin: 0 auto;
    li {
      padding: 0.6rem 0;
      height: 3.6rem;
      img {
        width: 2rem;
        height: 2rem;
        float: left;
        border-radius: 50%;
        margin-right: 0.5rem;
        vertical-align: middle;
        margin-top: 0.2rem;
      }
      div {
        float: left;
        font: 500 0.8rem/1rem "microsoft yahei";
        color: #333;
        i {
          display: inline-block;
          height: 1.4rem;
          padding: 0 0.7rem;
          line-height: 1.4rem;
          border-radius: 0.2rem;
          font-style: inherit;
          font-size: 0.7rem;
          background: #e8e8e8;
          color: #101010;
          margin-left: 0.6rem;
        }
        span {
          color: #bbb;
        }
      }
      .contact {
        float: right;
        height: 2rem;
        width: 5rem;
        text-align: center;
        line-height: 2rem;
        border-radius: 1rem;
        font-style: inherit;
        font-size: 0.8rem;
        background: #fff;
        color: #3291f8;
        border: 1px solid #3291f8;
      }
    }
  }
  .button {
    position: fixed;
    left: 5vw;
    bottom: 1rem;
    width: 90vw;
    height: 3rem;
    font-size: 1rem;
    border-radius: 1.5rem;
    z-index: 999999;
    
  }
  .det {
    position: fixed;
    left: 0vw;
    bottom: 1rem;
    .button {
      position: initial;
      display: inline-block;
      width: 40vw;
      height: 3rem;
      font-size: 1rem;
      border-radius: 1.5rem;
      margin: 0 5vw;
      border: 0;
      z-index: 999999;
    }
    .red-btn {
      border: 1px solid #ff0000;
      color: #ff0000;
      background: #fff;
    }
    .gray-btn {
      color: #FFFFFF;
      background: #7a7a7a;
    }
  }
}
.van-button::before {
  height: 0;
}
</style>
