<template>
  <div class="activity-need-list-con">
    <div
      class="item"
      v-for="(item, i) in dataList || []"
      :key="i"
      @click="gotoLink('activityNeedDetail?key=' + i)"
    >
      <div class="itemName">
        <div class="image">
          <img :src="item.image" alt="" />
        </div>
        <div class="text">
          <div class="title">
            {{ item.userName }}
            <span class="tag">{{ item.tag }}</span>
          </div>
          <div>{{ `发布时间：${item.createTime}` }}</div>
        </div>
      </div>
      <div class="itemDesc">
        {{ item.commentContent }}
      </div>
      <div class="image-list-con">
        <img
          v-for="(item, index) in item.images"
          :src="item"
          :key="index"
          alt=""
        />
      </div>
      <!-- <div class="divider"></div> -->
      <div class="footer">
        <div
          :style="{
            color: item.status === '已抢单' ? 'rgba(0,0,0,0.45)' : '#4479ED',
          }"
        >
          {{
            item.status === "已抢单"
              ? `${item.status}：${item.depart}`
              : item.status
          }}
        </div>
        <div
          v-if="item.status === '已抢单'"
          :style="{
            color:
              item.result === '联盟待接单' ? 'rgba(0,0,0,0.45)' : '#4479ED',
          }"
        >
          {{ item.result }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "activityNeedList",
  data() {
    return {};
  },
  props: {
    dataList: {
      type: Array,
      default: function() {
        return [
          {
            image: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png",
            userName: "张三",
            createTime: "2021-05-21 12:00",
            commentContent:
              "半云未来社区9幢2单元急需加装电梯，老年人上楼很不方便希望能安装电梯。半云未来社",
            hasThumbsUp: "未点赞",
            thumbsUp: 23,
            tag: "党员",
            images: [
              "http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png",
              "http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png",
              "http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png",
            ],
            status: "已抢单",
            depart: "润泽党支部",
            result: "联盟待接单",
          },
          {
            image: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png",
            userName: "张三",
            createTime: "2021-05-21 12:00",
            commentContent:
              "半云未来社区9幢2单元急需加装电梯，老年人上楼很不方便希望能安装电梯。半云未来社",
            hasThumbsUp: "已点赞",
            thumbsUp: 23,
            tag: "党员",
            images: [
              "http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png",
              "http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png",
              "http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png",
            ],
            status: "未抢单",
            depart: "润泽党支部",
            result: "联盟待接单",
          },
          {
            image: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png",
            userName: "张三",
            createTime: "2021-05-21 12:00",
            commentContent:
              "半云未来社区9幢2单元急需加装电梯，老年人上楼很不方便希望能安装电梯。半云未来社",
            hasThumbsUp: "已点赞",
            thumbsUp: 23,
            tag: "党员",
            images: [
              "http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png",
              "http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png",
              "http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png",
            ],
            status: "已抢单",
            depart: "润泽党支部",
            result: "联盟已接单",
          },
        ];
      },
    },
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === "我的发布" ? "yes" : "no";
    },
  },
  mounted() {},
  methods: {
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.activity-need-list-con {
  width: 100%;
  margin: 0 auto;
  border: none;
  padding: 10px;
  .item {
    position: relative;
    padding: 10px;
    min-height: 242px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    .itemName {
      display: flex;
      height: 50px;
      padding: 5px;
      .image {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .text {
        width: calc(100% - 50px);
        height: 100%;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        .title {
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          font-weight: 500;
          .tag {
            background: #e3effd;
            border-radius: 2px;
            font-size: 12px;
            color: #4479ed;
            padding: 3px 6px;
          }
        }
      }
    }
    .itemDesc {
      width: 100%;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
      font-size: 16px;
      margin-top: 10px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .image-list-con {
      width: 100%;
      // padding: 10px;
      display: flex;
      flex-wrap: wrap;
      > img {
        width: 56px;
        height: 56px;
        border-radius: 4px;
        margin: 5px;
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background-color: rgba(17, 31, 44, 0.1);
      margin: 10px 0;
    }
    .footer {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      line-height: 50px;
    }
  }
}
</style>
