<template>
  <div id="index">
    <div class="page-container">
      <!-- <div class="header-nav">
        <i @click="goBack"></i>
        <h1>物业评价</h1>
      </div> -->
      <div class="iframe-nav">
        <iframe id="myiframe" scrolling="auto" frameborder="0" width="100%" height="100%" :src="myLink" ></iframe>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      myLink: '',
    };
  },
  components: {},
  mounted() {
    const { link } = this.$route.query;
    this.myLink = link;
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
