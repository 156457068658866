<template>
  <div id="jystj-index">
    <div class="zyya-page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>金钥匙调解</h1>
      </div>
      <div class="blue-background">
        <img style="width: 100vw;" src="http://dssq.ikeqiao.net/data/image/dongsheng/jystj.jpg" />
        <!-- 志愿有爱 -->
        <div class="huigong-bar">
          <div :class="['huigong-bar-title', { opened: show }]">
            <div class="decoration-title">
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-l.png" alt="">
              <h1>金钥匙调解队</h1>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-r.png" alt="">
            </div>
            <p>{{ desc }}</p>
            <div class="organization-button" @click="show = !show">
              <span>{{ show ? "收起" : "展开" }}</span>
              <img
                src="http://dssq.ikeqiao.net/data/image/h5/images/hong/arrow.png"
                :class="['organization-arrow', { rollover: show }]"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 风采展示 -->
        <div class="hgxt-card-bar" style="background: #FFF;">
          <div class="card-bar-title">
            <span class="text-title">队员展示</span>
             <!-- <span class="text-more" @click="gotoLink('kghList')">
              更多
              <van-icon name="arrow" color="#bfbfbf" size="14px" />
            </span> -->
          </div>
          <div class="card-bar-content">
            <ul>
              <li class="fl-item" v-for="(item, index) in activityList" :key="index" @click="openArticleUrl(item)">
                <div class="fl-title">
                  <img v-if="item.image" :src="item.image" alt="">
                  <div>
                    <h1>{{item.name}}</h1>
                    <div class="jystj-fl-desc">
                      <span style="margin-right: 10px;">职务：{{item.job}}</span>
                    </div>
                    <div class="jystj-fl-desc">
                      <span style="margin-right: 10px;">{{item.introduce}}</span>
                    </div>
                  </div>
                </div>
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <van-button
        type="info"
        class="button"
        color="#4479ED"
        @click="gotoLink('jystjdNeedHelp')"
      >
        我要调解
      </van-button>
    </div>
  </div>
</template>

<script>
import jystjApi from 'api/jystj';
export default {
  data() {
    return { 
      show: false,
      desc: '浣东街道东盛社区发扬“枫桥经验”，延伸进小区，建立起了完整的小区治理体系，形成"193工作模式”（“1”个中心，“9”个小区微调室，“3”个部门联动），推动小区矛盾纠纷调解工作的开展，做到小事不出小区，大事不出社区。这9个小区微调室，综合称之为“金钥匙调解队”。“金钥匙调解队”于2021年6月由浣东街道东盛社区发起成立，目前有队员15人，其中12人是党员，他们来自不同的小区，从事不同的行业，他们充分发挥自身特长，在社区开展、参与各项矛盾调解，利用人熟、地熟、情况熟的优势，行走在社区矛盾纠纷调解的第一线，及时化解小区邻里纠纷、物业纠纷等问题，确保各类矛盾纠纷高效处理，营造祥和的社区氛围，促进社区和谐发展与文明建设。',
      list: [
        // {
        //   id: 0,
        //   title: "【瓜渚专栏】领跑竞跑 志愿启航 | 最美的遇见，从“柯工慧”开始",
        //   update: "2021-03-02",
        //   view: 3,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/kgh/zyya-bg.jpg",
        //   url: 'https://mp.weixin.qq.com/s/eWu6doQKrHcYMUQaMBfBRw'
        // },
        // {
        //   id: 1,
        //   title: "【瓜渚专栏】“我”将成为“你”，志愿会延续 | 三五学雷锋，工青志愿行",
        //   update: "2021-03-03",
        //   view: 3,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/kgh/zyya2.jpg",
        //   url: 'https://mp.weixin.qq.com/s/MLJqs2gOhBdSW_NXoYLu5A'
        // },
      ],
      activityList: [
        // {
        //   imgUrl: "http://dssq.ikeqiao.net/data/image/jsznt.jpeg",
        //   title: "王方柱",
        //   dep: "君越二区",
        //   desc: '从小事做起，从身边做起，从点滴做起，贡献社会，服务大家',
        // },
        // {
        //   imgUrl: "http://dssq.ikeqiao.net/data/image/lsznt.jpeg",
        //   title: "周铁勇",
        //   dep: "阳光家园",
        //   desc: '从小事做起，从身边做起，从点滴做起，贡献社会，服务大家',
        // },
        // {
        //   imgUrl: "http://dssq.ikeqiao.net/data/image/ylznt.jpeg",
        //   title: "俞欲娟",
        //   dep: "君越二区",
        //   desc: '从小事做起，从身边做起，从点滴做起，贡献社会，服务大家',
        // },
      ]
    };
  },
  components: {},
  mounted() {
    this.getDataList();
  },
  methods: {
    gotoLink(path) {
      this.$router.replace(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    openUrl(url) {
      if(url) {
        window.location.href = url;
      }
    },
    getDataList() {
      jystjApi.getList({})
      .then((res) => {
        this.activityList = res.data || [];
      })
      .catch((err) => {
        console.log('error====>', err);
      });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.zyya-page-container{
  .button {
    position: fixed;
    left: 10vw;
    bottom: 1.0667rem;

    width: 80vw;
    height: 48px;
    font: 20px;
    border-radius: 6px;
  }

}
.blue-background {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 0;
  height: 100vh;
  .huigong-bar-title {
    width: calc(100% - 30px);
    // background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/organization-bg.png) no-repeat;
    // background-size: 100% 100%;
    background: #FFF;
    border-radius: 4px;
    padding: calc(2rem - 15px);
    color: rgba(0,0,0,0.85);
    margin: -50px 15px 15px 15px;
    position: relative;
    z-index: 2;
    .decoration-title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 10px;
      > h1 {
        font-size: 18px;
        color: #4479ED;
        font-weight: 600;
        margin: 0 10px;
      }
      >img {
        height: 14px;
      }
    }
    
    > p {
      width: calc(100vw - 4rem);
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0;
      max-height: 60px;
      min-height: 60px;
      margin-bottom: 10px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .organization-button {
      margin: 0px auto;
      width: 60px;
      height: 24px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 12px;
      color: #4479ed;
      line-height: 24px;
      text-align: center;
      font-size: 14px;
      > img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .opened {
    > p {
      max-height: 100vh;
    }
    .organization-button {
      > img {
        transform: rotate(180deg);
      }
    }
  }
  .hgxt-card-bar {
    width: 100%;
    margin: 10px 0;
    padding: 6px 0;
    .card-bar-title {
      width: 100%;
      height: 33px;
      padding: 10px;
      background: #fff;
      .text-title {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        font-weight: 500;
        width: 50%;
      }
      .text-more {
        color: #bfbfbf;
        font-size: 14px;
        float: right;
        display: flex;
        align-items: center;
      }
    }
    .card-bar-content {
      width: 100%;
      padding-bottom: 60px;
      .fl-item {
        margin: 10px 10px 0 10px;
        padding: 10px 0;
        .fl-title {
          display: flex;
          align-items: flex-start;
          >img {
            width: 7rem;
            margin-left: 10px;
            margin-right: 10px;
          }
          >div {
            flex: 1;
            >h1 {
              font-size: 16px;
              line-height: 32px;
              color: rgba(0,0,0,0.85);
              font-weight: 500;
            }
            .jystj-fl-desc {
              display: flex;
              min-height: 24px;
              align-items: center;
              font-size: 12px;
              line-height: 16px;
              color: rgba(0,0,0,0.45);
              .time, .view {
                width: 16px;
                height: 16px;
                display: inline-block;
                background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/time.png) no-repeat;
                background-size: 100% 100%;
              }
              .view {
                background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/view.png) no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          
        }
        
      }
    }
    .hgxt-activity-list {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 10px;
      background-color: #FFF;
      .hgxt-activity-card {
        width: 252px !important;
        border-radius: 4px;
        margin-right: 10px;
        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
        .hgxt-activity-image {
          width: 100%;
          height: 87px;
        }
        .hgxt-activity-title-1 {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 10px;
          >h1 {
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            letter-spacing: 0;
            font-weight: 500;
          }
          >span {
            font-size: 12px;
            color: rgba(0,0,0,0.65);
            >b {
              color: #4479ed;
            }
          }
        }
        .hgxt-activity-text {
          font-size: 14px;
          line-height: 24px;
          color: rgba(0,0,0,0.85);
          padding: 0 10px;
          .text-label {
            color: rgba(0,0,0,0.45);
          }
        }
      }
    }
  }
  
}
</style>
