<template>
  <div class="sourceStore-container">
    <van-nav-bar
      title="入党积极分子培训"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="train-body-container">
      <div class="tab-container class-source-con">
        <div class="class-source-top">
          <div class="top-left">
            <div
              @click="category = '视频'"
              :class="['top-left-btn', { selected: category === '视频' }]"
            >
              视频
            </div>
            <div
              @click="category = '文章'"
              :class="['top-left-btn', { selected: category === '文章' }]"
            >
              文章
            </div>
            <div
              @click="category = '活动'"
              :class="['top-left-btn', { selected: category === '活动' }]"
            >
              活动
            </div>
          </div>
        </div>
        <div class="class-source-list">
          <van-list
            v-model="loading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
            class="class-list"
          >
            <ul>
              <li v-for="(item, index) in dataList" :key="index">
                <div class="left">
                  <img :src="item.picture" alt="" />
                  <i class="play" v-if="category === '视频'"></i>
                </div>
                <div class="right">
                  <div class="tit">{{ item.title }}</div>
                  <div class="foot-info">
                    <i class="tag1">{{ item.tag }}</i>
                    <div v-if="category === '视频'">
                      <span>{{ item.num }}</span
                      >次播放
                    </div>
                    <div v-if="category === '文章'">
                      <span>{{ item.num }}</span
                      >次浏览
                    </div>
                    <div v-if="category === '活动'">
                      <span>{{ item.num }}</span
                      >次查看
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import nrbApi from "api/llyjr/nrb";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      category: "视频", //党课资源类型
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    };
  },
  props: {
    type: {
      type: String,
      default: function() {
        return "video";
      },
    },
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === "我的发布" ? "yes" : "no";
    },
  },
  components: {},
  mounted() {
    this.getDataList();
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onLoad() {
      this.getDataList({ page: this.current });
      this.current += 1;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList({ page = this.page, size = this.size } = {}) {
      const { status = "", category = "" } = this;
      this.loading = false;
      this.dataList = new Array(4).fill({
        title: "奋勇前进 不负人民-习近平总书记“七一”重要讲话精神解读",
        tag: "党章党规",
        num: 342,
        picture: "http://dssq.ikeqiao.net/data/image/h5/images/coordination/videoDefault.png",
      });
      this.total = 4;
      this.listFinished = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sourceStore-container {
  height: 100vh;
  width: 100vw;

  .train-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .tab-container {
      height: calc(100vh - 2.7rem - 78px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin: 10px;
    }

    .class-source-con {
      margin: 10px;
      width: calc(100% - 20px);
      border-radius: 4px;
      background-color: #fff;
      padding: 10px;
      .class-source-top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top-left {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          .top-left-btn {
            width: 48px;
            height: 28px;
            background: #ededed;
            border-radius: 4px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 28px;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
          }
          .selected {
            background: #e3effd;
            color: #4479ed;
          }
        }
        .top-right {
          width: 30%;
          box-shadow: none;
          ::v-deep .van-dropdown-menu__bar {
            box-shadow: none;
          }
        }
      }
    }
    .class-list {
      width: 100%;
      margin: 0 auto;
      padding: 0.6rem 0;
      background: #fff;
      border: none;
      li {
        padding: 0.3rem 0;
        // border-bottom: 1px solid #ddd;
        font: 500 0.9rem/1.4rem "microsoft yahei";
        display: flex;
        .left {
          width: 140px;
          height: 86px;
          margin-right: 10px;
          position: relative;
          img {
            width: 140px;
            height: 86px;
          }
          .play {
            display: inline-block;
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/play.png) no-repeat;
            background-size: 100%;
            position: absolute;
            left: 58px;
            top: 31px;
            width: 24px;
            height: 24px;
          }
        }
        .right {
          flex: 1;
          .tit {
            font-weight: 600;
            color: #333;
            font-size: 16px;
            margin-bottom: 0.3rem;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .date {
            color: #999;
          }
          p {
            color: #7a7a7a;
          }

          .foot-info {
            margin-bottom: 0.4rem;
            position: relative;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              width: 2rem;
              height: 2rem;
              float: left;
              border-radius: 50%;
              margin-right: 0.5rem;
              vertical-align: middle;
            }
            div {
              float: left;
              font: 500 0.8rem/1rem "microsoft yahei";
              color: rgba(0, 0, 0, 0.45);
              span {
                color: #4479ed;
              }
            }
            i {
              display: inline-block;
              padding: 2px 4px;
              margin: 4px 0;
              border-radius: 4px;
              font-style: inherit;
              font-size: 0.7rem;
              color: #e13f30;
            }
            .tag1 {
              background: #fcebea;
            }
            .tag2 {
              background: #f8b432;
            }
            .tag3 {
              background: #c5cbd2;
            }
          }
          .state {
            padding-top: 0.4rem;
            span {
              display: inline-block;
              height: 1.4rem;
              padding: 0 0.7rem;
              line-height: 1.4rem;
              border-radius: 0.2rem;
              font-style: inherit;
              font-size: 0.8rem;
              background: #e8e8e8;
              color: #101010;
            }
            i {
              display: inline-block;
              vertical-align: middle;
              width: 1rem;
              height: 1rem;
              margin-right: 0.3rem;
              background: url(http://dssq.ikeqiao.net/data/image/h5/images2/icon-zan.png) no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
}
</style>
