<template>
  <div class="statistic-container">
    <van-nav-bar
      title="数据统计"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="statistic-body-container">
      <!-- 今日统计 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">今日统计</span>
          </div>
        </div>
        <div class="card-bar-content">
          <van-cell v-for="item in todayList" :key="item.communityName" :title="item.communityName" :value="item.register" />
        </div>
      </div>
      <!-- 累计 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">累计数据</span>
          </div>
        </div>
        <div class="card-bar-content">
          <van-cell v-for="item in statisticList" :key="item.communityName" :title="item.communityName" :value="item.register" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      todayList: [],
      statisticList: []
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    api.getTodayList({}).then(res => {
      if(res.flag) {
        this.todayList = res.data;
      }
    });
    api.getStatisticList({}).then(res => {
      if(res.flag) {
        this.statisticList = res.data;
      }
    })
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.statistic-container {
  height: 100vh;
  width: 100vw;
  
  .statistic-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      .card-bar-title {
        background-color: #FFF;
        width: 100%;
        // height: 2rem;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-bar {
            height: 18px;
            width: 4px;
            background: #4479ED;
            margin-right: 10px;
          }
          .title-text {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
          }
          .more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
          i {
            color: #4479ED;
          }
        }
      }
      .card-bar-content {
        width: 100%;
        
        
      }
    }

  }
}
</style>
