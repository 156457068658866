<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>借用工具</h1>
      </div>
      <div class="search-bar">
        <div class="input-bar" @click="showPicker">
          {{toolPlace}}
          <van-icon name="arrow-down" size="14px" />
        </div>
        <div style="flex: 1;">
          <van-search
            style="height: 1.8rem;"
            v-model="toolDescribe"
            placeholder="搜索"
            shape="round"
            @search="onSearch"
          />
        </div>
      </div>
      <van-list :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="list">
          <div
            v-for="item in list"
            :key="item.invitationId"
            class="card-con"
            @click="gotoDetail(item)"
          >
            <img :src="item.invitationContent || baseImg" class="image" />
            <div>
              <div class="card-title">
                <span>{{item.invitationTitle}}</span>
              </div>
              <div class="row">
                <span>数量：{{item.toolNumber}}</span>
              </div>
              <!-- <div class="row">
                <span>位置：{{item.toolPlace}}</span>
              </div> -->
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup v-model="showArea" position="bottom" :style="{ height: '30%', width: '100%' }">
      <van-picker
        title
        show-toolbar
        :columns="communityList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import gjxApi from "api/gjx";
import commonApi from "api/common";
import { Toast } from "vant";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      page: 0,
      size: 10,
      total: 0,
      list: [],
      finished: false,
      communityList: [],
      showArea: false,
      toolPlace: "全部小区",
      baseImg: require("../../assets/images/logo.png"),
      toolDescribe: ""
    };
  },
  components: {},
  mounted() {
    commonApi.getCommunityList().then(res => {
      this.communityList = ['全部小区', ...res.data];
    });
  },
  methods: {
    ...mapMutations(["setQuestionInfo"]),
    getList({
      page = this.page,
      size = this.size,
      toolPlace = this.toolPlace === "全部小区" ? "" : this.toolPlace,
      toolDescribe = this.toolDescribe
    } = {}) {
      gjxApi
        .getToolCasePage({
          page,
          size,
          object: {
            rentTool: true,
            toolPlace,
            toolDescribe
          }
        })
        .then(res => {
          const { records = [], total = 0 } = res.data || {};
          this.list = this.list.concat(records);
          this.total = total;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    gotoDetail(item) {
      this["setQuestionInfo"](item);
      this.gotoLink(`gjxxq?invitationId=${item.invitationId}`);
    },
    goBack() {
      this.$router.go(-1);
    },
    onSearch(value) {
      this.page = 1;
      this.list = [];
      this.getList();
    },
    onConfirm(value) {
      this.toolPlace = value;
      this.showArea = false;
      this.page = 1;
      this.list = [];
      this.getList();
    },
    onCancel() {
      this.showArea = false;
    },
    showPicker() {
      this.showArea = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  padding: 0.6rem;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  overflow-y: auto;
  .search-bar {
    padding: 0 0.8rem;
    display: flex;
    align-items: center;
    .input-bar {
      min-width: 7rem;
      background: #f4f5f8;
      border-radius: 18px;
      padding: 0.1rem 1rem;
      line-height: 1.8rem;
      height: 2rem;
      font-size: 14px;
      text-align: center;
    }
  }
  .list {
    width: 100%;
    .card-con {
      display: flex;
      align-items: flex-start;
      padding: 0.8rem;
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        // line-height: 1.6rem;
        height: 16px;

        .tag {
          background: #d5f4e2;
          border-radius: 4px 2px 8px 2px;
          font-size: 12px;
          color: #37d477;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 0.2rem 0.8rem;
          height: 1.2rem;
        }
      }
      .image {
        height: 4.6rem;
        border-radius: 2px;
        margin-right: 0.5rem;
        width: 7rem;
      }
      .row {
       
        font-size: 13px;
        line-height: 1.4rem;
        display: flex;
       
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        .label {
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 18px;
          margin-right: 0.6rem;
        }
      }
    }
  }
}
</style>
