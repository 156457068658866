<template>
  <div class="hui-study-container">
    <van-nav-bar
      class="hui-study-nav-bar"
      title="慧 学习"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="hui-study-body-container">
      
      <!-- 模块列表 -->
      <div v-for="bar in barList" :key="bar.title" class="coin-bar-con" @click="bar.toast ? showToast(bar.toast) : gotoLink(bar.path, bar.name)">
        <div :class="['coin-bar', bar.icon]">
          <h1>{{bar.title}}</h1>
          <!-- <h3>{{bar.desc}}</h3> -->
          <button>{{bar.btn}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      barList: [
        {
          title: '全龄幸福学堂',
          desc: '学堂一句话介绍',
          btn: '学习',
          icon: 'xingfu',
          path: 'qlxfxt',
          name: '学堂详情',
        },
        {
          title: '数字家长课堂',
          desc: '学堂一句话介绍学堂一句话介绍',
          btn: '学习',
          icon: 'jiazhang',
          path: 'szjzkt',
          name: '学堂详情',
        },
        {
          title: '老年常青课堂',
          desc: '学堂一句话介绍',
          btn: '学习',
          icon: 'changqing',
          path: 'lncqkt',
          name: '课堂详情',
        },
      ],
    };
  },
  computed: {
  },
  components: { },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path, name) {
      if(path) {
        this.$store.commit('study/setStudyItemSelect', name);
        this.$router.push(path);
      }
    },
    showToast(message) {
      this.$toast(message);
    }
  }
};
</script>

<style lang="scss" scoped>
.hui-study-container {
  height: 100vh;
  width: 100vw;
  .hui-study-nav-bar {
    background-image: linear-gradient(90deg, #3EBAF8 0%, #467EEA 100%);
    ::v-deep .van-nav-bar__title {
      color: white;
    }
    ::v-deep .van-icon {
      color: white;
    }
  }
  .hui-study-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .coin-bar-con {
      display: flex;
      .coin-bar {
        width: 100%;
        margin: 10px;
        height: 121px;
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/service/map-bar.png') no-repeat;
        background-size: 100% 100%;
        padding: 10px 20px;
        color: #FFF;
        border-radius: 4px;
        >h1 {
          font-size: 20px;
          letter-spacing: 0;
          line-height: 42px;
          text-shadow: 0 2px 4px rgba(0,0,0,0.13);
          font-weight: 600;
        }
        >h3 {
          font-size: 14px;
          letter-spacing: 0.92px;
          text-align: left;
          font-weight: 200;
        }
        >button {
          border: 0.5px solid #FFFFFF;
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.07);
          border-radius: 14px;
          padding: 2px 8px;
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: center;
          background: none;
          margin-top: 10px;
        }
      }
      .lexue {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/lexue.png') no-repeat;
        background-size: 100% 100%;
      }
      .jiazhang {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/jiazhang.png') no-repeat;
        background-size: 100% 100%;
      }
      .xingfu {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/xingfu.png') no-repeat;
        background-size: 100% 100%;
      }
      .changqing {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/changqing.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    

    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      .card-bar-title {
        width: 100%;
        height: 33px;
        padding: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .text-title {
          font-size: 16px;
          color: #4479ED;
          width: 50%;
          font-weight: 500;
          .hot {
            width: 21px;
            height: 12px;
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/service/hot.png) no-repeat;
            background-size: 100% 100%;
            display: inline-block;
            margin-left: 5px;
          }
        }
        .text-more {
          color: rgba(0,0,0,0.45);
          font-size: 14px;
          float: right;
          display: flex;
          align-items: center;
        }
      }
      .card-bar-content {
        width: 100%;
        
        
      }
    }
  }
}
</style>
