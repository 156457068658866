<template>
  <div class="catalog-container">
    <div class="top-search-nav">
      <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/catalog_logo.png" alt="场景应用">
      <van-search @search="handleSearch" @clear="handleClear" class="search-bar" v-model="name" placeholder="请输入功能名称" />
    </div>
    <div class="banner-catalog">
      <h2>东盛社区</h2>
      <p>东盛社区场景应用介绍</p>
      <div />
    </div>
    <div class="catalog-content">
      <van-sidebar
        style="width: 6rem;" 
        @change="handleSide"
        v-model="activeKey"
      >
        <van-sidebar-item style="width: 6rem;" v-for="item in sideBar" :key="item" :title="item" />
      </van-sidebar>
      <div class="side-content">
        <div v-for="(item, i) in (subTitle.length > 0 ? subTitle : [selectedCatalog])" :key="i">
        <h3>{{subTitle.length > 0 ? item : selectedCatalog}}</h3>
          <div class="all-nav">
            <ul class="clearfix">
              <li v-for="item in menuList.filter(v => v.env === item || subTitle.length === 0)" :key="item.name" @click="gotoLink(item)">
                <img v-if="autoList.indexOf(item.name) === -1" :src="item.picture" alt="">
                <span v-if="autoList.indexOf(item.name) === -1">{{item.name}}</span>
                <wx-open-launch-weapp
                  v-if="item.name ==='诸事帮'"
                  id="launch-btn1"
                  username="gh_f220c14e6658"
                >
                  <script type="text/wxtag-template">
                    <img style="height: 2.8rem;width: 2.8rem;" src="http://dssq.ikeqiao.net/data/image/moudle/zsb.png" alt="">
                    <span style="font-size:12px;color:rgba(0,0,0,0.65);margin-top:5px;text-align:center;display: block;">{{item.name}}</span>
                  </script>
                </wx-open-launch-weapp>
                <wx-open-launch-weapp
                  v-if="item.name ==='杜黄新村'"
                  id="launch-btn2"
                  username="gh_3eafbda3e6d2"
                >
                  <script type="text/wxtag-template">
                    <img style="height: 2.8rem;width: 2.8rem;" src="http://dssq.ikeqiao.net/data/image/moudle/dhxc.png" alt="">
                    <span style="font-size:12px;color:rgba(0,0,0,0.65);margin-top:5px;text-align:center;display: block;">{{item.name}}</span>
                  </script>
                </wx-open-launch-weapp>
                <wx-open-launch-weapp
                  v-if="item.name ==='诸事我呼'"
                  id="launch-btn3"
                  username="gh_ad7430e14ef2"
                >
                  <script type="text/wxtag-template">
                    <img style="height: 2.8rem;width: 2.8rem;" src="http://dssq.ikeqiao.net/data/image/moudle/zswh.png" alt="">
                    <span style="font-size:12px;color:rgba(0,0,0,0.65);margin-top:5px;text-align:center;display: block;">{{item.name}}</span>
                  </script>
                </wx-open-launch-weapp>
                <wx-open-launch-weapp
                  v-if="item.name ==='鲸宝宝'"
                  id="launch-btn6"
                  username="gh_48990ec66a10"
                >
                  <script type="text/wxtag-template">
                    <img style="height: 2.8rem;width: 2.8rem;" src="http://dssq.ikeqiao.net/data/image/module/jingbaobao.png" alt="">
                    <span style="font-size:12px;color:rgba(0,0,0,0.65);margin-top:5px;text-align:center;display: block;">{{item.name}}</span>
                  </script>
                </wx-open-launch-weapp>
                <wx-open-launch-weapp
                  v-if="item.name ==='智慧步道'"
                  id="launch-btn4"
                  username="gh_043383071eca"
                >
                  <script type="text/wxtag-template">
                    <img style="height: 2.8rem;width: 2.8rem;" src="http://dssq.ikeqiao.net/data/image/moudle/jkbd.png" alt="">
                    <span style="font-size:12px;color:rgba(0,0,0,0.65);margin-top:5px;text-align:center;display: block;">{{item.name}}</span>
                  </script>
                </wx-open-launch-weapp>
                <wx-open-launch-weapp
                  v-if="item.name ==='金融小店'"
                  id="launch-btn5"
                  username="gh_7e6a1d0132bf"
                >
                  <script type="text/wxtag-template">
                    <img style="height: 2.8rem;width: 2.8rem;" src="http://dssq.ikeqiao.net/data/image/module/jrxd2.png" alt="">
                    <span style="font-size:12px;color:rgba(0,0,0,0.65);margin-top:5px;text-align:center;display: block;">{{item.name}}</span>
                  </script>
                </wx-open-launch-weapp>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <ul>
        <li class="catalogS">
          <a href=""><i></i>
            <p>场景</p>
          </a>
        </li>
        <li class="home">
          <a @click="gotoNormlLink('indexCopy')"><i></i>
            <p>长乐东盛</p>
          </a>
        </li>
        <li class="mine">
          <a @click="gotoNormlLink('userInfo')"><i></i>
            <p>我的</p>
          </a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import { openUrl } from '@/utils/index';
import { init } from 'utils/common'
export default {
  data() {
    return {
      activeKey: 0,
      name: '',
      autoList: ['诸事帮', '杜黄新村', '诸事我呼', '智慧步道', '金融小店', '鲸宝宝'],
    };
  },
  computed: {
    ...mapState({
      selectedCatalog: (state) => state.catalog.selectedCatalog,
      menuList: (state) => state.catalog.menuList,
      sideBar: (state) => state.catalog.sideBar,
      subTitle: (state) => state.catalog.subTitle,
    }),
  },
  components: {},
  mounted() {
    if(!this.selectedCatalog) {
      const { type } = this.$route.query;
      homeApi.getCatalogSide({}).then(res => {
        if(res.flag) {
          const result = res.data;
          this.$store.commit('catalog/setSideBar',result);
          this.$store.commit('catalog/setSelectedCatalog', type || result[0]);
          this.getData({type: type || result[0]});
          if(type) {
            const index = result.findIndex(item => item === type);
            this.activeKey = index > 0 ? index : 0;
          }
        }
      })
    } else {

      this.activeKey = this.sideBar.findIndex((item) => item === this.selectedCatalog);
    }
    init();
  },
  watch: {
   
  },
  methods: {
    gotoLink(data) {
      if(data.link) {
        this.$router.push(data.link);
      } else if(data.url) {
        this.router(data.url)
      } else if(this.autoList.indexOf(data.name) > -1){
        console.log(data);
      } else {
        this.$dialog.alert({message: '系统建设中，敬请期待!'})
      }
    },
    gotoNormlLink(path) {
      if(path) {
        this.$router.push(path)
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleSide(data) {
      console.log('sideChanged',data,this.activeKey);
      this.$store.commit('catalog/setSelectedCatalog', this.sideBar[data]);
      this.getData({type: this.sideBar[data]})
    },
    handleSearch(value) {
      this.getData({name: value, type: ''});
      this.$store.commit('setName', value);
    },
    handleClear() {
      this.$store.commit('setName', '');
      this.getData();
    },
    getData(params) {
      console.log('params', params);
      
      this.$store.dispatch('catalog/getCatalogData', params);
    },
    router(vote_url) {
      openUrl(vote_url);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/index_copy.scss";
.catalog-container {
  height: 100vh;
  width: 100vw;
  background: #FFF;
  .top-search-nav {
    height: 4rem;
    display: flex;
    > img {
      height: 2.2rem;
      width: 2.2rem;
      margin: 10px 0 0 10px;
    }
    .search-bar {
      border-radius: 10px;
      flex: 1;
      .van-search__content {
        border-radius: 20px;
      }
    }
  }
  .banner-catalog {
    height: 10rem;
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/home/catalog_banner.jpg') no-repeat;
    background-size: 100% 100%;
    color: #FFF;
    padding-top: 2rem;
    padding-left: 20px;
    > h2, h3 {
      line-height: 32px;
    }
    > h3 {
      font-size: 13px;
    }
    > div {
      width: 2rem;
      height: 3px;
      background: #FFF;
      margin-top: 10px;
    }
  }
  .catalog-content {
    height: calc(100vh - 17.2rem);
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    .van-sidebar-item--select::before {
      background-color: #2e70ed;
    }
    .side-content {
      flex: 1;
      padding: 10px;
      overflow: scroll;
      > h3 {
        font-size: 16px;
      }
      .all-nav {
        background: #fff;
        margin-bottom: 0.8rem;
        padding: 0.8rem 0 0.8rem 0.8rem;
        ul {
          clear: both;
          padding: 0.5rem 0 0;
          display: flex;
          flex-wrap: wrap;
          li {
            // float: left;
            text-align: center;
            width: 4.2rem;
            margin: 0.5rem 0.7rem;
            img {
              display: inline-block;
              width: 2.8rem;
              height: 2.8rem;
            }
            span {
              display: block;
              font-size: 12px;
              color: rgba(0,0,0,0.65);
              text-align: center;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
