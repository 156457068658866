<template>
  <div class="main-container flex flex-col">
    <header class="flex">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <div class="title flex-1">邻里购</div>
      <van-icon />
    </header>
    <section class="flex-1">
      <div class="flex header">
        <div class="flex-1">
          <svg-icon icon-class="location" />
          <span class="location-text truncate">{{ location }}</span>
          <svg-icon icon-class="arrow-right" />
        </div>
        <div class="flex flex-col">
          <svg-icon icon-class="search" />
          <span>搜商品</span>
        </div>
        <div class="flex flex-col">
          <svg-icon icon-class="order" />
          <span>订单</span>
        </div>
      </div>
      <div class="banner">
        <van-swipe :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img src="~assets/images/llg/swipe.png" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="~assets/images/llg/swipe.png" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="~assets/images/llg/swipe.png" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="box">
        <van-grid :border="false">
          <van-grid-item
            v-for="menu in menuList"
            :key="menu.key || menu.id"
            :icon="menu.icon"
            :text="menu.text || menu.dictLabel"
            :to="`/llg/goodsList?type=${menu.dictValue - 1}`"
          />
        </van-grid>
      </div>
      <div class="box">
        <div class="flex box-title">
          <div>
            <img src="~assets/images/llg/one_buy.svg" alt="" />
          </div>
          <div @click="$router.push('/llg/goodsList?type=0')">
            更多
            <svg-icon icon-class="arrow-right" />
          </div>
        </div>
        <div class="flex one-buy-box">
          <div
            class="goods-item"
            v-for="item in grabShopList"
            :key="item.id"
            @click="$router.push(`/llg/goodsDetail?id=${item.id}`)"
          >
            <div class="goods-img">
              <img :src="item.image" alt="" />
            </div>
            <div class="goods-title truncate">
              {{item.name}}
            </div>
            <div class="goods-remark flex">
              <span>
                <span class="goods-price">{{`￥${item.price}`}}</span>
                /份
              </span>
              <img :src="shoppingCar" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex goods-box">
        <div
          class="goods-item"
          v-for="item in shopList"
          :key="item.id"
          @click="$router.push(`/llg/goodsDetail?id=${item.id}`)"
        >
          <div class="goods-img">
            <img :src="item.image" alt="" />
          </div>
          <div class="goods-title truncate">
            {{item.name}}
          </div>
          <div class="goods-remark flex">
            <span>
              <span class="goods-price">{{`￥${item.price}`}}</span>
              /份
            </span>
            <img :src="shoppingCar" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import llgApi from 'api/llg';
import haMiMelon from '@/assets/images/llg/hami_melon.png';
import shoppingCar from '@/assets/images/llg/shopping_car.svg';
export default {
  data() {
    return {
      location: '祥生君城1幢',
      menuList: [
        // {
        //   key: 1,
        //   icon: require('@/assets/images/llg/icon1.svg'),
        //   text: '时令水果',
        //   url: '/llg/goodsList?type=1',
        // },
        // {
        //   key: 2,
        //   icon: require('@/assets/images/llg/icon2.svg'),
        //   text: '新鲜蔬菜',
        //   url: '/llg/goodsList?type=2',
        // },
        // {
        //   key: 3,
        //   icon: require('@/assets/images/llg/icon3.svg'),
        //   text: '海鲜水产',
        //   url: '/llg/goodsList?type=3',
        // },
        // {
        //   key: 4,
        //   icon: require('@/assets/images/llg/icon4.svg'),
        //   text: '肉禽蛋品',
        //   url: '/llg/goodsList?type=4',
        // },
        // {
        //   key: 5,
        //   icon: require('@/assets/images/llg/icon5.svg'),
        //   text: '速食冷冻',
        //   url: '/llg/goodsList?type=5',
        // },
        // {
        //   key: 6,
        //   icon: require('@/assets/images/llg/icon6.svg'),
        //   text: '母婴保健',
        //   url: '/llg/goodsList?type=6',
        // },
        // {
        //   key: 7,
        //   icon: require('@/assets/images/llg/icon7.png'),
        //   text: '饮品酒类',
        //   url: '/llg/goodsList?type=7',
        // },
        {
          key: 8,
          icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/llg/icon8.svg',
          text: '更多分类',
          url: '/llg/goodsList?type=0',
          dictValue: 1,
        },
      ],
      haMiMelon,
      shoppingCar,
      shopList: [],
    };
  },
  computed: {
    grabShopList() {
      return this.shopList.filter(item => item.sellStatus)
    }
  },
  mounted() {
    this.getMenuList();
    this.getShopList();
  },
  methods: {
    getMenuList() {
      llgApi.getShopType().then(res => {
        if(res.flag) {
          this.menuList = res.data.map(item => ({ ...item, icon: `http://dssq.ikeqiao.net/data/image/dongsheng/llg/${item.dictLabel}.svg`})).concat(this.menuList)
        }
      })
    },
    getShopList() {
      llgApi.getShopList().then(res => {
        if(res.flag) {
          this.shopList = res.data;
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/llg.scss';
section {
  .banner {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
  }
  .van-swipe {
    height: 8.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .box {
    margin: 0 10px 10px 10px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    .box-title {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 2.7rem;
    }
  }
  .one-buy-box {
    overflow-x: auto;
  }
  .goods-box {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 10px 10px 10px;
  }
  .goods-item {
    width: 48%;
    // max-width: 9rem;
    padding: 10px;
    // padding-bottom: 20px;
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 10px;

    .goods-img {
      width: 7rem;
      margin: 0 auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .goods-title {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 0;
      font-weight: 600;
    }
    .goods-price {
      font-size: 16px;
      color: #ff6c2b;
    }
    .goods-remark {
      padding: 10px 0;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
</style>
