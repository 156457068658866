<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="news-list"
  >
    <ul>
      <li v-for="(item, index) in dataList" :key="index" @click="gotoLink('newsDetail?key='+index)">
        <div class="left">
          <div class="tit">{{item.title}}</div>
          <div class="reply-info">
            <span>{{`${item.num}浏览量`}}</span>
            <span>{{item.date}}</span>
          </div>
        </div>
        <div class="right">
          <img :src="item.picture" alt="">
        </div>
      </li>
    </ul>
  </van-list>
</template>
<script>
import nrbApi from "api/llyjr/nrb";
import { mapState } from 'vuex';
export default {
  name: 'newsList',
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    }
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList({ page = this.page, size = this.size } = {}) {
      const { status = '', category = '' } = this;
      this.loading = false;
      // nrbApi
      //   .getNrbList({
      //     page,
      //     size,
      //     object: {
      //       className: category,
      //       issueStatus: status,
      //       belongOwn: this.belongOwn,
      //     },
      //   })
      //   .then((res) => {
      //     console.log('res', res);
          
      //     this.loading = false;
      //     const { list = [], total = 0 } = res.data || {};
      //     if(page === 1) {
      //       this.dataList = list;
      //     } else {
      //       this.dataList = Array.from(new Set(this.dataList.concat(list)))
      //     }
      //     this.total = total;
      //     if (this.dataList.length >= this.total) {
      //       // 数据全部加载完成
      //       this.listFinished = true;
      //     }
      //   })
      //   .catch((err) => {
      //     console.log('error====>', err);
      //     this.loading = false;
      //   });
      this.dataList = new Array(4).fill({
          title: '深入学习贯彻党的十九大精神全面开创教育改革发展新局面',
          num: 342,
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/default.png',
          date: '2021-08-15'
        });
      this.total = 4;
      this.listFinished = true;
    },
  }
}
</script>
<style lang="scss" scoped>
.news-list {
  width: 100%;
  margin: 0 auto;
  li {
    padding: 0.3rem;
    // box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    border-bottom: 0.5px solid rgba($color: #000000, $alpha: 0.1);
    .right {
      width: 120px;
      height: 86px;
      margin-left: 10px;
      img {
        width: 120px;
        height: 86px;
      }
    }
    .left {
      flex: 1;
      height: 86px;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .tit {
        font-weight: 600;
        color: #333;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0.3rem;
      }
      .reply-info {
        span {
          color: #bbb;
          margin-right: 10px;
        }
        
      }
      .state {
        padding-top: 0.4rem;
        span {
          display: inline-block;
          height: 1.4rem;
          padding: 0 0.7rem;
          line-height: 1.4rem;
          border-radius: 0.2rem;
          font-style: inherit;
          font-size: 0.8rem;
          background: #e8e8e8;
          color: #101010;
        }
        i {
          display: inline-block;
          vertical-align: middle;
          width: 1rem;
          height: 1rem;
          margin-right: 0.3rem;
          background: url(http://dssq.ikeqiao.net/data/image/h5/images2/icon-zan.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>