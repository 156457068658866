<template>
  <div id="index">
    <div class="edu-container">
      <header class="eduheader-nav">
        <i @click="gotoLink('/')"></i>
        <h1>我要反馈</h1>
      </header>
      <div class="banner m-top">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/feedback-banner.png" />
      </div>
      <div class="feed-nav">
        <ul>
          <li><i class="icon1"></i><span>匿名举报</span></li>
          <li><i class="icon2"></i><span>投诉纠纷</span></li>
          <li><i class="icon3"></i><span>留言咨询</span></li>
        </ul>
      </div>
      <div class="feed-list">
        <div class="main-tab">
          <b><i>我的</i>反馈</b>
          <span>更多 &gt;</span>
        </div>
        <div class="bd">
          <ul>
            <li>
              <div class="desc">
                反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容
              </div>
              <div>
                <i class="tag1">已反馈</i><span>反馈时间：2021-6-1 10:00</span>
              </div>
            </li>
            <li>
              <div class="desc">
                反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容反馈内容
              </div>
              <div>
                <i class="tag2">未反馈</i><span>反馈时间：2021-6-1 10:00</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/educate.scss";
</style>
