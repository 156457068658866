<template>
  <div class="sourceStore-container">
    <van-nav-bar
      title="线上考证辅导"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="online-study-body-container">
      <van-tabs v-model="selected" color="#4479ED">
        <van-tab title="教师资格证" name="教师资格证">
          <div class="tab-container">
            <videoList :type="selected"></videoList>
          </div>
        </van-tab>
        <van-tab title="药师资格证" name="药师资格证">
          <div class="tab-container">
            <videoList :type="selected"></videoList>
          </div>
        </van-tab>
        <van-tab title="营养师资格证" name="营养师资格证">
          <div class="tab-container">
            <videoList :type="selected"></videoList>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import videoList from './videoList';
export default {
  data() {
    return {
      selected: '教师资格证'
    };
  },
  computed: {
  },
  components: { videoList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    }
  }
};
</script>

<style lang="scss" scoped>
.sourceStore-container {
  height: 100vh;
  width: 100vw;
  
  .online-study-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .tab-container {
      height: calc(100vh - 2.7rem - 78px);
      overflow-y: auto;
      overflow-x: hidden;
      margin: 10px;
    }

    .class-source-con {
      margin: 10px;
      width: calc(100% - 20px);
      border-radius: 4px;
      background-color: #FFF;
      padding: 10px;
      .class-source-top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .top-left {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          .top-left-btn {
            width: 48px;
            height: 28px;
            background: #EDEDED;
            border-radius: 4px;
            font-size: 14px;
            color: rgba(0,0,0,0.45);
            line-height: 28px;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
          }
          .selected {
            background: #E3EFFD;
            color: #4479ED;
          }
        }
        .top-right {
          width: 30%;
          box-shadow: none;
          ::v-deep .van-dropdown-menu__bar {
            box-shadow: none;
          }
        }
      }
    }
  }
}
</style>
