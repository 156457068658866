<template>
  <div class="payMoneyPage" style="height: 100vh; padding-top: 3.5rem">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>订单详情</h1>
    </div>
    <div class="payList">
      <div class="list-title">
        下单时间：{{activeData.createTime}}
      </div>
      <div class="list-title">
        取餐时间：{{activeData.takeMealTime}}
      </div>
      <div class="list-content">
        <div class="list-order-title">
          套餐 ｜ {{activeData.setMeal}}
        </div>
        <div class="list-order-text">
          <span>应付金额：</span>
          <span class="list-order-value">{{activeData.orderMoney}}</span>
        </div>
        <div class="list-order-text">
          <span>优惠金额：</span>
          <span class="list-order-value">0</span>
        </div>
        <div class="list-order-text">
          <span>实付金额：</span>
          <span class="list-order-value" style="color: #FF6C2B">{{activeData.payMoney}}</span>
        </div>
      </div>
      <div class="list-tips">
        <img src="../../assets/images/axst/pass.svg" alt="">
        <div class="list-tips-text">已预约成功，请到爱心食堂取餐或就餐。</div>
      </div>
    </div>
    <div class="foot-btn-axst">
      <div v-if="!isGet" @click="setOrderCancel">取消订单</div>
      <div v-if="!isGet" @click="getOrder">取餐</div>
      <div v-if="isGet" style="color: #ccc" disabled>取消订单</div>
      <div v-if="isGet">{{cancelText}}</div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import service from 'utils/service';
import lodash from 'lodash';
import { mapMutations } from 'vuex'
import axstApi from 'api/axst';
import moment from 'moment';
moment.locale('zh-cn', {
    months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
    weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY-MM-DD',
        LL: 'YYYY年MM月DD日',
        LLL: 'YYYY年MM月DD日Ah点mm分',
        LLLL: 'YYYY年MM月DD日ddddAh点mm分',
        l: 'YYYY-M-D',
        ll: 'YYYY年M月D日',
        lll: 'YYYY年M月D日 HH:mm',
        llll: 'YYYY年M月D日dddd HH:mm'
    },
    meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
    meridiemHour: function (hour, meridiem) {
        if (hour === 12) {
            hour = 0;
        }
        if (meridiem === '凌晨' || meridiem === '早上' ||
            meridiem === '上午') {
            return hour;
        } else if (meridiem === '下午' || meridiem === '晚上') {
            return hour + 12;
        } else {
            // '中午'
            return hour >= 11 ? hour : hour + 12;
        }
    },
    meridiem: function (hour, minute, isLower) {
        const hm = hour * 100 + minute;
        if (hm < 600) {
            return '凌晨';
        } else if (hm < 900) {
            return '早上';
        } else if (hm < 1130) {
            return '上午';
        } else if (hm < 1230) {
            return '中午';
        } else if (hm < 1800) {
            return '下午';
        } else {
            return '晚上';
        }
    },
    calendar: {
        sameDay: '[今天]LT',
        nextDay: '[明天]LT',
        nextWeek: '[下]ddddLT',
        lastDay: '[昨天]LT',
        lastWeek: '[上]ddddLT',
        sameElse: 'L'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
    ordinal: function (number, period) {
        switch (period) {
            case 'd':
            case 'D':
            case 'DDD':
                return number + '日';
            case 'M':
                return number + '月';
            case 'w':
            case 'W':
                return number + '周';
            default:
                return number;
        }
    },
    relativeTime: {
        future: '%s内',
        past: '%s前',
        s: '几秒',
        ss: '%d秒',
        m: '1分钟',
        mm: '%d分钟',
        h: '1小时',
        hh: '%d小时',
        d: '1天',
        dd: '%d天',
        M: '1个月',
        MM: '%d个月',
        y: '1年',
        yy: '%d年'
    },
    week: {
        // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
})
export default {
  data() {
    return {
      type: '',
      meet: '',
      momey: '',
      payList: [],
      count: 0,
      countMoney: 0,
      showCount: false,
      time: '',
      isGet: false,
      activeData: {},
      cancelText: '已取餐',
      today: '',
      toweek: '',
    };
  },
  components: {},
  mounted(opt) {
    const { type, money, meet, time, activeId = '' }  = this.$route.query;
    axstApi.neighborLoveCanteenOrderList().then(res => {
      const { data } = res;
      if (res.flag) {
        const list = data ? data.filter(v => `${v.id}` === `${activeId}` || activeId === '') : [];
        console.log('list', list);
        this.activeData = list && list.length > 0 ? list[list.length - 1] : {},
        console.log(data);
        if (this.activeData.orderStatus === 2) {
          this.isGet = true;
          this.cancelText = '已取餐';
        }
        if (this.activeData.orderStatus === 3) {
          this.isGet = true;
          this.cancelText = '已取消';
        }
      } else {
        this.activeData = {};
      }
    })
    this.type = type;
    this.momey = money;
    this.meet = meet;
    this.time = time;
    this.payList = this.$store.state.allOther.buyList;
      this.count = 0;
      this.countMoney = 0;
      this.payList.forEach(element => {
        this.count += element.count;
        if (element.count) {
          this.countMoney += element.momeny * element.count;
        }
      });
    this.today = moment().format('YYYY-MM-DD');
    this.toweek = moment().format('dddd');
  },
  methods: {
    getDate(date){
    const today = date? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    const  toweek = date? moment(date).format('dddd') : moment().format('dddd');
    return `${today}(${toweek})`
    },
    goBack() {
      this.$router.go(-1);
    },
    ydCancel(){
      this.$router.push(`/cancelOrder`)
      // Toast.success("预定成功！");
    },
    setOrderCancel(){
      axstApi.updateOrderStatus({
        id:  this.activeData.id,
        orderStatus: 3,
      }).then(res => {
        if (res.flag) {
          Toast.success('取消成功');
          this.$router.replace(`/axst`)
        }
      })
    },
    getOrder(){
      axstApi.updateOrderStatus({
        id:  this.activeData.id,
        orderStatus: 2,
      }).then(res => {
        if (res.flag) {
          Toast.success('取餐成功');
          this.isGet = true;
          // this.$router.replace(`/axst`)
        }
      })
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .payMoneyPage{
    height: 100% !important;
    position: relative;
    padding: 10px;
    padding-bottom: 50px;
    .list-title{
      font-size: 16px;
      font-weight: 400;
      color: rgba(0,0,0,0.85);
      line-height: 50px;
      padding: 0 10px;
      background: #fff;
      border-radius: 8px;
    }
    .list-content{
      padding: 10px;
      background: #fff;
      border-radius: 8px;
      .list-order-title{
        line-height: 30px;
        font-size: 18px;
        font-weight: 600;
      }
      .list-order-text{
        line-height: 30px;
        font-size: 16px;
        font-weight: 400;
        .list-order-value{
          font-weight: 600;
        }
      }
    }
    .list-tips{
      line-height: 30px;
      margin: 5px 0px;
      padding: 0px 10px;
      font-size: 16px;
      color: #1ECA55;
      letter-spacing: 0;
      font-weight: 400;
      display: flex;
      >img{
        width: 24px;
        height: 24px;
        margin: 3px;
        margin-right: 10px;
      }
    }
  .foot-btn-axst{
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: 10px;
    line-height: 36px;
    height: 36px;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    display: flex;
    justify-content:space-between;
    >div{
      width: 48%;
      height: 100%;
      text-align: center;
      background: #08f;
      border-radius: 18px;
      &:first-child{
        color: #000;
        background: #fff;
        border: 1px solid rgba(0,0,0,0.1);
      }
    }
  }
  }
</style>
