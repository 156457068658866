<template>
  <div class="container">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>健康随访</h1>
    </div>
    <div class="main">
      <div class="form">
        <template v-if="showList && showList.length > 0">
          <div
            class="sub-main"
            v-for="item in showList"
            :key="item.id"
            @click="gotoFollowDetail(item.id)"
          >
            <div class="sub-content">
              <div class="cont template">
                <div class="info">
                  <span class="label">随访日期</span
                  ><span class="val"> {{ item.sfrq }}</span>
                </div>
                <div class="info">
                  <span class="label">随访医生</span
                  ><span class="val"> {{ item.sfysxm }}</span>
                </div>
                <div class="info" v-if="item.sfjgmc">
                  <span class="label">随访机构</span
                  ><span class="val"> {{ item.sfjgmc }}</span>
                </div>
                <div class="info">
                  <span class="label">随访方式</span>
                  <span class="val">
                    <template v-if="item.sffs == '1'">电话</template>
                    <template v-if="item.sffs == '2'">家庭访视</template>
                    <template v-if="item.sffs == '3'"
                      >门诊(含康复机构等)</template
                    >
                    <template v-if="item.sffs == '5'">视频</template>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="sub-main" v-if="!showList || showList.length == 0">
          <div class="sub-content">
            <div class="cont template">
              无相关记录
            </div>
          </div>
        </div>
      </div>
    </div>

    <mt-tabbar v-model="selected">
      <mt-tab-item id="0">
        <img slot="icon" src="http://dssq.ikeqiao.net/data/image/h5/images/gxyxz.png" />
        高血压
      </mt-tab-item>
      <mt-tab-item id="1">
        <img slot="icon" src="http://dssq.ikeqiao.net/data/image/h5/images/tnbxz.png" />
        糖尿病
      </mt-tab-item>
    </mt-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: "0",
      tnbList: [],
      gxyList: [],
      showList: [],
    };
  },
  watch: {
    selected(val, oldVal) {
      let showList;
      if (val == "0") {
        showList = this.gxyList;
      } else {
        showList = this.tnbList;
      }
      this.showList = showList;
    },
  },
  components: {},
  mounted() {
   this.getFollowList(0);
   this.getFollowList(1);
  },
  methods: {
    getFollowList: function(type) {
      let _this = this;
      let userId = this.getSessionData("healthUserId");
      let token = this.getSessionData("healthtToken");
      this.axios
        .get(
          "https://yyh.ikeqiao.net/app/health/follow/" + type + "/" + userId,
          {
            headers: { token: token },
          }
        )
        .then(function(res) {
          var sfjlb = res.data.followList.sfjllb;
          if (sfjlb == "无") {
            sfjlb = [];
          }
          if (type == "0") {
            _this.gxyList = sfjlb;
            _this.showList = sfjlb;
          } else {
            _this.tnbList = sfjlb;
          }
        })
        .catch(function(err) {});
    },
    gotoFollowDetail: function(id) {
      var type = this.selectedType;
      this.$router.push({
        name: "health/follow",
        params: { id: id, type: type },
      });
    },
    gotoLink(path) {
      this.$router.push(path);
    }, goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/health.scss";
</style>
