
// import wx from 'weixin-js-sdk';
import axios from 'axios';
export function init(){
  if (!is_weixn()) {
    return
  }
  const url = window.location.href.split('#')[0];
  axios.get(`/authApi/user/sign?url=${encodeURIComponent(url)}`).then(res => {
    const { wx } = window;
    if(res.data.flag) {
      const data = res.data.data;
      console.log('signdata',data);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appid, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名
        jsApiList: [
          'updateAppMessageShareData',
          "onMenuShareAppMessage", // 分享给朋友
          "onMenuShareTimeline", // 分享到朋友圈
          "onMenuShareQQ", // 分享到QQ
          "onMenuShareQZone", // 分享到QQ空间
          "checkJsApi", //判断当前客户端版本是否支持指定JS接口
          'wx-open-launch-weapp', // 微信开放标签
        ], // 必填，需要使用的JS接口列表
        openTagList:['wx-open-launch-weapp'] // 微信开放标签 小程序跳转按钮：<wx-open-launch-weapp>
      });
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.checkJsApi({
          jsApiList: ['updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function(res) {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          // console.log(222222, res);
          }
        });
        wx.updateAppMessageShareData({
          title: '东盛未来社区', // 分享标题
          desc: '更好的联接社区生活，打造更智能更便捷的社区体验', // 分享描述
          link: 'http://dssq.ikeqiao.net/#/wxLogin', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/main_select.png', // 分享图标
          success: function (e) {
            // console.log(3333331,'成功', e);
          },
          cancel: function (e) {
            // console.log(3333332,'取消', e);
          },
          complete: function (e) {
            // console.log(3333333,e);
          }
        });
        // wx.onMenuShareAppMessage({ 
        //   title: '东盛未来社区', // 分享标题
        //   desc: '更好的联接社区生活，打造更智能更便捷的社区体验', // 分享描述
        //   link: 'http://dssq.ikeqiao.net/#/wxLogin', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //   imgUrl: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/main_select.png', // 分享图标
        //   success: function (e) {
        //     console.log(3333331,'成功', e);
        //   },
        //   cancel: function (e) {
        //     console.log(3333332,'取消', e);
        //   },
        //   complete: function (e) {
        //     console.log(3333333,e);
        //   }
        // });
      });
      wx.error((e) => {
        console.log('error' ,e);
      })
    }
  }).catch(err => {
    console.log('error',err)
  })
}


// 判断是否微信环境
function is_weixn() {
  let ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}

// 判断当前微信版本号是否支持
function is_version(){
  let client = false; // 当前版本号是否支持 (默认不支持)
  let wxInfo = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i); // 微信浏览器信息
  // 微信版本号 wxInfo[1] = "7.0.18.1740" (示例)
  //进行split转成数组进行判断 [7,0,18,1740] (示例)
  let version = wxInfo[1].split(".");
  // 判断版本在7.0.12及以上的版本
  if (version[0] >= 7) {
    if (version[1] >= 0) {
      if (version[2] >= 12) {
        client = true; // 当前版本支持
      }
    }
  }
  return client;
}

