<template>
  <div class="happy-room-container">
    <van-nav-bar title="幸福学堂" left-arrow @click-left="onClickLeft" />
    <div class="happy-room-body-container">
      <!-- 智创空间 -->
      <div class="coin-bar-con">
        <div class="coin-bar">
          <h1>智创空间</h1>
          <!-- <h3>学堂一句话介绍</h3> -->
        </div>
      </div>
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="status"
          @change="handleChange($event, 'status')"
          :options="option2"
        />
        <van-dropdown-item
          v-model="sortField"
          @change="handleChange($event, 'sortField')"
          :options="option3"
        />
      </van-dropdown-menu>
      <div class="class-source-con">
        <div class="class-source-list">
          <van-list
            v-model="loading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
            class="activity-list"
          >
            <ul>
              <li v-for="(item, index) in dataList" :key="index" @click="gotoLink('happyRoomDetail?key='+index)">
                <div class="img">
                  <van-image width="100%" height="8rem" :src="item.picture" fit="cover" />
                  <van-tag v-if="item.state == '0'" type="off">报名中</van-tag>
                  <van-tag v-if="item.state == '1'" type="on">进行中</van-tag>
                  <van-tag v-if="item.state == '2'" type="finish">已完成</van-tag>
                  <div
                    v-if="item.state == '0' || item.state == '1'"
                    class="time"
                  >
                    {{ item.time }}
                  </div>
                  <div v-if="item.state == '1'" class="end">报名已截止</div>
                </div>
                <div class="desc">
                  <div class="title">
                    <b>{{ item.title }}</b>
                    <span class="num">已报名 <i>{{ item.number }}</i></span>
                  </div>
                  <div class="info">
                    <label>发布人：</label><span>{{ item.username }}</span>
                  </div>
                  <div class="info">
                    <label>活动时间：</label><span>{{ item.activityTime }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import huiApi from "api/education/huiStudy";
import { mapMutations, mapState } from "vuex";
import { Image } from 'vant';
import Vue from 'vue';
Vue.use(Image);
export default {
  data() {
    return {
      status: "",
      sortField: "",
      type: '',
      loading: false,
      listFinished: false,
      option1: [
        { text: "全部类型", value: "" },
        { text: "图书管理", value: "图书管理" },
        { text: "垃圾分类", value: "垃圾分类" },
        { text: "卫生清洁", value: "卫生清洁" },
        { text: "知识宣讲", value: "知识宣讲" },
      ],
      option2: [
        { text: "全部状态", value: "" },
        { text: "报名中", value: "报名中" },
        { text: "进行中", value: "进行中" },
        { text: "已完成", value: "已完成" },
      ],
      option3: [
        { text: "默认排序", value: "" },
        { text: "最新发布", value: "最新发布" },
      ],
      dataList: [],
      page: 0,
      size: 10,
      total: 0,
    };
  },
  computed: {},
  components: {},
  mounted() {},
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    handleChange(e, type) {
      this[type] = e;
    },
    onLoad() {
      console.log('onLoad')
      this.page += 1;
      this.getList();
    },
    getList({ page = this.page, size = this.size } = {}) {
      huiApi
        .getHuiActivityList({
          page,
          size,
          object: {
            type: '1399994404554407937',
            status: this.status,
            sortField: this.sortField,
            sort: 'desc'
          }
        })
        .then(res => {
          this.loading = false;
          const { records = [], total = 0 } = res.data || {};
          if(this.page === 1) {
            this.dataList = records;
          } else {
            this.dataList = this.dataList.concat(records);
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.happy-room-container {
  height: 100vh;
  width: 100vw;

  .happy-room-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .coin-bar-con {
      // display: flex;
      // background-color: #FFF;
      .coin-bar {
        width: 100%;
        height: 121px;
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/huiStudy/xingfu-top.png') no-repeat;
        background-size: 100% 100%;
        padding: 20px;
        color: #FFF;
        >h1 {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 32px;
          text-shadow: 0 2px 4px rgba(0,0,0,0.13);
          font-weight: 600;
        }
        >h3 {
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: left;
          font-weight: 200;
        }
      }
    }

    .class-source-con {
      margin: 10px;
      width: calc(100% - 20px);
      
    }
    .activity-list {
      li {
        margin-bottom: 0.8rem;
        .img {
          height: 8rem;
          position: relative;
          img {
            width: 100%;
            height: 8rem;
          }
          .van-tag {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 0.4rem;
          }
          .van-tag--on {
            background: #e3effd;
            color: #4479ed;
          }
          .van-tag--off {
            background: #d3f1e3;
            color: #40c76d;
          }
          .van-tag--finish {
            background: #ededed;
            color: rgba(0, 0, 0, 0.45);
          }
          .time {
            background: rgba(0, 0, 0, 0.45);
            padding: 0 0.4rem;
            color: #fff;
            height: 1.6rem;
            line-height: 1.6rem;
            border-radius: 0.3rem;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 0.4rem;
          }
          .end {
            background: rgba(0, 0, 0, 0.45);
            padding: 0 0.4rem;
            color: #fff;
            height: 1.6rem;
            line-height: 1.6rem;
            border-radius: 0.3rem;
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 0.4rem;
          }
        }
        .desc {
          background: #fff;
          border-radius: 0 0 0.3rem 0.3rem;
          padding: 0.5rem 0.7rem;
          .title {
            height: 1.8rem;
            line-height: 1.8rem;
            overflow: hidden;
            .type-tag {
              float: left;
              line-height: 1rem;
              margin-top: 0.3rem;
              border: 0.5px solid #4479ed;
              color: #4479ed;
              font-size: 12px;
              border-radius: 10px;
              padding: 0 5px;
              margin-right: 5px;
            }
            b {
              float: left;
              color: rgba(0, 0, 0, 0.85);
              font-size: 1rem;
              width: 15.4rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .num {
              color: rgba(0, 0, 0, 0.65);
              font-size: 0.4rem;
              float: right;
              i {
                color: #4479ed;
                font-family: arial;
                font-style: initial;
              }
            }
          }
          .info {
            font-size: 0.9rem;
            line-height: 1.4rem;
            color: rgba(0, 0, 0, 0.85);
            label {
              color: rgba(0, 0, 0, 0.45);
            }
          }
        }
      }
    }
  }
}
</style>
