<template>
  <div class="">
    <van-nav-bar title="邻里互助" left-arrow @click-left="onClickLeft" />

    <div class="llhz-body-container">
      <van-tabs v-model="selected">
        <van-tab title="发现上报">
          <div class="tip">将身边的弱势群体上报到社区来进行救助</div>
          <div class="form-bar">
            <van-form @submit="onSubmit">
              <van-field
                size="large"
                v-model="name"
                name="name"
                label="户主姓名"
                placeholder="请填写"
                :rules="[{ required: true, message: '请填写姓名' }]"
                class="input-bar"
                input-align="right"
              />
              <van-cell title-class="cell" title="性别" size="large">
                <template #default>
                  <span
                    :class="['radio', { selected: sex === '男' }]"
                    @click="changeSex('男')"
                    >男</span
                  >
                  <span
                    :class="['radio', { selected: sex === '女' }]"
                    @click="changeSex('女')"
                    >女</span
                  >
                </template>
              </van-cell>
              <van-field
                size="large"
                v-model="idCard"
                name="idCard"
                label="身份证号"
                placeholder="请输入"
                :rules="[{ required: true, message: '请填写身份证号' }]"
                class="input-bar"
                input-align="right"
              />
              <van-field
                size="large"
                v-model="phone"
                name="phone"
                label="联系电话"
                placeholder="请输入"
                :rules="[{ required: true, message: '请填写联系电话' }]"
                class="input-bar"
                input-align="right"
              />
              <van-field
                size="large"
                v-model="type"
                name="type"
                label="救助类型"
                placeholder="请输入"
                :rules="[{ required: true, message: '请填写救助类型' }]"
                class="input-bar"
                input-align="right"
              />
              <van-field
                size="large"
                v-model="people"
                name="people"
                label="家庭人口"
                placeholder="请输入"
                :rules="[{ required: true, message: '请填写家庭人口' }]"
                class="input-bar"
                input-align="right"
              />
              <van-field
                size="large"
                v-model="juzhudi"
                name="juzhudi"
                label="家庭地址"
                placeholder="请输入"
                :rules="[{ required: true, message: '请填写家庭地址' }]"
                class="input-bar"
                input-align="right"
              />
              <van-field
                size="large"
                v-model="address"
                name="address"
                label="发现位置"
                placeholder="请输入"
                :rules="[{ required: true, message: '请填写发现位置' }]"
                class="input-bar"
                input-align="right"
              />
              <van-cell
                title-class="cell"
                title="有无家庭主要劳动力发生变化"
                size="large"
              >
                <template #default>
                  <span class="radio" @click="changew('有')">有</span>
                  <span class="radio" @click="changew('无')">无</span>
                </template>
              </van-cell>
              <van-cell
                title-class="cell"
                title="有无家庭成员发生疾病或意外"
                size="large"
              >
                <template #default>
                  <span class="radio" @click="changew('有')">有</span>
                  <span class="radio" @click="changew('无')">无</span>
                </template>
              </van-cell>
              <van-cell
                title-class="cell"
                title="有无家庭成员失业，工资减少收入"
                size="large"
              >
                <template #default>
                  <span class="radio" @click="changew('有')">有</span>
                  <span class="radio" @click="changew('无')">无</span>
                </template>
              </van-cell>
              <van-cell
                title-class="cell"
                title="救助家庭有无新增刚性支出"
                size="large"
              >
                <template #default>
                  <span class="radio" @click="changew('有')">有</span>
                  <span class="radio" @click="changew('无')">无</span>
                </template>
              </van-cell>
              <van-field
                v-model="qita"
                size="large"
                name="qita"
                rows="2"
                autosize
                label="其他"
                type="textarea"
                maxlength="50"
                placeholder="请输入留言"
                show-word-limit
                class="input-bar"
                input-align="right"
              />
              <div class="footer-button">
                <van-button
                  round
                  block
                  type="info"
                  style="font-size: 16px"
                  native-type="submit"
                >
                  上报
                </van-button>
              </div>
            </van-form>
          </div>
        </van-tab>
        <van-tab title="上报记录">
          <div class="cata-box">
            <div class="list">
              <ul>
                <li v-for="(item, index) in dataList" :key="index">
                  <van-tag v-if="item.stats == '不符合'" class="red"
                    >不符合</van-tag
                  >
                  <van-tag v-if="item.stats == '核实中'" class="blue"
                    >核实中</van-tag
                  >
                  <van-tag v-if="item.stats == '已核实'" class="green"
                    >已核实</van-tag
                  >
                  <div class="info">
                    <div class="data title">
                      <b>户主名字</b><span>{{ item.name }}</span>
                    </div>
                    <div class="data">
                      <b>身份证号</b><span>{{ item.cid }}</span>
                    </div>
                    <div class="data">
                      <b>电话号码</b><span>{{ item.tel }}</span>
                    </div>
                    <div class="data">
                      <b>家庭人口</b><span>{{ item.num }}</span>
                    </div>
                    <div class="data">
                      <b>救助类型</b><span>{{ item.type }}</span>
                    </div>
                  </div>
                  <div class="remark">
                    <div class="place">
                      <i class="address"></i> {{ item.address }}
                    </div>
                    <div class=""><i class="date"></i> {{ item.date }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: "",
      selected: 1,
      type: "全血",
      sex: "男",
      name: "",
      phone: "",
      idCard: "",
      date: "",
      showDate: false,
      showDepart: false,
      depart: "",
      departList: [],
      juzhudi: "",
      address: "",
      people: "",

      qita: "",
      show: true,
      dataList: [
        {
          stats: "审核中",
          name: "张三",
          cid: "33061……1000",
          num: "1",
          type: "不符合",
          address: "东盛社区润泽大院",
          date: "2021-8-15 12：00",
          tel: "136……1234",
        },
        {
          stats: "待帮扶",
          name: "张三",
          cid: "33061……1000",
          num: "1",
          type: "核实中",
          address: "东盛社区润泽大院",
          date: "2021-8-15 12：00",
          tel: "136……1234",
        },
        {
          stats: "已帮扶",
          name: "张三",
          cid: "33061……1000",
          num: "1",
          type: "已核实",
          address: "东盛社区润泽大院",
          date: "2021-8-15 12：00",
          tel: "136……1234",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    tip() {
      this.$message("页面正在建设中");
    },
    gotoLink(path) {
      this.$router.push(path);
    },

    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit(values) {
      console.log("values", values);
      this.$toast({
        message: "申请提交成功！",
        position: "top",
      });
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.llhz-body-container {
  height: calc(100vh - 3rem);
  width: 100vw;
  background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
  background-size: 100% 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  .van-tabs__line {
    background-color: #4e79f2;
  }
  .tip {
    height: 2rem;
    line-height: 2rem;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    padding: 0 0.8rem;
    background: #efefef;
  }
  .van-form {
    margin-bottom: 1rem;
    .cell {
      color: rgba(0, 0, 0, 0.65);
    }
    .radio {
      height: 2rem;
      border-radius: 1rem;
      // background: #4479ED;
      color: rgba(0, 0, 0, 0.35);
      border: 1px solid rgba(0, 0, 0, 0.35);
      padding: 0.1rem 1.2rem;
      margin-left: 8px;
    }
    .selected {
      background: #4479ed;
      color: #ffffff;
      border: 1px solid #4479ed;
    }
  }
  .cata-menu {
    background: #fff;
    border-radius: 0.5rem;
    height: 7rem;
    width: 23.6rem;
    padding-top: 1.5rem;
    li {
      float: left;
      width: 33%;
      text-align: center;
      i {
        display: inline-block;
        width: 3.2rem;
        height: 3.2rem;
      }
      .icon1 {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/cata-nav1.png) no-repeat;
        background-size: 100% 100%;
      }
      .icon2 {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/cata-nav2.png) no-repeat;
        background-size: 100% 100%;
      }
      .icon3 {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/catalog/cata-nav3.png) no-repeat;
        background-size: 100% 100%;
      }
      span {
        display: block;
        color: #656565;
        font: 500 0.8rem/1.6rem "microsoft yahei";
      }
    }
  }
  .cata-box {
    padding: 10px;
    margin: 0;
    .list {
      li {
        background: #fff;
        border-radius: 0.3rem;
        padding: 0.5rem 0.7rem;
        position: relative;
        margin-top: 0.8rem;
        .van-tag {
          border-radius: 0 0.3rem 0 0.8rem;
          padding: 0.2rem 0.7rem;
          position: absolute;
          top: 0;
          right: 0;
        }
        .blue {
          background: #7592f4;
        }
        .red {
          background: #eb808d;
        }
        .green {
          background: #36c9ac;
        }
        .info {
          .data {
            display: inline-block;
            width: 50%;
            padding: 0 0.4rem;
            font-size: 0.8rem;
            line-height: 1.6rem;
            color: #000;
            height: 1.6rem;
            overflow: hidden;
            b {
              color: #bcbcbc;
              font-weight: 500;
              margin-right: 0.4rem;
            }
          }
          .title {
            width: 100%;
          }
        }
        .remark {
          border-top: 1px solid #bbbbbb;
          padding: 0.5rem 0.4rem;
          display: flex;
          div {
            display: inline-block;
            width: 50%;
            i {
              display: inline-block;
            }
          }
          .address {
            background: url("http://dssq.ikeqiao.net/data/image/h5/images/catalog/address.png") no-repeat;
            background-size: 100% 100%;
            height: 0.8rem;
            width: 0.6rem;
            margin-right: 0.3rem;
          }
          .date {
            background: url("http://dssq.ikeqiao.net/data/image/h5/images/catalog/date.png") no-repeat;
            background-size: 100% 100%;
            height: 0.6rem;
            width: 0.6rem;
            margin-right: 0.3rem;
          }
        }
      }
    }
  }
  .cata-tab {
    line-height: 1.4rem;
    font-size: 0.9rem;
    height: 1.4rem;
    b {
      float: left;
      color: #5879e9;
      i {
        display: inline-block;
        color: #fff;
        background: #5879e9;
        border-radius: 0.1rem;
        text-align: center;
        font-size: 0.4rem;
        font-style: initial;
        line-height: 0.8rem;
        padding: 0 0.1rem;
        margin-left: 0.2rem;
      }
    }
    span {
      float: right;
      color: #6d7279;
    }
  }
}
</style>
