<template>
  <div id="index">
    <div class="page-container" style="margin-top: 0px !important; padding: 0px !important;height: 100vh !important;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>社区小匠人</h1>
        <span @click="gotoLink('myNrb')">我的</span>
      </div>
      <div class="nrb-box">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="category"
            @change="handleChange($event, 'category')"
            :options="categoryList"
          />
          <van-dropdown-item
            v-model="status"
            @change="handleChange($event, 'status')"
            :options="option2"
          />
        </van-dropdown-menu>
        <div class="reply-list">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            style="height: 100%;"
          >
            <ul>
              <li v-for="item in list" :key="item.abilityId" @click="gotoLink(`nrbDetail?id=${item.abilityId}`)">
                <div class="reply-info">
                  <img :src="item.avatarUrl || 'http://dssq.ikeqiao.net/data/image/h5/images2/sdb-banner.jpg'" />
                  <div>{{item.needName}}<br /><span>{{`发布于${item.issueTime}`}}</span></div>
                 <i
                  :class="{
                    tag1: item.issueStatus == '未认领',
                    tag2: item.issueStatus == '已认领',
                    tag3: item.issueStatus == '已解决',
                  }"
                  >{{ item.issueStatus === '未认领' ? '认领中' : item.issueStatus }}</i
                >
                </div>
                <div class="tit">{{item.title}}</div>
                <p>{{item.content}}</p>
                <div class="state"><span>{{item.className}}</span></div>
              </li>
            </ul>
          </van-list>
        </div>
        <div class="float-button" @click="gotoLink('help')">发布需求</div>
        <!-- <div class="float-button" @click="handleService">我要求助</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import nrbApi from "api/llyjr/nrb";
import { mapMutations, mapActions, mapState } from "vuex";
import { List, Toast } from "vant";

export default {
  data() {
    return {
      page: 0,
      size: 10,
      total: 0,
      list: [],
      finished: false,
      loading: false,
      status: "",
      category: "",
      option1: [
        { text: "全部类型", value: "" },
      ],
      option2: [
        { text: "全部情况", value: "" },
        { text: "未认领", value: '未认领' },
        { text: "已认领", value: '已认领' },
        { text: "已解决", value: '已解决' },
      ],
    };
  },
  created() {
    this.getCategoryList();
  },
  computed: {
    ...mapState({
      categoryList: (state) => state.llyjr.categoryList,
    })
  },
  methods: {
    ...mapMutations(["setQuestionInfo"]),
    ...mapActions(["getCategoryList"]),
    gotoLink(path) {
      this.$router.push(path);
    },
    handleChange(e, type) {
      this[type] = e;
      this.getList({ page: 1, size: 10 });
    },
    getList({ page = this.page, size = this.size } = {}) {
      const { status = '', category = '' } = this;
      nrbApi
        .getNrbList({
          page,
          size,
          object: {
            className: category,
            issueStatus: status,
          },
        })
        .then((res) => {
          this.loading = false;
          const { list = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.list = list;
          } else {
            this.list = Array.from(new Set(this.list.concat(list)))
          }
          this.total = total;
          
          if (this.list.length >= this.total || list === []) {
            // 数据全部加载完成
            this.finished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
          this.finished = true;
        });
    },
    onLoad() {
      console.log('load');
      
      this.page += 1;
      this.getList();
    },
    onQuestionClick(item) {
      this["setQuestionInfo"](item);
      this.gotoLink("serviceDetail");
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  // padding: 0.8rem 0rem;
  background: #fff;
  padding: 0;
  height: calc(100vh - 2.7rem);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  .nrb-box {
    margin-top: 2.8rem;
    .van-dropdown-menu__bar {
      height: 3rem;
      .van-dropdown-menu__title {
        font-size: 0.9rem;
        line-height: 3rem;
      }
    }
  }
  .comm-banner {
    width: 23.4rem;
    margin: 0.7rem auto 0;
    height: 7.8rem;
    img {
      height: 7.8rem;
      width: 100%;
      border-radius: 0.5rem;
    }
  }
  .float-button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background: #4479ed;
    -webkit-box-shadow: 0 0 10px 0 rgb(68 121 237 / 60%);
    box-shadow: 0 0 10px 0 rgb(68 121 237 / 60%);
    font-size: 13px;
    color: #fff;
    letter-spacing: -0.31px;
    text-align: center;
    line-height: 15px;
    padding: 1rem;
  }
  .reply-list {
    width: 23.4rem;
    margin: 0 auto;
    padding: 0.6rem 0;
    li {
      padding: 0.8rem 0;
      //border-bottom: 1px solid #ddd;
      font: 500 0.9rem/1.4rem "microsoft yahei";
      .tit {
        font-weight: 600;
        color: #333;
        font-size: 1rem;
        margin-bottom: 0.3rem;
      }
      .date {
        color: #999;
      }
      p {
        color: #7a7a7a;
      }
      .reply-info {
        margin-bottom: 0.4rem;
        position: relative;
        height: 2rem;
        img {
          width: 2rem;
          height: 2rem;
          float: left;
          border-radius: 50%;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
        div {
          float: left;
          font: 500 0.8rem/1rem "microsoft yahei";
          color: #333;
          span {
            color: #bbb;
          }
        }
        i {
          position: absolute;
          display: inline-block;
          height: 1.8rem;
          padding: 0 0.7rem 0 0.9rem;
          line-height: 1.8rem;
          border-radius: 0.9rem 0 0 0.9rem;
          font-style: inherit;
          font-size: 0.7rem;
          color: #fff;
          right: -0.8rem;
          top: 0.1rem;
        }
        .tag1 {
          background: #3291f8;
        }
        .tag2 {
          background: #f8b432;
        }
        .tag3 {
          background: #c5cbd2;
        }
      }
      .state {
        padding-top: 0.4rem;
        span {
          display: inline-block;
          height: 1.4rem;
          padding: 0 0.7rem;
          line-height: 1.4rem;
          border-radius: 0.2rem;
          font-style: inherit;
          font-size: 0.8rem;
          background: #e8e8e8;
          color: #101010;
        }
        i {
          display: inline-block;
          vertical-align: middle;
          width: 1rem;
          height: 1rem;
          margin-right: 0.3rem;
          background: url(http://dssq.ikeqiao.net/data/image/h5/images2/icon-zan.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .card-nav {
    width: 100%;
    // border-top: 0.8rem solid #f6f7fa;
    // background: #FFFFFF;
    // padding: 0.8rem;
    .question {
      margin-bottom: 0.8rem;
      background: #ffffff;
      padding: 0.8rem;
      .question-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 12px;
        line-height: 2rem;
        font-weight: 600;
      }
      .foot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .person {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          text-align: left;
          line-height: 22px;
          height: 2.5rem;
          .avatar {
            height: 2rem;
            width: 2rem;
            border-radius: 1rem;
            margin-right: 5px;
          }
        }
        .question-foot {
          height: 1.5rem;
          margin: 0.4rem 0;
          color: rgba(0, 0, 0, 0.45);
          display: flex;
          align-items: center;

          div {
            margin-right: 0.4rem;
          }
          img {
            height: 1rem;
            margin-right: 0.2rem;
          }
        }
      }
      .paragraph {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
        .long {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
  .float-button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background: #4479ed;
    box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);

    font-size: 13px;
    color: #ffffff;
    letter-spacing: -0.31px;
    text-align: center;
    line-height: 15px;
    padding: 1rem;
  }
}
</style>
