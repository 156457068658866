<template>
  <div id="index"
       class="clearfix">
    <div class="page-container clearfix" style="margin-top: 0rem !important;">
      <div class="header-nav">
        <i @click="goBack()"></i>
        <h1>我的托管</h1>
      </div>
      <van-tabs color="#4479ED" style="margin-top: 2.7rem !important;">
        <!-- <van-tab title="进行中">
          <div class="card"
               v-for="(item, index) in processingList"
               v-bind:key="index">
            <div class="time">
              <span>2021-5-20 11:50</span>
            </div>
            <div class="obj">
              <span>托管对象: 孩子</span>
            </div>
            <div class="time-period">
              <span>托管时间: 2021-5-20 12:00 ~ 15:00</span>
            </div>
            <div class="remark">
              <span>托管备注: 无</span>
            </div>
            <div class="cancel">
              <van-button type="info">取消托管</van-button>
            </div>
          </div>
        </van-tab> -->
        <van-tab title="进行中">
          <div class="card"
               v-for="(item, index) in processingList"
               v-bind:key="index">
            <div class="time">
              <!-- <span>{2021-5-21 13:14}</span> -->
              <!-- <span>{{ item.beginTime.slice(0, item.beginTime.length)}}</span> -->
              <span>{{ item.despointName }}</span>
            </div>
            <div class="obj">
              <span>托管对象: {{item.deposit}}</span>
            </div>
            <div class="time-period">
              <!-- <span>托管时间: 2021-5-21 13:14 ~ 15:16</span> -->
              <span>托管学期: {{item.term}}</span>
            </div>
            <div class="remark">
              <span>托管备注: {{item.comments}}</span>
            </div>
            <div class="cancel">
              <van-button type="info"
              @click="cancleHost(item.id)">取消托管</van-button>
            </div>
          </div>
        </van-tab>
        <van-tab title="已结束">
          <div class="card"
               v-for="(item, index) in finishedList"
               v-bind:key="index">
            <div class="time">
              <!-- <span>{2021-5-21 13:14}</span> -->
              <!-- <span>{{ item.beginTime.slice(0, item.beginTime.length)}}</span> -->
              <!-- <span>{{ hostBeginTime(item) }}</span> -->
              <span>{{item.term}}</span>
            </div>
            <div class="obj">
              <span>托管对象: {{item.deposit}}</span>
            </div>
            <div class="time-period">
              <!-- <span>托管时间: 2021-5-21 13:14 ~ 15:16</span> -->
              <span>托管时间: {{hostPeriodTime(item.createTime)}}</span>
            </div>
            <div class="remark">
              <span>托管备注: {{item.comments}}</span>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
var moment = require("moment")
import sdbxtApi from 'api/sdbxt/sdbxt';
export default {
  data () {
    return {
      processingList: [],
      finishedList: [],
    };
  },
  components: {},
  mounted () {
    sdbxtApi.queryPreviewList({
      "page": 1,
      "size": 10,
      "status": "1"
    }).then((res1) => {
      this.processingList = res1.data.list
    })
    sdbxtApi.queryPreviewList({
      "page": 1,
      "size": 10,
      "status": "0"
    }).then((res2) => {
      
      this.finishedList = res2.data.list
    })
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },
    cancleHost (id) {
      sdbxtApi.cancelHostPreview(id).then(res => {
        let targetIndex = this.processingList.findIndex(item => item.id === id)
        this.processingList.splice(targetIndex, 1)
      })
    },
  },

  computed: {
    hostBeginTime: function () {
      return function (item) {
        // return item.beginTime.slice(0, item.beginTime.length - 3)
        return moment(item.beginTime).format("YYYY-MM-DD  hh:mm")
      }
    },
    hostPeriodTime: function () {
      return function (item) {
        return moment(item.beginTime).format("YYYY-MM-DD")
          + '  '
          + moment(item.beginTime).format("hh:mm")
          + ' ~ '
          + moment(item.endTime).format("hh:mm")
      }
    },

  }
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.page-container {
  padding: 0;
}
.van-tabs {
  /* padding-left: 0.8rem;
  padding-right: 0.8rem; */
  width: 100%;
  background-color: #f6f7fa;
  overflow: hidden;
  .van-tab {
    font-weight: 600;
  }
}
.card {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font: 0.933rem/0.8rem "PingFangSC-Regular";
  padding-bottom: 1.4rem;
  position: relative;
  margin-bottom: 0.8rem;
  color: #000000;
  .time {
    margin-top: 1.2667rem;
    margin-bottom: 0.933rem;
    span {
      opacity: 0.85;
      font: 1.0667rem/0.8rem "PingFangSC-Medium";
    }
  }
  .obj,
  .time-period,
  .remark {
    margin-bottom: 0.6rem;
    span {
      opacity: 0.65;
      font: 0.933rem/0.8rem "PingFangSC-Regular";
    }
  }
  .remark {
    display: flex;

    span:nth-child(1) {
      display: inline-block;
    }
    span:nth-child(2) {
      display: inline-block;
      width: 13.533rem;
      line-height: 1.4667rem;
      margin-top: -0.333rem;
      margin-left: 0.333rem;
    }
  }
  .cancel {
    position: absolute;
    right: 0.8rem;

    .van-button {
      height: 2rem;
      border-radius: 0.4rem;
      font-size: 13px;
    }
  }
}
</style>
