<template>
  <div class="zljgDetail" style="background: #fff; height: 100vh">
    <div class="page-container-zljg paddclose" style="height: calc(100vh);overflow-y: auto;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>{{title}}</h1>
      </div>
      <div class="zljg-main" style="width: 100%;">
        <div class="item" v-for="(v, i) in list" :key="i" @click="choiceItem(v,i)">
          <div class="title">
            <div class="line-v"></div>
            <!-- 物业公司 -->
            <div>{{v.value2}}</div>
            <div style="color: rgba(0,0,0,0.5);">({{v.value3}})</div>
            <van-icon :name="selectItem.indexOf(`${v.value2}${i}`) > -1 ? 'arrow-down' : 'arrow'" />
          </div>
          <div v-if="key==='102'" class="subTitle">所在小区：{{v.value1}}</div>
          <div v-if="selectItem.indexOf(`${v.value2}${i}`) > -1">
            <div class="person-item" v-for="(item, index) in (v.listData || [])" :key="index">
              <div class="image"><img :src="item.image || require('../../assets/images/personBig.png')" alt=""></div>
              <div class="text">
                <div>
                  {{item.value2}}
                  <span v-if="item.value4" class="tag">{{item.value4}}</span>
                </div>
                <!-- 物业公司 -->
                <div v-if="key==='102'">
                  <span class="label">性别:</span>
                  <span class="value">{{item.value3}}</span>
                </div>
                <div v-if="key==='102'">
                  <span class="label">联系电话:</span>
                  <span class="value">{{handleHidden(item.value5)}}</span>
                </div>
                <!-- 党员信息 -->
                <!-- <div v-if="key!=='102'">
                  <span class="label">学历:</span>
                  <span class="value">{{item.education}}</span>
                </div>
                <div v-if="key!=='102'">
                  <span class="label">住址:</span>
                  <span class="value">{{item.address}}</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import {mapState} from 'vuex';
import service from 'utils/service';
import homeApi from 'api/home';
export default {
  data() {
    return {
      selectItem: [],
      list: [],
      title: '',
    };
  },
  components: {},
  mounted(opt) {
      const { key, name } = this.$route.query;
      this.key = key;
      this.title = name;
      if (key === '102') {
        homeApi.getParty({ id: key }).then((res) => {
          const { data = [] } = res;
          this.list = data;
        });
      } else if (key === '99') {
        homeApi.getPartyOrganization({}).then((res) => {
          const { data = [] } = res;
          this.list = data && data.length > 0 ? data.map(v =>({
            value2: v.organizationName,
            value3: v.memberNum,
            ...v,
          })) : [];
        });
      } else if (key === '97') {
        homeApi.getPartyOrganization({ type: '功能性党组织' }).then((res) => {
          const { data = [] } = res;
          this.list = data && data.length > 0 ? data.map(v =>({
            value2: v.organizationName,
            value3: v.memberNum,
            ...v,
          })) : [];
        });
      } else if (key === '96') {
        homeApi.getPartyGroup().then((res) => {
          const { data = [] } = res;
          this.list = data && data.length > 0 ? data.map(v =>({
            value2: v.groupName,
            value3: v.memberNum,
            ...v,
          })) : [];
        });
      }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleHidden(phone) {
      return phone ? phone.substr(0,3) + "********" : '*****'
    },
    choiceItem(item,i){
      // console.log(`${item.value2}${i}`)
      if (this.selectItem.indexOf(`${item.value2}${i}`) > -1) {
        this.selectItem = this.selectItem.filter(v => v !== `${item.value2}${i}`)
      } else {
        if (this.key !== '102') {
          if (this.key !== '96') {
            homeApi.getPartyOrganizationMember({ organizationId: item.organizationId }).then((res) => {
              const { data = {} } = res;
              const list = data.records && data.records.length > 0 ? data.records: [];
              item.listData = list.map(v =>({
                value2: v.name,
                value4: v.tag,
                ...v,
            }));
              this.selectItem = [...this.selectItem, `${item.value2}${i}`]
            });
          } else {
            homeApi.getPartyGroupMember({ organizationId: item.organizationId }).then((res) => {
              const { data = {} } = res;
              const list = data.records && data.records.length > 0 ? data.records: [];
              item.listData = list.map(v =>({
                value2: v.name,
                value4: v.tag,
                ...v,
            }));
              this.selectItem = [...this.selectItem, `${item.value2}${i}`]
            });
          }
        } else {
          this.selectItem = [...this.selectItem, `${item.value2}${i}`]
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";
.zljgDetail{
  .zljg-main{
    height: 100vh;
    padding: 10px;
    padding-top: 2.7rem;
    background: #f6f7fa;
    .item{
      padding: 10px;
      margin-top: 10px;
      background: #FFFFFF;
      border-radius: 8px;
      .title{
        line-height: 30px;
        // height: 30px;
        display: flex;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
        font-weight: 500;
        >div{
          line-height: 30px;
        }
        .line-v{
          width: 4px;
          height: 18px;
          background: #3E71F9;
          margin: 6px;
        }
        .van-icon{
          margin-left: auto;
          margin-right: 0px;
          margin-top: 6px;
          font-size: 18px;
          color: #a5aaaf
        }
      }
      .subTitle{
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        font-weight: 400;
        padding: 6px;
      }
      .person-item{
        padding: 10px;
        display: flex;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        .image{
          width: 120px;
          height: 100px;
          margin-right: 10px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          width: calc(100% - 130px);
          font-size: 14px;
          color: rgba(0,0,0,0.85);
          font-weight: 500;
          line-height: 24px;
          .label{
            font-weight: 400;
            color: rgba(0,0,0,0.45);
            margin-right: 10px;
          }
          .value{
            font-weight: 400;
          }
          .tag{
            font-size: 12px;
            margin-left: 10px;
            padding: 3px 6px;
            color: #3E71F9;
            background: rgba(62,113,249,0.20);
            border-radius: 10px;
          }
        }
      }
    }
  }
}
</style>
