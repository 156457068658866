<template>
  <div class="payMoneyPage" style="height: 100vh; padding-top: 2.7rem">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>我的订单</h1>
    </div>
    <van-tabs color="#4379ed" v-model="selected" @click="handleSelect">
      <van-tab :title="item.name" :name="item.id" v-for="(item, index) in selectList" :key="index"></van-tab>
    </van-tabs>
    <div class="order-content">
      <div class="order-tips">
        <span style="color: #f00">温馨提示：</span>
        <span>预定成功后，请到爱心食堂付款取餐就餐</span>
      </div>
      <div class="order-list-list">
        <div class="order-list-item" v-for="(item, i) in activeList" :key="i">
          <div class="list-order-title">
            套餐 ｜ {{item.setMeal}}
          </div>
          <div class="list-order-text">
            <span>取餐时间：</span>
            <span class="list-order-value"> {{item.takeMealTime}}</span>
          </div>
          <div class="list-order-text">
            <span>应付金额：</span>
            <span class="list-order-value">{{item.payMoney}}元</span>
          </div>
          <div class="list-order-text">
            <span>取餐地点：</span>
            <span class="list-order-value">东详路86号东盛社区爱心食堂</span>
          </div>
          <div class="list-order-btnGroup">
            <div @click="getOrder(item.id)" v-if="selected === '1'">确认取餐</div>
            <div @click="showDetail(item)">查看详情</div>
          </div>
          <div class="list-order-tag" :style="`background: ${selected === '1' ? '#f00' : '#08f'}`">{{selected === '1' ? '待取餐' : '已取餐'}}</div>
        </div>
        <div v-if="activeList.length === 0">
          <van-empty description="暂无数据" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import service from 'utils/service';
import lodash from 'lodash';
import { mapMutations } from 'vuex'
import axstApi from 'api/axst';
import moment from 'moment';
moment.locale('zh-cn', {
    months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
    weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY-MM-DD',
        LL: 'YYYY年MM月DD日',
        LLL: 'YYYY年MM月DD日Ah点mm分',
        LLLL: 'YYYY年MM月DD日ddddAh点mm分',
        l: 'YYYY-M-D',
        ll: 'YYYY年M月D日',
        lll: 'YYYY年M月D日 HH:mm',
        llll: 'YYYY年M月D日dddd HH:mm'
    },
    meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
    meridiemHour: function (hour, meridiem) {
        if (hour === 12) {
            hour = 0;
        }
        if (meridiem === '凌晨' || meridiem === '早上' ||
            meridiem === '上午') {
            return hour;
        } else if (meridiem === '下午' || meridiem === '晚上') {
            return hour + 12;
        } else {
            // '中午'
            return hour >= 11 ? hour : hour + 12;
        }
    },
    meridiem: function (hour, minute, isLower) {
        const hm = hour * 100 + minute;
        if (hm < 600) {
            return '凌晨';
        } else if (hm < 900) {
            return '早上';
        } else if (hm < 1130) {
            return '上午';
        } else if (hm < 1230) {
            return '中午';
        } else if (hm < 1800) {
            return '下午';
        } else {
            return '晚上';
        }
    },
    calendar: {
        sameDay: '[今天]LT',
        nextDay: '[明天]LT',
        nextWeek: '[下]ddddLT',
        lastDay: '[昨天]LT',
        lastWeek: '[上]ddddLT',
        sameElse: 'L'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
    ordinal: function (number, period) {
        switch (period) {
            case 'd':
            case 'D':
            case 'DDD':
                return number + '日';
            case 'M':
                return number + '月';
            case 'w':
            case 'W':
                return number + '周';
            default:
                return number;
        }
    },
    relativeTime: {
        future: '%s内',
        past: '%s前',
        s: '几秒',
        ss: '%d秒',
        m: '1分钟',
        mm: '%d分钟',
        h: '1小时',
        hh: '%d小时',
        d: '1天',
        dd: '%d天',
        M: '1个月',
        MM: '%d个月',
        y: '1年',
        yy: '%d年'
    },
    week: {
        // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
})
export default {
  data() {
    return {
      today: '',
      toweek: '',
      activeList: [],
      selected: '1',
      list: [],
      selectList: [
        { id: '1', name: '待取餐'},
        { id: '2', name: '已取餐'},
      ],
    };
  },
  components: {},
  mounted(opt) {
    this.getList();
    this.today = moment().format('YYYY-MM-DD');
    this.toweek = moment().format('dddd');
  },
  methods: {
    getDate(date){
    const today = date? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    const  toweek = date? moment(date).format('dddd') : moment().format('dddd');
    return `${today}(${toweek})`
    },
    getList(){
      axstApi.neighborLoveCanteenOrderList().then(res => {
        const { data } = res || {};
        if (res.flag) {
          this.list = data ? data.reverse() : [];
          this.activeList = data ? data.filter(v => `${v.orderStatus}` === this.selected) : [];
        } else {
          this.list = [];
          this.activeList = []
        }
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    handleSelect(name) {
      console.log(name);
      this.activeList = this.list ? this.list.filter(v => `${v.orderStatus}` === name) : [];
    },
    showDetail(item){
      this.$router.push(`/orderList?activeId=${item.id}`)
    },
    getOrder(id){
      axstApi.updateOrderStatus({
        id,
        orderStatus: 2,
      }).then(res => {
        if (res.flag) {
          Toast.success('取餐成功');
          this.isGet = true;
          setTimeout(() => {
            this.getList();
          }, 500);
          // this.$router.replace(`/axst`)
        }
      })
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .payMoneyPage{
    height: 100% !important;
    position: relative;
    padding-bottom: 50px;
    .order-content{
      padding: 10px;
    .order-list-item{
      position: relative;
      padding: 10px;
      background: #fff;
      border-radius: 8px;
      margin-bottom: 10px;
      .list-order-title{
        line-height: 30px;
        font-size: 18px;
        font-weight: 600;
      }
      .list-order-text{
        color: rgba(0,0,0,0.65);
        line-height: 30px;
        font-size: 16px;
        font-weight: 400;
      }
      .list-order-tag{
        position: absolute;
        right: 0px;
        top: 10px;
        font-size: 12px;
        color: #fff;
        background: #f00;
        line-height: 22px;
        height: 22px;
        border-top-left-radius: 11px;
        border-bottom-left-radius: 11px;
        text-align: center;
        padding: 0 10px;
      }
      .list-order-btnGroup{
        font-size: 14px;
        display: flex;
        border-top: 1px solid rgba(0,0,0,0.1);
        >div{
          flex: 1;
          height: 30px;
          line-height: 40px;
          text-align: center;
          color: #08f;
        }
      }
    }
    }
    .order-tips{
      color: rgba(0,0,0,0.65);
      font-size: 14px;
      line-height: 30px;
      height: 30px;
    }
  }
</style>
