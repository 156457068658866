<template>
  <div id="index">
    <div class="jfdh-page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>积分兑换</h1>
      </div>
      <div class="top-bar">
        <!-- <van-image src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/banner.png" width="100%" height="160px" class="banner-image"></van-image> -->
        <!-- <h1 class="top-bar-title">邻里积分<van-icon @click="gotoLink('jfRule')" name="question-o" /></h1>
        <p>兑换积分商品，共享邻里幸福</p> -->
        <div @click="gotoLink('jfRule')" class="top-bar-button">积分规则</div>
      </div>
      
      <div class="number-card">
        <div class="top">
          <div class="top-left">
            <div class="label">可用积分</div>
            <div class="number">{{currentUserScore}}</div>
            <!-- <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/arrow-right.png" alt=""> -->
          </div>
          <div class="hqjf-button" @click="gotoLink('mrqd')">获取积分</div>
        </div>
        <div class="foot-nav">
          <div class="foot-item" @click="gotoLink('dhjl')">
            <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/ds-jfdh-dhjl.png"
                 alt=""
                 class="icon" />
            <span>兑换记录</span>
          </div>
          <div class="divide" />
          <div class="foot-item"
               @click="gotoLink('jfmx')">
            <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/ds-jfdh-jfmx.png"
                 alt=""
                 class="icon" />
            <span>积分明细</span>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="list-title">商品类型</div>
        <div class="flex wrap">
          <div class="card" @click="gotoLink(`jfdhList?type=${item.commodityTypeId}`)" v-for="item in iconList" :key="item.name">
            <img :src="item.commodityTypeIcon" class="icon" alt="">
            <p class="small-text">{{item.commodityTypeName}}</p>
          </div>
        </div>
      </div>
      <div class="jfdh-divider"></div>
      <div class="list">
        <div class="list-title">
          <span>热门兑换</span>
          <div class="more" @click="gotoLink('jfdhList')">
            <span>更多</span>
            <img
              src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png"
              alt=""
            />
          </div>
        </div>
        <van-list
          class="list-content"
          v-if="list && list.length > 0"
          :loading="loading"
          :finished="listFinished"
          @load="onLoad"
          finished-text="没有更多了"
        >
          <div class="card-nav"
            v-for="item in list"
            :key="item.commodityId"
            @click="gotoLink(`dhDetail?id=${item.commodityId}`)"
          >
            <div class="jfdh-status-tag jfdh-hot" v-if="item.commodityIsHot === 1">热门</div>
            <div class="jfdh-status-tag" v-else-if="item.stockType === 3">已兑完</div>
            <div class="jfdh-status-tag" v-else-if="item.expiredState === 1">已过期</div>
            <img class="image"
                :src="item.commodityImage"
                alt="暂无图片" />
            <div class="card-foot">
              <div class="title van-ellipsis">{{item.commodityName}}</div>
              <div class="jf">
                <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/积分.png" />
                <span>{{`${item.commodityNeedIntegral}积分`}}</span>
              </div>
              <div class="small-text gray">{{`${item.commoditySellNum}件已兑换`}}</div>
              <!-- <div @click="gotoLink(`dhDetail?id=${item.id}`)"
                  class="button">立即兑换</div> -->
            </div>
          </div>
          <!-- <div class="flex blue" style="width: 100%;justify-content: center;">
            <span>查看更多</span>
            <van-icon name="arrow-down" size="14" />
          </div> -->
        </van-list>
        <div class="no-data" v-else>
          <img src="http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/无数据.png" alt="">
          <p>暂无数据</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Toast } from 'vant';
import jfdhApi from 'api/ddya/jfdh';
import {jfdhShoping} from 'assets/json/jfdh';
export default {
  data () {
    return {
      currentUserScore: 0,
      list: [],
      current: 1,
      pageSize: 10,
      loading: false,
      listFinished: false,
      iconList: [],
      scrollTop: 0, // 滚动条的高度
      showTop: false, // 置顶按钮是否展示
    };
  },
  components: {},
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true);
    // 获取物品类型列表
    jfdhApi.getGoodsTypeList().then(res => {
      this.iconList = [ 
        ...(res.data || []),
        {
          commodityTypeName: '全部类型',
          commodityTypeIcon: 'http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/全部类型.png',
          commodityTypeId: '',
        } 
      ];
    });
    // 查询我的积分
    jfdhApi.getUsableIntegral().then(res => {
      this.currentUserScore = res.data || 0;
    })
    this.getEventList();
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },
    showAlert () {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getImage (key) {
      return key
    },
    dleEarnScore() {
      jfdhApi.checkUser().then(res => {
        if (res.data) {
          this.$dialog.alert({
            title: '提示',
            message: "您已被移入黑名单，无法获取积分",
          }).then(() => {
            this.gotoLink('mrqd');
          })
        } else {
          this.gotoLink('mrqd');
        }
      })
    },
    onLoad() {
      this.getEventList({ page: this.current + 1 });
      this.current = this.current + 1;
    },
    // 获取列表数据
    getEventList({ page = this.current, size = this.pageSize, thingType = this.selected } = {}) {
      jfdhApi.getScoreGoodList({
        page,
        size,
        commodityTypeId: null,
        isHot: 1,
        sorted: null
      }).then(res => {
        this.loading = false;
        const { records = [], total = 0 } = res.data || {};
        if(page === 1) {
          this.list = records;
        } else {
          this.list = Array.from(new Set(this.list.concat(records)))
        }
        this.total = total;
        if (this.list.length >= this.total) {
          // 数据全部加载完成
          this.listFinished = true;
        }

      })
    },
    // 滚动条回到顶部
    backTop () {      
      if (this.scrollTop > 10) {
        this.$refs.pageCon.scrollTop = 0;
      }
    },
    // 保存滚动值，这是兼容的写法
    handleScroll () {
      this.scrollTop = this.$refs.pageCon.scrollTop;
          //变量scrollTop是滚动条滚动时，距离顶部的距离
      const scrollTop = this.$refs.pageCon.scrollTop;      
      //变量windowHeight是可视区的高度
      const windowHeight = this.$refs.pageCon.offsetHeight;
      //变量windowHeight是可视区的高度
      const scrollHeight = this.$refs.pageCon.scrollHeight;
          //滚动条到底部的条件
      //  if(scrollTop + windowHeight >= scrollHeight){
        if (scrollTop > 10) {
            //你要触发的方法
            this.showTop = true;
        } else {
          this.showTop = false;
        }
    },

  },
};
</script>

<style lang="scss" scoped>
.jfdh-page-container {
  background: #ffffff;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.9rem;
  color: rgba(0,0,0,0.85);
  .flex {
    display: flex;
  }
  .around {
    justify-content: space-around;
  }
  .wrap {
    flex-wrap: wrap;
  }
  .between {
    justify-content: space-between;
  }
  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    >img {
      height: 120px;
      width: 120px;
    }
  }
  .top-btn {
    position: fixed;
    bottom: 20px;
    right: 6px;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    text-align: center;
    line-height: 36px;
    // border: 1px solid rgba(0,0,0,0.15);
    color: #FFF;
    font-size: 12px;
    background: #4379ed;
  }
  .list-title {
    border-left: 4px solid #4479ed;
    padding-left: 8px;
    font-size: 1.07rem;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    .more {
      float: right;
      color: rgba(0,0,0,0.45);
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      >img {
        height: 1rem;
      }
    }
  }
  .top-bar {
    height: 160px;
    width: 100vw;
    background: url("http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/ds-jfdh-banner.png") no-repeat;
    background-size: 100% 100%;
    color: #FFF;
    padding: 28px 31px;
    position: relative;
    .top-bar-title {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 36px;
      display: flex;
      align-items: center;
      .van-icon {
        margin-left: 6px;
      }
    }
    .top-bar-button {
      position: absolute;
      right: 0;
      top: 12px;
      background: #C6F9F2;
      color: #1352DD;
      border-radius: 15px 0 0 15px;
      padding: 0.3rem 0.5rem 0.3rem 0.8rem;
      font-size: 14px;
    }
  }
  .jfdh-divider {
    width: 100%;
    height: 12px;
    background: #f6f7fa;
  }
  .number-card {
    width: calc(100% - 24px);
    height: 7.2rem;
    border-radius: 4px;
    margin: -45px 12px 15px;
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
    border-radius: 4px;
    position: relative;
    // background: url("http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/积分背景.png") no-repeat;
    // background-size: 100% 100%;
    &::after {
      content: '',;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 5.3rem;
      height: 2.13rem;
      background: url("http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/jfdh-footer-nav-decoration.png") no-repeat;
      background-size: 100% 100%;
    }
    .top {
      // background: rgba(68,121,237,0.61);
      height: 60%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      .top-left {
        text-align: center;
        display: flex;
        align-items: center;
        .number {
          font-size: 24px;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: 600;
          margin: 0 4px;
        }
        .label {
          font-size: 16px;
        }
        >img {
          height: 12px;
        }
      }
      .hqjf-button {
        border: 1px solid #4479ED;
        border-radius: 4px;
        padding: 0.3rem 0.5rem;
        font-size: 14px;
        color: #FFF;
        background: #4479ED;
        letter-spacing: 0;
        text-align: center;
      }
    }
    .foot-nav {
      display: flex;
      align-items: center;
      height: 40%;
      align-items: center;
      border-top: 1px solid rgba(0,0,0,0.15);
      margin: 0 12px;
      .foot-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #4479ED;
        span {
          margin-left: 6px;
        }
        .icon {
          height: 1rem;
          width: 1rem;
        }
      }
      .divide {
        height: 1.6rem;
        width: 1px;
        background: rgba(0,0,0,0.15);
      }
    }
  }
  .card {
    width: 24%;
    padding: 6px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 1.4rem;
      margin-bottom: 4px;
    }
  }
  .list {
    width: 100%;
    padding: 12px;
    .list-title {
      border-left: 4px solid #4479ed;
      padding-left: 8px;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
    .list-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      ::v-deep .van-list__finished-text {
        width: 100%;
      }
      .card-nav {
        width: calc(50% - 1rem);
        margin: 1rem 0.5rem;
        position: relative;
        .jfdh-status-tag {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 0.8rem;
          line-height: 1.2rem;
          color: rgba(0,0,0,0.45);
          background: #FFF;
          border-radius: 0 2px 6px 2px;
          padding: 0 8px;
        }
        .jfdh-hot {
          color: #e13f30;
          background: #f9dcd7;
        }
        .image {
          width: 100%;
          height: 8rem;
          border-radius: 8px;
          background: #f6f7fa;
        }
        .card-foot {
          .title {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 500;
          }
          .jf {
            height: 24px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #e5b20a;
            img {
              height: 14px;
              width: 14px;
              margin-right: 4px;
            }
          }
          .button {
            background: #4479ed;
            border-radius: 4px;
            padding: 0.4rem;
            color: #ffffff;
            width: 5rem;
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
