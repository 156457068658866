<template>
  <div class="party-building-container">
   <div class="party-building-body-container">
      <div class="image-bar"></div>
      <!-- 按钮栏 -->
      <div class="icon-bar">
        <div v-for="(icon, index) in iconList" @click="handleApp(icon.path)" :key="`${icon.name}_${index}`">
          <img class="icon" :src="icon.icon" :alt="icon.name">
          <p>{{icon.name}}</p>
        </div>
      </div>
      <!-- 党建通知 -->
      <div class="notice-bar">
        <div class="left">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/notice.png" alt="">
        </div>
        <div class="right">
          <h4>7月党建会议即将召开</h4>
          <p>7月党建会议于2021年7月10日上午10点召开</p>
        </div>
        <div class="arrow">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/arrow.png" alt="">
        </div>
      </div>

       <!-- 我的应用 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <span class="text-title">我的应用</span>
        </div>
        <div class="card-bar-content">
          <div class="icon-bar">
            <div v-for="(icon, index) in appList" :key="`${icon.name}_${index}`">
              <img class="icon" :src="icon.icon" :alt="icon.name">
              <p>{{icon.name}}</p>
            </div>
          </div>
        </div>
      </div>
      
     <!-- 今日热搜 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <span class="text-title">今日热搜</span>
        </div>
        <div class="card-bar-content">
          <noticeList></noticeList>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import NoticeList from './noticeList';
// import OfficerCard from './officerCard.vue';
export default {
  data() {
    return {
      iconList: [
        {
          name: '红·脉动',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/hong.png',
          path: 'hong',
        },
        {
          name: '享·协同',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/coordination.png',
          path: 'coordination',
        },
        {
          name: '邻·共建',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/building.png',
          path: 'building'
        },
        {
          name: '合·管家',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/butler.png',
          path: 'butler',
        },
      ],
      appList: [
        {
          name: '我要入党',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/dang.png',
          path: 'dang',
        },
        {
          name: '组织生活',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/community.png',
          path: 'community',
        },
        {
          name: '两地报道',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/report.png',
          path: 'report'
        },
        {
          name: '更多',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/more.png',
          path: 'more',
        },
      ],
      communityList: ['润泽党支部', '香水湾党支部', '香林党支部']
    };
  },
  computed: {
  },
  components: { NoticeList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleApp(url) {
      this.$router.push(url);
    }
  }
};
</script>

<style lang="scss" scoped>
.party-building-container {
  height: 100vh;
  width: 100vw;
  
  .party-building-body-container {
    height: 100%;
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 223px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/partyBuilding/banner.png') no-repeat;
      background-size: 100% 100%;
    }

    .icon-bar {
      width: 100%;
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #FFF;
      >div {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        .icon {
          width: 47px;
          height: 47px;
          margin-bottom: 5px;
        }
        >p {
          text-align: center;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .notice-bar {
      height: 61px;
      width: calc(100% - 20px);
      margin: 10px;
      padding: 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      background-color: #FFF;
      .left {
        width: 41px;
        height: 41px;
        >img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        flex: 1;
        margin: 0 10px;
        >p {
          color: #a6a6a6;
          line-height: 24px;
        }
      }
      .arrow {
        >img {
          height: 20px;
        }
      }
    }

    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      background-color: #FFF;
      .card-bar-title {
        width: 100%;
        height: 33px;
        padding: 10px;
        background: #FFF;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .text-title {
          font-size: 13px;
          color: #2ba6e1;
          width: 50%;
        }
        .text-more {
          color: #bfbfbf;
          font-size: 11px;
          float: right;
          display: flex;
          align-items: center;
        }
      }
      .card-bar-content {
        width: 100%;
        
        .community-bar {
          width: 100%;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          >div {
            width: 30%;
            height: 94px;
            background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/community.png') no-repeat;
            background-size: 100%;
            display: flex;
            align-items: center;
            >p {
              width: 100%;
              line-height: 26px;
              background-color: rgba(255,0,0,0.61);
              color: #FFF;
              font-size: 14px;
              text-align: center;
            }
          }
        }
      }
    }
    .officers {
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/officer-bg.png') no-repeat;
      background-size: 100%;
      width: calc(100% - 20px);
    }
  }
}
</style>
