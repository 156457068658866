<template>
  <div class="own-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>接送详情</h1>
        <span v-if="data.organizer" class="deleDemand" @click="handleDeleteRequire">删除需求</span>
      </div>
      <div class="nrb-box">
        <div class="reply-detail">
          <div class="reply-info">
            <img :src="dataInfo.image" />
            <div class="title">{{ dataInfo.owner }}<br /><span>发布于{{ dataInfo.createTime }}</span></div>
            <div
              v-if="Object.keys(dataInfo).length"
              :style="{background: colors[dataInfo.status].background,color: colors[dataInfo.status].text}"
              class="tag"
            >
            {{dataInfo.status}}
          </div>
          </div>

          <div class="data">
            <div><label>孩子姓名：</label><span>{{ dataInfo.childName }}</span></div>
            <div><label>所在学校/班级：</label><span>{{ dataInfo.meetAddress }} {{ dataInfo.meetClass }}</span></div>
            <div><label>接送时间：</label><span>{{ dataInfo.meetTime }}</span></div>
            <div>
              <label>送往位置：</label><span>{{ dataInfo.sendAddress }}</span>
            </div>
          </div>
          <!-- <div class="img">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/yqpdd_banner.png" />
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/yqpdd_banner.png" />
          </div> -->
        </div>
        <div>
          <div class="main-table">
            接送备注
          </div>
          <div class="pj-info">
            {{ dataInfo.remark }}
          </div>
        </div>
        <div>
          <div class="main-table">
            接单情况
          </div>
          <div class="nr-list">
            <ul>
              <li v-for="item in orders" :key="item.id">
                <img :src="item.image" />
                <div>
                  <p>{{ item.owner }}</p>
                  <span>发布于{{ item.createTime }}</span>
                </div>
                <span v-if="data.organizer" class="contact">联系ta</span>
              </li>
              <!-- <li>
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/yqpdd_banner.png" />
                <div>
                  <p>李四<i>能人认证</i></p>
                  <span>发布于2021.5.21 12:00</span>
                </div>
                <span class="contact">联系ta</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="btns-flex" v-if="data && Object.keys(data).length > 0" style="justify-content: space-around;">
        <div
          v-for="(item, index) in getButton(data)"
          :key="index"
          class="cancel-btn"
        >
          <button
            :class="item.class"
            form-type="submit"
            @click="item.action"
          >
            {{item.name}}
          </button>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import { Search, Dialog, Toast } from "vant";
import yqpddApi from "api/yqpdd";
import { mapState } from "vuex";
export default {
  data() {
    return {
      value: "",
      data: {},
      dataInfo: {},
      orders: [],
      colors: {
        '未接单': { text: '#4479ED', background: '#E3EFFD' },
        '已接单': { text: '#E5B20A', background: '#FFF6D9' },
        '已完成': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' }
      },
    };
  },
  components: {},
  computed: {
    ...mapState({
      childrenToMeetDetail: state => {
        return state.llyjr.childrenToMeetDetail;
      }
    })
  },
  mounted() {
    this.getChildrenToMeetInfo();
    // this.getlist({ page: 1, size: 10 });
  },
  methods: {
    handleCloseOrder() {
      const { dataInfo } = this;
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '继续关闭',
        confirmButtonColor: '#2e70ed',
        cancelButtonText: '返回',
        cancelButtonColor: '#aaa',
        message: '关闭接单后，他人将无法继续接单，您也可以重新开启，请问是否关闭？',
      })
      .then(() => {
        yqpddApi.closeOrder({ id: dataInfo.id }).then(res => {
          const { message, flag } = res;
          if (flag) {
            Toast('已关闭接单');
            this.getChildrenToMeetInfo();
          } else {
            Toast(message);
          }
        });
      })
      .catch(() => {

      })
    },
    handleOpenOrder() {
      const { dataInfo } = this;
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '继续开启',
        confirmButtonColor: '#2e70ed',
        cancelButtonText: '返回',
        cancelButtonColor: '#aaa',
        message: '开启接单后，他人可以继续接单，请问是否开启？',
      })
      .then(() => {
        yqpddApi.openOrder({ id: dataInfo.id }).then(res => {
          const { message, flag } = res;
          if (flag) {
            Toast('已开启接单');
            this.getChildrenToMeetInfo();
          } else {
            Toast(message);
          }
        });
      })
      .catch(() => {

      })
    },
    handleCompleteOrder() {
      const { dataInfo } = this;
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '确认已完成',
        confirmButtonColor: '#2e70ed',
        cancelButtonText: '返回',
        cancelButtonColor: '#aaa',
        message: '请确认当前接单是否已完成',
      })
      .then(() => {
        yqpddApi.completeOrder({ id: dataInfo.id }).then(res => {
          const { message, flag } = res;
          if (flag) {
            Toast('当前接单已完成');
            this.getChildrenToMeetInfo();
          } else {
            Toast(message);
          }
        });
      })
      .catch(() => {

      })
    },
    handleReceiveOrder() {
      const { dataInfo } = this;
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '继续接单',
        confirmButtonColor: '#2e70ed',
        cancelButtonText: '返回',
        cancelButtonColor: '#aaa',
        message: '接单后，需求人可拨打您的联系电话，请问是否继续接单？',
      })
      .then(() => {
        yqpddApi.receiveOrder({ id: dataInfo.id }).then(res => {
          const { message, flag } = res;
          if (flag) {
            Toast('接单成功');
            this.getChildrenToMeetInfo();
          } else {
            Toast(message);
          }
        });
      })
      .catch(() => {

      })
    },
    handleCancelOrder() {
      const { dataInfo } = this;
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '确认取消',
        confirmButtonColor: '#2e70ed',
        cancelButtonText: '返回',
        cancelButtonColor: '#aaa',
        message: '请确认是否取消',
      })
      .then(() => {
        yqpddApi.cancelOrder({ id: dataInfo.id }).then(res => {
          const { message, flag } = res;
          if (flag) {
            Toast('取消接单成功');
            this.getChildrenToMeetInfo();
          } else {
            Toast(message);
          }
        });
      })
      .catch(() => {

      })
    },
    handleDeleteRequire() {
      const { dataInfo } = this;
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '确认删除',
        confirmButtonColor: '#2e70ed',
        cancelButtonText: '返回',
        cancelButtonColor: '#aaa',
        message: '请确认是否删除',
      })
      .then(() => {
        yqpddApi.deleteRequire({ id: dataInfo.id }).then(res => {
          const { message, flag } = res;
          if (flag) {
            Toast('删除成功');
            this.$router.go(-1);
          } else {
            Toast(message);
          }
        });
      })
      .catch(() => {

      })
    },
    uselessClick() {
      console.log('uselessClick')
    },
    getButton(record) {
      let list = [];
      const { detail, orders, organizer, enroll } = record
      const obj = {
        closeAndFinish: [
          { name: '关闭接单', class: 'close', action: this.handleCloseOrder},
          { name: '接单已完成', class: 'save', action: this.handleCompleteOrder},
        ],
        openAndFinish: [
          { name: '开启接单', class: 'open', action: this.handleOpenOrder},
          { name: '接单已完成', class: 'save', action: this.handleCompleteOrder},
        ],
        finish: [
          { name: '接单已完成', class: 'disabled', action: this.uselessClick},
        ],
        wyjd: [
          { name: '我要接单', class: 'save', action: this.handleReceiveOrder},
        ],
        qxjd: [
          { name: '取消接单', class: 'cancel', action: this.handleCancelOrder},
        ],
        jdysc: [
          { name: '接单已删除', class: 'disabled', action: this.uselessClick},
        ],
        ybjd: [
          { name: '已被接单', class: 'disabled', action: this.uselessClick},
        ]
      };
      if (organizer) {
        switch (detail.status) {
          case '未接单': list = obj.closeAndFinish;break;
          case '已接单': list = obj.openAndFinish;break;
          case '已完成': list = obj.finish;break;
        }
      } else {
        if (detail.status === '未接单') {
          if (enroll) {
            list = obj.qxjd;
          } else {
            list = obj.wyjd;
          }
        } else if (detail.status === '已接单') {
          list = obj.ybjd;
        } else if (detail.status === '已完成') {
          list = obj.finish;
        } else if (detail.status === '已删除') {
          list = obj.jdysc;
        }
      }
      return list;
    },
    getChildrenToMeetInfo() {
      yqpddApi.getChildrenToMeetDetail({ id: this.childrenToMeetDetail.id }).then(res => {
        const { detail, orders } = res.data;
        this.dataInfo = detail;
        this.orders = orders;
        this.data = res.data;
      })
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getlist(value) {
      const params = {
        page: 1,
        size: 10,
        object: {
          type: "拼接",
        },
        ...params,
      };
      yqpddApi.getOrders(params).then((res) => {
        const { records = [] } = res.data;
        records &&
          records.length &&
          records.map((item) => (item.img = item.image.split("#")[0]));
        this.pageDataList = records.filter((item) => item.type === "拼接");
      });
    },
    onSearch(value) {
      const params = {
        page: 1,
        size: 10,
        object: {
          title: value,
          type: "拼接",
        },
      };
      yqpddApi.getOrders(params).then((res) => {
        const { records = [] } = res.data;
        records &&
          records.length &&
          records.map((item) => (item.img = item.image.split("#")[0]));
        this.pageDataList = records;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/index.scss";
.page-container {
  .deleDemand {
    color: red;
  }
  // padding: 0.8rem 0rem;
  background: #fff;
  padding: 0;
  .nrb-box {
    margin-top: 2.8rem;
    .van-dropdown-menu__bar {
      height: 3rem;
      .van-dropdown-menu__title {
        font-size: 0.9rem;
        line-height: 3rem;
      }
    }
  }
  .btns-flex {
    position: fixed;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    bottom: 0;
    background-color: #ffffff;
    padding: 10px 5px;
    border-top: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    margin: 0;
    > .cancel-btn,
    > .delete-btn,
    > .save-btn {
      // width: 43.2vw;
      flex: 1;
      margin: 0 10px;
      > button {
        width: 100%;
        height: 2.5rem;
        border-radius: 22.5px;
        border: none !important;
        font-size: 1rem;
      }
      > .cancel {
        background: red;
        color: #fff;
      }
      > .open {
        border: 1px solid #2a70ed !important;
        background: #fff;
        color: #2a70ed;
      }
      > .close {
        border: 1px solid red !important;
        background: #fff;
        color: red;
      }
      > .disabeld {
        background: #eeeeee;
        color: #ffffff;
      }
      > .delete {
        background: #ff0000;
        color: #ffffff;
      }
      > .save {
        background: #2a70ed;
        color: #fff;
      }
      > .normal {
        background: #ffffff;
        color: #2a70ed;
        border: 1px solid #2a70ed !important;
      }
    }
  }
  .main-table {
    padding: 0 0 0 0.5rem;
    border-left: 0.2rem solid #3291f8;
    text-align: left;
    color: #101010;
    width: 23.4rem;
    margin: 0.3rem auto;
    font: 600 1rem/1.2rem "microsoft yahei";
  }
  .pj-info {
    color: #bbb;
    width: 23.4rem;
    margin: 0 auto 1.5rem;
    font: 500 0.9rem/1.4rem "microsoft yahei";
  }
  .reply-detail {
    width: 23.4rem;
    margin: 0 auto;
    padding: 1.5rem 0;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    .tit {
      font-weight: 600;
      color: #333;
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
    .data {
      font-size: 0.9rem;
      label {
        color: #bbb;
      }
      .blue {
        color: #1890ff;
      }
    }
    p {
      color: #7a7a7a;
    }
    .img {
      margin-top: 0.4rem;
      img {
        border-radius: 0.3rem;
        height: 5.4rem;
        width: 5.4rem;
        margin-right: 0.8rem;
      }
    }
    .reply-info {
      margin-bottom: 0.4rem;
      position: relative;
      height: 2rem;
      img {
        width: 2rem;
        height: 2rem;
        float: left;
        border-radius: 50%;
        margin-right: 0.5rem;
        vertical-align: middle;
      }
      .title {
        float: left;
        font: 500 0.8rem/1rem "microsoft yahei";
        color: #333;
        span {
          color: #bbb;
        }
      }
      .tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.2rem 0.5rem;
        border-radius: 4px;
        font-size: 12px;
      }
    }
    .state {
      padding-top: 0.4rem;
      span {
        display: inline-block;
        height: 1.4rem;
        padding: 0 0.7rem;
        line-height: 1.4rem;
        border-radius: 0.2rem;
        font-style: inherit;
        font-size: 0.8rem;
        background: #e8e8e8;
        color: #101010;
      }
    }
  }
  .nr-list {
    width: 23.4rem;
    margin: 0 auto;
    li {
      padding: 0.6rem 0;
      height: 3.6rem;
      img {
        width: 2rem;
        height: 2rem;
        float: left;
        border-radius: 50%;
        margin-right: 0.5rem;
        vertical-align: middle;
        margin-top: 0.2rem;
      }
      div {
        float: left;
        font: 500 0.8rem/1rem "microsoft yahei";
        color: #333;
        i {
          display: inline-block;
          height: 1.4rem;
          padding: 0 0.7rem;
          line-height: 1.4rem;
          border-radius: 0.2rem;
          font-style: inherit;
          font-size: 0.7rem;
          background: #e8e8e8;
          color: #101010;
          margin-left: 0.6rem;
        }
        span {
          color: #bbb;
        }
      }
      .contact {
        float: right;
        height: 2rem;
        width: 5rem;
        text-align: center;
        line-height: 2rem;
        border-radius: 1rem;
        font-style: inherit;
        font-size: 0.8rem;
        background: #fff;
        color: #3291f8;
        border: 1px solid #3291f8;
      }
    }
  }
  .button {
    position: fixed;
    left: 5vw;
    bottom: 1rem;
    width: 90vw;
    height: 3rem;
    font-size: 1rem;
    border-radius: 1.5rem;
  }
  .det {
    position: fixed;
    left: 0vw;
    bottom: 1rem;
    .button {
      position: initial;
      display: inline-block;
      width: 40vw;
      height: 3rem;
      font-size: 1rem;
      border-radius: 1.5rem;
      margin: 0 5vw;
      border: 0;
    }
    .red-btn {
      border: 1px solid #ff0000;
      color: #ff0000;
      background: #fff;
    }
  }
}
</style>
