<template>
  <div class="zljgDetailPerson" style="background: #fff; height: 100vh">
    <div class="page-container-zljg paddclose" style="height: calc(100vh);overflow-y: auto;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>{{title}}</h1>
      </div>
      <div class="zljg-main" style="width: 100%;">
        <div class="item" v-for="(item, index) in (list || [])" :key="index" @click="choiceItem(v)">
          <div class="person-item">
            <div class="image"><img :src="item.image || require('../../assets/images/personBig.png')" alt=""></div>
            <div class="text">
              <div>
                {{item.value1}}
                <!-- 妇联 -->
                <span v-if="key==='105'" class="tag">{{item.value2 || ''}}</span>
                <!-- 关工委 -->
                <span v-if="key==='106'" class="tag">{{item.value3 || ''}}</span>
                <!-- 调解委员会 -->
                <span v-if="key==='107'" class="tag">{{item.value5 || ''}}</span>
                <!-- 义警分会 -->
                <span v-if="key==='108'" class="tag">{{item.value2 || ''}}</span>
                <!-- 党总支人员 -->
                <span v-if="key==='98'" class="tag">{{item.tag || ''}}</span>
              </div>
                <!-- 监委会 -->
              <div v-if="key==='103'">
                <span class="label">政治面貌:</span>
                <span class="value">{{item.value5}}</span>
              </div>
              <div v-if="key==='103'">
                <span class="label">联系电话:</span>
                <span class="value">{{handleHidden(item.value2)}}</span>
              </div>
              <!-- 团支部 -->
              <div v-if="key==='104'">
                <span class="label">所属小区:</span>
                <span class="value">{{item.value2}}</span>
              </div>
              <div v-if="key==='104'">
                <span class="label">联系电话:</span>
                <span class="value">{{handleHidden(item.value4)}}</span>
              </div>
              <!-- 妇联 -->
              <div v-if="key==='105'">
                <span class="label">分管队伍:</span>
                <span class="value">{{item.value4 || '暂无'}}</span>
              </div>
              <div v-if="key==='105'">
                <span class="label">工作职责:</span>
                <span class="value">{{item.value3 || '暂无'}}</span>
              </div>
              <!-- 关工委 -->
              <div v-if="key==='106'">
                <span class="label">联系电话:</span>
                <span class="value">{{handleHidden(item.value2)}}</span>
              </div>
              <!-- 调解委员会 -->
              <div v-if="key==='107'">
                <span class="label">学历:</span>
                <span class="value">{{item.value3}}</span>
              </div>
              <div v-if="key==='107'">
                <span class="label">联系电话:</span>
                <span class="value">{{handleHidden(item.value4)}}</span>
              </div>
              <!-- 义警分会 -->
              <div v-if="key==='108'">
                <span class="label">公益团队:</span>
                <span class="value">{{item.value4}}</span>
              </div>
              <div v-if="key==='108'">
                <span class="label">联系电话:</span>
                <span class="value">{{handleHidden(item.value3)}}</span>
              </div>
              <!-- 法律顾问 -->
              <div v-if="key==='109'">
                <span class="label">工作单位:</span>
                <span class="value">{{item.value5}}</span>
              </div>
              <div v-if="key==='109'">
                <span class="label">联系电话:</span>
                <span class="value">{{handleHidden(item.value4)}}</span>
              </div>
              <!-- 金融顾问 -->
              <div v-if="key==='110'">
                <span class="label">工作单位:</span>
                <span class="value">{{item.value3}}</span>
              </div>
              <div v-if="key==='110'">
                <span class="label">联系电话:</span>
                <span class="value">{{handleHidden(item.value2)}}</span>
              </div>
              <!-- 健康顾问 -->
              <div v-if="key==='111'">
                <span class="label">联系电话:</span>
                <span class="value">{{handleHidden(item.value2)}}</span>
              </div>
              <div v-if="key==='111'">
                <span class="label">工作单位:</span>
                <span class="value">{{item.value3}}</span>
              </div>
              <!-- 政务顾问 -->
              <div v-if="key==='112'">
                <span class="label">联系电话:</span>
                <span class="value">{{handleHidden(item.value2)}}</span>
              </div>
              <div v-if="key==='112'">
                <span class="label">工作单位:</span>
                <span class="value">{{item.value3}}</span>
              </div>
              <!-- 党总支人员 -->
              <!-- <div v-if="key==='98'">
                <span class="label">学历:</span>
                <span class="value">{{item.education}}</span>
              </div> -->
              <!-- <div v-if="key==='98'">
                <span class="label">住址:</span>
                <span class="value">{{item.address}}</span>
              </div> -->
            </div>
          </div>
          <!-- 调解委员会 -->
          <div v-if="key==='107'" class="desc">{{item.value6 || ''}}</div>
          <!-- 法律顾问 -->
          <div v-if="key==='109'" class="desc">{{item.value6 || ''}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import {mapState} from 'vuex';
import service from 'utils/service';
import homeApi from 'api/home';
export default {
  data() {
    return {
      selectItem: [],
      list: [],
      title: '',
    };
  },
  components: {},
  mounted(opt) {
      const { key, name } = this.$route.query;
      this.key = key;
      this.title = name;
      if(key !== '98') {
        homeApi.getParty({ id: key }).then((res) => {
          const { data = [] } = res;
          this.list = data;
        })
      } else {
        homeApi.getPartyGeneralBbranchMember({}).then((res) => {
          const { data = [] } = res;
          this.list = data ? data.map(v => ({
            value1: v.name,
            ...v,
          })) : [];
        })
      }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleHidden(phone) {
      return phone.substr(0,3) + "********"
    },
    choiceItem(item){
      console.log(item)
      if (this.selectItem.indexOf(item.name) > -1) {
        this.selectItem = this.selectItem.filter(v => v !== item.name)
        console.log(1111, this.selectItem)
      } else {
        this.selectItem = [...this.selectItem, item.name]
        console.log(22222, this.selectItem)
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";
.zljgDetailPerson{
  .zljg-main{
    height: 100vh;
    padding: 10px;
    padding-top: 3.7rem;
    background: #f6f7fa;
    .item{
      background: #fff;
      padding: 10px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      .desc{
        line-height: 22px;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        font-weight: 400;
      }
      .person-item{
        display: flex;
        .image{
          width: 120px;
          height: 100px;
          margin-right: 10px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          width: calc(100% - 130px);
          font-size: 14px;
          color: rgba(0,0,0,0.85);
          font-weight: 500;
          line-height: 24px;
          .label{
            font-weight: 400;
            color: rgba(0,0,0,0.45);
            margin-right: 10px;
          }
          .value{
            font-weight: 400;
          }
          .tag{
            font-size: 12px;
            margin-left: 10px;
            padding: 3px 6px;
            color: #3E71F9;
            background: rgba(62,113,249,0.20);
            border-radius: 10px;
          }
        }
      }
    }
  }
}
</style>
