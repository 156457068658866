<template>
  <div class="main-container flex flex-col">
    <header class="flex">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <div class="title flex-1">订单信息</div>
      <van-icon />
    </header>
    <section class="flex-1 flex flex-col">
      <div class="box">
        <div class="order-status">待自提</div>
        <div class="flex items-center merchant-info">
          <div class="flex-1">
            <div class="flex">
              <span class="text-gray">提供店家：</span>
              <span>东盛超市</span>
            </div>
            <div class="flex">
              <span class="text-gray">自提地点：</span>
              <span>祥生君城商铺103号</span>
            </div>
            <div class="flex">
              <span class="text-gray">自提时间：</span>
              <span>周一至周五 9:00-20:00</span>
            </div>
            <div class="flex">
              <span class="text-gray">订单备注：</span>
              <span>
                请尽快送货哦
              </span>
            </div>
          </div>
          <span class="text-blue">
            <svg-icon icon-class="location" />
            查看
          </span>
        </div>
        <div class="flex flex-col items-center order-info">
          <span>提货码：4387 13</span>
          <img src="~assets/images/llg/qrcode.png" alt="" />
          <span class="text-gray">提货时将此二维码或提货码展示给商家</span>
        </div>
      </div>
      <div class="box shopping-car">
        <div class="title">
          <img src="~assets/images/llg/shop.svg" alt="" />
          东盛超市
        </div>
        <div>
          <div class="flex goods" v-for="goods in shoppingCar" :key="goods.id">
            <img :src="goods.image" alt="" />
            <span class="flex-1">{{ goods.name }}</span>
            <span>X{{ goods.num }}</span>
            <span class="text-pink">￥{{ goods.price }}</span>
          </div>
        </div>
      </div>
      <div class="box">
        <div>
          <div>
            <span class="text-gray">订单编号：</span>
            <span>2021121409543401</span>
          </div>
          <div>
            <span class="text-gray">下单时间：</span>
            <span>{{moment().format('YYYY-MM-DD HH:mm:ss')}}</span>
          </div>
        </div>
      </div>
      <div class="button">
        <van-button type="info" block round @click="noOrder">取消订单</van-button>
      </div>
    </section>
  </div>
</template>
<script>
import haMiMelon from '@/assets/images/llg/hami_melon.png';
import { Notify } from 'vant';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      haMiMelon,
      moment,
    };
  },
  computed: {
    ...mapState({
      shoppingCar: (state) => state.llg.order,
    }),
    total() {
      return this.shoppingCarList.reduce(
        (prev, curr) => {
          return {
            num: prev.num + curr.num,
            price: prev.price + +(curr.num * curr.price).toFixed(2),
          };
        },
        { num: 0, price: 0 },
      );
    },
  },
  mounted() {
  },
  methods: {
    noOrder() {
      Notify('取消成功');
      this.$router.push('/llg');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/llg.scss';
section {
  padding-top: 10px;
}
.box {
  position: relative;
  margin: 0 10px 10px 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 14px;
  line-height: 22px;
  .title {
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0;
    > img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
}
.shopping-car {
  .goods {
    padding: 5px 0;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    > img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }
    > span {
      opacity: 0.85;
    }
    > span:not(:nth-child(2)) {
      min-width: 50px;
      text-align: right;
    }
  }
}
.order-status {
  padding: 5px 10px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #e3effd;
  color: #3e71f9;
  border-radius: 4px;
}
.merchant-info {
  font-size: 14px;
  line-height: 22px;
  .text-gray {
    white-space: nowrap;
  }
}
.order-info {
  padding: 10px;
  > span:first-child {
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
  }
  > img {
    width: 10rem;
    height: 10rem;
    margin: 10px;
  }
}
.button {
  margin: 10px;
}
</style>
