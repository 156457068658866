<template>
  <div class="service-card" @click="gotoLink(`wiseManDetail?id=${data.id}`)">
    <div class="image-bar">
      <!-- <img :src="data.picture" alt=""> -->
      <van-image
        class="img-head"
        fit="cover"
        :src="data.serviceImage" />
    </div>
    <div class="info-bar">
      <div class="title">{{data.serviceTitle}}</div>
      <div class="row">
        <span class="label">服务时间：</span>
        <span class="value">{{data.serviceTime}}</span>
      </div>
      <div class="row">
        <span class="label">服务人：</span>
        <span class="value">{{data.servicePerson}}</span>
        <i class="phone" @click="callPhone(data.servicePhone)"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'serviceCard',
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/building/service-default.png',
          title: '家庭电脑帮维修，解决蓝屏问题',
          location: '东盛社区邻里中心',
          date: '2021-07-08 12:00',
        };
      }
    },
  },
  mounted() {
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    callPhone(phone) {
      if(phone) {
        window.location.href = `tel://${phone}`;
      } else {
        this.$toast('暂无联系方式')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.service-card {
  width: calc(100% - 20px);
  margin: 12px 10px;
  padding: 0;
  border-radius: 4px;
  background: #FFF;
  .image-bar {
    width: 100%;
    height: 119px;
    .img-head {
      height: 100%;
      width: 100%;
    }
  }
  .info-bar {
    width: 100%;
    padding: 10px;
    .title {
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      letter-spacing: 0;
      font-weight: 500;
      line-height: 36px;
    }
    .row {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      .label {
        color: rgba(0,0,0,0.45);
      }
      .value {
        color: rgba(0,0,0,0.85);
      }
      .phone {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 5px;
        background: url("~assets/images/phone.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>