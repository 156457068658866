<template>
  <div class="xyxxgs-container">
    <van-nav-bar
      class="xyxxgs-head-bar"
      title="信用信息公示"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="xyxxgs-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <!-- <svg-icon icon-class="xyxxgs" ></svg-icon> -->
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/butler/xyxxgs.svg" alt="">
      </div>
      <div class="way-one">
        <h1>方式一</h1>
        <div class="flex-way-one">
          <span>点击前往国家企业信用信息公示系统(企业年报）</span>
          <button @click="turnTo">点击前往</button>
        </div>
      </div>
      <div class="way-two">
        <h1>方式二</h1>
        <p>微信搜索“浙江企业在线” 公众号或扫二维码</p>
        <div class="image-con">
          <div>
            <img src="~assets/images/qynbImageWechat.png" alt="">
          </div>
          <div>
            <img src="~assets/images/qynbImage.jpg" alt="">
            <p>微信扫码</p>
          </div>
        </div>
      </div>
      <!-- <svg-icon class="phoneWechat" icon-class="phoneWechat" ></svg-icon> -->
      <img src="http://dssq.ikeqiao.net/data/image/h5/images/butler/phoneWechat.svg" class="phoneWechat" alt="">
      <!-- <svg-icon class="cloud" icon-class="cloud" ></svg-icon> -->
      <img src="http://dssq.ikeqiao.net/data/image/h5/images/butler/cloud.svg" class="cloud" alt="">
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    turnTo() {
      window.open('http://223.4.72.189/reg/client/login/all');
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.xyxxgs-container {
  height: 100vh;
  width: 100vw;
  .xyxxgs-head-bar {
    background: #55CFC5;
    ::v-deep .van-nav-bar__title {
      color: white;
    }
    ::v-deep .van-icon {
      color: white;
    }
  }
  .xyxxgs-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background-image: linear-gradient(0deg, #547DF5 12%, #55CFC5 97%);
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    line-height: 24px;
    .top-bar {
      width: 100%;
      margin: 20px 0;
      img {
        width: 80%;
        margin-left: 10%;
      }
    }
    .way-one {
      background: #FFF;
      border-radius: 4px;
      margin: 12px 20px;
      width: calc(100% - 40px);
      padding: 12px;
      >h1 {
        font-size: 20px;
        color: #1C60F6;
        line-height: 24px;
        font-weight: 600;
      }
      .flex-way-one {
        display: flex;
        >button {
          height: 32px;
          width: 80px;
          background: #4479ED;
          box-shadow: 0px 1px 8px 0px rgba(19,43,107,0.2);
          border-radius: 2px;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 32px;
          font-weight: 500;
          border: none;
        }
        >span {
          width: calc(100% - 80px);
        }
      }
    }
    .way-two {
      background-image: linear-gradient(180deg, #F1F3F9 0%, rgba(255,255,255,0.20) 100%);
      box-shadow: 0px 1px 8px 0px rgba(19,43,107,0.2);
      border-radius: 4px;
      margin: 12px 20px;
      width: calc(100% - 40px);
      padding: 12px;
      position: relative;
      >h1 {
        font-size: 20px;
        color: #1C60F6;
        line-height: 24px;
        font-weight: 600;
      }
      .image-con {
        width: 100%;
        padding: 20px 0;
        display: flex;
        >div {
          width: 50%;
          >img {
            width: 90%;
            margin-left: 5%;
          }
          >p {
            text-align: center;
            font-size: 14px;
            color: #FFFFFF;
          }
        }
      }
    }
     .phoneWechat {
      position: fixed;
      bottom: 0;
      left: 10px;
      width: 181px;
      height: 96px;
      z-index: 9;
    }
    .cloud {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 179px;
      width: 100vw;
    }
  }
}
</style>
