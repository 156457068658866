<template>
  <div id="index">
    <div class="edu-container">
      <header class="eduheader-nav">
        <i @click="gotoLink('/')"></i>
        <h1>培训地图</h1>
      </header>
      <el-amap
        vid="amap"
        class="map-area"
        :center="center"
        :amap-manager="amapManager"
        :zoom="zoom"
        mapStyle="fresh"
      >
        <el-amap-marker
          v-for="item in orgList"
          :key="item.type"
          :position="item.gisArr"
          :title="item.orgName"
          :events="events"
          :extData="item"
          :icon="getIcon(item.type)"
        >
        </el-amap-marker>
      </el-amap>
      <div class="m-top">
        <div class="search">
          <div class="box">
            <div class="mint-search">
              <el-input placeholder="请输入内容" v-model="search">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
              <i class="el-icon-error"></i>
            </div>
            <el-button type="primary" icon="icon-cancel" @click="toogleSearch"
              >筛选</el-button
            >
          </div>
          <div class="cate">
            <span :class="{ selected: selectTag == 0 }" @click="selectType(0)"
              >文化科目</span
            >
            <span :class="{ selected: selectTag == 1 }" @click="selectType(1)"
              >艺体科学</span
            >
          </div>
        </div>
        <div class="screen-box" v-if="moreSearch">
          <div class="main">
            <div class="item-data">
              <b>范围</b>
              <div class="category">
                <span
                  v-for="item in scopeList"
                  :key="item"
                  :class="{
                    ahover:
                      searchTags.filter(function(e) {
                        return e == item;
                      }).length != 0,
                  }"
                  @click="selectScope(item)"
                  >{{ item }}</span
                >
              </div>
            </div>
            <div class="item-data">
              <b>星级</b>
              <div class="category">
                <span
                  v-for="item in starList"
                  :key="item"
                  :class="{
                    ahover:
                      searchTags.filter(function(e) {
                        return e == item;
                      }).length != 0,
                  }"
                  @click="selectScope(item)"
                  >{{ item }}</span
                >
              </div>
            </div>
            <div class="item-data">
              <b>街道</b>
              <div class="category">
                <span
                  v-for="item in streetList"
                  :key="item"
                  :class="{
                    ahover:
                      searchTags.filter(function(e) {
                        return e == item;
                      }).length != 0,
                  }"
                  @click="selectScope(item)"
                  >{{ item }}</span
                >
              </div>
            </div>
          </div>
          <div class="sreen-opear">
            <span @click="clearSearch">清 空</span>
            <span class="ahover" @click="toogleSearch">完 成</span>
          </div>
        </div>
        <div class="map-deatil clearfix" v-if="showOrg.id">
          <div class="float-l img">
            <img :src="getSrc(showOrg.id)" :style="{ height: imgH + 'px' }" />
          </div>
          <div class="float-l desc" ref="mapdesc">
            <div class="title">{{ showOrg.orgName }}</div>
            <div class="star">
              <el-rate
                v-model="showOrg.stars"
                disabled
                show-score
                text-color="#dc6e86"
                :score-template="showOrg.stars + ''"
              ></el-rate>
            </div>
            <p>{{ showOrg.address }}</p>
            <p>联系电话：13600000000</p>
            <div class="state">
              <div class="float-l">
                <span class="exam-tag tag1">审批合格</span>
              </div>
              <div class="float-r">
                <span class="commbtn" @click="gotoLink('organDetail')"
                  >详情</span
                ><span class="commbtn">报名</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
let amapManager = new AMapManager(); // 新建生成地图画布

export default {
  data() {
    let self = this;
    return {
      value: 4.5,
      imgH: 0,
      search: "",
      zoom: 16,
      center: [120.490566, 30.086084],
      amapManager,
      showOrg: {},
      searchTags: [],
      orgList: [],
      starList: ["三星级", "四星级", "五星级"],
      streetList: [
        "浣东街道",
        "柯岩街道",
        "华舍街道",
        "湖塘街道",
        "钱清街道",
        "杨汛桥街道",
        "马鞍街道",
        "齐贤街道",
        "安昌街道",
        "福全街道",
        "漓渚镇",
        "兰亭街道",
        "夏履镇",
        "平水镇",
      ],
      moreSearch: false,
      selectTag: null,
      scopeList: [
        "语文",
        "数学",
        "英语",
        "物理",
        "英语（成人）",
        "科学",
        "信息技术",
        "史地",
        "中学语文",
        "思政",
        "生物",
        "舞蹈",
        "音乐",
        "书法",
        "美术",
        "乐器艺术",
        "奥数",
        "跆拳道",
        "搏击",
        "武术",
        "散打",
        "积木搭建",
        "信息技术编程",
        "少儿艺术",
      ],
      events: {
        click: (a) => {
          var orgDetail = a.target.G.extData;
          self.showOrg = orgDetail;
          setTimeout(function() {
            self.imgH = self.$refs.mapdesc.offsetHeight;
            console.log(this.imgH);
          }, 300);
        },
      },
    };
  },
  components: {},
  mounted() {
    this.getOrgList();
  },
  methods: {
    getOrgList() {
      let _this = this;
      //获取机构信息
      this.axios
        .get("http://124.91.150.6:8071/app/train/list")
        .then(function(response) {
          let result = response.data.list;
          result.forEach((val, index) => {
            let gis = val.gis;

            if (gis) {
              val.gisArr = gis.split(",");
            }
          });
          _this.orgList = result;
        })
        .catch(function(err) {
          // eslint-disable-line no-unused-vars
        });
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    toogleSearch() {
      let moreSearch = this.moreSearch;
      this.moreSearch = !moreSearch;
    },
    clearSearch() {
      this.searchTags = [];
    },
    getSrc(id) {
      return "http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/face-" +
        id +
        ".jpg";
    },
    getIcon(type) {
      if (type == "文化类") {
        return "http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/icon-art.png";
      } else {
        return "http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/icon-cult.png";
      }
    },
    selectType(type) {
      if (this.selectTag == type) {
      }
      this.selectTag = type;
      if (type == 0) {
        this.scopeList = [
          "语文",
          "数学",
          "英语",
          "物理",
          "英语（成人）",
          "科学",
          "信息技术",
          "史地",
          "中学语文",
          "思政",
          "生物",
        ];
      } else {
        this.scopeList = [
          "舞蹈",
          "音乐",
          "书法",
          "美术",
          "乐器艺术",
          "奥数",
          "跆拳道",
          "搏击",
          "武术",
          "散打",
          "积木搭建",
          "信息技术编程",
          "少儿艺术",
        ];
      }
    },
    selectScope(item) {
      let searchTags = this.searchTags;

      if (
        searchTags.filter(function(e) {
          return e == item;
        }).length != 0
      ) {
        //取消选中

        searchTags.splice(
          searchTags.findIndex((val) => val == item),
          1
        );
      } else {
        //选中
        searchTags.push(item);
      }
      this.searchTags = searchTags;
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/educate.scss";
</style>
