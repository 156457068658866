<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="活动需求"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="activity-needs-body-container">
      <!-- 过滤下拉选择 -->
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item v-model="status" :options="statusOption" />
        <van-dropdown-item :title="dateString || '全部时间'" ref="datetime">
          <van-datetime-picker
            v-model="date"
            type="date"
            title="选择时间"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="cancleDate"
            @confirm="onConfirm"
            :formatter="formatter"
          />
        </van-dropdown-item>
      </van-dropdown-menu>
      <div class="card-list-con">
        <activityNeedList></activityNeedList>
      </div>
    </div>
    <div class="tab-flex-bottom" @click="gotoLink('publishNeed')">发布需求</div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import activityNeedList from './activityNeedList';
export default {
  data() {
    return {
      date: '', // 过滤日期
      status: 0, // 过滤状态
      statusOption: [
        { text: '全部状态', value: 0 },
        { text: '已抢单', value: 1 },
        { text: '未抢单', value: 2 } ,
      ],
      minDate: new Date(2021, 5, 1),
      maxDate: new Date(2025, 10, 1),
    };
  },
  computed: {
    dateString() {
      return this.date ? this.formatDateTime(this.date) : '全部时间';
    }
  },
  components: { activityNeedList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    cancleDate() {
      this.$refs.datetime.toggle();
    },
    onConfirm(value) {
      this.$refs.datetime.toggle(); // 关闭筛选
      this.date = value; // 日期赋值
      // 过滤
    },
    // 日期格式化
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      console.log("y  ", y + "-" + m + "-" + d + " " + h + ":" + m + ":00");
      return y + "-" + m + "-" + d;
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .activity-needs-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .card-list-con {
      height: calc(100% - 48px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

  }
  .tab-flex-bottom {
    position: fixed;
    bottom: 3rem;
    right: 10px;
    background: #4479ed;
    box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    color: #fff;
    padding: 0.8rem;
    font-size: 0.85rem;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.2rem;
  }
}
</style>
