
<template>
  <div class="education-source-container">
    <van-nav-bar
      title="政策机构"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="policy-depart-body-container">
      <!-- 学习地图 -->
      <!-- <div class="coin-bar-con">
        <div class="coin-bar">
          <h1>学习地图</h1>
          <h3>汇聚周边优质学校、机构</h3>
          <button>查看</button>
        </div>
      </div> -->
      <!-- 政策资讯 -->
      <div class="card-nav">
        <div class="card-title">
          <div>
            <span class="title-bar"> </span>
            <span class="title-text">政策热搜</span>
          </div>
        </div>
        <div class="card-content">
          <newsList></newsList>
          <!-- <van-tabs @click="handleTab" color="#4479ED">
            <van-tab title="改革发展">
              <newsList></newsList>
            </van-tab>
            <van-tab title="统计规划">
              <newsList></newsList>
            </van-tab>
            <van-tab title="考试报名">
              <newsList></newsList>
            </van-tab>
            <van-tab title="合作交流">
              <newsList></newsList>
            </van-tab>
          </van-tabs> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import newsList from './newsList.vue';
export default {
  data() {
    return {
      iconList: [
        {
          name: '政策机构',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/policy.png',
        },
        {
          name: '学习资源',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/study.png',
          // path: 'wiseMan',
        },
        {
          name: '数字小社员',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/educationSource/digital.png',
        },
      ],
    };
  },
  computed: {
  },
  components: { newsList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.education-source-container {
  height: 100vh;
  width: 100vw;
  .policy-depart-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .coin-bar-con {
      display: flex;
      background-color: #FFF;
      .coin-bar {
        width: 100%;
        margin: 10px;
        height: 100px;
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/educationSource/study-map.png') no-repeat;
        background-size: 100% 100%;
        padding: 10px;
        color: #FFF;
        >h1 {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 32px;
          text-shadow: 0 2px 4px rgba(0,0,0,0.13);
          font-weight: 600;
        }
        >h3 {
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: left;
          font-weight: 200;
        }
        >button {
          border: 0.5px solid #FFFFFF;
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.07);
          border-radius: 14px;
          padding: 2px 8px;
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: center;
          background: none;
          margin-top: 10px;
        }
      }

      .map {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/map-bg.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 0;
      }
    }

    .card-nav {
      width: 100vw;
      background: #FFF;
      padding: 10px;
      margin-top: 10px;
      .card-title {
        width: 100%;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-bar {
            height: 18px;
            width: 4px;
            background: #4479ED;
            margin-right: 10px;
          }
          .title-text {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
          }
          .more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
          i {
            color: #4479ED;
          }
        }
      }
      .card-content {
        
      }
    }
  }
}
</style>
