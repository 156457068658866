<template>
  <div class="own-index" style="background-color:#f5f5f5">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>个人信息</h1>
      </div>
      <div class="park-main">
        <div class='own-form'>
          <div class="row">
                <div class="lable">
                    头&nbsp;&nbsp;像
                </div>
                <div class='value'>
                    <img class="image-value" :src="userInfo.image || 'http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png'" alt="">
                </div>
            </div>
            <div class="row">
                <div class="lable">
                    姓&nbsp;&nbsp;名
                </div>
                <div class='value'>
                    <input placeholder="暂无信息" disabled :value="userInfo.personName || userInfo.realName" maxlength="6" />
                </div>
            </div>
            <div class="row">
                <div class="lable">
                  身份证号
                </div>
                <div class='value'>
                    <input maxlength="18" :value="userInfo.identifyCard" disabled placeholder="暂无信息">
                </div>
            </div>
            <div class="row">
                <div class="lable">
                  注册手机
                </div>
                <div class='value'>
                    <input disabled :value="userInfo.phone" placeholder="暂无信息">
                </div>
            </div>
            <div class="daryLine"></div>
            <div class="row">
                <div class="lable">
                    所在小区
                    <!-- <span class="required">*</span> -->
                </div>
                <div class='value icon' @click="showCommunity = true">
                    <input disabled  placeholder="请选择所在小区" v-model="communityName">
                </div>
            </div>
            <div class="row">
                <div class="lable">
                    所在房屋
                    <!-- <span class="required">*</span> -->
                </div>
                <div class='value icon' @click="handleHouseClick">
                    <input disabled v-model="positionName" placeholder="请选择所在房屋">
                </div>
            </div>
             <div class="row">
                <div class="lable">
                    与户主关系
                    <!-- <span class="required">*</span> -->
                </div>
                <div class='value icon' @click="gotoSel('loginList')">
                    <input disabled v-model="relationship" placeholder="请选择住户类型">
                </div>
            </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showType" round position="bottom">
      <van-picker
        title="与户主关系"
        show-toolbar
        :columns="livingTypeData"
        @confirm="onConfirm"
        @cancel="onCancle"
        value-key='dictLabel'
      />
    </van-popup>
    <van-popup v-model="showCommunity" round position="bottom">
      <van-picker
        title="小区"
        show-toolbar
        :columns="communityList"
        @confirm="onCfmConfirm"
        @cancel="onCfmCancle"
        value-key='key'
      />
    </van-popup>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        :value="positionName"
        @close="onFwCancle"
        title="选择房屋"
        :options="addressList"
        :field-names="fieldNames"
        @finish="onFinish"
      />
    </van-popup>
    <div class="btns-flex" style="justify-content:center">
        <div class="save-btn"><button class="save" @click="handleSubmit">保存</button></div>
    </div>
  </div>
</template>

<script>
import { Actionsheet } from 'mint-ui';
import { mapState } from 'vuex';
import { Toast } from 'vant';
import context from '@/main';
import api from "api";
import { isIdentityId } from 'utils';
export default {
  data() {
    return {
      show:false,
      showType: false,
      showCommunity: false,
      positionName:null,
      address:null,
      sheetVisible:false,
      relationship:null,
      fieldNames: {
        text: 'key',
        value: 'value',
        children: 'children',
      },
      personName: '',
      certificatesCode: '',
      householdType:'',
      houseId: '',
      communityList: [],
      communityId: '',
      communityName: '',
      inviterId: '',
      inviterCode: '',
      userInfo: {},
      oldPositionName: '',
    }
  },
  components: {},
  mounted() {
      this.address=this.$route.params.detail&&this.$route.params.detail.title;
      this.$store.dispatch('login/getlivingTypeData');
      this.getCommunityData();
      this.getUserInfo();
  },
  methods: {
    handleName() {
      this.personName=this.personName.replace(/^\w*/g, '');
    },
    handleHouseClick() {
      if(this.communityId || (this.community && this.community.value)) {
        this.getHouseData();
        this.show = true;
      } else {
        Toast('请先选择小区!')
      }
    },
    getUserInfo() {
      api.getUserDetailInfo({}).then(res => {
        // console.log('data', res.data);
        const result = res.data || {};
        this.userInfo = result;
        this.communityName = result.communityName || '';
        this.communityId = result.communityId || '';
        this.positionName = result.positionName || '';
        this.oldPositionName = result.positionName || '';
        this.relationship = result.relationship || '';
      })
    },
    getCommunityData() {
      this.$store.dispatch('login/getCommunityData', { params:{}, obj: this });
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    onConfirm(e){
        this.relationship = e.dictLabel;
        this.householdType = e.dictValue;
        this.showType = false;
    },
    onCancle() {
      this.showType = false;
    },
    onFwCancle(){
      console.log(111111);
      this.show = false;
      this.positionName = this.oldPositionName;
    },
    onCfmConfirm(e){
        this.communityId = e.value;
        this.communityName = e.key;
        this.houseId = '';
        this.positionName = '';
        this.relationship = '';
        this.showCommunity = false;
        this.$store.commit('login/selectCommunity', e);
    },
    onCfmCancle() {
      this.showCommunity = false;
    },
    onFinish({ selectedOptions }) {
      console.log('11111');
      
      this.show = false;
      this.positionName = selectedOptions.map((option) => option.key).join('-');
      this.houseId = selectedOptions[2].value;
      this.oldPositionName = this.positionName || '';
    },
    gotoSel(){
        this.showType=true;
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getHouseData() {
      this.$store.dispatch('login/getBuildingData', this);
    },
    checkForm() {
      const checkObj = {
        // personName: '姓名',
        // certificatesCode: '身份证号',
        // positionName: '所在房屋',
        // relationship: '与户主关系',
        // community: '所住小区'
      };
      let message = Object.keys(checkObj).reduce((result, current) => {
        if(!this[current]) {
          result += `${checkObj[current]}，`;
        }
        return result;
      },'');
      if(message) {
        message += '不能为空。';
        if(isIdentityId(this.certificatesCode)) {
          message += isIdentityId(this.certificatesCode);
        }
        Toast.fail(message);
        return false;
      }
      return true;
    },
    handleSubmit() {
      if(this.checkForm()) {
        const params = [
          'communityId', 
          'communityName', 
          'houseId', 
          'positionName', 
          'householdType', 
          'relationship',
        ].reduce((result, current) => {
          result[current] = this[current];
          return result;
        }, {});
        // console.log('params', params);
        api.updateUserDetailInfo({...this.userInfo, ...params }).then(res => {
          if(res.flag) {
            Toast('信息修改成功！');
            setTimeout(() => {
              // this.$router.push('/');
              this.$router.go(-1);
            }, 500);
          }
            
        })
      }
    }
  },
  computed: {
    ...mapState({
      community: (state) => state.login.community,
      addressList: (state) => state.login.buildingData,
      livingTypeData: (state) => state.login.livingTypeData,
      phone: (state) => state.login.phone || context.getSessionData('phone'),
      // userInfo: (state) => (state.login.userInfo.nickname ? state.login.userInfo : null) || context.getSessionData('userInfo'),
    }),
    // communityId() {
    //   return this.community.value;
    // },
    // communityName() {
    //   return this.community.key;
    // }
  }
};
</script>

<style lang="scss">
.park-main {
    padding-top: 3.2rem;
    .nav {
      margin-bottom: 0.8rem;
      border-bottom: 0;
      .item {
        float: left;
        width: 33%;
        img {
          width: 30vw;
        }
      }
    }
  .own-form {
    background: #FFF;
      padding: 0.5rem 0.8rem;
      .row {
        padding: 0.5rem;
        width: calc(100% - 1rem);
        display: flex;
        font-size: 16px;
        line-height: 1.8rem;
        border-bottom: 1px solid #f5f5f5;
        .lable {
          min-width: 6rem;
        }
        .required {
          color: #e13f30;
        }
        .icon::after {
          content: "";
          background-image: url("http://dssq.ikeqiao.net/data/image/h5/images/arrow_down.png");
          background-repeat: no-repeat;
          background-size: 1.2rem;
          width: 1rem;
          height: 1rem;
          top: 0.4rem;
          position: absolute;
          right: -0.8rem;
        }
        .value {
          width: calc(100% - 6rem);
          display: flex;
          justify-content: flex-end;
          position: relative;
          img {
            width: 36px;
            height: 36px;
            float: right;
          }
          input {
            width: 100%;
            border: none;
            text-align: right;
            padding: 0 0.5rem;
          }
          input:disabled {
            background: #ffffff;
          }
          button {
            font-size: 0.8rem;
            border-radius: 30px;
            background-color: rgba(50, 145, 248, 100);
            color: rgba(255, 255, 255, 100);
            border: none;
            min-width: 5rem;
          }
          textarea {
            border: 1px solid #f5f5f5;
            text-align: left;
            width: 100%;
            padding: 0 0.5rem;
          }
          .fileUpload,
          input[type="file"] {
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/plus.png) repeat-y top center #f8f8f8;
            background-size: auto 100%;
            width: 5rem;
            height: 5rem;
            margin: 0.8rem 0;
          }
          select {
            width: 100%;
            border: none;
            text-align: right;
            padding: 0 0.5rem;
            background-color: #fff;
            line-height: 1.8rem;
            height: 1.8rem;
          }
        }
      }
    }
  }
.main-des{
    background-color: #f5f5f5;
    line-height: 1.8rem;
    color: rgba(187, 187, 187, 100);
    font-size: 0.9rem;
    padding:0.8rem 1.6rem;
    margin-left: -0.8rem;
    width:110%;
}
.daryLine{
    margin-left: -1.6rem !important;
    width:120% !important
}
.btns-flex {
    position: fixed;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    bottom: 0;
    background-color: #ffffff;
    padding: 10px 5px;
    border-top: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    margin: 0;
    > .cancel-btn,
    > .delete-btn,
    > .save-btn {
      // width: 43.2vw;
      flex: 1;
      margin: 0 10px;
      > button {
        width: 100%;
        height: 2.5rem;
        border-radius: 22.5px;
        border: none !important;
        font-size: 1rem;
      }
      > .cancel {
        background: #eeeeee;
        color: #000000;
      }
      > .disabeld {
        background: #eeeeee;
        color: #ffffff;
      }
      > .delete {
        background: #ff0000;
        color: #ffffff;
      }
      > .save {
        background: #2a70ed;
        color: #fff;
      }
      > .normal {
        background: #ffffff;
        color: #2a70ed;
        border: 1px solid #2a70ed !important;
      }
    }
  }
</style>
