<template>
  <div id="index">
    <div style="padding-top: 0px; margin-top: 0px !important; height: 100vh !important">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>邻里聊</h1>
      </div>
      <!-- <div class="float-button" @click="handleQuestion">我要提问</div> -->
      <div class="zntContent">
        <div class="zntDb" v-if="zntItemSelect === 'zntDb'">
          <van-tabs @click="handleTabSelect" v-model="zntDbSelected" color="#4479ED">
            <van-tab v-for="item in dbTypeList" :key="item"  :title="item" :name="item">
            </van-tab>
          </van-tabs>
          <div class="zntList">
            <div class="zntItem" v-for="(item, i) in list" :key="i" @click="gotoLink('/zntDbDetail?key=' + item.invitationId)">
              <div class="zntItemTitle">{{item.invitationTitle}}</div>
              <div class="zntItemName">
                <img :src="item.avatarUrl" alt="">
                <span>{{item.nickName}}</span>
                <!-- <span class="tags" v-for='v in (item.manage ? item.manage.split(",").filter(v1 => v1) : [])' :key="v">{{v}}</span> -->
              </div>
              <div class="zntItemDesc">
                {{item.subTitle}}
              </div>
              <div class="zntItemImage">
                <img v-for='v in (item.invitationImage ? item.invitationImage.split(",").filter(v1 => v1) : [])' :key="v" :src="v" alt="">
              </div>
              <div class="zntItemTime">
                <div>{{item.issueTime}}</div>
                <div class="edit">
                  <!-- <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/dz.png" alt="">
                  <div style="margin-right: 10px; margin-left: 5px">{{item.thumbsUp || 0}}</div> -->
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/fwl.png" alt="">
                  <div style="margin-right: 10px; margin-left: 5px">{{item.visitsUp || 0}}</div>
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/pl.png" alt="">
                  <div style="margin-right: 10px; margin-left: 5px">{{item.commentsUp || 0}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="zntQues" v-if="zntItemSelect === 'zntQues'">
          <div class="search-bar">
            <div class="input-bar" @click="showPicker">
              {{ quesType }}
              <van-icon name="arrow-down" size="14px" />
            </div>
            <div class="input-bar" @click="showOkPicker">
              {{ quesOk }}
              <van-icon name="arrow-down" size="14px" />
            </div>
          </div>
          <div class="zntList">
            <div class="zntItem" v-for="(item, i) in list" :key="i" @click="gotoLink('/zntQuesDetail?key=' + item.invitationId)">
              <div class="zntItemIconName">
                <div class="image">
                  <img :src="item.avatarUrl" alt="">
                </div>
                <div class="text">
                  <div style="color: rgba(0,0,0,0.85)">{{item.nickName}}</div>
                  <div>{{item.issueTime}}</div>
                </div>
              </div>
              <div class="zntItemTitle">{{item.invitationTitle}}</div>
              <div class="zntItemDesc">
                {{item.subTitle}}
              </div>
              <div class="zntItemImage">
                <img v-for='v in (item.invitationImage ? item.invitationImage.split(",").filter(v1 => v1) : [])' :key="v" :src="v" alt="">
              </div>
              <div class="zntItemTime">
                <div style="width: 100%"><span class="tags">{{item.classChildrenName}}</span></div>
                <div class="edit">
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/fwl.png" alt="">
                  <div style="margin-right: 10px; margin-left: 5px">{{item.visitsUp}}</div>
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/pl.png" alt="">
                  <div style="margin-right: 10px; margin-left: 5px">{{item.commentsUp}}</div>
                </div>
              </div>
              <div class="resultType" v-if="item.hasAnswer === '已解答'">{{item.hasAnswer}}</div>
            </div>
          </div>
        </div>
        <div class="zntQues" v-if="zntItemSelect === 'zntAll'">
          <div class="zntBanner">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/llyjr_banner.png" alt="">
          </div>
          <div style="height: 30px; line-height: 30px; padding-left: 10px; font-size: 13px">
            共有
            <span style="color: #26a2ff">{{groupList.length || 0}}</span>
            个智囊团
          </div>
          <div class="zntList" style="height: calc(100% - 150px)">
            <div class="zntItem" v-for="(item, i) in groupList" :key="i">
              <div class="zntItemIconName" @click="gotoLink('/zntDetail?key=' + item.id)">
                <div class="image">
                  <img :src="item.headImage" alt="">
                </div>
                <div class="text">
                  <div style="color: #000; font-size: 16px;font-weight: bold">{{item.title}}</div>
                  <div>
                    人数：
                    <span style="color: #26a2ff; margin-right: 10px;">{{item.number}}</span>
                    回答：
                    <span style="color: #26a2ff; margin-right: 10px;">{{item.answerNum}}</span>
                  </div>
                </div>
              </div>
              <div v-html="item.content" class="zntItemDesc">
                <!-- {{item.content}} -->
              </div>
              <div class="userIconList">
                <div class="iconItem" v-for="(v, k) in (item.users.slice(0,7) || [])" :key="k">
                  <div class="topIcon"><img :src="v ? v.image : ''" alt=""></div>
                  <div class="bottomIcon">{{v ? v.nickname : ''}}</div>
                </div>
              </div>
              <div @click="joinZnt(item)" class="editBtn" :style="`background: ${item.status === '申请中' ? 'rgba(0,0,0,0.5)' : item.status === '已参加' ? '#f00' : '#26a2ff'}`">{{item.status === '申请中' ? '申请中' : item.status === '已参加' ? '取消加入' : '我要加入'}}</div>
            </div>
          </div>
        </div>
        <div class="zntQues" v-if="zntItemSelect === 'zntUser'">
          <mt-navbar v-model="zntUserTab">
            <mt-tab-item id="myQuestions">我的提问</mt-tab-item>
            <mt-tab-item id="myAnswers">我的回答</mt-tab-item>
          </mt-navbar>
          <div class="zntList" v-if="zntUserTab === 'myQuestions'">
            <div class="zntItem" v-for="(item, i) in (quesList)" :key="i" @click="gotoLink(`/zntQuesDetail?key=${item.invitationId}`)">
              <div class="zntItemTitle">{{item.invitationTitle}}</div>
              <div class="zntItemTime">
                <div class="text">
                  <div>提问时间：{{item.issueTime}}</div>
                </div>
              </div>
              <div v-html="item.invitationContent" class="zntItemDesc">
                <!-- {{}} -->
              </div>
              <div class="zntItemTime">
                <div style="width: 100%"><span class="tags">{{item.classChildrenName}}</span></div>
                <div class="edit">
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/fwl.png" alt="">
                  <div style="margin-right: 10px; margin-left: 5px">{{item.visitsUp}}</div>
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/znt/pl.png" alt="">
                  <div style="margin-right: 10px; margin-left: 5px">{{item.commentsUp}}</div>
                </div>
              </div>
              <div class="resultType" v-if="item.hasAnswer === '已解答'">{{item.hasAnswer}}</div>
            </div>
          </div>
          <div class="zntList" v-if="zntUserTab === 'myAnswers'">
            <div class="zntItem" v-for="(item, i) in (answerList)" :key="i" @click="gotoLink('/zntQuesDetail?key=' + item.invitationId)">
              <div class="zntItemTitle">{{item.invitationTitle}}</div>
              <div class="zntItemTime">
                <div class="text">
                  <div>回答时间：{{item.createTime}}</div>
                </div>
              </div>
              <div v-html="item.commentContent" class="zntItemDesc">
                <!-- {{item.commentContent}} -->
              </div>
              <div class="resultType" v-if="item.specialComment === '最佳回答'">{{item.specialComment}}</div>
            </div>
          </div>
        </div>
      </div>
      <footer class="zntFooter">
        <ul>
          <li :class="zntItemSelect === 'zntQues' ? 'zntQuesS selected' : 'zntQues'" @click="changeChoice('zntQues')">
            <a><i></i>
              <p>问答</p>
            </a>
          </li>
          <li :class="zntItemSelect === 'zntDb' ? 'zntDbS selected' : 'zntDb'" @click="changeChoice('zntDb')">
            <a><i></i>
              <p>智库</p>
            </a>
          </li>
          <li @click="handleAddZnt" class="zntAdd">
            <a><i></i></a>
          </li>
          <li :class="zntItemSelect === 'zntAll' ? 'zntAllS selected' : 'zntAll'" @click="changeChoice('zntAll')">
            <a><i></i>
              <p>智囊团</p>
            </a>
          </li>
          <li :class="zntItemSelect === 'zntUser' ? 'zntUserS selected' : 'zntUser'" @click="changeChoice('zntUser')">
            <a><i></i>
              <p>我的</p>
            </a>
          </li>
        </ul>
      </footer>
    </div>
    <van-popup v-model="showArea" position="bottom">
      <van-picker
        title="选择类型"
        show-toolbar
        :columns="quesTypeList"
        value-key= "name"
        @confirm="areaConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
    <van-popup v-model="showOkArea" position="bottom">
      <van-picker
        title="选择类型"
        show-toolbar
        :columns="quesOkList"
        value-key= "name"
        @confirm="areaOkConfirm"
        @cancel="showOkArea = false"
      />
    </van-popup>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import { mapMutations, mapState } from "vuex";
import { List, Dialog, Toast } from "vant";

export default {
  mounted() {
    // console.log('selectItem', this.zntItemSelect, this.zntDbSelected);
    this.onLoad();
  },
  data() {
    return {
      page: 0,
      size: 20,
      total: 0,
      list: [],
      groupList: [],
      finished: false,
      showArea: false,
      showOkArea: false,
      quesType: '全部类型',
      quesOk: '全部',
      dbTypeList: [],
      quesTypeList: [
        {id: "", name: "全部类型"},
        {id: "生活类", name: "生活类"},
        {id: "健康类", name: "健康类"},
        {id: "教育类", name: "教育类"},
      ],
      quesOkList: [
        {id: "", name: "全部"},
        {id: "已解答", name: "已解答"},
        {id: "未解答", name: "未解答"},
      ],
      zntDblist: [],
      zntUserTab: 'myQuestions',
      quesList: [],
      answerList: [],
      zntUserlist: [],
    };
  },
  computed: {
    zntDbSelected: {
      get() {
        return this.$store.state.znt.zntDbSelected;
      },
      set(value) {
        this.setZntDbSelected(value);
      }
    },
    zntItemSelect: {
      get() {
        return this.$store.state.znt.zntItemSelect;
      },
      set(value) {
        this.setZntItemSelect(value);
      }
    }
  },
  watch: {
   
  },
  methods: {
    ...mapMutations('znt', ["setZntDbSelected", "setZntItemSelect"]),
    handleTabSelect(name, title) {
      this.$store.commit('znt/setZntDbSelected', name);
      this.page = 1;
      this.getList({ className: '智库', classChildrenName: name});
    },
    getList({ page = this.page, size = this.size, ...obj } = {}) {
      llyjrApi
        .getPeopleSharePage({
          page,
          size,
          object: obj,
        })
        .then(res => {
          const { 问答, 问答结果数, 智库结果, 智库结果数 } = res.data || {};
          let arr = [];
          let count = 0;
          if (this.zntItemSelect === 'zntDb') {
            arr = 智库结果;
            count = 智库结果数;
          }
          if (this.zntItemSelect === 'zntQues') {
            arr = 问答;
            count = 问答结果数;
          }
          this.list = page === 1 ? arr : this.list.concat(arr);
          this.total = count;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    getGroupList({ page = this.page, size = this.size } = {}){
      llyjrApi
        .getGroupList({
          page,
          size,
        })
        .then(res => {
          const { records = [], total = 0 } = res.data || {};
          this.groupList = page === 1 ? records : this.groupList.concat(records);
          this.total = total;
          if (this.groupList.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    getMyCenterList({ page = this.page, size = this.size } = {}){
      llyjrApi
        .getMyCenterList()
        .then(res => {
          const { 我的提问 = [], 我的提问结果数 = 0, 我的回答 = [], 我的回答结果数 = 0 } = res.data || {};
          let arr = [];
          let count = 0;
          this.quesList = 我的提问;
          this.answerList = 我的回答;
        });
    },
    onLoad() {
      this.page += 1;
      llyjrApi
        .getClassType()
        .then(res => {
          const { 问答 = [], 智库 = [] } = res.data || {};
          this.dbTypeList = 智库;
          this.quesTypeList = ([{ id: '', name: '全部类型'}]).concat(问答.map(v => ({ id: v, name: v })));
          this.zntDbSelected = 智库.length ? 智库[0] : '';
          this.getList({ className: this.zntItemSelect === 'zntDb' ? '智库' : '问答', classChildrenName: this.zntItemSelect === 'zntDb' ? this.zntDbSelected : null});
        });
      if (this.zntItemSelect === 'zntDb') {
        this.getList({ className: '智库', classChildrenName: this.zntDbSelected});
      }
      if (this.zntItemSelect === 'zntQues') {
        this.getList({ className: '问答', classChildrenName: this.quesType === '全部类型' ? null : this.quesType, hasAnswer: this.quesOk === '全部' ? null : this.quesOk });
      }
      if(this.zntItemSelect === 'zntAll') {
        this.getGroupList();
      }
      if(this.zntItemSelect === 'zntUser') {
        this.getMyCenterList();
      }
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待");
    },
    handleQuestion() {
      this.$store.dispatch('login/checkRealName', () => {
        this.gotoLink('askQuestion');
      })
    },
    handleAddZnt() {
      this.$router.push('askQuestion');
    },
    changeChoice(type) {
      this.setZntItemSelect(type);
      this.page = 1;
      if (type === 'zntDb') {
        this.getList({ className: '智库', classChildrenName: this.zntDbSelected});
      }
      if (type === 'zntQues') {
        this.getList({ className: '问答', classChildrenName: this.quesType === '全部类型' ? null : this.quesType, hasAnswer: this.quesOk === '全部' ? null : this.quesOk });
      }
      if(type === 'zntAll') {
        this.getGroupList();
      }
      if(type === 'zntUser') {
        this.getMyCenterList();
      }
    },
    showPicker() {
      this.showArea = true;
    },
    showOkPicker() {
      this.showOkArea = true;
    },
    areaConfirm(value, index) {
      this.quesType = value.name;
      this.showArea = false;
      this.page = 1;
      this.getList({ className: '问答', classChildrenName: value.id, hasAnswer: this.quesOk === '全部' ? null : this.quesOk });
    },
    areaOkConfirm(value, index) {
      this.quesOk = value.name;
      this.showOkArea = false;
      this.page = 1;
      this.getList({ className: '问答', classChildrenName: this.quesType === '全部类型' ? null : this.quesType, hasAnswer: value.id });
    },
    joinZnt(record){
      if(record.status === '已参加') {
        Dialog.confirm({
          title: "取消提示",
          message: "确定要退出智囊团吗？",
        }).then(() => {
          llyjrApi
          .deleteGroup({ groupId: record.id })
          .then(res => {
            console.log(res);
            const { flag } = res || {};
            if (flag) {
              Toast('取消加入成功！')
              this.getGroupList();
            }
          });
        })
        .catch(() => {
           Toast('取消加入失败')
        });
      } else if(record.status !== '已参加' || record.status !== '申请中') {
        console.log(11111)
        this.$router.push('joinZnt?key=' + record.id);
      }
    },
  }
};
</script>

<style lang="scss" scoped>

  .zntContent{
    width:  100%;
    height: 100vh;
    padding-top: 2.7rem;
    padding-bottom: 2.8rem;
    .mint-navbar {
      background-color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-align: center;
      border: 0px solid #fff !important;
      .mint-tab-item.is-selected {
        border-bottom: 3px solid #26a2ff;
        color: #26a2ff;
        margin-bottom: -3px;
        background: #fff !important;
      }
    }
    .zntDb, .zntQues{
      height: 100%;
    }
    .zntList{
      height: calc(100% - 56px);
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      background: #f7f7f7;
      margin-top: 2px;
      .zntItem{
        padding: 10px;
        margin-bottom: 10px;
        background: #fff;
        position: relative;
        .zntItemTitle{
          line-height: 30px;
          color: #000;
          font-size: 17px;
          font-weight: bold;
        }
        .zntItemName{
          line-height: 24px;
          color: #000;
          font-size: 12px;
          display: flex;
          img{
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
        .zntItemIconName{
          display: flex;
          height: 50px;
          padding: 5px;
          .image{
            width: 40px;
            height: 40px;
            margin-right: 10px;
            >img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .text{
            width: calc(100% - 50px);
            height: 100%;
            line-height: 20px;
            color: #707070;
          }
        }
        .zntItemDesc{
          line-height: 24px;
          font-size: 14px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          color: rgba(0,0,0,0.85);
        }
        .zntItemImage{
          display: flex;
          img{
            width: 60px;
            height: 60px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        .zntItemTime{
          line-height: 24px;
          font-size: 12px;
          color: #707070;
          display: flex;
          .edit{
            margin-left: auto;
            margin-right: 10px;
            display: flex;
            >div{
              line-height: 20px;
            }
            img{
              width: 12px;
              height: 12px;
              margin-top: 3.5px;
            }
          }
        }
        .userIconList{
          display: flex;
          flex-wrap: wrap;
          height: 70px;
          overflow-y: hidden;
          .iconItem{
            margin-right: 10px;
            margin-top: 10px;
            width: 50px;
            text-align: center;
            .topIcon{
              width: 50px;
              height: 30px;
              padding: 0 10px;
              >img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .bottomIcon{
              line-height: 20px;
              font-size: 12px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .tags{
          margin-left: 5%;
          background: rgba(0,0,0,0.15);
          border-radius: 5px;
          color: #000;
          padding: 0px 5px;
        }
        .resultType{
          position: absolute;
          right: -10px;
          top: 15px;
          height: 24px;
          line-height: 24px;
          padding: 0px 10px;
          padding-right: 15px;
          text-align: left;
          color: #fff;
          background: #26a2ff;
          border-radius: 12px;
        }
        .editBtn{
          position: absolute;
          right: 10px;
          top: 15px;
          height: 24px;
          line-height: 24px;
          padding: 0px 10px;
          text-align: left;
          color: #fff;
          background: #26a2ff;
          border-radius: 12px;
        }
      }
    }
    .zntBanner{
      width: 100%;
      height: 120px;
      padding: 10px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .search-bar {
      display: flex;
      align-items: center;
      padding: 5px;
      background: #fff;
      margin-bottom: 10px;
      .input-bar {
        margin-right: 10px;
        min-width: 7rem;
        background: #f7f8fa;
        border-radius: 18px;
        padding: 5px 12px;
        line-height: 24px;
        height: 34px;
        font-size: 14px;
        text-align: left;
      
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          height: 1rem;
          width: 1rem;
        }
      }
      .normal-bar {
        height: 2rem;
        line-height: 2rem;
        margin: 0.2rem 0.8rem;
        font-size: 14px;
      }
    }
  }
  .zntFooter {
    height: 3.2rem;
    border-top: 2px solid #f1f2f5;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0.4rem;
    ul {
      display: flex;
      justify-content: space-around;
      li {
        flex: 1;
        text-align: center;
        i {
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
        }
        p {
          font: 500 0.6rem/1rem "microsoft yahei";
          color: #cdcdcd;
        }
      }
      .zntAdd i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/znt/zntAdd.png) no-repeat;
        background-size: 4.5rem 4.5rem;
        margin-top: -2.5rem;
        margin-bottom: -0.7rem;
        width: 4.5rem;
        height: 4.5rem;
      }
      .zntDb i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/znt/zntDb.png) no-repeat;
        background-size: 100% 100%;
      }
      .zntDbS i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/znt/zntDb_blue.png) no-repeat;
        background-size: 100% 100%;
      }
      .zntDbS p {
        color: #2a70ed;
      }
      .zntQues i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/znt/zntQues.png) no-repeat;
        background-size: 100% 100%;
      }
      .zntQuesS i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/znt/zntQues_blue.png) no-repeat;
        background-size: 100% 100%;
      }
      .zntQuesS p {
        color: #2a70ed;
      }
      .selected p {
        color: #2a70ed;
      }
      .selected i {
        color: #2a70ed;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
      .zntAll i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/znt/zntAll.png) no-repeat;
        background-size: 100% 100%;
      }
      .zntAllS i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/znt/zntAll_blue.png) no-repeat;
        background-size: 100% 100%;
      }
      .zntAllS p {
        color: #2a70ed;
      }
      .selected p {
        color: #2a70ed;
      }
      .zntUser i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/znt/zntUser.png) no-repeat;
        background-size: 100% 100%;
      }
      .zntUserS i {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/znt/zntUser_blue.png) no-repeat;
        background-size: 100% 100%;
      }
      .zntUserS p {
        color: #2a70ed;
      }
    }
    
  }
</style>
