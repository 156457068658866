<template>
  <div class="axstDetail" style="height: 100vh; padding-top: 2.7rem">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>菜单</h1>
    </div>
    <van-notice-bar color="#FF4535" background="#FFEFEE" left-icon="info-o">
      <template #left-icon>
        <img src="../../assets/images/axst/tongzhi.svg" alt="">
      </template>
      三素10元，一荤2素13元，一荤3素15元，2荤2素20元，欢迎预定。
    </van-notice-bar>
    <div class="type-content">
      <van-sidebar
        style="width: 6rem;" 
        @change="handleSide"
        v-model="activeKey"
      >
        <van-sidebar-item style="width: 6rem;" v-for="(item) in sideBar" :key="item" :title="item" />
      </van-sidebar>
      <div class="side-content">
        <div v-for="(item, i) in getShowList(imageList || [])" class="item" :key="i">
            <div class="image"><img :src="item.image" alt=""></div>
            <div class="text">
              <div class="title">{{item.name}}</div>
              <!-- <div class="btnGroup">
                <img src="../../assets/images/axst/lessen.png" alt="" @click="addNum(item, -1)">
                <div>{{item.count}}</div>
                <img src="../../assets/images/axst/add.png" alt="" @click="addNum(item, 1)">
              </div> -->
            </div>
        </div>
      </div>
    </div>
    <div class="gwc-content" @click="showModalContent">
      <!-- <van-badge :content="count">
        <img src="../../assets/images/axst/gwc.png" alt="">
      </van-badge>
      <div class="momeny" v-if="hunCount || suCount">{{`已选${hunCount}荤${suCount}素`}}</div>
      <div class="momeny">¥{{countMoney}}</div>
      <div class="editBtn" @click="payMoney($event)">去支付</div> -->
      <div class="editBtn" @click="payMoney($event)">去预定</div>
    </div>
    <van-popup v-model="showModal" position="bottom">
      <div class="gwcContent">
        <div class="title">
          <div>已选商品</div>
          <div class="clear" @click="clearCount">清空</div>
        </div>
        <div class="gwcList">
          <div v-for="(item, i) in (buyList || []).filter(v => v.count)" class="item" :key="i">
            <div class="image">
              <img :src="item.image" alt="">
            </div>
            <div class="text">
              <div class="name">{{item.name}}</div>
              <div class="btnGroup">
                <!-- <div class="momeny">{{item.momeny || 0}}元/个</div> -->
                <img src="../../assets/images/axst/lessen.png" alt="" @click="addNum(item, -1)">
                <div>{{item.count}}</div>
                <img src="../../assets/images/axst/add.png" alt="" @click="addNum(item, 1)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import service from 'utils/service';
import lodash from 'lodash';
import { mapMutations } from 'vuex'
import axstData from 'assets/json/axst';
import moment from 'moment';
import axstApi from 'api/axst';
export default {
  data() {
    return {
      count: 0,
      hunCount: 0, // 荤菜数量
      suCount: 0, // 素菜数量
      countMoney: 0,
      zhuCount: 0,
      showModal: false,
      activeKey: 0,
      activeItem: 0,
      sideBar: [],
      morningType: ['素菜','主食'],
      lunchType: ['荤菜','素菜','主食',],
      dinnerType: ['荤菜','素菜','主食',],
      titleName: '',
      imageList: [],
      buyList: [],
      costArr: [
        { cost: 10, su: 3, hun: 0 },
        { cost: 13, su: 2, hun: 1 },
        { cost: 15, su: 3, hun: 1 },
        { cost: 20, su: 2, hun: 2 }
      ]
    };
  },
  components: {},
  mounted(opt) {
    const { key, momey, name } = this.$route.query;
    this.titleName = key;
    this.momey = momey;
    this.meet = name;
    this.sideBar = this.dinnerType;
    if(key === '早餐') {
      this.sideBar = this.morningType;
      this.imageList = axstData['早餐'][this.sideBar[this.activeKey]];
    }
    if(key === '午餐') {
      this.sideBar = this.lunchType;
      this.imageList = axstData['午餐'][this.sideBar[this.activeKey]];
    }
    if(key === '晚餐') {
      this.sideBar = this.dinnerType;
      this.imageList = axstData['晚餐'][this.sideBar[this.activeKey]];
    }

  },
  methods: {
    ...mapMutations(["setLuckyActiveItem"]),
    goBack() {
      this.$router.go(-1);
    },
    getShowList(list){
      const arr = [];
      list.forEach(element => {
        const index = lodash.findIndex(this.buyList, { name: element.name });
        if (index > -1) {
          const active = this.buyList.filter(v => v.name === element.name)[0];
          arr.push(active);
        } else {
          arr.push(element);
        }
      });
      return arr;
    },
    handleSide(key){
      this.activeType = key;
      const name = this.sideBar[key];
      this.imageList = axstData[this.titleName][this.sideBar[this.activeKey]];
      console.log(name);
    },
    addNum(item, num){
      const index = lodash.findIndex(this.buyList, { name: item.name });
      if (index > -1) {
        const active = this.buyList.filter(v => v.name === item.name)[0];
        const index = lodash.findIndex(this.buyList, { name: item.name });
        active.count = item.count + num >= 0 ? item.count + num : 0;
        const list = [...this.buyList];
        list.splice(index, 1, active);
        this.buyList = list;
      } else {
        const arr = lodash.cloneDeep(this.imageList);
        const active = arr.filter(v => v.name === item.name)[0];
        active.count = item.count + num >= 0 ? item.count + num : 0;
        this.buyList = [...this.buyList, active];
      }
      this.buyList = this.buyList.filter(v => v.count > 0);
      console.log(this.buyList, index);
      this.count = 0;
      this.countMoney = 0;
      this.hunCount = 0;
      this.suCount = 0;
      this.zhuCount = 0;
      this.buyList.forEach(element => {
        this.count += element.count;
        this.hunCount += element.type === '荤菜' ? element.count : 0;
        this.suCount += element.type === '素菜' ? element.count : 0;
        this.zhuCount += element.name === '白米饭' ? element.count : 0;
        // if (element.count) {
        //   this.countMoney += element.momeny * element.count;
        // }
      });
      const ele = this.costArr.find(item => item.hun === this.hunCount && item.su === this.suCount)
      if(ele) {
        this.countMoney = ele.cost + this.zhuCount * 2;
      }
    },
    showModalContent(){
      this.showModal = true;
    },
    payMoney(e){
      e.stopPropagation();
      // if (!this.countMoney) {
      //   Toast.fail("请按组合选择要购买的商品");
      // } else if(!this.zhuCount) {
      //   this.$dialog.confirm({
      //   title: '提示',
      //   message: '您尚未挑选主食，确认提交？',
      // })
      //   .then(() => {
      //    const list = this.buyList.filter(v => v.count);
      //     this.$store.commit('allOther/setBuyList', list);
      //     this.$router.push(`/payList?type=${this.titleName}`)
      //   })
      //   .catch(() => {
          
      //   });
      // } else {
      //   const list = this.buyList.filter(v => v.count);
      //   this.$store.commit('allOther/setBuyList', list);
      //   this.$router.push(`/payList?type=${this.titleName}`)
      // }
      axstApi.insertOrder({
        discountMoney: 0,
        orderMoney: this.momey,
        orderNum: `${moment().valueOf()}`.substring(`${moment().valueOf()}`.length - 8),
        orderStatus: 1,
        payMoney: this.momey,
        setMeal: this.meet,
      }).then(res => {
        if (res.flag) {
          Toast.success('预约成功');
          const time = moment().format('YYYY-MM-DD HH:mm');
          this.$router.push(`/orderList?type=${this.titleName}&money=${this.momey}&meet=${this.meet}&time=${time}`)
        } else {
          this.info = {};
        }
      })
      // const startTime = moment(moment().format('YYYY-MM-DD 11:00:00')).valueOf();
      // const endTime = moment(moment().format('YYYY-MM-DD 13:00:00')).valueOf();
      // const nowTime = moment(moment().format('YYYY-MM-DD HH:mm:ss')).valueOf();
      // if (nowTime <= endTime && nowTime >= startTime) {
      //   axstApi.insertOrder({
      //     discountMoney: 0,
      //     orderMoney: this.momey,
      //     orderNum: `${moment().valueOf()}`.substring(`${moment().valueOf()}`.length - 8),
      //     orderStatus: 1,
      //     payMoney: this.momey,
      //     setMeal: this.meet,
      //   }).then(res => {
      //     if (res.flag) {
      //       Toast.success('预约成功');
      //       const time = moment().format('YYYY-MM-DD HH:mm');
      //       this.$router.push(`/orderList?type=${this.titleName}&money=${this.momey}&meet=${this.meet}&time=${time}`)
      //     } else {
      //       this.info = {};
      //     }
      //   })
      // } else {
      //   Dialog.confirm({
      //     title: '提示',
      //     message: '未到预定时间，请在11:00-13:00内预订！',
      //   })
      // }
    },
    clearCount(){
      Dialog.confirm({
        title: '提示',
        message: '确定要清空购物车？',
        'confirm-button-text': '取消',
        'confirm-button-color': '#2e70ed',
        'cancel-button-color': '#eeeeee'
      })
      .then(() => {
        this.buyList = [];
        this.count = 0;
        this.countMoney = 0;
        this.showModal = false;
      })
    }
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

  .axstDetail{
    height: 100% !important;
    position: relative;
    .type-content {
      height: calc(100vh - 2.7rem - 40px);
      width: 100vw;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      .van-sidebar-item--select::before {
        background-color: #2e70ed;
      }
      .side-content {
        overflow: scroll;
        background: #FFFFFF;
        flex: 1;
        padding: 10px;
        padding-bottom: 50px;
        .item{
          margin-bottom: 10px;
          display: flex;
          height: 80px;
          .image{
            width: 120px;
            height: 80px;
            >img{
              width: 100%;
              height: 100%;
            }
          }
          .text{
            width: calc(100% - 130px);
            margin-left: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .title{
              line-height: 30px;
              font-size: 16px;
              color: #000000;
              font-weight: 500;
            }
            .btnGroup{
              display: flex;
              line-height: 30px;
              margin-left: auto;
              margin-right: 10px;
              .momeny{
                font-size: 16px;
                color: #FF6C2B;
                margin-right: 10px;
                min-width: 60px;
              }
              >img{
                width: 16px;
                height: 16px;
                margin: 7px;
              }
            }
          }
        }
      }
    }
    .gwc-content{
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0px;
      height: 50px;
      text-align: center;
      display: flex;
      line-height: 50px;
      background: #ecf1fe;
      img{
        width: 30px;
        height: 30px;
        margin: 10px;
      }
      .momeny{
        font-size: 16px;
        color: #FF6C2B;
        margin-left: 20px;
      }
      .editBtn{
        // margin-left: auto;
        // margin-right: 10px;
        text-align: center;
        width: 100%;
        font-size: 16px;
        color: #3E71F9;
      }
    }
  }
  .gwcContent{
    padding: 10px;
    max-height: 300px;
    overflow: scroll;
    .title{
      font-size: 16px;
      color: #000000;
      line-height: 30px;
      display: flex;
      .clear{
        margin-left: auto;
        margin-right: 10px;
        font-size: 16px;
        color: #FF4535;
      }
    }
    .gwcList{
      .item{
        margin-bottom: 10px;
        display: flex;
        height: 50px;
        line-height: 50px;
        .image{
          width: 40px;
          height: 40px;
          margin: 5px;
          >img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          display: flex;
          width: calc(100%);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          border-bottom: 1px solid rgba(0,0,0,0.1);
          .name{
            font-size: 16px;
            color: #000000;
            font-weight: 500;
          }
          .btnGroup{
            display: flex;
            margin-left: auto;
            margin-right: 10px;
            .momeny{
              font-size: 16px;
              color: #FF6C2B;
              margin-right: 10px;
            }
            >img{
              width: 16px;
              height: 16px;
              margin: 17px 5px;
            }
          }
        }
      }
    }
  }
</style>
