<template>
  <div id="index">
    <div class="edu-container">
      <header class="eduheader-nav">
        <i @click="goBack()"></i>
        <h1>我在上课</h1>
      </header>
      <div class="m-top">
        <div class="classlist">
          <div class="box">
            <h3>二宝</h3>
            <div class="info clearfix">
              <ul>
                <li class="bg1" @click="gotoLink('classDetail')">
                  <div><b>儿童涂鸦</b>最近课程：2021-06-07 14:30</div>
                  <i class="el-icon-arrow-right"></i>
                </li>
                <li class="bg2" @click="gotoLink('classDetail')">
                  <div><b>编程积木</b>最近课程：2021-06-07 14:30</div>
                  <i class="el-icon-arrow-right"></i>
                </li>
                <li class="bg3" @click="gotoLink('classDetail')">
                  <div><b>少儿游泳</b>最近课程：2021-06-07 14:30</div>
                  <i class="el-icon-arrow-right"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="box">
            <h3>大宝</h3>
            <div class="info clearfix">
              <ul>
                <li class="bg3" @click="gotoLink('classDetail')">
                  <div><b>一年级数学</b>最近课程：2021-06-07 14:30</div>
                  <i class="el-icon-arrow-right"></i>
                </li>
                <li class="bg1" @click="gotoLink('classDetail')">
                  <div><b>一年级语文</b>最近课程：2021-06-07 14:30</div>
                  <i class="el-icon-arrow-right"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="comment">
          <div class="main-tab">
            <b><i>精彩</i>瞬间</b>
            <span>更多 &gt;</span>
          </div>
          <div class="bd">
            <ul>
              <li>
                <div class="desc">
                  通过体验不同表情传达的不同意思来发现与观察五官的动态和位置，以卡通人物的形象来体现孩子的童真有趣，同时进一步巩固并提高绘画的线描水准
                </div>
                <p>表情练习 2021-6-2 15:23</p>
                <div class="img">
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/class2.jpg" />
                </div>
              </li>
              <li>
                <div class="desc">
                  主要了解钻石切割面的工艺，复习水油分离的绘画方式，观察挖土机与吊机的外形并进行描绘，孩子的作品如下，3D效果加上串灯的装饰特别漂亮
                </div>
                <p>钻石矿场 2021-6-2 15:23</p>
                <div class="img">
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/class1.jpg" />
                </div>
              </li>
              <li>
                <div class="desc">
                  主要以造型设计加想象；色彩搭配和运用为主，作品效果呈现出强烈的艺术感
                </div>
                <p>夸张的头 2021-6-2 15:23</p>
                <div class="img">
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/class0.jpg" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/educate.scss";
</style>
