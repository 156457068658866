<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>订单确认</h1>
      </div>
      <van-cell v-if="detailInfo.position === '实物类'" is-link center @click="gotoLink('addressList')"	>
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <span class="custom-title">{{`${address.name} ${address.phone}`}}</span>
        </template>
        <template #label>
          <span class="label-text">{{address.address}}</span>
        </template>
        <template #icon>
          <span class="shou-icon">收</span>
        </template>
      </van-cell>
      <div class="dhsure-divider"></div>
      <van-card
        :num="count"
        :thumb="detailInfo.commodityImage"
        style="background-color: #FFF;margin-top: 0;margin-bottom: 12px;"
      >
       <template #title>
          <p class="title-text">{{detailInfo.commodityName}}</p>
        </template>
        <template #tags>
          <span class="tag">{{detailInfo.commodityTypeName}}</span>
          <!-- <span class="tag">{{detailInfo.position}}</span> -->
        </template>
        <template #price>
          <p class="orange label-text">{{`${detailInfo.commodityNeedIntegral}积分`}}</p>
        </template>
      </van-card>
    </div>
    <div class="footer">
      <div class="foot-left">
        <span>合计：</span>
        <span>{{detailInfo.commodityNeedIntegral * count}}</span>
        <span>积分</span>
      </div>
      <div class="foot-button"
           @click="exchange(commodityId, count)">
        确定兑换
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import jfdhApi from 'api/ddya/jfdh';
import {jfdhShoping} from 'assets/json/jfdh';
export default {
  data () {
    return {
      list: [],
      count: this.$route.query.count,
      detailInfo: {},
      address: {
        name: '华先生',
        phone: '18888888888',
        address: '浙江省 杭州市 西湖区 双浦镇 云栖小镇六和金座9幢1单元901室',
      },
      commodityId: '',
    };
  },
  components: {},
  mounted () {
    const { id } = this.$route.query;
    this.commodityId = id;
    this.getDetailInfo({ commodityId: id });
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },

    getImage (key) {
      return key
    },
    getDetailInfo({ commodityId = this.commodityId }) {
      jfdhApi.getGoodDetail({ commodityId }).then((res) => {
        this.detailInfo = res.data || {}
      })
    },
    exchange () {
      if(this.count < 1) {
        Toast('兑换数量不能小于1！');
        return;
      }
      jfdhApi.createOrder({
        orderCommodityId: this.commodityId,
        orderExchangeNum: this.count
      }).then(res => {
        if (res.flag) {
          this.$router.replace(`dhResult?name=${this.detailInfo.commodityName}&count=${this.count}`)
        }
      })
      
      
    }
  },
};
</script>

<style lang="scss" scoped>
@import "assets/scss/common.scss";
.page-container {
  background: #f6f7fa;
  padding: 0.6rem 0 1rem 0;
  height: calc(100vh - 6rem);
  width: 100vw;
  margin-top: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  .tag {
    background: #f6f7fa;
    padding: 2px 6px;
    border-radius: 2px;
    margin-right: 5px;
  }
  .dhsure-divider {
    width: 100%;
    height: 12px;
    background: #f6f7fa;
  }
}
.shou-icon {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background: #4379ed;
  color: #FFF;
  text-align: center;
  line-height: 36px;
  margin-right: 6px;
}
.footer {
  position: fixed;
  height: 5rem;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: space-around;
  .foot-left {
    font-size: 14px;
    span:nth-child(2) {
      font-size: 24px;
      color: #e5b20a;
      font-weight: 500;
    }
    span:nth-child(3) {
      color: #e5b20a;
    }
  }
  .foot-button {
    position: unset;
    width: 14rem;
    height: 3rem;
    background: #4479ed;
    border-radius: 4px;
    line-height: 3rem;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
  }
}
</style>