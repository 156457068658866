import { render, staticRenderFns } from "./policyDepart.vue?vue&type=template&id=77fa14ac&scoped=true&"
import script from "./policyDepart.vue?vue&type=script&lang=js&"
export * from "./policyDepart.vue?vue&type=script&lang=js&"
import style0 from "./policyDepart.vue?vue&type=style&index=0&id=77fa14ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77fa14ac",
  null
  
)

export default component.exports