<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我的车位</h1>
      </div>
      <div class="list">
        <div v-for="(item, index) in list" :key="index" class="card-con">
          <div class="tag" :style="{color: Object.values(colors)[index % 3].text, background: Object.values(colors)[index % 3].background}">
            {{Object.keys(colors)[index % 3]}}
          </div>
          <img style="width: 89px" :src="item.image || 'http://dssq.ikeqiao.net/data/image/h5/images/cwyy/cw7.png'" class="image" />
          <div class="card-content" @click="handleClick(item)">
            <div class="card-title">
              <span>车位号：{{item.parkingCode}}</span>
            </div>
            <div class="row">
              <span>小区名称：{{item.communityName}}</span>
            </div>
          </div>
          <div class="card-button" @click="handlePark(item)">
            {{item.stopSign == 0 ? '取消共享' : '重新共享'}}
          </div>
        </div> 
        <div v-if="list.length === 0" style="margin-top:40%; text-align:center; font-size: 18px">暂未分享车位</div> 
      </div>
      <!-- <ul>
        <li v-for="(item, index) in parkingData"
            :key="index + item.parkingCode"
            class="card-con">
          <img style="width: 80px; height: 80px"
               :src="item.image"
               class="image" />
          <div class="tag"
               :style="{
                  color: item.parkingStatus != null ? colors[item.parkingStatus].text : '#13BB49',
                  background: item.parkingStatus != null ? colors[item.parkingStatus].background : 'D5F4E2',
                }">
            {{ item.parkingStatus == null ? '空闲中' : item.parkingStatus }}
          </div>
          <div>
            <div class="card-title">
              <span style="font-weight:bold;">车位号：{{ item.parkingCode }}</span>
            </div>
            <div class="row">
              <div>时间：</div>
              <div>
                {{ `${item.startTime.substring(0, item.startTime.lastIndexOf(":"))}${item.overTime.substring(0, item.overTime.lastIndexOf(":"))}` }}
              </div>
            </div>
            <div class="row">
              <span>{{ '\u2002\u2002\u2002\u2002\u2002\u2002' + item.week }}</span>
            </div>
            <div class="row">
              <span>位置：{{ item.location }}</span>
            </div>
            <div class="card-button"
                 @click="gotoLink(selectItem, item)">立即预约</div>
          </div>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { mapActions } from 'vuex';
export default {
  data () {
    return {
      list: [
        // {
        //   name: 'B117',
        //   time: '春节期间',
        //   address: '润泽B117',
        //   status: '空闲中', 
        //   img: 'http://dssq.ikeqiao.net/data/image/h5/images/cwyy/cw7.png',
        // },
      ],
      colors: {
        '空闲中': { text: '#13BB49', background: '#D5F4E2' },
        '已预定': { text: '#E5B20A', background: '#FFF6D9' },
        '未开放': { text: 'rgba(0,0,0,0.450', background: '#EDEDED' },
      },
      // buttons: ['取消共享', '取消共享', '重新共享']
    };
  },
  components: {},
  mounted () {
    this.getMyParkData();
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },
    showAlert () {
      this.$messagebox("提示", "系统正在建设中，尽情期待");
    },
    getMyParkData () {
      this.$store.dispatch('llcw/getMyParkData', this);
    },
    handleClick (item) {
      this.$store.commit('llcw/setPark', item.id);
      this.gotoLink('cwxq')
    },
    handlePark (data) {
      console.log(11111, data);
      if (data.stopSign === '0') {
        this.$store.dispatch('llcw/stopShare', {
          params: { shareParkingId: data.id }, callback: () => {
            this.getMyParkData();
          }, _this: this,
        })
      } else {
        this.$store.dispatch('llcw/startShare', {
          params: { shareParkingId: data.id }, callback: () => {
            this.getMyParkData();
          }, _this: this,
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  padding: 0.6rem;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;

  .list {
    width: 100%;
    .list-title {
      border-left: 4px solid #4479ed;
      padding-left: 0.4rem;

      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0.4rem;
    }
    .card-con {
      align-items: center;
      padding: 0.4rem 0.6rem;
      position: relative;
      display: flex;
      background: #ffffff;
      margin-bottom: 0.6rem;
      .card-content {
        height: 5.6rem;
      }
      .tag {
        position: absolute;
        top: 0.6rem;
        left: 0.6rem;
        padding: 0.2rem 0.8rem;
        border-radius: 4px;
      }
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 1.8rem;
        .tag {
          background: #d5f4e2;
          border-radius: 4px 2px 8px 2px;
          font-size: 12px;
          color: #37d477;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 0.2rem 0.8rem;
          height: 1.2rem;
        }
      }
      .image {
        height: 5.6rem;
        border-radius: 2px;
        margin-right: 0.5rem;
      }
      .row {
        font-size: 13px;
        line-height: 1.4rem;
        display: flex;

        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        .label {
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 18px;
          margin-right: 0.6rem;
        }
      }
      .card-button {
        position: absolute;
        z-index: 99;
        top: 0.5rem;
        right: 0.8rem;
        width: 5rem;
        height: 2rem;
        background: #4479ed;
        color: #ffffff;
        text-align: center;
        line-height: 2rem;
        border-radius: 4px;
      }
    }
  }
}
</style>
