<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="reply-list"
  >
    <ul>
      <li
        v-for="(item, index) in dataList"
        :key="index"
        @click="gotoLink(item)"
      >
        <div class="left">
          <img :src="item.cover" alt="封面" />
        </div>
        <div class="right">
          <div class="tit">{{ item.title }}</div>
          <div class="info">{{ item.info }}</div>
          <div class="reply-info">
            <!-- <div><span>{{`${item.num}次浏览`}}</span></div> -->
          </div>
        </div>
      </li>
    </ul>
  </van-list>
</template>
<script>
import api from "api";
import { mapState } from "vuex";
import data from 'assets/json/resou';
export default {
  name: "noticeList",
  data() {
    return {
      current: 0,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    };
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === "我的发布" ? "yes" : "no";
    },
  },
  props: {
    resou: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    // this.getDataList();
  },
  methods: {
    onLoad() {
      this.current += 1;
      this.getDataList();
    },
    gotoLink(data) {
      if (data.content && data.contentType === 1) {
        // 增加浏览量
        api.addArticleVisit({ id: data.id }).then((res) => {
          
        });
        window.location.href = data.content;
      } else if (data.contentType === 0) {
        this.$router.push(`resouArticleDetail?id=${data.id}`)
      }
    },
    getDataList({ page = this.current, size = this.pageSize } = {}) {
      const { status = "", category = "" } = this;
      this.loading = false;
      api
        .getHongArticleList({
          page,
          pageSize: size,
          type: 1,
        })
        .then((res) => {
          this.loading = false;
          const { records = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.dataList = records;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(records)))
          }
          this.total = total;
          console.log('num', this.dataList.length >= this.total, this.total, this.dataList)
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
      // this.dataList = data;
      // this.total = data.length;
      // this.listFinished = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.reply-list {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
  background: #fff;
  border: none;
  li {
    padding: 0.3rem 0;
    // border-bottom: 1px solid #ddd;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    display: flex;
    .left {
      width: 140px;
      height: 86px;
      margin-right: 10px;
      img {
        width: 140px;
        height: 86px;
      }
    }
    .right {
      flex: 1;
      .tit {
        color: rgba(0,0,0,0.85);
        font-size: 14px;
      }
      .info {
        color: rgb(200, 201, 203);
        font-size: 10px;
      }
      .date {
        color: #999;
      }
      p {
        color: #7a7a7a;
      }
      .reply-info {
        margin-bottom: 0.4rem;
        position: relative;
        height: 2rem;
        img {
          width: 2rem;
          height: 2rem;
          float: left;
          border-radius: 50%;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
        div {
          float: left;
          font: 500 0.8rem/1rem "microsoft yahei";
          color: #333;
          span {
            color: rgb(200, 201, 203);
            font-size: 8px;
          }
        }
      }
    }
  }
}
</style>
