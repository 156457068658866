<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="“红小二”义工"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="hongxiaoer-body-container">
      <!-- 列表 -->
      <div class="list-con">
        <van-list
          v-model="loading"
          :finished="listFinished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <ul>
            <li v-for="(item, index) in dataList" :key="index">
              <div class="top">
                <div class="left">
                  <img :src="item.image" alt="">
                </div>
                <div class="right">
                  <div class="tit">{{item.userName}}</div>
                  <div class="icon-text">
                    <span>特长：</span>
                    {{item.specialty}}
                  </div>
                  <div class="icon-text">
                    <span>介绍：</span>
                    {{item.userIntro}}
                  </div>
                </div>
              
              </div>
              <div class="bottom">
                <span>义工事迹：</span>
                {{item.deeds}}
              </div>
              
            </li>
          </ul>
        </van-list>
      </div>
      <div class="tab-flex-bottom" @click="gotoLink('hongxiaoerJoin')">我要加入</div>
    </div>
  </div>
</template>

<script>
import Api from 'api';
import { mapMutations, mapState } from "vuex";
import data from 'assets/json/yigong.js';
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
      condition: '',
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    getImage(picture) {
      return require(picture);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    getDataList({ page = this.page, size = this.size, condition = this.condition } = {}) {
      const { status = '', category = '' } = this;
      Api.getHongXiaoErData({
        page,
        size
      }).then(res => {
        const { list = [], total = 0 } = res.data || {};
        if(this.page === 1) {
          this.dataList = list;
        } else {
          this.dataList = this.dataList.concat(list);
        }
        this.total = total;
        if (this.dataList.length >= this.total) {
          // 数据全部加载完成
          this.listFinished = true;
        }
      });
    },
    onSearch(value) {
      this.condition = value;
      this.getDataList();

    },
    onCancel() {
      this.condition = '';
      this.getDataList();
    }
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .hongxiaoer-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      width: 100%;
      height: 48px;
    }
    .list-con {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      li {
        padding: 1rem;
        margin: 10px;
        // border-bottom: 1px solid #ddd;
        font: 500 0.9rem/1.4rem "microsoft yahei";
        background: #FFF;
        border-radius: 4px;
        .top {
          display: flex;
          padding: 10px 0;
          .left {
            width: 80px;
            min-height: 80px;
            margin-right: 10px;
            img {
              width: 100%;
              // height: 100%;
            }
          }
          .right {
            flex: 1;
            .tit {
              font-weight: 500;
              color: #000000;
              font-size: 16px;
              line-height: 24px;
            }
            .introduce {
              color: rgba(0,0,0,0.45);
              font-size: 14px;
              line-height: 24px;
            }
            .icon-text {
              font-size: 14px;
              // display: flex;
              // align-items: center;
              color: rgba(0,0,0,0.85);
              line-height: 24px;
              .address {
                background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/location.png') no-repeat;
                background-size: 100% 100%;
                height: 16px;
                width: 16px;
                margin-right: 5px;
              }
              .date {
                background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/date.png') no-repeat;
                background-size: 100% 100%;
                height: 16px;
                width: 16px;
                margin-right: 5px;
              }
              >span {
                color: rgba(0,0,0,0.45);
              }
            }
          }
          
        }
        .bottom {
          border-top: 0.5px solid rgba($color: #000000, $alpha: 0.1);
          padding: 10px 0;
          >span {
            color: rgba(0,0,0,0.45);
          }
        }
      }
    }
    .tab-flex-bottom {
      position: fixed;
      bottom: 4rem;
      right: 10px;
      background: #4479ed;
      box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      color: #fff;
      padding: 0.8rem;
      font-size: 0.85rem;
      letter-spacing: 0;
      text-align: center;
      line-height: 1.2rem;
    }
  }
}
</style>
